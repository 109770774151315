import React from 'react'
import { FormattedMessage } from 'react-intl'

const handleObfuscatedLink = (link, target) => {
  window.open(atob(link), target || '_self')
}

const FooterLinkObfuscated = ({ message, link, target, title = null }) => (
  <span className='obflink' title={title} onClick={() => handleObfuscatedLink(link, target)}>
    <FormattedMessage id={message} />
  </span>
)

export default FooterLinkObfuscated
