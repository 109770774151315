import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import HistoryModalOp from '../components/HistoryModalOp'
import { locationHasParam, removeParamFromLocation } from '../helpers/application'
import { isMobile } from '../helpers/browser'
import { getMapWrapperComponent } from '../../../libs/map'

const MAP_MODAL_NAME = 'map_park'

class LocationSection extends Component {
  state = {
    initModal: false,
    mobileDevice: true
  }

  componentDidMount () {
    this.setState({ mobileDevice: isMobile() })
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleOrientationChange)

    if (locationHasParam('modal', MAP_MODAL_NAME)) {
      history.replaceState({}, '', removeParamFromLocation('modal'))
    }
  }

  handleResize = () => {
    this.setState({ mobileDevice: isMobile() })
  }

  handleOrientationChange = () => {
    // iPad does not trigger resize event after orientation change
    if (navigator.platform === 'iPad') {
      setTimeout(() => this.handleResize(), 250)
    }
  }

  handleClickOpenMap = () => {
    this.setState({ initModal: true }, () => $('#react-map-modal').modal('show'))
  }

  render () {
    const { bestOffer, type, park, parkState, nearbyParks } = this.props
    const { mobileDevice, initModal } = this.state
    const parks = [{ bestOffer, state: parkState, ...park }, ...nearbyParks]

    return (
      <section id='park-location'>
        <h2>
          <FormattedMessage id='pages.park.location.title' />
        </h2>
        {
          React.createElement(
            getMapWrapperComponent(),
            {
              defaultCenter: { lat: park.address.lat, lng: park.address.lng },
              defaultZoom: 15,
              parks,
              firstParkIsPrimary: true,
              itinaryControl: !mobileDevice,
              twoFingersMovemement: true,
              zoomControl: !mobileDevice,
              openModalControl: mobileDevice,
              isSubscription: type === 'subscription',
              onOpenInModalClick: this.handleClickOpenMap,
              lazyload: true
            }
          )
        }
        {initModal &&
          <HistoryModalOp id='react-map-modal' name={MAP_MODAL_NAME} mobileOnly fullScreen>
            {
              React.createElement(
                getMapWrapperComponent(),
                {
                  defaultCenter: { lat: park.address.lat, lng: park.address.lng },
                  defaultZoom: 15,
                  parks,
                  firstParkIsPrimary: true,
                  zoomControl: !mobileDevice,
                  itinaryControl: true,
                  isSubscription: type === 'subscription',
                  autoOpenParkCard: true
                }
              )
            }
          </HistoryModalOp>
        }
      </section>
    )
  }
}

const mapStateToProps = ({ bookingData: { bestOffer, state }, nearby, search: { params: { type } } }) => ({
  bestOffer,
  parkState: state,
  nearbyParks: nearby.parks.filter(p => p.bestOffer),
  type
})

export default connect(mapStateToProps)(LocationSection)
