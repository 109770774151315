import React from 'react'
import { FormattedMessage } from 'react-intl'

import SvgImage from '../../components/SvgImage'
import car from '../../../../../../app/assets/images/svg-on-react/icon-car.svg'
import walking from '../../../../../../app/assets/images/svg-on-react/icon-walking.svg'

const i18nBase = 'pages.park.access.addresses'

const Addresses = ({ park }) => (
  <ul className='small-icon-list m-top-30'>
    <li>
      <SvgImage svg={car} />
      <FormattedMessage id={`${i18nBase}.car`} /> {asFullAddress(park.address)}
    </li>

    {park.pedestrianAddress && (
      <li>
        <SvgImage svg={walking} />
        <FormattedMessage id={`${i18nBase}.pedestrian`} /> {asFullAddress(park.pedestrianAddress)}
      </li>)}
  </ul>
)

const asFullAddress = address => `${address.address.trim()}, ${address.zipcode} ${address.city}`

export default Addresses
