import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import Affix from '../components/Affix'
import { hasDescription } from '../helpers/park'

export default class NavTabs extends Component {
  componentDidMount () {
    $('body').scrollspy({ target: '#park-navigation-affix', offset: getNavBarHeight() })

    const $innerMenu = $('#park-navigation-affix').find('.park-navigation__inner')
    $('#park-navigation-affix').on('activate.bs.scrollspy', (event) => {
      const leftPos = $(event.target).offset().left + $innerMenu.scrollLeft() - 20
      $innerMenu.animate({ scrollLeft: leftPos < 0 ? 0 : leftPos }, 500)
    })

    // When the navbar becomes stuck, it's removed from the page. This causes the div below to jump
    // and disturbs the scroll on navbar items click
    // https://stackoverflow.com/questions/20652581/bootstrap-affix-nav-causes-div-below-to-jump-up
    this.el.style.minHeight = getNavBarHeight() + 'px'
  }

  componentWillUnMount () {
    $('#park-navigation-affix').off('activate.bs.scrollspy')
  }

  render () {
    return (
      <Affix className='park-navigation' offsetTop={0}>
        <div id='park-navigation-affix' ref={el => { this.el = el }}>
          <div className='park-navigation__inner'>
            {this.getItemsListElement()}
          </div>
        </div>
      </Affix>
    )
  }

  getItemsListElement () {
    return (
      <ul className='nav nav-tabs'>
        {this.getMenuKeys().map(key => (
          <li role='presentation' key={key}>
            <a href={`#park-${key}`} onClick={e => {
              e.preventDefault()
              smoothScrollTo(`park-${key}`)
            }}>
              <FormattedMessage id={`pages.park.${key}.title`} />
            </a>
          </li>
        ))}
      </ul>
    )
  }

  getMenuKeys () {
    return this.props.park.isSeo
      ? [...(hasDescription(this.props.park) ? ['description'] : []), 'location']
      : ['description', 'access', 'location', 'reviews']
  }
}

export const smoothScrollTo = sectionId => {
  const scrollTop = $('#' + sectionId).offset().top - getNavBarHeight() + 2
  $('html, body').animate({ scrollTop }, 300, 'swing')
}

const getNavBarHeight = () => document.getElementById('park-navigation-affix').clientHeight
