import React from 'react'
import { isIE } from '../../helpers/browser'
import { getMarkerZIndex, getMarkerLabelClassname } from '../../../../libs/map'
import MapMarkerArrow from './MapMarkerArrow'
import MapMarkerContent from './MapMarkerContent'

const MapMarker = ({
  onMarkerClick,
  onMarkerMouseOver,
  onMarkerMouseOut,
  intl,
  park,
  firstParkIsPrimary,
  activeInfoWindow,
  activeMarker,
  index
}) => (
  <div
    className={
      getMarkerLabelClassname({
        activeInfoWindow,
        activeMarker,
        firstParkIsPrimary,
        index,
        park
      })
    }
    style={{
      zIndex: getMarkerZIndex(park, index, activeMarker, activeInfoWindow, firstParkIsPrimary)
    }}
    onClick={(e) => onMarkerClick(e, park.id)}
    onMouseDown={(e) => onMarkerClick(e, park.id)}
    onMouseOver={(e) => !isIE() && onMarkerMouseOver && onMarkerMouseOver(park.id, e)}
    onMouseOut={(e) => !isIE() && onMarkerMouseOut(park.id, e)}
  >
    <>
      <MapMarkerContent
        firstParkIsPrimary={firstParkIsPrimary}
        index={index}
        intl={intl}
        park={park}
      />
      <MapMarkerArrow />
    </>
  </div>
)

export default MapMarker
