import React, { Component } from 'react'
import { CountryFlag } from './CountryFlag'
import classNames from 'classnames'
import { NETHERLANDS_COUNTRY_ID } from './constants'

export class LicensePlateComponent extends Component {
  handleInputChange = (event) => {
    this.props.onInputChange(event.target.value.toUpperCase())
  }

  render () {
    const { inputValue, validLicensePlate, country, disabled } = this.props

    const inputClassNames = classNames('license-plate__input', { 'license-plate__input--disabled': disabled })
    const componentClassNames = classNames('license-plate', {
      'license-plate--valid': validLicensePlate,
      'license-plate--invalid': validLicensePlate === false
    })

    const flagClassNames = classNames('license-plate__flag', {
      'license-plate__flag--disabled': disabled
    })

    return (
      <div className={componentClassNames}>
        <CountryFlag country={country} className={flagClassNames} />
        <input
          id='license_plate'
          name='license_plate'
          type='text'
          value={inputValue}
          onChange={this.handleInputChange}
          onBlur={this.props.onInputBlur}
          className={inputClassNames}
          disabled={this.props.disabled}
        />
      </div>
    )
  }
}
