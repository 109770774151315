import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import URLSearchParams from 'url-search-params'

import SvgImage from 'Components/SvgImage'
import ShareSvg from '../../../../../../app/assets/images/svg-on-react/icon-share.svg'

class ShareLinks extends Component {
  state = {
    copied: false
  }

  formattedUrl () {
    const { railsContext, type, beginDate, endDate, bestOffer } = this.props
    const currentUrl = railsContext.href

    const newParams = new URLSearchParams()

    if (bestOffer) {
      newParams.set('offer_id', bestOffer.id)
    }
    newParams.set('begin_date', beginDate.toISOString(true))

    if (type === 'subscription') {
      newParams.set('type', 'subscription')
    } else {
      newParams.set('end_date', endDate.toISOString(true))
    }

    return `${currentUrl.split('?')[0]}?${newParams}`
  }

  render () {
    const { parkName, parkAddress } = this.props
    const parkUrl = this.formattedUrl()

    return (
      <div className='share-links'>
        <button
          data-target='#'
          className='share-links__button'
          id='share-links__label'>
          <FormattedMessage id='pages.park.share_links.email.body' values={{ park_name: parkName, park_url: parkUrl, park_address: parkAddress }} >
            {(formattedBody) => (
              <FormattedMessage id='pages.park.share_links.email.subject' values={{ park_name: parkName }} >
                {(subject) => (
                  <a href={`mailto:?subject=${subject}&body=${encodeURIComponent(formattedBody)}`}>
                    <SvgImage svg={ShareSvg} />
                  </a>
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        </button>
      </div>
    )
  }
}

const mapStateToProps = ({
  railsContext,
  bookingData: { bestOffer },
  search: { params: { type, beginDate, endDate } }
}) => ({ railsContext, type, beginDate, endDate, bestOffer })

export default connect(mapStateToProps)(ShareLinks)
