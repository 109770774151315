import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl'
import Loader from 'Components/Loader'

import RegistrationBreadcrumb from '../../Authentication/Registration/components/RegistrationBreadcrumb'
import { CommonSummary } from '../CommonSummary'

class EditChangesComponent extends CommonSummary {
  static storagePrefix = (_props) => {
    return window === undefined ? 'nonexisting-prefix' : window.location.pathname
  }

  duplicateBookingUrl = () => {
    const { localePrefix, offerBuilder: { park: { id: parkId } }, originalBookingId, travelInfos: { license_plate: licensePlate } } = this.props
    return `${localePrefix}/bookings/check_duplicated?park_id=${parkId}&original_booking_id=${originalBookingId}&license_plate=${licensePlate}`
  }

  // is used for completeBooking: WHICH data to submit on the main CTA button
  backendPostParams = () => {
    const { userConsent, offerBuilder, beginDate, endDate, renewalDate, travelInfos,
      discountCode, partnerCard, originalBookingId, changeId } = this.props

    return {
      park_id: offerBuilder.park_id,
      price: offerBuilder.amount.bookingAmount,
      begin_date: beginDate.toISOString(true),
      end_date: endDate.toISOString(true),
      original_booking_id: originalBookingId,
      booking_change_id: changeId,
      ...travelInfos,
      discount_code: discountCode ? discountCode.code : null,
      partner_loyalty_number: partnerCard ? partnerCard.cardNumber : null,
      partner: partnerCard ? partnerCard.id : null,
      multiple_entries: offerBuilder.multipleEntries.allowSelected,
      personal_data_com: userConsent,
      renewal_date: renewalDate,
      professional: this.state.professionalUse
    }
  }

  // is used for completeBooking: WHERE to submit data on the main CTA button
  backendPostUrl = () => `${this.props.localePrefix}/offers/${this.props.offerBuilder.id}/purchases`

  goBackToPurchase = () => {
    this.setState({ loading: true }, () => { window.location.href = `${this.props.localePrefix}/purchases/${this.props.originalPurchaseId}` })
  }

  render () {
    const { user } = this.props
    const { loading } = this.state

    return (
      <div className='react-component react-component--flex react-component--no-margin row'>
        <div className='row purchase-container h-100p'>
          <div className='purchase-summary'>
            {this.modalWindows()}

            <div className='row purchase-summary__header purchase-summary__header--always-display-left'>
              <RegistrationBreadcrumb previousSteps={[]} futureSteps={[]} />
              <hr className='purchase-summary__divider purchase-summary__divider--no-shadow purchase-summary__divider--starting-left-visible' />
            </div>
            <div className='purchase-summary__page-title row'>
              <h1><FormattedMessage id='booking.edit.page_title' /></h1>
            </div>

            {this.nonPaymentErrorsWithRedirectSection()}

            <div className='row'>
              <div className='purchase-summary__summary-section col-xs-12 col-sm-4 col-sm-push-8'>
                <div className='row'>
                  <div className='purchase-summary__offer'>
                    <div className='purchase-summary__offer-title'>
                      <FormattedMessage id='booking.edit.summary_title' />
                    </div>
                    {this.parkOfferSummary({
                      oldBeginDate: this.props.oldBeginAt,
                      oldEndDate: this.props.oldEndAt,
                      paidPriceAlternativeCurrency: this.props.paidPriceAlternativeCurrency,
                      oldPrice: this.props.oldAmount
                    })}

                    {this.reassurancePanel()}
                  </div>
                </div>
              </div>
              <div className='purchase-summary__editable-section col-xs-12 col-sm-8 col-sm-pull-4'>
                <div className='row purchase-summary__information-section'>
                  <FormattedHTMLMessage id='booking.edit.validate_order_desc' />
                </div>
                {this.editableSection({ saveUrl: true })}

                {this.paymentErrorsSection()}
              </div>
            </div>
          </div>
          {user.billingIdentity &&
            <>
              <div className='purchase-summary__button-container' ref={this.footerRef}>
                <hr className='purchase-summary__divider' />

                {this.checkboxesSection()}

                <div className='purchase-summary__button-wrapper'>
                  {loading &&
                    <div className='booking-information__loader'>
                      <Loader grey />
                    </div>
                  }
                  {!loading &&
                    <>
                      <button
                        type='button'
                        className='purchase-summary__button purchase-summary__button--cancel btn btn-primary registration__btn-form'
                        onClick={this.goBackToPurchase}
                        disabled={loading}
                        data-automation-id='purchase-summary__cancel-button'>
                        <span>
                          <FormattedMessage id={'actions.cancel'} />
                        </span>
                      </button>
                      <button
                        type='submit'
                        className='purchase-summary__button btn btn-primary registration__btn-form'
                        disabled={loading}
                        onClick={this.handleSubmitPurchase}
                        data-automation-id='purchase-summary__save-button'>
                        <span>
                          <FormattedMessage id={'authentication.save'} />
                        </span>
                      </button>
                    </>}
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  purchase: {
    purchaseId,
    discountCode,
    mandatoryInfos, travelInfos,
    partnerCard,
    offer: { beginDate, endDate, renewalDate, offerBuilder, oldBeginAt, oldEndAt, oldAmount, paidPriceAlternativeCurrency },
    originalBookingId,
    salesTermsPath
  },
  changeId,
  resources: {
    mobilePurchaseTunnel,
    mobilePurchaseTunnelPaymentInside,
    localePrefix,
    termsPath,
    isDefaultHost
  },
  search: { params: { type } },
  user,
  optinOptions: { userConsent, partnerName, optinDisplaying }
}) => {
  const storagePrefix = window === undefined ? 'nonexisting-path' : window.location.pathname
  const storedTravelInfos = sessionStorage.getItem(`${storagePrefix}-travelInfos`)
  const resultingTravelInfos = storedTravelInfos ? JSON.parse(storedTravelInfos) : travelInfos

  return ({
    mobilePurchaseTunnel,
    mobilePurchaseTunnelPaymentInside,
    isDefaultHost,
    beginDate,
    discountCode,
    salesTermsPath,
    termsPath,
    endDate,
    renewalDate,
    localePrefix,
    mandatoryInfos,
    offerBuilder,
    partnerCard,
    travelInfos: resultingTravelInfos,
    type,
    user,
    userConsent,
    partnerName,
    optinDisplaying,
    changeId,
    originalBookingId,
    originalPurchaseId: purchaseId,
    oldBeginAt,
    oldEndAt,
    oldAmount,
    paidPriceAlternativeCurrency
  })
}

export const EditChanges = withRouter(injectIntl(connect(mapStateToProps)(EditChangesComponent)))
