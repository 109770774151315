import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class TransparentModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    desktopOnly: PropTypes.bool,
    mobileOnly: PropTypes.bool,
    autoHideOnResize: PropTypes.bool
  }

  componentDidMount () {
    const { onModalClosed } = this.props
    if (onModalClosed) {
      this.$el.on('hidden.bs.modal', this.props.onModalClosed)
    }

    $(window).on('scroll resize', this.handleResize)
    $(window).on('orientationchange', this.handleOrientationChange)

    this.handleResize()
    requestAnimationFrame(() => $(document).find('.modal-backdrop').css({ 'background-color': '#101010', opacity: '0.85' }))
  }

  componentWillUnmount () {
    const { onModalClosed } = this.props
    if (onModalClosed) {
      this.$el.off('hidden.bs.modal', onModalClosed)
    }

    $(window).off('scroll resize', this.handleResize)
    $(window).off('orientationchange', this.handleOrientationChange)
  }

  handleResize = () => requestAnimationFrame(() => {
    const { autoHideOnResize, mobileOnly, desktopOnly } = this.props

    if ($(window).width() < 992) {
      if (desktopOnly && autoHideOnResize) {
        this.$el.modal('hide')
      }
    } else if (mobileOnly && autoHideOnResize) {
      this.$el.modal('hide')
    }
  })

  handleOrientationChange = () => {
    // iPad does not trigger resize event after orientation change
    if (navigator.platform === 'iPad') {
      setTimeout(() => this.handleResize, 250)
    }
  }

  render () {
    const { children, id } = this.props
    return (
      <div
        id={id}
        className='modal transparent-modal fade'
        tabIndex='-1'
        role='dialog'
        aria-hidden='true'
        ref={el => { this.$el = $(el) }}
        onClick={e => this.$el.modal('hide')}
      >
        <div className='container'>
          <button className='close' type='button' data-dismiss='modal' aria-label='close'>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-dialog'>
          <div className='modal-body'>
            { children }
          </div>
        </div>
      </div>
    )
  }
}
