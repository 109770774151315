import React from 'react'
import { FormattedNumber } from 'react-intl'

import NearbyPhoto from '../common/NearbyPhoto'
import DistanceItem from '../common/DistanceItem'
import Rating from '../common/Rating'

const NearbyPark = ({ park, isSubscription, imgWidth, imgHeight }) => (
  <a
    className='nearby-section__item link--block row'
    href={park.url ? park.url + (isSubscription ? '?type=subscription' : '') : null}
    title={park.seoTitle}
    target='_blank'
  >
    <div className='col-md-4 m-top-20 m-bottom-20'>
      <NearbyPhoto nearby={park} imgWidth={imgWidth} imgHeight={imgHeight} />
    </div>

    <div className='col-md-8 m-top-20 m-bottom-20'>
      <h3>{park.name}</h3>

      <Rating className='nearby-section__rating-stars' rating={park.rating} />
      <span className='park-card__rating__count'>({park.ratingCount})</span>

      <div className='nearby-section__item__address'>
        {park.address.address},
        <br />
        {park.address.zipcode} {park.address.city}
      </div>

      <ul className='small-icon-list'>
        <DistanceItem by={park.distance.by} time={park.distance.time} distance={park.distance.distance} />
      </ul>

      {park.bestOffer && <Price {...park.bestOffer} /> }
    </div>
  </a>
)

const Price = ({ amount }) => (
  <div className='nearby-section__item__price'>
    {amount.ref && (
      <div className='nearby-section__item__price__ref'>
        <FormattedNumber value={amount.ref} style='currency' currency='EUR' />
      </div>
    )}
    <div className='nearby-section__item__price__total'>
      <FormattedNumber value={amount.totalWithoutOptions} style='currency' currency='EUR' />
    </div>
  </div>
)

export default NearbyPark
