import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import ModalOp from './ModalOp'

export class LegacySubscriptionNoticeDialog extends Component {
  onCloseDialog = e => {
    // TODO: Remove jQuery from React
    e.preventDefault()
    $('#legacy-subscription-notice-modal').modal('hide')
  }

  render () {
    return (
      <ModalOp id='legacy-subscription-notice-modal' name='legacy-subscription-notice'>
        <div className='legacy-subscription-notice-container'>
          <h3 className='text--align-left'>
            <FormattedMessage id='purchase.legacy_subscription_notice.title' />
          </h3>
          <div className='m-top-20'>
            <FormattedMessage id='purchase.legacy_subscription_notice.monthly_subscription_in_past' />
          </div>
          <div className='m-top-20'>
            <FormattedMessage id='purchase.legacy_subscription_notice.dont_worry_about_renewal' />
          </div>
          <div className='m-top-20'>
            <FormattedMessage id='purchase.legacy_subscription_notice.terminate_whenever_you_want' />
          </div>
          <div className='legacy-subscription-notice-container__button-row'>
            <button className='legacy-subscription-notice-container__button o-btn o-btn--primary' onClick={this.onCloseDialog}>
              <FormattedMessage id={`purchase.legacy_subscription_notice.understood`} />
            </button>
          </div>
        </div>
      </ModalOp>
    )
  }
}
