import React from 'react'
import { FormattedMessage } from 'react-intl'

import HistoryComponent from '../../../components/HistoryComponent'
import { localePrefix } from '../../../helpers/authentication'
import SvgImage from '../../../components/SvgImage'
import iconSend from '../../../../../../../app/assets/images/svg-on-react/icon-send.svg'

class PasswordRecoveryConfirmation extends React.Component {
  closeConfirmation (e) {
    this.props.closeConfirmationCallback()
  }

  render () {
    const { email, isPage } = this.props
    const prefix = localePrefix.call(this)

    let closeConfirmationAttributes = {}
    if (isPage) {
      closeConfirmationAttributes = {
        href: `${prefix}/connect?origin=${this.props.source}`,
        className: 'btn btn-primary'
      }
    } else {
      closeConfirmationAttributes = {
        href: '#',
        className: 'btn btn-primary',
        'data-dismiss': 'modal',
        onClick: (e) => this.closeConfirmation(e)
      }
    }

    return (
      <div className='react-component password-confirmation'>
        <div className='row'>
          <div className='col-xs-12 m-bottom-10 text-center'>
            <SvgImage svg={iconSend} />
          </div>
          <div className='col-xs-12'>
            <p className='text-center'>
              <FormattedMessage id='authentication.connection.password_confirmation.link_sent' />
              <span className='text--medium-weight'>{email}</span>
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 text-center'>
            <a {...closeConfirmationAttributes}>
                OK
            </a>
          </div>
        </div>
        <HistoryComponent id='authentication.connection.password_confirmation.title_tag' skipSavingLocation origin={this.props.source} modal={!isPage && 'sent-password'} sentPassword={isPage && 'true'} closeModal />
      </div>
    )
  }
}

export default PasswordRecoveryConfirmation
