import React from 'react'
import { injectIntl } from 'react-intl'
import { getMapProvider } from '../../../../libs/mapProvider'
import MapMarker from '../MapMarker'

const Markers = ({
  activeInfoWindow,
  activeMarker,
  clusters,
  firstParkIsPrimary,
  intl,
  mapRef,
  onMarkerClick,
  onMarkerMouseOut,
  onMarkerMouseOver,
  parks,
  showClusters
}) => {
  return (
    (
      mapRef &&
      mapRef.getMap &&
      (
        !showClusters ||
        (clusters && clusters.length === 0)
      )
    )
      ? (
        parks.map((park, index) => (
          React.createElement(
            getMapProvider().Marker,
            {
              key: `${park.id}-${park.state}-${!![activeInfoWindow, activeMarker].includes(park.id)}`,
              latitude: parseFloat(park.address.lat),
              longitude: parseFloat(park.address.lng)
            },
            <MapMarker
              activeInfoWindow={activeInfoWindow}
              activeMarker={activeMarker}
              firstParkIsPrimary={firstParkIsPrimary}
              index={index}
              intl={intl}
              onMarkerClick={onMarkerClick}
              onMarkerMouseOut={onMarkerMouseOut}
              onMarkerMouseOver={onMarkerMouseOver}
              park={park}
            />
          )
        ))
      )
      : null
  )
}

export default injectIntl(Markers)
