import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'

import { ONEPX_TRANSPARENT_IMAGE } from '../../constants/application'
import { getDiscount } from '../../helpers/park'

const NearbyPhoto = ({ defaultImage, nearby, nearby: { image, seoTitle }, imgWidth, imgHeight }) => {
  const { discountRate, formattedNumberStyle } = getDiscount(nearby)
  return (
    <div className='photo--with-caption'>
      <img
        title={seoTitle}
        alt={seoTitle}
        className='park-nearby__photo img-responsive lazyload with-border'
        src={ONEPX_TRANSPARENT_IMAGE}
        sizes='(max-width: 992px) 280px, 180px'
        data-src={image ? image.url200 : defaultImage}
        data-srcset={image ? `${image.url200} 180w, ${image.url280} 280w` : null}
        width={imgWidth}
        height={imgHeight}
      />
      {!image && <div className='photo__caption'><FormattedMessage id='pages.photo.unavailable' /></div>}
      {discountRate < 0 && (
        <div className='park-nearby__photo__discount'>
          <FormattedNumber value={discountRate} style={formattedNumberStyle} currency={'EUR'} minimumFractionDigits={0} />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ resources: { defaultImage } }) => ({ defaultImage })

export default connect(mapStateToProps)(NearbyPhoto)
