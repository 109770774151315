import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { LICENSE_PLATE_COUNTRIES } from './constants'

export class CountriesSelect extends Component {
  componentDidMount () {
    document.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick = (event) => {
    const { selectRef } = this.props

    if (selectRef && selectRef.current && !selectRef.current.contains(event.target)) {
      this.props.hideList()
    }
  }

  render () {
    const { handleOptionSelect, country, selectRef } = this.props

    return (
      <div className='countries-dropdown__select' ref={selectRef}>
        {LICENSE_PLATE_COUNTRIES.map((licensePlateCountry) => (
          <div
            id={licensePlateCountry.key}
            className={`countries-dropdown__option ${licensePlateCountry.id === country.id ? 'countries-dropdown__option--selected' : ''}`}
            data-automation-id={`countries-dropdown__option--${licensePlateCountry.key}`}
            key={licensePlateCountry.id}
            onClick={() => handleOptionSelect(licensePlateCountry)}
          >
            <div className='input-prefix-flag'>
              <div className={licensePlateCountry.iso} />
            </div>
            <FormattedMessage id={`countries.${licensePlateCountry.key}`} />
          </div>
        ))}
      </div>
    )
  }
}
