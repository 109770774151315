import React from 'react'
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps'
import { injectIntl } from 'react-intl'
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel'
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'

import { isIE } from '../../helpers/browser'
import { googleMapStyles, markerClustererStyles } from './theme'
import MapMarkerContent from '../MapMarker/MapMarkerContent'
import MapMarkerArrow from '../MapMarker/MapMarkerArrow'
import { getMarkerLabelClassname, getMarkerZIndex } from '../../../../libs/map'

const Map = injectIntl(withGoogleMap(({
  activeInfoWindow,
  activeMarker,
  defaultCenter,
  defaultZoom,
  firstParkIsPrimary,
  intl,
  clusters = [],
  parks,
  onIdle,
  onMapClick,
  onMapMounted,
  onMarkerClick,
  onMarkerMouseOver,
  onMarkerMouseOut,
  onZoomChanged,
  showClusters,
  showMainMarker,
  twoFingersMovemement,
  zoomControl
}) => (
  <GoogleMap
    ref={onMapMounted}
    defaultCenter={defaultCenter}
    defaultZoom={defaultZoom}
    options={{
      clickableIcons: false,
      fullscreenControl: false,
      streetViewControl: true,
      mapTypeControl: false,
      maxZoom: 17,
      minZoom: 4,
      zoomControl,
      gestureHandling: twoFingersMovemement ? 'cooperative' : 'greedy',
      styles: googleMapStyles
    }}
    onClick={onMapClick}
    onIdle={onIdle}
    onZoomChanged={onZoomChanged}
  >
    {showMainMarker && (<Marker position={defaultCenter} />)}

    {showClusters && clusters.length > 0 && (
      <MarkerClusterer averageCenter minimumClusterSize={1} styles={markerClustererStyles}>
        {clusters
          .map(c => [...Array(c.number).keys()].map(index => ({ id: `${c.id}_${index}`, lat: c.lat, lng: c.lng })))
          .flat()
          .map(c => <Marker key={c.id} opacity={0} position={{ lat: c.lat, lng: c.lng }} />)
        }
      </MarkerClusterer>
    )}

    {(!showClusters || clusters.length === 0) && (
      <MarkerClusterer averageCenter maxZoom={11} styles={markerClustererStyles}>
        {parks.map((p, index) => (
          <MarkerWithLabel
            key={p.id}
            zIndex={getMarkerZIndex(p, index, activeMarker, activeInfoWindow, firstParkIsPrimary)}
            opacity={0}
            position={{ lat: parseFloat(p.address.lat), lng: parseFloat(p.address.lng) }}
            labelAnchor={new google.maps.Point(28, 50)}
            labelClass={
              getMarkerLabelClassname({
                activeInfoWindow,
                activeMarker,
                firstParkIsPrimary,
                index,
                park: p
              })
            }
            onClick={(e) => onMarkerClick(e, p.id)}
            onMouseDown={(e) => onMarkerClick(e, p.id)}
            onMouseOver={(e) => !isIE() && onMarkerMouseOver && onMarkerMouseOver(p.id, e)}
            onMouseOut={(e) => !isIE() && onMarkerMouseOut(p.id, e)}
          >
            <>
              {
                // MarkerWithLabel triggers mouseover and mouseout events for every level of the element tree,
                // but not all the time, causing interleaved events such as:
                // mouseover PARENT -> mouseover CHILD -> mouseout PARENT (yes!)
                // mouseout CHILD -> mouseover PARENT -> nothing (re-yes!)
                // To avoid this, the simplest solution is to make the element tree flat.
                // That's why we use intl.formatNumber instead of <FormattedNumber> here, because the latter wraps
                // text into a span element.
              }
              <MapMarkerContent
                firstParkIsPrimary={firstParkIsPrimary}
                index={index}
                intl={intl}
                park={p}
              />
              <MapMarkerArrow />
            </>
          </MarkerWithLabel>
        ))}
      </MarkerClusterer>
    )}
  </GoogleMap>
)))

export default Map
