import React, { Component } from 'react'

import PaymentInfoPanel from './PaymentInfoPanel'
import HistoryModalOp from '../components/HistoryModalOp'
import { locationHasParam, removeParamFromLocation } from '../helpers/application'
import { connect } from 'react-redux'

const MODAL_NAME = 'payment-info-edit'

class PaymentInfoModal extends Component {
  componentDidMount () {
    if (locationHasParam('modal', MODAL_NAME)) {
      history.replaceState({}, '', removeParamFromLocation('modal'))
    }
  }

  render () {
    return (
      <div className='react-component payment-info-modal'>
        <HistoryModalOp id='payment-info-modal' name={MODAL_NAME}>
          <PaymentInfoPanel
            {...this.props}
            callingPage='purchase'
            onCancel={() => $('#payment-info-modal').modal('hide')} />
        </HistoryModalOp>
      </div>
    )
  }
}

const mapStateToProps = ({
  resources: { mobilePurchaseTunnel, mobilePurchaseTunnelPaymentInside }
}) => ({ mobilePurchaseTunnel, mobilePurchaseTunnelPaymentInside })

export default connect(mapStateToProps)(PaymentInfoModal)
