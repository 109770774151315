import React from 'react'
import URLSearchParams from 'url-search-params'

import ModalOp from '../../components/ModalOp'
import OpI18n from '../../components/OpI18n'
import { localePrefix } from '../../helpers/authentication'
import { Login } from '../Connection/components/Login'
import { PasswordRecoveryTunnel } from '../Connection/components/PasswordRecoveryTunnel'

const PopupRouterWithContext = (props, railsContext) => (
  <OpI18n {...railsContext}>
    <PopupRouterComponent {...props} railsContext={railsContext} />
  </OpI18n>
)

const LOGIN_FORM_DIALOG_ID = 'connect'
const FORGOTTEN_PASSWORD_DIALOG_ID = 'forgotten-password'
const SEND_PASSWORD_DIALOG_ID = 'sent-password'
const allowedModals = [
  LOGIN_FORM_DIALOG_ID,
  FORGOTTEN_PASSWORD_DIALOG_ID,
  SEND_PASSWORD_DIALOG_ID
]

class PopupRouterComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      params: new URLSearchParams(window.location.search.slice(1))
    }
  }

  componentDidMount () {
    const { params } = this.state

    const modal = params.get('modal')
    if (modal && allowedModals.includes(modal)) {
      $('#' + modal).modal('show')
    }
  }

  render () {
    const { params } = this.state
    const { railsContext, isUserSignedIn } = this.props
    const prefix = localePrefix.call(this)
    let urlSource = new URLSearchParams(window.location.search).get('origin')
    urlSource = urlSource === 'email' ? urlSource : ''

    const pathname = document.location.pathname
    const modalParam = params.get('modal')

    if (isUserSignedIn && [LOGIN_FORM_DIALOG_ID, FORGOTTEN_PASSWORD_DIALOG_ID, SEND_PASSWORD_DIALOG_ID].includes(modalParam)) {
      return null
    }

    if (modalParam === LOGIN_FORM_DIALOG_ID && pathname !== `${prefix}/connect`) {
      return (
        <ModalOp id={LOGIN_FORM_DIALOG_ID}>
          <Login railsContext={railsContext} source={urlSource} />
        </ModalOp>
      )
    } else if ([FORGOTTEN_PASSWORD_DIALOG_ID, SEND_PASSWORD_DIALOG_ID].includes(modalParam) &&
      ![`${prefix}/forgotten-password`, `${prefix}/sent-password`].includes(document.location.pathname)) {
      return (
        <ModalOp id={FORGOTTEN_PASSWORD_DIALOG_ID}>
          <PasswordRecoveryTunnel railsContext={railsContext} source={urlSource} />
        </ModalOp>
      )
    } else {
      return null
    }
  }
}

export default PopupRouterWithContext
