import classNames from 'classnames'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import Loader from '../../components/Loader'
import SvgImage from '../../components/SvgImage'
import searchIcon from '../../../../../../app/assets/images/svg-on-react/search-icon.svg'

export class SearchSubmitButton extends Component {
  render() {
    const { loading, submitButtonClass = {}, showSearchIcon } = this.props

    const submitButtonClasses = classNames('op-search__form-cta', 'text-center', 'col-xs-12', 'col-sm-4', 'col-md-3', {
      'op-search__form-cta--half-border-rounded': submitButtonClass.halfBorderRounded,
      'op-search__form-cta--fixed-size': submitButtonClass.fixedSize,
    })
    const iconClasses = classNames({ 'hidden': loading || !showSearchIcon })
    const labelClasses = classNames({ 'invisible': loading })

    return (
      <div className={submitButtonClasses} >
        <button type='submit' disabled={loading} className='btn btn-primary'>
          {loading && <Loader overlay white />}

          <SvgImage className={iconClasses} svg={searchIcon} />

          <span className={labelClasses}>
            <FormattedMessage id='pages.homepage.search.submit' />
          </span>
        </button>
      </div>
    )
  }
}
