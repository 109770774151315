import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import SvgImage from '../../components/SvgImage'
import LinksList from './LinksList'
import MobileApps from './MobileApps'
import SocialNetworkIcons, { hasSocialNetworkIcons } from './SocialNetworkIcons'
import iconArrowDown from '../../../../../../app/assets/images/svg-on-react/icon-arrow-down.svg'

class Links extends Component {
  state = {
    activeSections: []
  }

  toggle (name) {
    const { activeSections } = this.state
    this.setState({
      activeSections: activeSections.includes(name) ? activeSections.filter(n => n !== name) : [...activeSections, name]
    })
  }

  isActive (name) {
    return this.state.activeSections.includes(name)
  }

  render () {
    const { footerLinks, legalNotice, mobileApp, socialNetwork, isHomepage } = this.props
    const displaySocialNetworkIcons = hasSocialNetworkIcons(socialNetwork)

    return (
      <div className='links container'>
        <div className='links__flex'>
          {footerLinks.editorialColumns.map((sections, i) => (
            <div className='links__col' key={i}>
              {sections.map((section, j) =>
                this.renderCollapsibleSection({
                  name: `editorial-${i}.${j}`,
                  title: section.title,
                  body: <LinksList isHomepage={isHomepage} legalNotice={legalNotice} links={section.links} />
                })
              )}
            </div>
          ))}

          {displaySocialNetworkIcons && mobileApp.displayMobile && (
            <div className='links__col'>
              {displaySocialNetworkIcons && (
                <section className='active links__light-section'>
                  <h4><FormattedMessage id='pages.footer.follow_us' /></h4>
                  <SocialNetworkIcons socialNetwork={socialNetwork} />
                </section>
              )}

              {mobileApp.displayMobile && (
                <section className='active links__light-section'>
                  <h4><FormattedMessage id='pages.footer.mobile_apps' /></h4>
                  <MobileApps />
                </section>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderCollapsibleSection ({ name, title, body }) {
    if (title.length === 0) return

    return (
      <section key={name} className={this.isActive(name) ? 'active' : null}>
        <span className='links__title' onClick={() => this.toggle(name)}>
          <div className={`dropdown-control ${this.isActive(name) ? 'dropdown-control--open' : ''}`}>
            <SvgImage svg={iconArrowDown} />
          </div>
          {title}
        </span>
        {body}
      </section>
    )
  }
}

export default Links
