import React from 'react'
import { FormattedMessage } from 'react-intl'

import OpeningHours from './OpeningHours'
import Caracteristics from './Caracteristics'
import Services from './Services'
import AllowedVehicles from './AllowedVehicles'
import Information from './Information'
import { BookingActions } from './BookingActions'

const DescriptionSection = ({ park }) => (
  <section id='park-description'>
    <h2>
      <FormattedMessage id='pages.park.description.title' />
    </h2>

    {<OpeningHours park={park} />}

    {!park.isSeo && <Caracteristics park={park} />}

    {<Services park={park} />}

    {!park.isSeo && <AllowedVehicles park={park} />}

    {!park.isSeo && <Information park={park} />}

    {!park.isSeo && <BookingActions park={park} />}
  </section>
)

export default DescriptionSection
