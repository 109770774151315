import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export class MobileSlideShow extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    ).isRequired
  }

  render () {
    const { images } = this.props

    return (
      <div className='slideshow--mobile' onClick={event => event.stopPropagation()}>
        { images.map(image => { return (<LazyLoadImage src={image.url} alt={image.alt} key={image.url} />) }) }
      </div>
    )
  }
}
