import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import TooltipComponent from 'Components/TooltipComponent'
import classNames from 'classnames'

export class AttributeCheckbox extends Component {
  render () {
    const { attributeName, isChecked, handleCheckboxChange, showTooltip, disabled, readonly, intl } = this.props
    const labelClassName = classNames('attribute-checkbox__label', { 'attribute-checkbox__label--readonly': readonly })
    return (
      <div className='attribute-checkbox'>
        <input id={attributeName}
          name={attributeName}
          disabled={disabled}
          readOnly={readonly}
          className='checkbox-op checkbox-blue'
          type='checkbox'
          checked={isChecked}
          onChange={(event) => {
            if (readonly) event.preventDefault()
            else handleCheckboxChange(attributeName, event.target.checked)
          }} />
        <label htmlFor={attributeName} />
        <div className={labelClassName}>
          <span onClick={() => { !readonly && handleCheckboxChange(attributeName, !isChecked) }}>
            {intl.formatMessage({ id: `purchase.travel_informations.attributes.${attributeName}` })}
          </span>
          {showTooltip &&
            <TooltipComponent
              isOpen={false}
              message={`purchase.travel_informations.attributes.${attributeName}_tooltip`}
              containerRef={this.props.containerRef}
              bemModifier='payment-pages-sourced'
              fitWindow />}
        </div>
      </div>
    )
  }
}

AttributeCheckbox.propTypes = {
  attributeName: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  showTooltip: PropTypes.bool
}

export default injectIntl(AttributeCheckbox)
