import React from 'react'
import SvgImage from '../../components/SvgImage'

import iconFacebook from '../../../../../../app/assets/images/svg-on-react/icon-facebook.svg'
import iconGooglePlus from '../../../../../../app/assets/images/svg-on-react/icon-google+.svg'
import iconInstagram from '../../../../../../app/assets/images/svg-on-react/icon-instagram.svg'
import iconLinkedin from '../../../../../../app/assets/images/svg-on-react/icon-linkedin.svg'
import iconTwitter from '../../../../../../app/assets/images/svg-on-react/icon-twitter.svg'

const SocialNetworkIcons = ({ socialNetwork }) => (
  <div className='links__social-network-icons'>
    {[
      [socialNetwork.facebook_url, iconFacebook],
      [socialNetwork.twitter_url, iconTwitter],
      [socialNetwork.gplus_url, iconGooglePlus],
      [socialNetwork.instagram_url, iconInstagram],
      [socialNetwork.linkedin_url, iconLinkedin]
    ].filter(([url]) => !!url).map(([url, icon], i) => (
      <a key={i} href={url} target='_blank'>
        <SvgImage svg={icon} />
      </a>
    ))}
  </div>
)

export const hasSocialNetworkIcons = (socialNetwork) => ([
  socialNetwork.facebook_url,
  socialNetwork.twitter_url,
  socialNetwork.gplus_url,
  socialNetwork.instagram_url,
  socialNetwork.linkedin_url
].some((url) => !!url))

export default SocialNetworkIcons
