import React from 'react'
import { FormattedMessage } from 'react-intl'

const AndAlsoSection = ({ andAlsoLinks, className, inline }) => (
  <section id='and-also-section' className={`and-also-section ${className}`}>
    <FormattedMessage id='pages.park.and_also.title' />

    <ul className={`${inline ? 'list-inline list-inline--tablet-landscape-up' : ''} m-bottom-0`}>
      {andAlsoLinks.map(({ name, url }) => (
        <li key={name}>
          <a href={url} title={name} className='link--block'>{name}</a>
        </li>
      ))}
    </ul>
  </section>
)

export default AndAlsoSection
