import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import CalendarIcon from '../../../../../app/assets/images/svg-on-react/icon-calendar-white.svg'
import EditIcon from '../../../../../app/assets/images/svg-on-react/icon-edit-white.svg'
import GeolocationIcon from '../../../../../app/assets/images/svg-on-react/icon-geolocation-white.svg'
import SvgImage from '../components/SvgImage'

const HeaderSearchSummary = ({ beginDate, endDate, address, onClickSummary, type }) => (
  <div className='search-summary row' onClick={onClickSummary}>
    <div className='col-xs-11'>
      <div className='search-summary__location row'>
        <div className='col-xs-1 search-summary__geolocation'>
          <SvgImage svg={GeolocationIcon} />
        </div>

        <div className='search-summary__address col-xs-11'>
          {address ? <p>{address}</p> : <div className='search-summary__address--empty' />}
        </div>
      </div>

      <div className='search-summary__infos row'>
        <div className='col-xs-1 search-summary__calendar'>
          <SvgImage svg={CalendarIcon} />
        </div>

        <div className='col-xs-11 search-summary__date'>
          <p>
            {type === 'subscription' &&
              <span>
                <FormattedMessage id='pages.header.summary.subscription_from' />
                <FormattedMessage id='pages.header.summary.format_day' >{f => beginDate.format(f)}</FormattedMessage>
              </span>
            }

            {type === 'package' && beginDate.isSame(endDate, 'day') &&
              <span>
                <FormattedMessage id='pages.header.summary.subscription_on' />
                <FormattedMessage id='pages.header.summary.format_day' >{f => beginDate.format(f)}</FormattedMessage>
                <FormattedMessage id='pages.header.summary.hour_from' />
                <FormattedMessage id='pages.header.summary.format_hour_from' >{f => beginDate.format(f)}</FormattedMessage>
                <FormattedMessage id='pages.header.summary.hour_to' />
                <FormattedMessage id='pages.header.summary.format_hour_to' >{f => endDate.format(f)}</FormattedMessage>
              </span>
            }

            {type === 'package' && !beginDate.isSame(endDate, 'day') &&
              <span>
                <span className='text-nowrap'>
                  <FormattedMessage id='pages.header.summary.from' />
                  <FormattedMessage id='pages.header.summary.format_day' >{f => beginDate.format(f)}</FormattedMessage>
                  <FormattedMessage id='pages.header.summary.at' />
                  <FormattedMessage id='pages.header.summary.format_hour' >{f => beginDate.format(f)}</FormattedMessage>
                </span>
                {' '}
                <span className='text-nowrap'>
                  <FormattedMessage id='pages.header.summary.to' />
                  <FormattedMessage id='pages.header.summary.format_day' >{f => endDate.format(f)}</FormattedMessage>
                  <FormattedMessage id='pages.header.summary.at' />
                  <FormattedMessage id='pages.header.summary.format_hour' >{f => endDate.format(f)}</FormattedMessage>
                </span>
              </span>
            }
          </p>
        </div>
      </div>
    </div>

    <div className='col-xs-1 search-summary__edit'>
      <SvgImage svg={EditIcon} />
    </div>
  </div>
)

const mapStateToProps = ({
  search: { params: { beginDate, endDate, address, type } }
}) => ({
  address, beginDate, endDate, type
})

export default connect(mapStateToProps)(HeaderSearchSummary)
