import { buildSearchApiParams, buildUrl, setCurrentLocationParams } from '../bundles/Onepark/helpers/search'
import * as types from '../types/BookingDataTypes'

export const loadDetailedOffer = (offerId) => async (dispatch, getState) => {
  await dispatch({ type: types.BOOKING_DATA_QUERY_PENDING })
  await dispatch({ type: types.BOOKING_DATA_RESET })
  if (offerId) {
    await dispatch({ type: types.BOOKING_DATA_UPDATE, bookingData: { selectedSubscriptionId: offerId } })
  }
  const url = buildDetailedOfferUrl(`${window.location.pathname}/detailed-offer`, getState().search.params, offerId)

  try {
    const response = await fetch(url, { credentials: 'include' })
    if (response.ok) {
      const { availabilityRate, bestOffer, noOfferReason, state } = await response.json()
      setCurrentLocationParams({ offer_id: bestOffer ? bestOffer.id : null })
      await dispatch({ type: types.BOOKING_DATA_UPDATE, bookingData: { availabilityRate, bestOffer, noOfferReason, state } })
      await dispatch({ type: types.BOOKING_DATA_QUERY_SUCCESS })
    }
  } catch (error) {
    if (!(error instanceof TypeError)) { // Network errors
      Sentry.captureException(error)
    }
  }
}

function buildDetailedOfferUrl (pathname, { address, beginDate, endDate, type }, offerId) {
  const apiParams = buildSearchApiParams({ address, beginDate, endDate, type })
  const urlParams = new URLSearchParams(window.location.search)
  const placeId = urlParams.get('place_id')
  if (placeId) { apiParams.place_id = placeId }
  if (offerId) {
    apiParams.offer_id = offerId
  }
  return buildUrl(pathname, apiParams)
}
