import React from 'react'
import { FormattedMessage } from 'react-intl'

import SvgImage from '../../components/SvgImage'
import { splitHalf } from '../../helpers/collections'
import iconCovered from '../../../../../../app/assets/images/svg-on-react/icon-covered.svg'
import iconElevator from '../../../../../../app/assets/images/svg-on-react/icon-elevator.svg'
import iconGuarding from '../../../../../../app/assets/images/svg-on-react/icon-guarding.svg'
import iconMultipleEntries from '../../../../../../app/assets/images/svg-on-react/icon-multiple-entries.svg'
import iconSingleEntry from '../../../../../../app/assets/images/svg-on-react/icon-single-entry.svg'
import iconUncovered from '../../../../../../app/assets/images/svg-on-react/icon-uncovered.svg'
import iconUncoveredClosed from '../../../../../../app/assets/images/svg-on-react/icon-uncovered-closed.svg'
import iconValet from '../../../../../../app/assets/images/svg-on-react/icon-valet.svg'
import iconVideosurveillance from '../../../../../../app/assets/images/svg-on-react/icon-videosurveillance.svg'

const i18nBase = 'pages.park.description.caracteristics'

const Caracteristics = ({ park }) => {
  const caracteristics = getCaracteristics(park)

  if (caracteristics.length === 0) {
    return <div />
  }

  return (
    <div>
      <h3>
        <FormattedMessage id={`${i18nBase}.title`} />
      </h3>

      <div className='row park__description__list'>
        {splitHalf(caracteristics).map((caracteristics, i) => caracteristics.length > 0 && (
          <div key={i} className='col-md-6'>
            <ul>
              {caracteristics.map(characteristic => getItem(characteristic, park))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

const getCaracteristics = park => {
  const services = park.services.map(service => service.split(':')[0])
  const maybeGetService = (service, ...aliases) => [service, ...aliases].some(s => services.includes(s)) ? service : null
  const multipleEntriesType = park => {
    if (park.minimumDurationToAllowMultipleEntries) {
      if (park.includeMultipleEntries) {
        return 'multiple_entries_free_included'
      } else {
        return 'multiple_entries_paid_available'
      }
    } else if (park.includeMultipleEntries) {
      return 'multiple_entries'
    } else {
      return 'single_entry'
    }
  }
  return [
    multipleEntriesType(park),
    maybeGetService('guarding'),
    maybeGetService('videosurveillance'),
    maybeGetService('valet'),
    maybeGetService('covered'),
    maybeGetService('uncovered'),
    maybeGetService('uncovered_closed', 'uncovered'),
    maybeGetService('elevator')
  ].filter(service => !!service)
}

const getItem = (caracteristic, park) => (
  <li key={caracteristic}>
    <SvgImage svg={iconsByCaracteristic[caracteristic]} />
    { ['multiple_entries_to', 'multiple_entries_free_included', 'multiple_entries_paid_available'].includes(caracteristic)
      ? (<FormattedMessage id={`${i18nBase}.${caracteristic}`} values={{ duration: park.minimumDurationToAllowMultipleEntries }} />)
      : (<FormattedMessage id={`${i18nBase}.${caracteristic}`} />)
    }
  </li>
)

const iconsByCaracteristic = {
  single_entry: iconSingleEntry,
  multiple_entries: iconMultipleEntries,
  multiple_entries_free_included: iconMultipleEntries,
  multiple_entries_paid_available: iconMultipleEntries,
  guarding: iconGuarding,
  videosurveillance: iconVideosurveillance,
  valet: iconValet,
  covered: iconCovered,
  uncovered: iconUncovered,
  uncovered_closed: iconUncoveredClosed,
  elevator: iconElevator
}

export default Caracteristics
