import React, { Component, createRef } from 'react'
import { CountriesSelect } from './CountriesSelect'
import { FormattedMessage } from 'react-intl'
import SvgImage from 'Components/SvgImage'

import arrowDown from 'Assets/images/svg-on-react/arrow-down-green.svg'

export class Dropdown extends Component {
  selectRef = createRef()

  state = {
    isOpen: false
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }))
  }

  handleOptionSelect = (newOption) => {
    this.props.onCountryChange(newOption)

    this.hideList()
  }

  toggleDropdown = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    } else {
      this.setState({ isOpen: true })
    }
  }

  hideList = () => {
    this.setState({ isOpen: false })
  }

  render () {
    const { isOpen } = this.state
    const { country } = this.props

    return (
      <div className='countries-dropdown'>
        <a className='countries-dropdown__link' onClick={this.toggleDropdown} data-automation-id='countries-dropdown__link'>
          <FormattedMessage id={'vehicle_info.dropdown_link'} className='countries-dropdown__link--inline' />
          <SvgImage svg={arrowDown} className='countries-dropdown__link--inline' />
        </a>

        {isOpen && (
          <CountriesSelect
            country={country}
            handleOptionSelect={this.handleOptionSelect}
            selectRef={this.selectRef}
            hideList={this.hideList}
          />)}
      </div>
    )
  }
}
