import PropTypes from 'prop-types'
import React from 'react'

const MAX_RATE = 5

const Rating = ({ className, rating }) => (
  <div className={className}>
    {Array(MAX_RATE)
      .fill()
      .map((_, i) => (
        <i
          key={i + 1}
          className={`fa fa-star ${i >= rating ? 'fa-star--empty' : ''}`}
        />
      ))}
  </div>
)

Rating.defaultProps = {
  className: null,
  rating: 1
}

Rating.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number
}

export default Rating
