import React from 'react'
import PinGroup from './PinGroup'

const ClusterWrapper = ({
  mapRef,
  showClusters,
  clusters
}) => (
  mapRef &&
  mapRef.getMap &&
  showClusters &&
  clusters &&
  clusters.length > 0
    ? React.createElement(
      require('./Cluster').default,
      {
        map: mapRef.getMap(),
        radius: 60,
        extent: 512,
        element: ({ cluster }) => (
          !!cluster &&
          !!cluster.properties &&
          !!cluster.properties.point_count &&
          !!cluster.geometry &&
          !!cluster.geometry.coordinates &&
          !!cluster.geometry.coordinates.length &&
          <PinGroup
            key={cluster.id}
            longitude={parseFloat(cluster.geometry.coordinates[0])}
            latitude={parseFloat(cluster.geometry.coordinates[1])}
            pointCount={cluster.properties.totalPointCount}
          />
        ),
        clusters: clusters.map((cluster) => (
          <PinGroup
            key={cluster.id}
            longitude={parseFloat(cluster.lng)}
            latitude={parseFloat(cluster.lat)}
            pointCount={cluster.number}
          />
        ))
      }
    )
    : null
)

export default ClusterWrapper
