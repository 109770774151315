import mapBoxClient from '@mapbox/mapbox-sdk';
import mapBoxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

const DEFAULT_SEARCH_TYPES = [
  'address',
  'country',
  'district',
  'locality',
  'neighborhood',
  'place',
  'poi.landmark',
  'poi',
  'postcode',
  'region'
]

const SEARCH_RESULT_LIMIT = 30

const getBaseClient = () => mapBoxClient({ accessToken: process.env.MAPBOX_API_KEY });

export const getGeocodingService = () => mapBoxGeocoding(getBaseClient());

const transformFeaturesToPlaces = (features) => features.reduce((acc, item) => {
  const placeAddress = item.place_name.split(', ')
  return {
    ...acc,
    [item.id]: {
      locationName: placeAddress[1] || '',   // e.g. "Disney Village"
      center: item.center,                   // [2.782498, 48.86865]
      city: placeAddress[2] || '',           // e.g. "Chessy"
      country: placeAddress[4] || '',        // e.g. "France"
      poi: placeAddress[0] || '',            // e.g. "World of Disney"
      postalCode: placeAddress[3] || '',     // e.g. "Seine-et-Marne 77700"
      relevance: item.relevance,             // e.g. 0.5
    }
  }
}, {})

export const searchForLocation = (
  {
    countries = [],
    limit = SEARCH_RESULT_LIMIT,
    proximity = undefined,
    query = '',
    types = DEFAULT_SEARCH_TYPES,
  }
) => (
  getGeocodingService()
  .forwardGeocode({
    countries,
    limit,
    proximity,
    query,
    types,
  })
  .send()
  .then(({ body }) => {
    if (body && body.features) {
      return transformFeaturesToPlaces(body.features)
    }

    return {}
  })
  .catch(console.warn)
)
