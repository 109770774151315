import React, { Component } from 'react'

import RegistrationBreadcrumb from '../../Authentication/Registration/components/RegistrationBreadcrumb'

import PurchaseConfirmationSuccess from './Success'
import PurchaseConfirmationError from './Error'
import { datalayerPush } from '../../helpers/application'

class PurchaseConfirmationPage extends Component {
  componentDidMount = () => {
    datalayerPush({
      event: 'page_view',
      ep_page_type_level1: 'booking_confirmation',
      ep_page_type: 'ecommerce',
      ep_site: window._oneparkDatas.ep_site,
      ep_page_name: document.getElementsByTagName('title')[0].innerText,
      ep_logged_status: window._oneparkDatas.user_signed_in,
      up_user_id: window._oneparkDatas.up_user_id,
      up_language: window._oneparkDatas.up_language,
      up_locale: window._oneparkDatas.up_locale
    })
  }

  render () {
    const { history } = this.props

    return (
      <div className='react-component react-component--flex purchase-confirmation purchase-confirmation--short-padded'>
        <div className='purchase-summary'>
          <div className='row purchase-summary__header purchase-summary__header--always-display-left'>
            <RegistrationBreadcrumb previousSteps={[]} futureSteps={[]} activeStepId=''
              breadcrumbClasses='registration__breadcrumb-wrapper--no-padding'
              additionalClasses='registration__breadcrumb--at-confirmation' />
            <hr className='purchase-summary__divider purchase-summary__divider--no-shadow purchase-summary__divider--starting-left-visible' />
          </div>
          {history.location.search.includes('success=true')
            ? <PurchaseConfirmationSuccess history={history} />
            : <PurchaseConfirmationError history={history} />
          }
        </div>
      </div>
    )
  }
}

export default PurchaseConfirmationPage
