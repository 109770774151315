import React, { Component } from 'react'
import FormInput from './FormInput'

import iconPwdHidden from '../../../../../../app/assets/images/svg-on-react/icon-pwd-hidden.svg'
import iconPwdVisible from '../../../../../../app/assets/images/svg-on-react/icon-pwd-visible.svg'

class FormInputPassword extends Component {
  state = {
    passwordVisible: false
  }

  handleToggleVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible })
  }

  render () {
    const { passwordVisible } = this.state

    return (
      <FormInput
        {...this.props}
        iconCallback={this.handleToggleVisibility}
        svgIcon={passwordVisible ? iconPwdVisible : iconPwdHidden}
      >
        <input type={passwordVisible ? 'text' : 'password'} className='form-control' />
      </FormInput>
    )
  }
}

export default FormInputPassword
