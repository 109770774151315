import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import FormInput from '../components/Form/FormInput'
import { performValidation, genericEmailValidation } from '../helpers/formValidations'
import Loader from '../components/Loader'
import iconMail from '../../../../../app/assets/images/svg-on-react/icon-mail.svg'

export default class Newsletter extends Component {
  state = {
    email: '',
    emailError: null,
    subscribeErrorMessage: null,
    subscribeSuccessMessage: null,
    loading: false
  }

  handleFormSubmit = (e) => {
    e.preventDefault()

    if (!this.state.emailError) {
      const emailValidate = performValidation.call(this, genericEmailValidation, this.state.email, 'emailError')
      if (emailValidate) {
        this.doSubscribe()
      }
    }
  }

  doSubscribe () {
    this.setState({ loading: true })

    $.ajax({
      url: '/leads',
      method: 'POST',
      dataType: 'json',
      data: { user_email: this.state.email },
      success: ({ key }) => {
        this.setState({ subscribeSuccessMessage: key, subscribeErrorMessage: null })
      },
      error: ({ responseJSON }) => {
        if (responseJSON) {
          this.setState({ subscribeSuccessMessage: null, subscribeErrorMessage: responseJSON.key })
        }
      },
      complete: () => {
        this.setState({ loading: false })
      }
    })
  }

  render () {
    const { email, emailError, subscribeErrorMessage, subscribeSuccessMessage, loading } = this.state

    return (
      <div className='newsletter'>
        <div className='container'>
          <div className='row'>
            <span className='col-md-4 newsletter__title'>
              <span className='hidden-xs hidden-sm'>
                <FormattedMessage id='pages.footer.newsletter.title' />
              </span>
              <span className='hidden-md hidden-lg'>
                <FormattedMessage id='pages.footer.newsletter.title_short' />
              </span>
            </span>

            <div className='col-md-8'>
              <form onSubmit={this.handleFormSubmit} action='' method='post'>
                <div>
                  <FormInput
                    id='user_email'
                    setCallback={(value) => this.setState({
                      email: value,
                      emailError: null,
                      subscribeErrorMessage: null,
                      subscribeSuccessMessage: null
                    })}
                    validateCallback={(value) => performValidation.call(this, genericEmailValidation, value, 'emailError')}
                    errorMessage={subscribeErrorMessage || emailError}
                    successMessage={subscribeSuccessMessage}
                    svgIcon={iconMail}
                    placeholderKey='authentication.user.email'
                    inputValue={email}>
                    <input type='email' className='form-control' />
                  </FormInput>
                </div>

                <div>
                  <button className='btn btn-primary m-0' type='submit' disabled={loading}>
                    {loading && <Loader overlay white />}
                    <span className={loading ? 'invisible' : ''}>
                      <span className='hidden-xs hidden-sm'>
                        <FormattedMessage id='pages.footer.newsletter.submit' />
                      </span>
                      <span className='hidden-md hidden-lg'>
                        <FormattedMessage id='pages.footer.newsletter.submit_short' />
                      </span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
