import React from 'react'
import PropTypes from 'prop-types'

const SvgImage = ({ className = '', svg, ...props }) =>
  <span className={`svg-image svg-image--${svg.id.slice(0, 0 - '-usage'.length)} ${className}`} {...props}>
    <svg viewBox={svg.viewBox}>
      <use xlinkHref={svg.url} />
    </svg>
  </span>

export default SvgImage

SvgImage.propTypes = {
  svg: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
}
