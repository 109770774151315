import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import Collapsible from 'react-collapsible'
import classNames from 'classnames'

import SvgImage from '../../components/SvgImage'
import iconEdit from 'Assets/images/svg-on-react/icon-pencil.svg'
import TravelInformationsForm from '../TravelInformationsForm'

class TravelInformationsSection extends Component {
  enableEditMode = () => {
    if (this.props.travelInfoEditModeSwitcher) { this.props.travelInfoEditModeSwitcher(true) }
  }

  disableEditMode = () => {
    if (this.props.travelInfoEditModeSwitcher) { this.props.travelInfoEditModeSwitcher(false) }
  }

  constructor (props) {
    super(props)
    this.state = {
      editMode: false,
      open: this.warning == null ? true : this.warning
    }
  }

  dateInTimezone = (dateTime, timeZone) => {
    const dateTimeInTimezone = moment(dateTime).tz(timeZone)
    return (
      <span className='purchase-summary__info-block-date'>
        <FormattedMessage id='purchase.purchase_summary.format_date' >
          {datePattern => dateTimeInTimezone.format(datePattern)}
        </FormattedMessage>
        {' '}
        <FormattedMessage id='pages.header.summary.format_hour' >
          {timePattern => dateTimeInTimezone.format(timePattern)}
        </FormattedMessage>
      </span>
    )
  }

  render () {
    const { mandatoryInfos, forwardedRef, formRef, warning, storagePrefix } = this.props
    const { open } = this.state
    const sectionTitleId = mandatoryInfos.length > 0
      ? 'purchase.purchase_summary.travel_informations.title'
      : 'purchase.purchase_summary.travel_informations.title_vehicle'

    const blockClassName = classNames('purchase-summary__block row', {
      'purchase-summary__block--orange': warning
    })

    const storedTravelInfos = sessionStorage.getItem(`${storagePrefix}-travelInfos`)
    const travelInfos = storedTravelInfos ? JSON.parse(storedTravelInfos) : this.props.travelInfos

    return (
      <div className={blockClassName} ref={forwardedRef}>
        <FormattedMessage id={sectionTitleId}>
          {(message) => (
            <Collapsible trigger={message} open={open} overflowWhenOpen={'visible'}
              triggerClassName='purchase-summary__collapsible-block' triggerOpenedClassName='purchase-summary__collapsible-block'>
              {this.props.editMode &&
                <TravelInformationsForm
                  disableEditMode={this.disableEditMode}
                  ref={formRef}
                  travelInfos={travelInfos}
                  protectFromReload={this.props.protectFromReload}
                  turnoffWarning={this.props.turnoffWarning} />}
              {!this.props.editMode &&
                <div className='purchase-summary__block--flex'>
                  <div className='purchase-summary__info-block'>
                    <h4><FormattedMessage id='purchase.purchase_summary.travel_informations.vehicle_title' /></h4>
                    <p key='license_plate'>
                      <span className='sublabel'>
                        <FormattedMessage id='purchase.travel_informations.attributes.license_plate' />
                      </span>
                      {' : '}
                      {travelInfos.unknown_license_plate ? '--' : travelInfos.license_plate}
                    </p>
                    <p key='vehicle-model' className='travel-info-form__label travel-info-form__label--limited-width'>
                      <span className='sublabel'>
                        <FormattedMessage id='purchase.travel_informations.attributes.vehicle_model' />
                      </span>
                      &nbsp;
                      {':'}
                      &nbsp;
                      {travelInfos.vehicle_model}
                    </p>

                    { mandatoryInfos.map((mandatoryInfo, i) => (
                      <p key={i} className='travel-info-form__label travel-info-form__label--limited-width'>
                        <span className='sublabel'>
                          <FormattedMessage id={`purchase.travel_informations.attributes.${mandatoryInfo['name']}`} />
                        </span>
                        {' : '}
                        {mandatoryInfo['type'] === 'datetime_picker'
                          ? this.dateInTimezone(travelInfos[mandatoryInfo['name']], this.props.parkTimezone)
                          : travelInfos[mandatoryInfo['name']]}
                      </p>
                    ))}
                  </div>
                  <div onClick={this.enableEditMode} data-automation-id='purchase-summary__vehicle-information-edit'>
                    <SvgImage svg={iconEdit} className='svg-image--clickable' />
                  </div>
                </div>
              }
            </Collapsible>
          )}
        </FormattedMessage>
      </div>
    )
  }
}

export default TravelInformationsSection
