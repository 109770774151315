import React, { Component } from 'react'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import franceNewFormat from '../../../../../assets/images/plates/franceNewFormat.png'
import franceOldFormat from '../../../../../assets/images/plates/franceOldFormat.png'
import belgiumNewFormat from '../../../../../assets/images/plates/belgiumNewFormat.png'
import belgiumOldFormat from '../../../../../assets/images/plates/belgiumOldFormat.png'
import spainNewFormat from '../../../../../assets/images/plates/spainNewFormat.png'
import spainOldFormat from '../../../../../assets/images/plates/spainOldFormat.png'
import germanyNewFormat from '../../../../../assets/images/plates/germanyNewFormat.png'
import switzerlandNewFormat from '../../../../../assets/images/plates/switzerlandNewFormat.png'
import italyNewFormat from '../../../../../assets/images/plates/italyNewFormat.png'
import italyOldFormat from '../../../../../assets/images/plates/italyOldFormat.png'
import netherlandsNewFormat from '../../../../../assets/images/plates/netherlandsNewFormat.png'
import netherlandsOldFormat from '../../../../../assets/images/plates/netherlandsOldFormat.png'
import portugalNewFormat from '../../../../../assets/images/plates/portugalNewFormat.png'
import portugalOldFormat from '../../../../../assets/images/plates/portugalOldFormat.png'
import {
  COUNTRIES_WITH_CUSTOM_TOOLTIP,
  FRANCE_COUNTRY_ID,
  GERMANY_COUNTRY_ID,
  BELGIUM_COUNTRY_ID,
  ITALY_COUNTRY_ID,
  LICENSE_PLATE_NEW_FORMATS,
  NETHERLANDS_COUNTRY_ID,
  PORTUGAL_COUNTRY_ID,
  SPAIN_COUNTRY_ID,
  SWITZERLAND_COUNTRY_ID
} from './constants'

export class HelpTooltip extends Component {
  state = {
    tooltipRef: React.createRef()
  }

  render () {
    const { country, intl, isOpened, setIsOpened } = this.props
    const tooltipClasses = classNames('tooltip-op__box', 'tootltip-op-arrow', 'tooltip-op__box--top-right', { hide: !isOpened })
    const newFormat = LICENSE_PLATE_NEW_FORMATS[country.id]

    const newLicensePlateFormats = {
      [FRANCE_COUNTRY_ID]: franceNewFormat,
      [SPAIN_COUNTRY_ID]: spainNewFormat,
      [BELGIUM_COUNTRY_ID]: belgiumNewFormat,
      [NETHERLANDS_COUNTRY_ID]: netherlandsNewFormat,
      [PORTUGAL_COUNTRY_ID]: portugalNewFormat,
      [ITALY_COUNTRY_ID]: italyNewFormat,
      [GERMANY_COUNTRY_ID]: germanyNewFormat,
      [SWITZERLAND_COUNTRY_ID]: switzerlandNewFormat
    }

    const oldLicensePlateFormats = {
      [FRANCE_COUNTRY_ID]: franceOldFormat,
      [BELGIUM_COUNTRY_ID]: belgiumOldFormat,
      [SPAIN_COUNTRY_ID]: spainOldFormat,
      [NETHERLANDS_COUNTRY_ID]: netherlandsOldFormat,
      [PORTUGAL_COUNTRY_ID]: portugalOldFormat,
      [ITALY_COUNTRY_ID]: italyOldFormat
    }

    return (
      <div id='tooltip-op' ref={this.state.tooltipRef}>
        <div onClick={() => setIsOpened(!isOpened)}>
          <a className='tooltip-op__link'>
            <FormattedMessage id='booking.need_more_help' />
          </a>
        </div>
        <div className={tooltipClasses}>
          <div className='tooltip-op__box__content'>
            {
              COUNTRIES_WITH_CUSTOM_TOOLTIP.includes(country.id) ? (
                <>
                  <div>
                    <FormattedMessage id={`booking.${country.key}_lp_format_tooltip`} values={{ br: <br /> }} />
                  </div>
                  {newLicensePlateFormats[country.id] && (
                    <img style={{ width: '300px' }} src={newLicensePlateFormats[country.id]} alt={country.key} />
                  )}
                </>
              ) : (
                <>
                  <div>
                    <FormattedMessage
                      id='booking.license_plate_format_tooltip'
                      values={{
                        date: intl.formatMessage({ id: `booking.${country.key}_new_lp_format_date` }),
                        format: newFormat
                      }}
                    />
                  </div>
                  <div>
                    <FormattedMessage id='booking.new_license_plate_examples' />
                  </div>
                  <img style={{ width: '300px' }} src={newLicensePlateFormats[country.id]} alt={country.key} />
                  <div>
                    <FormattedMessage id='booking.old_license_plate_examples' />
                  </div>
                  <img style={{ width: '300px' }} src={oldLicensePlateFormats[country.id]} alt={country.key} />
                </>
              )
            }
          </div>
          <div className='tooltip-op__box__close'>
            <span onClick={() => setIsOpened(false)}>&times;</span>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(HelpTooltip)
