import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import FormInputPassword from '../../../components/Form/FormInputPassword'
import Loader from '../../../components/Loader'
import {
  performPasswordValidation,
  genericConfirmPasswordValidation,
  genericNewPasswordValidation
} from '../../../helpers/formValidations'
import { getParam, datalayerPushGTM } from '../../../helpers/application'
import { localePrefix } from '../../../helpers/authentication'

class ResetPassword extends Component {
  state = {
    newPassword: '',
    confirmPassword: '',
    resetPasswordToken: getParam()['reset_password_token'],
    newPasswordError: false,
    confirmPasswordError: false,
    loading: false
  }

  formSubmitted (e) {
    e.preventDefault()

    // Todo: refactor code
    const inputValidations = [
      performPasswordValidation.call(this, genericNewPasswordValidation, this.state.newPassword, ['newPasswordError', 'confirmPasswordError']),
      performPasswordValidation.call(this, genericConfirmPasswordValidation, this.state.confirmPassword, ['confirmPasswordError', 'newPasswordError'])
    ]

    if (!inputValidations.includes(false)) { this.doReinitialise() }
  }

  doReinitialise () {
    const { newPassword, confirmPassword, resetPasswordToken } = this.state
    const prefix = localePrefix.call(this)

    this.setState({ loading: true })
    fetch(`${prefix}/users/password`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        user: {
          password: newPassword,
          password_confirmation: confirmPassword,
          reset_password_token: resetPasswordToken
        }
      })
    }).then((response) => {
      if (response.status === 204) {
        datalayerPushGTM('form-tracking', 'Users', 'password-reset/success', response.id)
        window.location.replace('/')
      } else { return response.json() }
    }).then((json) => {
      let msg = ''
      if (json && json.errors) {
        for (const error in json.errors) {
          msg += json.errors[error][0]
        }
        this.setState({
          confirmPasswordError: msg,
          loading: false
        })
        datalayerPushGTM('form-tracking', 'Forms', 'Password-reset', msg)
      }
    })
  }

  render () {
    const { newPassword, confirmPassword,
      newPasswordError, confirmPasswordError, loading } = this.state
    const { cguLink } = this.props

    const inputNewPasswordConfig = {
      id: 'user_new_password',
      setCallback: (value) => this.setState({ newPassword: value }),
      validateCallback: (value) => performPasswordValidation.call(this, genericNewPasswordValidation, value, ['newPasswordError', 'confirmPasswordError']),
      errorMessage: newPasswordError,
      inputValue: newPassword,
      placeholderKey: 'authentication.connection.reset_password.new_password'
    }

    const inputConfirmPasswordConfig = {
      id: 'user_confirm_password',
      setCallback: (value) => this.setState({ confirmPassword: value }),
      validateCallback: (value) => performPasswordValidation.call(this, genericConfirmPasswordValidation, value, ['confirmPasswordError', 'newPasswordError']),
      errorMessage: confirmPasswordError,
      inputValue: confirmPassword,
      placeholderKey: 'authentication.connection.reset_password.confirm_password'
    }

    return (
      <div className='react-component'>
        <div className='row' id='reset-password'>
          <div className='col-sm-12'>
            <h1 className='text-center m-bottom-30'>
              <FormattedMessage id='authentication.connection.reset_password.title' />
            </h1>
          </div>
          <div className='row reset-password__form'>
            <div className='col-xs-12'>
              <form onSubmit={(e) => this.formSubmitted(e)} noValidate>

                <div className='form-group col-xs-12'>
                  <FormInputPassword {...inputNewPasswordConfig} />
                </div>

                <div className='form-group col-xs-12'>
                  <FormInputPassword {...inputConfirmPasswordConfig} />
                </div>

                <div className='form-group col-xs-12 password-recovery__cgu'>
                  <FormattedMessage
                    id='authentication.connection.reset_password.accept_cgu_and_pco'
                    values={{
                      cgu: (<a href={cguLink} target='_blank' className='link--secondary'>
                        <FormattedMessage id='authentication.connection.reset_password.cgu' />
                      </a>),
                      privacy_policy: (<a href='' target='_blank' className='link--secondary'>
                        <FormattedMessage id='authentication.connection.reset_password.privacy_policy' />
                      </a>)
                    }}
                  />
                </div>

                <div className='form-group col-xs-12 text-center'>
                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {loading && <Loader overlay white />}
                    <span className={loading ? 'invisible' : ''}>
                      <FormattedMessage id='authentication.validate' />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword
