import React from 'react'
import { FormattedMessage } from 'react-intl'

import EditorialHtml from '../../components/EditorialHtml'
import TranslatedByGoogle from '../../components/TranslatedByGoogle'

const ParkEntryAndExit = ({ park }) => {
  if (!park.access) {
    return <div />
  }

  return (
    <div>
      <h3>
        <FormattedMessage id='pages.park.access.park_entry_and_exit.title' />
        {park.editorialContentTranslatedByGoogle && <aside><TranslatedByGoogle /></aside>}
      </h3>

      <EditorialHtml html={park.access} />
    </div>
  )
}

export default ParkEntryAndExit
