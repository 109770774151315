import MapWrapperMapbox from '../bundles/Onepark/components/MapMapbox/MapWrapper'

export const MAP_PROVIDER_GOOGLE = 'Google'
export const MAP_PROVIDER_MAPBOX = 'Mapbox'

// Pay attention value is a function
export const PROVIDER_MAP_WRAPPER_MAPPING = {
  // This component is not compatible with SSR
  [MAP_PROVIDER_GOOGLE]: () => require('../bundles/Onepark/components/Map/MapWrapper').default,
  [MAP_PROVIDER_MAPBOX]: () => MapWrapperMapbox
}

export const MAP_MARKER_PRICE_FORMAT = {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}
