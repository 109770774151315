import chunk from 'lodash/chunk'

import * as bookingDataTypes from '../types/BookingDataTypes'
import * as types from '../types/SearchTypes'
import { datalayerPushGTM, searchDataLayerPush } from '../bundles/Onepark/helpers/application'
import { buildUrl, buildSearchApiParams, setCurrentLocationType, setCurrentLocationParams } from '../bundles/Onepark/helpers/search'

// TODO: consider sending current park tz as reference timezone for nearby parks to handle country borders edge case

export const loadBestOffers = () => (dispatch, getState) => {
  const { search: { parks, params: { beginDate, endDate, type } }, resources: { localePrefix } } = getState()

  const lookupParks = parks.filter(p => !p.state)
  const internalIds = lookupParks.filter(p => !p.external).map(p => p.id)
  const externalIds = lookupParks.filter(p => p.external).map(p => p.id)
  const internalIdsChunks = chunk(internalIds, 20).map(ids => ids.join(','))
  const groups = [...internalIdsChunks, ...externalIds]
  const apiParams = buildSearchApiParams({ beginDate, endDate, type })

  // needed only for GA events
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('place_id')) {
    apiParams.place_id = urlParams.get('place_id')
  }

  return Promise.all(groups.map(group =>
    fetch(buildUrl(`${localePrefix}/parkings/${group}/best-offer`, apiParams), { credentials: 'include' })
      .then(response => response.ok ? response.json() : [])
      .catch(() => [])
      .then(parks => dispatch({ type: types.BEST_OFFERS_UPDATE, parks }))
  ))
}

export const searchClustersInRect = (rect, zoom) => (dispatch, getState) => {
  const { search: { clusters }, resources: { localePrefix } } = getState()
  const apiParams = {
    evict: clusters.filter(c => c.zoom === zoom).map(p => p.id).join(','),
    rect: rect.join(','),
    zoom
  }
  const url = buildUrl(`${localePrefix}/parkings/async-search-clusters`, apiParams)
  return fetch(url, { credentials: 'include' })
    .then(response => response.ok ? response.json() : [])
    .catch(() => [])
    .then(clusters => clusters.length > 0 && dispatch({ type: types.CLUSTERS_UPDATE, clusters }))
}

export const searchParksInRect = (rect) => (dispatch, getState) => {
  const { search: { params, parks }, resources: { localePrefix } } = getState()
  const apiParams = {
    ...buildSearchApiParams(params),
    evict: parks.map(p => p.id).join(','),
    rect: rect.join(',')
  }
  const url = buildUrl(`${localePrefix}/parkings/async-search-parks`, apiParams)
  return fetch(url, { credentials: 'include' })
    .then(response => response.ok ? response.json() : [])
    .catch(() => [])
    .then(parks => parks.length > 0 && dispatch({ type: types.PARKS_UPDATE, parks }))
}

export const searchParks = () => (dispatch, getState) => {
  const { search: { params }, resources: { localePrefix } } = getState()
  const apiParams = buildSearchApiParams(params)
  const url = buildUrl(`${localePrefix}/parkings/async-search-parks`, apiParams)
  return fetch(url, { credentials: 'include' })
    .then(response => response.ok ? response.json() : [])
    .catch(() => [])
    .then(parks => dispatch({ type: types.PARKS_REPLACE, parks }))
}

export const loadParkSummary = (id) => async (dispatch, getState) => {
  const { resources: { localePrefix } } = getState()
  const res = await fetch(`${localePrefix}/parkings/${id}/summary`, { credentials: 'include' })
  const park = await res.json()
  return dispatch({ type: types.PARKS_UPDATE, parks: [park] })
}

export const updateSorting = (sortBy) => ({ type: types.SORTING_PARAMETERS_UPDATE, sortBy })

export const updateSearchParams = (params) => (dispatch, getState) => {
  if (params.type && params.type !== getState().search.params.type) {
    setCurrentLocationType(params.type)
  }
  return dispatch({ type: types.PARAMS_UPDATE, params })
}

export const updateNextSearchParams = (params) => (dispatch, getState) => {
  if (params.type && params.type !== getState().search.nextParams.type) {
    setCurrentLocationType(params.type)
  }
  return dispatch({ type: types.NEXT_PARAMS_UPDATE, params })
}

export const applySearch = (origin) => async (dispatch, getState) => {
  const { search: { nextParams, nextParams: { address, type } } } = getState()

  datalayerPushGTM('search-tracking', 'Search', `map/success/${type}s/${origin}`, address)
  searchDataLayerPush(nextParams, address, type)

  await dispatch({ type: types.PARKS_SEARCH_PENDING })
  await dispatch({ type: types.PARAMS_UPDATE, params: nextParams })
  setCurrentLocationParams({ type: type === 'subscription' ? 'subscription' : null, q: address })
  await dispatch(searchParks())
  await dispatch({ type: bookingDataTypes.BOOKING_DATA_RESET })
  await dispatch({ type: types.PARKS_SEARCH_SUCCESS })
  dispatch(loadBestOffers())
}

export const navigateToSearchPage = (origin) => async (dispatch, getState) => {
  const { search: { nextParams, nextParams: { address, type } }, resources: { localePrefix } } = getState()

  datalayerPushGTM('search-tracking', 'Search', `map/success/${type}s/${origin}`, address)
  searchDataLayerPush(nextParams, address, type)

  await dispatch({ type: types.PARKS_SEARCH_PENDING })
  const apiParams = buildSearchApiParams(nextParams)
  window.location = buildUrl(`${localePrefix}/parkings`, apiParams)
}
