import { createTransform, persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

import { encode, decode } from '../bundles/Onepark/helpers/jsonCodec'
import bookingDataReducer from './BookingDataReducer'
import nearbyReducer from './NearbyReducer'
import placeReducer from './PlaceReducer'
import railsContextReducer from './RailsContextReducer'
import resourcesReducer from './ResourcesReducer'
import searchReducer from './SearchReducer'
import siteReducer from './SiteReducer'
import purchaseReducer from './PurchaseReducer'
import userReducer from './UserReducer'
import optinReducer from './OptinReducer'

const purchasePersistConfig = {
  key: 'purchase',
  storage: sessionStorage,
  transforms: [createTransform(encode, decode)],
  whitelist: ['partnerCard']
}

export default {
  bookingData: bookingDataReducer,
  nearby: nearbyReducer,
  place: placeReducer,
  railsContext: railsContextReducer,
  resources: resourcesReducer,
  search: searchReducer,
  site: siteReducer,
  purchase: persistReducer(purchasePersistConfig, purchaseReducer),
  user: userReducer,
  optinOptions: optinReducer
}
