import imgMastercard from '../../../../assets/images/icon-payment-mastercard.svg'
import imgVisa from '../../../../assets/images/icon-payment-visa.svg'

export const defaultPaymentMethods = ['visa', 'mastercard']

export const paymentMethodsByCountry = {
  be: ['visa', 'mastercard'],
  ch: ['visa', 'mastercard'],
  de: ['visa', 'mastercard'],
  es: ['visa', 'mastercard'],
  fr: ['visa', 'mastercard'],
  it: ['visa', 'mastercard'],
  nl: ['visa', 'mastercard'],
  pt: ['visa', 'mastercard']
}

// Delete Visa Electron and Maestro
export const paymentIconByName = {
  mastercard: { src: imgMastercard, alt: 'Mastercard' },
  visa: { src: imgVisa, alt: 'Visa' }
}
