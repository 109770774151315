import moment from 'moment'

export const toLocaleDate = (intl, date) =>
  intl.formatDate(date, { year: 'numeric', month: 'long' })

export const zoneAwareDate = (dateString) => {
  const date = moment.parseZone(dateString)
  const tzDelta = date.utcOffset() - new Date().getTimezoneOffset()
  date.subtract(tzDelta, 'minutes')

  return date.toDate()
}

export const toHumanDuration = (intl, duration) => {
  let days = Math.floor(duration / 86400)
  const daysDecimalPart = duration % 86400
  let hours = Math.floor(daysDecimalPart / 3600)
  const hoursDecimalPart = daysDecimalPart % 3600
  const minutes = Math.floor(hoursDecimalPart / 60)

  if (days <= 3 && hours % 12 === 0 && minutes === 0) {
    hours = hours + days * 24
    days = 0
  }

  return intl.formatMessage({ id: 'time.human_duration' }, { days, hours, minutes }).trim()
}

// @deprecated Use moment.parseZone(dateString) instead
export const parseWithTimeZone = (dateStr) => {
  const date = moment(dateStr)
  const [utcOffset] = dateStr.match(/(\+\d\d:\d\d)$/) || []
  if (utcOffset) {
    date.utcOffset(utcOffset)
  }
  return date
}

export const valueToMomentInTimezone = ({ value, timezone, fallback = null }) => {
  const expectedMoment = moment(value)

  if (expectedMoment.isValid()) {
    return expectedMoment.tz(timezone)
  }

  if (!fallback) { return moment().tz(timezone) }

  const fallbackMoment = moment(fallback)

  if (fallbackMoment.isValid()) {
    return fallbackMoment.tz(timezone)
  }

  return moment().tz(timezone)
}
