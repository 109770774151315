import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from './Dropdown'
import { LicensePlateComponent } from './LicensePlateComponent'
import TooltipComponent from 'Components/TooltipComponent'
import AttributeCheckbox from './AttributeCheckbox'
import { Warning } from './Warning'
import { FormattedMessage, injectIntl } from 'react-intl'
import { COUNTRY_LICENSE_PLATE_REGEX, LICENSE_PLATE_SAMPLES, LICENSE_PLATE_COUNTRIES, OTHER_COUNTRY } from './constants'
import HelpTooltip from './HelpTooltip'
import { LicensePlateHelpModal } from './LicensePlateHelpModal'

class VehicleCard extends Component {
  state = {
    licensePlateValue: this.props.licensePlateValue || '',
    country: LICENSE_PLATE_COUNTRIES.find(item => item.id === this.props.countryId),
    validLicensePlate: null,
    isHelpTooltipOpened: false,
    electricVehicle: this.props.electricVehicle || false,
    motorcycle: this.props.motorcycle,
    motorcycleMayChange: this.props.motorcycleMayChange,
    isUnknownLicensePlateDisabled: this.props.isUnknownLicensePlateDisabled,
    unknownLicensePlate: this.props.unknownLicensePlate || false,
    vehicleModel: this.props.vehicleModel || '',
    travelInfoRef: React.createRef(),
    isMobile: false
  }

  componentDidMount () {
    // having empty field is ok until the user submits/blurs from it
    if (this.state.licensePlateValue !== '') this.validateLicensePlate()
    this.setState({ isMobile: window.innerWidth < 768 })
  }

  handleCountryChange = (newCountry) => {
    this.setState({ country: newCountry }, function () {
      this.validateLicensePlate()
    })
  }

  setIsHelpTooltipOpened = (isHelpTooltipOpened) => {
    this.setState({ isHelpTooltipOpened })
  }

  handleLicensePlateInputChange = (value) => {
    this.setState({ licensePlateValue: value })
  }

  handleInputFocus = () => {
    this.setState({ validLicensePlate: null, isHelpTooltipOpened: false })
  }

  handleInputBlur = () => {
    this.validateLicensePlate()
  }

  validateLicensePlate = () => {
    const { licensePlateValue, country, unknownLicensePlate } = this.state

    if (unknownLicensePlate) {
      this.setState({ validLicensePlate: null })
      return
    }

    const normalizedLicensePlateValue = licensePlateValue.trim()

    if (normalizedLicensePlateValue === '') {
      this.setState({ validLicensePlate: false })
      return
    }

    // TODO: to be changed when new requirements appears after [OPDEV-2150]
    const validLicensePlate = new RegExp(COUNTRY_LICENSE_PLATE_REGEX[null], 'gm').test(normalizedLicensePlateValue)

    this.setState({ validLicensePlate, isHelpTooltipOpened: !validLicensePlate })
  }

  handleCheckboxChange = (name, value) => {
    const attributeName = name.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())

    this.setState({ [attributeName]: value })
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event.target.value })
  }

  handleUnknownLicensePlateSet = (name, value) => {
    const attributeName = name.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())

    this.setState({ [attributeName]: value }, () => this.validateLicensePlate())
  }

  render () {
    const { licensePlateValue, country, validLicensePlate, electricVehicle, motorcycle, unknownLicensePlate, vehicleModel, motorcycleMayChange, isUnknownLicensePlateDisabled } = this.state
    const licensePlateRecognition = this.props.licensePlateRecognition
    const { isMobile } = this.state

    return (
      <div className='vehicle-card' ref={this.state.travelInfoRef}>
        {(!isUnknownLicensePlateDisabled && licensePlateRecognition && (unknownLicensePlate || country.id === null)) &&
          <Warning />}

        <div className='form-group'>
          <FormattedMessage id='vehicle_info.form_title' />
        </div>

        {isUnknownLicensePlateDisabled && (
          <div
            className='form-group vehicle-card__aena-warning'
            dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: 'vehicle_info.plate_recognition_system_warning' }) }}
          />
        )}

        <div className='vehicle-card__checkboxes'>
          <AttributeCheckbox
            attributeName={'electric_vehicle'}
            isChecked={electricVehicle}
            handleCheckboxChange={this.handleCheckboxChange}
            containerRef={this.state.travelInfoRef}
            showTooltip
          />
          <AttributeCheckbox
            attributeName={'motorcycle'}
            isChecked={!!motorcycle}
            handleCheckboxChange={this.handleCheckboxChange}
            readonly={!motorcycleMayChange}
            containerRef={this.state.travelInfoRef}
            showTooltip
          />
          <AttributeCheckbox
            attributeName={'unknown_license_plate'}
            isChecked={unknownLicensePlate}
            handleCheckboxChange={this.handleUnknownLicensePlateSet}
            readonly={isUnknownLicensePlateDisabled}
          />
        </div>

        <div className='vehicle-card__model'>
          <div className='vehicle-card__left'>
            <div className='vehicle-card__label-container'>
              <label className='control-label required' htmlFor='vehicle_model'>
                <FormattedMessage id='purchase.travel_informations.attributes.vehicle_model' />
              </label>
            </div>
          </div>
          <div className='vehicle-card__right'>
            <input
              id='vehicle_model'
              name='vehicle_model'
              type='text'
              value={vehicleModel}
              className={'form-control'}
              onChange={this.handleChange('vehicleModel')}
            />
          </div>
        </div>

        <div className='vehicle-card__license-plate'>
          <div className='vehicle-card__left'>
            <div className='vehicle-card__label-container'>
              <label className='control-label required' htmlFor='license_plate'>
                <FormattedMessage id='purchase.travel_informations.attributes.license_plate' />
              </label>
              <TooltipComponent message='vehicle_info.tooltip' />
            </div>
            {!unknownLicensePlate && <Dropdown country={country} onCountryChange={this.handleCountryChange} />}
          </div>
          <input
            type='hidden'
            id='license_plate_country_id'
            name='license_plate_country_id'
            value={country.id || ''}
          />
          <div className='vehicle-card__right'>
            <LicensePlateComponent
              inputValue={unknownLicensePlate ? '' : licensePlateValue}
              country={country}
              validLicensePlate={validLicensePlate}
              onInputChange={this.handleLicensePlateInputChange}
              onInputBlur={this.handleInputBlur}
              disabled={unknownLicensePlate}
            />
            {
              validLicensePlate === false && (
                <span className='vehicle-card__invalid-message'>
                  { (country.id !== OTHER_COUNTRY) &&
                    <FormattedMessage
                      id={'purchase.purchase_error.invalid_license_plate'}
                      values={{ examples: LICENSE_PLATE_SAMPLES[country.id] }}
                    />
                  }
                  { (country.id === OTHER_COUNTRY) && <FormattedMessage id={'purchase.purchase_error.invalid_license_plate_basic'} /> }
                </span>
              )
            }
          </div>
        </div>
        {!unknownLicensePlate && (!isMobile ? (
          <div className='vehicle-card__tooltip-container'>
            <HelpTooltip country={country} isOpened={this.state.isHelpTooltipOpened} setIsOpened={this.setIsHelpTooltipOpened} />
          </div>
        ) : (
          <div>
            <a
              className='vehicle-card__help-modal-link'
              onClick={() => $(`#license-plate-help-modal`).modal('show')}
            >
              <FormattedMessage id='booking.need_more_help' />
            </a>
            <LicensePlateHelpModal country={country} />
          </div>
        ))}
      </div>
    )
  }
}

VehicleCard.propTypes = {
  motorcycle: PropTypes.bool,
  motorcycleMayChange: PropTypes.bool,
  isUnknownLicensePlateDisabled: PropTypes.bool,
  electricVehicle: PropTypes.bool,
  unknownLicensePlate: PropTypes.bool,
  licensePlateRecognition: PropTypes.bool,
  licensePlateValue: PropTypes.string,
  countryId: PropTypes.number,
  vehicleModel: PropTypes.string
}

export default injectIntl(VehicleCard)
