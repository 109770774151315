import React from 'react'
import { FormattedMessage } from 'react-intl'

const SwitchAuthentication = ({ prefix, source, active }) => (
  <div className='switch-authentication row'>
    <div className='col-xs-6 switch-authentication__left'>
      <a href={`${prefix}/connect?origin=${source}`} className={`switch-authentication__link ${active === 'connect' ? 'active' : null}`}>
        <FormattedMessage id='authentication.registration.account.signin' />
      </a>
    </div>
    <div className='col-xs-6 switch-authentication__right'>
      <a href={`${prefix}/registration?origin=${source}`} className={`switch-authentication__link ${active === 'registration' ? 'active' : null}`}>
        <FormattedMessage id='authentication.registration.account.signup' />
      </a>
    </div>
  </div>
)

export default SwitchAuthentication
