import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import LazyLoad, { forceCheck } from 'react-lazyload'

import SvgImage from '../components/SvgImage'
import IconArrowLeftCircle from '../../../../assets/images/icon-arrow-left-circle.svg'
import IconArrowRight from '../../../../assets/images/icon-arrow-right.svg'
import IconArrowRightCircle from '../../../../assets/images/icon-arrow-right-circle.svg'
import IconCity from '../../../../assets/images/icon-city.svg'
import IconAirport from '../../../../assets/images/icon-airport.svg'
import IconStation from '../../../../assets/images/icon-station.svg'
import IconTicket from '../../../../assets/images/icon-ticket.svg'
import IconHoliday from '../../../../assets/images/icon-holiday.svg'

const placeTypes = ['city', 'airport', 'station', 'event', 'country']

const PlacesSection = ({ intl, localePrefix, placesByType }) => (
  <section className='o-section o-section--gray u-center c-slider-city'>
    <div className='l-container'>
      <p className='title-4 u-caps'><FormattedMessage id='pages.homepage.near_you' /></p>
      <h2><FormattedMessage id='pages.homepage.h2' /></h2>
      <p><FormattedMessage id='pages.homepage.h2_subtitle' /></p>
      <div className='o-select'>
        <a className='o-btn o-btn--secondary' href='#'>
          <SvgImage svg={IconCity} className='p-right-10' />
          <FormattedMessage id='pages.homepage.slider.poi_types.city' />
          <SvgImage className='o-btn__icon-right' svg={IconArrowRight} />
        </a>

        <select className='js-tab-select'>
          {placeTypes.map(placeType =>
            <option key={placeType} value={`#destination-${placeType}`}>
              {intl.formatMessage({ id: `pages.homepage.slider.poi_types.${placeType}` })}
            </option>
          )}
        </select>
      </div>

      <ul className='o-tab js-tab-link'>
        {placeTypes.map((placeType, i) =>
          <li key={placeType}>
            <a href={`#destination-${placeType}`} className={i === 0 ? 'is-active' : null} onClick={forceCheck}>
              <SvgImage svg={iconByTab[placeType]} />
              <FormattedMessage id={`pages.homepage.slider.poi_types.${placeType}`} />
            </a>
          </li>
        )}
      </ul>

      {placeTypes.map((placeType, i) =>
        <section
          key={placeType}
          id={`destination-${placeType}`}
          className={`swiper-container o-tab__content js-slider ${i === 0 ? 'is-active' : ''}`}
          data-slides={placesByType[placeType].length}
        >
          <div className='swiper-wrapper'>
            {placesByType[placeType].map(place =>
              <article key={place.id} className='o-card swiper-slide'>
                <div className='o-img-veil' />
                <LazyLoad height={165} width={270} once>
                  <img alt={place.name} src={place.imageUrl} />
                </LazyLoad>

                <span className='place-title'>
                  <a href={place.url}>{place.name}</a>
                </span>

                <div className='o-card__wrapper'>
                  <p className='u-caps'>
                    <FormattedMessage id='pages.homepage.slider.available_parks' values={{ count: place.parksCount }} />
                  </p>
                  <ul>
                    {[...place.places].map(park => <li key={park.id}><a href={park.url}>{park.name}</a></li>)}
                    {[...place.parks].map(park => <li key={park.id}><a href={park.url}>{park.name}</a></li>)}
                  </ul>
                </div>
              </article>
            )}
          </div>

          <div className='swiper-button-prev'>
            <SvgImage svg={IconArrowLeftCircle} />
          </div>
          <div className='swiper-pagination' />
          <div className='swiper-button-next'>
            <SvgImage svg={IconArrowRightCircle} />
          </div>
        </section>
      )}

      <a href={`${localePrefix}/places`} className='o-btn o-btn--primary'>
        <FormattedMessage id='pages.homepage.slider.all_destinations' />
        <SvgImage className='o-btn__icon-right' svg={IconArrowRight} />
      </a>
    </div>
  </section>
)

const iconByTab = {
  airport: IconAirport,
  city: IconCity,
  country: IconHoliday,
  event: IconTicket,
  station: IconStation
}

const mapStateToProps = ({ place: { placesByType }, resources: { localePrefix } }) => ({ localePrefix, placesByType })

export default injectIntl(connect(mapStateToProps)(PlacesSection))
