export const BEST_OFFERS_UPDATE = 'SEARCH/BEST_OFFERS_UPDATE'
export const CLUSTERS_UPDATE = 'SEARCH/CLUSTERS_UPDATE'
export const SORTING_PARAMETERS_UPDATE = 'SEARCH/SORTING_PARAMETERS_UPDATE'
export const PARAMS_UPDATE = 'SEARCH/PARAMS_UPDATE'
export const NEXT_PARAMS_UPDATE = 'SEARCH/NEXT_PARAMS_UPDATE'
export const PARKS_SEARCH_PENDING = 'SEARCH/PARKS_SEARCH_PENDING'
export const PARKS_SEARCH_SUCCESS = 'SEARCH/PARKS_SEARCH_SUCCESS'
export const PARKS_REPLACE = 'SEARCH/PARKS_REPLACE'
export const PARKS_UPDATE = 'SEARCH/PARKS_UPDATE'
export const PARK_FILTERS_UPDATE = 'SEARCH/PARK_FILTERS_UPDATE'
