import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import SvgImage from '../../components/SvgImage'
import Rating from '../common/Rating'
import TranslatedByGoogle from '../../components/TranslatedByGoogle'
import { toLocaleDate, zoneAwareDate } from '../../helpers/date'
import iconArrowDown from '../../../../../../app/assets/images/svg-on-react/icon-arrow-down.svg'
import iconOnepark from '../../../../../../app/assets/images/svg-on-react/icon-onepark.svg'

const i18nBase = 'pages.park.reviews'

class ReviewItem extends Component {
  state = {
    opClamped: true,
    userClamped: true
  }

  handleClickUserClampControl = () => {
    this.setState({ userClamped: !this.state.userClamped })
  }

  handleClickOpClampControl = () => {
    this.setState({ opClamped: !this.state.opClamped })
  }

  componentDidMount () {
    const { comment, showTranslated } = this.props
    const userMessage = (showTranslated && comment.translatedMessage) ? comment.translatedMessage : comment.message
    let userClampedInit = userMessage && userMessage.length > 80
    this.setState({ userClamped: userClampedInit })
  }

  render () {
    const { comment, showTranslated } = this.props
    const { opClamped, userClamped } = this.state

    return (
      <li>
        <div className='row'>
          <div className='col-md-3'>
            <div>
              <strong>{comment.author}</strong>
            </div>
            <Rating
              className='park-reviews__item__rating-stars'
              rating={comment.rating}
            />
            <b className='park-reviews__item__rating-stars'>&nbsp;
              <FormattedMessage id={i18nBase + '.' + ['bad', 'fair', 'good', 'very_good', 'excellent'][(comment.rating - 1)]} />
            </b>
            <PublishedOn comment={comment} withSeo />
          </div>

          <div className='col-md-9 comment'>
            <div className='comment__inner'>
              <div
                className='user-comment'
                onClick={this.handleClickUserClampControl}
              >
                <div className={userClamped ? 'user-comment--clamped' : ''}>
                  <Message
                    comment={comment}
                    showTranslated={showTranslated}
                    withSeo
                  />
                </div>
                <div className={`clamp-backdrop ${userClamped ? '' : 'open'}`}>
                  <div
                    className={`dropdown-control ${
                      userClamped ? '' : 'dropdown-control--open'
                    }`}
                  >
                    <SvgImage svg={iconArrowDown} />
                  </div>
                </div>
              </div>

              {comment.threadComments && (comment.threadComments.map(threadComment => ( // render thread comments for review
                <div
                  className='op-comment'
                  onClick={this.handleClickOpClampControl}
                >
                  <div className={opClamped ? 'op-comment--clamped' : ''}>
                    {(threadComment.isOneparkComment == false) && (
                      <p>
                        <strong>{threadComment.author}</strong>
                      </p>
                    )}
                    {(threadComment.isOneparkComment == true) && (
                      <p>
                        <SvgImage svg={iconOnepark} />
                        <strong>
                          <FormattedMessage id={`${i18nBase}.answer_from`} />{' '}
                          Onepark Team
                        </strong>
                      </p>
                    )}
                    <PublishedOn comment={threadComment} />
                    <Message
                      comment={threadComment}
                      showTranslated={showTranslated}
                    />
                  </div>
                  <div className={`clamp-backdrop ${opClamped ? '' : 'open'}`}>
                    <div
                      className={`dropdown-control ${
                        opClamped ? '' : 'dropdown-control--open'
                      }`}
                    >
                      <SvgImage svg={iconArrowDown} />
                    </div>
                  </div>
                </div>
              )))}

              {comment.opComment && (   // TBD: remove later
                <div
                  className='op-comment'
                  onClick={this.handleClickOpClampControl}
                >
                  <div className={opClamped ? 'op-comment--clamped' : ''}>
                    <p>
                      <SvgImage svg={iconOnepark} />
                      <strong>
                        <FormattedMessage id={`${i18nBase}.answer_from`} />{' '}
                        Onepark Team
                      </strong>
                    </p>
                    <PublishedOn comment={comment.opComment} />
                    <Message
                      comment={comment.opComment}
                      showTranslated={showTranslated}
                    />
                  </div>
                  <div className={`clamp-backdrop ${opClamped ? '' : 'open'}`}>
                    <div
                      className={`dropdown-control ${
                        opClamped ? '' : 'dropdown-control--open'
                      }`}
                    >
                      <SvgImage svg={iconArrowDown} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </li>
    )
  }
}

const PublishedOn = injectIntl(({ comment, intl }) => (
  <p>
    <small className='text-muted'>
      <FormattedMessage
        id={`${i18nBase}.published_on`}
        values={{ d: toLocaleDate(intl, zoneAwareDate(comment.createdAt)) }}
      />
    </small>
  </p>
))

const Message = injectIntl(({ comment, intl, showTranslated }) => {
  const translated = showTranslated && comment.translatedMessage
  return (
    <div>
      <p className='text--pre-line'>
        {translated ? comment.translatedMessage : comment.message}
      </p>
      {translated && comment.sourceLocale !== intl.locale && (
        <p>
          <TranslatedByGoogle />
        </p>
      )}
    </div>
  )
})

export default ReviewItem
