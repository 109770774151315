import React from 'react'
import SvgImage from '../SvgImage'
import { capitalize } from '../../../../libs/string'
import iconMarker from '../../../../../../app/assets/images/svg-on-react/icon-marker.svg'

const SearchAutocompleteItem = (props) => {
  const { onChange, place } = props
  return (
    <div
      key={place.id}
      className='pac-item'
      onClick={() => {
        onChange({
          address: `${capitalize(place.poi)}, ${place.locationName} ${place.city}`,
          addressCompleted: false,
          place: null
        })
      }}
    >
      <SvgImage svg={iconMarker} className='pac-icon' />
      <span className='pac-item-query'>
        <span className='pac-matched'>{capitalize(place.poi)}</span>
      </span>
      {' '}
      <span className='pac-item-other'>
        <span>
          {place.locationName}
        </span>
        {' '}
        <span>
          {place.city}
        </span>
      </span>
    </div>
  )
}

export default SearchAutocompleteItem
