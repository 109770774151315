import moment from 'moment'
import React, { Component } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { getTimePickerOptions } from '../../helpers/search'
import FormInputDatePicker from './FormInputDatePicker'
import FormInputTimePicker from './FormInputTimePicker'

class FormInputDateTimePicker extends Component {
  state = {
    dateTimeFocus: false,
    value: this.props.value === undefined ? undefined : moment(this.props.value)
  }

  focusTimePicker () {
    this.setState({ dateTimeFocus: true })
    setTimeout(() => this.timePickerInput.focus(), 0)
  }

  handleDateChange = (value) => {
    this.props.setCallback(value)
    this.setState({ value })
    // TODO if date valid, call this.props.onChange
  }

  handleBlur = () => {
    this.setState({ dateTimeFocus: false })
  }

  calculateTimePickerDate = () => {
    const { value } = this.state
    const { timezone, disabledBefore, disabledAfter } = this.props

    const timezonedValue = (value && value.isValid()) ? value.clone().tz(timezone) : null

    if (!timezonedValue || timezonedValue.isSame(disabledBefore, 'day')) {
      return disabledBefore.clone().tz(timezone)
    }

    if (disabledAfter && timezonedValue.isSame(disabledAfter, 'day')) {
      return disabledAfter.clone().tz(timezone).startOf('day')
    }

    if (timezonedValue.isAfter(disabledBefore, 'day')) {
      return timezonedValue.startOf('day')
    }
  }

  calculateTimePickerOptions = () => {
    const { hoursFormat } = this.props.railsContext
    const { disabledBefore, disabledAfter } = this.props

    if (disabledAfter && disabledAfter.isSame(disabledBefore, 'day')) {
      return getTimePickerOptions(disabledBefore, hoursFormat, disabledAfter)
    }

    const timePickerDate = this.calculateTimePickerDate()

    if (disabledAfter && timePickerDate.isSame(disabledAfter, 'day')) {
      return getTimePickerOptions(timePickerDate, hoursFormat, disabledAfter)
    }

    return getTimePickerOptions(timePickerDate, hoursFormat)
  }

  render () {
    const { id, placeholderDateKey, placeholderTimeKey, errorMessage, disabledBefore, disabledAfter } = this.props
    const { value, dateTimeFocus } = this.state
    const timezone = this.props.timezone || moment.tz.guess()

    const dateTimeState = classNames('inputDateTimePicker', 'u-clearfix', 'form-group', {
      'inputDateTimePicker--has-value': !!value,
      'inputDateTimePicker--focus': dateTimeFocus,
      'is-invalid': !dateTimeFocus && errorMessage
    })

    const options = this.calculateTimePickerOptions()

    let timepickerValue = value ? value.toISOString() : ''

    if (timepickerValue !== '' && options.every(option => option.value !== timepickerValue)) {
      timepickerValue = options[0].value
    }

    return (
      <div className='input-keyboard-op'>
        <div className={dateTimeState}>
          <div className='row'>
            <div className='col-xs-6'>
              <FormInputDatePicker
                id={`${id}Date`}
                value={value}
                disabledBefore={disabledBefore}
                disabledAfter={disabledAfter}
                placeholderKey={placeholderDateKey}
                setCallback={(date) => {
                  this.handleDateChange(date)
                  this.focusTimePicker()
                }}
                required
                inputRef={el => { this.dayPickerInput = el }}
                onDateBlur={this.handleBlur}
              />
            </div>
            <div className='col-xs-6'>
              <FormInputTimePicker
                id={`${id}Time`}
                value={timepickerValue}
                placeholderKey={placeholderTimeKey}
                options={options}
                setCallback={isoDate => {
                  const newTime = moment(isoDate).tz(timezone)
                  this.handleDateChange(newTime)
                  this.props.validateCallback(newTime)
                }}
                required
                inputRef={el => { this.timePickerInput = el }}
                onTimeBlur={this.handleBlur}
              />
            </div>
          </div>
        </div>
        {errorMessage &&
          <div className={`input-error ${this.props.additionalErrorClass || ''}`}>
            <div className='p-4'>
              <FormattedMessage id={errorMessage} />
            </div>
          </div>
        }
      </div>
    )
  }
}

export default FormInputDateTimePicker
