import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl'
import { toHumanDuration } from 'Helpers/date'
import moment from 'moment'

import ParkOfferSummary from '../ParkOfferSummary'
import { RegistrationProgress } from './RegistrationProgress'

import SvgImage from 'Components/SvgImage'

import iconCarFace from 'Assets/images/svg-on-react/icon-car-face.svg'
import iconCoins from 'Assets/images/svg-on-react/icon-coins.svg'
import iconPencil from 'Assets/images/svg-on-react/icon-pencil.svg'
import iconPhone from 'Assets/images/svg-on-react/icon-phone.svg'
import iconPrinter from 'Assets/images/svg-on-react/icon-printer.svg'

import { clearPreviousEcommerceObject } from '../../../../libs/google_analytics_helpers'
import URLSearchParams from 'url-search-params'

class PurchaseConfirmationSuccess extends Component {
  state = {
    offerType: this.props.offerType
  }

  handlePrintPage = () => {
    const { history, offerBuilder, localePrefix } = this.props
    const purchase = history.location.state ? history.location.state.purchase : offerBuilder
    window.open(`${localePrefix}/purchases/${purchase.id}/confirmation_email?print=true`, '_blank')
  }

  pullBookingState (ga4Events) {
    const renderedWithoutGa4EventFromBackend = ga4Events === undefined
    const bookingFinished = ga4Events && ga4Events.some((ga4Event) => (ga4Event.event === 'purchase'))
    const bookingError = ga4Events !== undefined && ga4Events.length === 0
    const eventNeedsFurtherReloading = !bookingFinished && !bookingError
    if (renderedWithoutGa4EventFromBackend || eventNeedsFurtherReloading) {
      setTimeout(() => { window.location.reload() }, 5000)
    }
  }

  emitGaEvent (ga4Events) {
    if (ga4Events === undefined) return

    clearPreviousEcommerceObject()
    ga4Events.forEach(ga4Event => window.dataLayer.push(ga4Event))
  }

  componentDidMount () {
    if (!this.state.offerType) {
      const urlParams = new URLSearchParams(this.props.history.location.search)
      const offerTypeParams = urlParams.get('type') === 'subscription' ? 'subscription' : 'package'
      this.setState({ offerType: offerTypeParams })
    }
    history.pushState(null, null, location.href)
    window.onpopstate = () => {
      history.go(1)
    }
    const { ga4Events } = this.props
    this.emitGaEvent(ga4Events)
    this.pullBookingState(ga4Events)

    if (process.env.TAG_ANALYTICS_TRANSACTION_SUCCESS_ENABLED === 'true') {
      const { history, offerBuilder } = this.props
      const purchase = history.location.state ? history.location.state.purchase : offerBuilder

      // Google Analytics Ecommerce Tracking
      window.gaTransaction = {
        id: purchase.booking.id,
        affiliation: purchase.park.name,
        revenue: purchase.paidPrice,
        currency: 'EUR'
      }
      window.gaItem = {
        id: purchase.booking.id,
        name: purchase.park.name,
        sku: purchase.offer.title || 'Grille tarifaire',
        category: purchase.offer.type,
        price: parseFloat(purchase.paidPrice),
        quantity: 1
      }
      document.cookie = 'new_purchase_confirmation=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

      // Facebook Tracking
      window.fbConversion = { 'value': purchase.quotedPrice, 'currency': 'EUR' }
    }
  }

  render () {
    const { beginDate, discountCode, endDate, renewalDate, history, localePrefix, offerBuilder, user, intl } = this.props
    const { offerType } = this.state
    const purchase = history.location.state ? history.location.state.purchase : offerBuilder
    const purchaseState = history.location.state ? history.location.state.purchaseState : offerBuilder.state
    const parkUrl = `${localePrefix}/parkings/${offerBuilder.park.id}`
    const showRegistrationProgress = ['recovering', 'created', 'error', 'confirmed'].includes(purchaseState)
    const showUsefulPhoneNumbers = purchaseState === 'confirmed' && (offerBuilder.park.valetPhone || offerBuilder.park.shuttlePhone)
    const offerEndDate = moment(this.props.offerEndDate).tz(offerBuilder.park.timezoneName)
    const showOptionPrice = purchase.booking.multiple_entries_option && purchase.multipleEntries.optionPrice > 0

    return (
      <div className='row purchase-container h-100p'>
        <div className='col-xs-12 col-sm-8'>
          {showRegistrationProgress &&
            <RegistrationProgress purchaseState={purchaseState} parkUrl={parkUrl} bookingId={purchase.booking.id} email={user.email} localePrefix={localePrefix} />
          }

          {!showRegistrationProgress &&
            <h1>
              <FormattedMessage
                id={`purchase.confirmation.purchase_${purchaseState}`}
                values={{ purchase_id: purchase.booking.id }}
              />
            </h1>
          }

          { showUsefulPhoneNumbers &&
            <div className='purchase-confirmation__shuttle-info'>
              <div>
                <SvgImage svg={iconPhone} />
              </div>
              <div>
                <h3 className='purchase-confirmation__shuttle-info--green purchase-confirmation__shuttle-info--marginless purchase-confirmation__title'>
                  <FormattedMessage id='purchase.confirmation.contact_phones_title' />
                </h3>
                {
                  offerBuilder.park.valetPhone &&
                  <p>
                    <FormattedMessage
                      id='purchase.confirmation.valet_phone'
                    >
                      {text => <><span className='purchase-confirmation--bold'>{text}</span> <a className='purchase-confirmation__shuttle-info--green' href={`tel:${offerBuilder.park.valetPhone}`}>{offerBuilder.park.valetPhone}</a></>}
                    </FormattedMessage>
                  </p>
                }

                {
                  offerBuilder.park.shuttlePhone &&
                  <p>
                    <FormattedMessage
                      id='purchase.confirmation.shuttle_phone'
                    >
                      {text => <><span className='purchase-confirmation--bold'>{text}</span> <a className='purchase-confirmation__shuttle-info--green' href={`tel:${offerBuilder.park.shuttlePhone}`}>{offerBuilder.park.shuttlePhone}</a></>}
                    </FormattedMessage>
                  </p>
                }
              </div>
            </div>
          }

          {purchaseState !== 'error' &&
            <>
              <div className='purchase-confirmation__reassurance hidden-xs'>
                {offerType === 'package' &&
                  <p>
                    <SvgImage svg={iconCarFace} />
                    <FormattedMessage
                      id='purchase.confirmation.purchase_recover_car'
                      values={{
                        date: <FormattedMessage id='pages.header.summary.format_day' >{f => offerEndDate.format(f)}</FormattedMessage>,
                        time: <FormattedMessage id='pages.header.summary.format_hour' >{f => offerEndDate.format(f)}</FormattedMessage>
                      }}
                    />
                  </p>
                }
                <p>
                  <SvgImage svg={iconPencil} />
                  {offerType === 'package' && offerBuilder.maxModificationDelay > 0 &&
                    <FormattedHTMLMessage
                      id='purchase.purchase_summary.package.purchase_update'
                      values={{ duration: toHumanDuration(intl, offerBuilder.maxModificationDelay) }}
                    />
                  }
                  {offerType === 'package' && offerBuilder.maxModificationDelay === 0 &&
                    <FormattedMessage id='purchase.purchase_summary.package.purchase_update_zero' />
                  }
                  {offerType === 'subscription' &&
                    <FormattedMessage
                      id='purchase.purchase_summary.subscription.renewal'
                      values={{ date: moment(renewalDate).locale(intl.locale).format('D MMMM YYYY') }}
                    />
                  }
                </p>
                <p>
                  <SvgImage svg={iconCoins} />
                  {offerType === 'package' && offerBuilder.maxCancellationDelay > 0 &&
                    <FormattedHTMLMessage
                      id='purchase.purchase_summary.package.purchase_cancel'
                      values={{ duration: toHumanDuration(intl, offerBuilder.maxCancellationDelay) }}
                    />
                  }
                  {offerType === 'package' && offerBuilder.maxCancellationDelay === 0 &&
                    <FormattedMessage
                      id='purchase.purchase_summary.package.purchase_cancel_zero'
                    />
                  }
                  {offerType === 'subscription' && offerBuilder.maxModificationDelay > 0 &&
                    <FormattedHTMLMessage
                      id='purchase.purchase_summary.subscription.purchase_cancel'
                      values={{ duration: toHumanDuration(intl, offerBuilder.maxModificationDelay) }}
                    />
                  }
                  {offerType === 'subscription' && offerBuilder.maxModificationDelay === 0 &&
                    <FormattedMessage
                      id='purchase.purchase_summary.subscription.purchase_cancel_zero'
                    />
                  }
                </p>
              </div>

              {purchaseState === 'confirmed' &&
                <>
                  <div className='purchase-confirmation__offer-actions'>
                    <div className='purchase-confirmation__print-btn'>
                      <a href='#print' onClick={this.handlePrintPage} className='link--secondary'>
                        <SvgImage svg={iconPrinter} />
                        <FormattedMessage id='purchase.confirmation.print' />
                      </a>
                    </div>
                    <a href={`${localePrefix}/purchases/${purchase.id}`} className='btn btn-primary purchase-confirmation__renew-btn'>
                      <FormattedMessage id='purchase.confirmation.show_purchase' />
                    </a>
                  </div>
                </>
              }
            </>
          }
        </div>

        <div className='purchase-confirmation__summary col-xs-12 col-sm-4'>
          <div className='purchase-confirmation__offer'>
            <div className='purchase-confirmation__offer-title'>
              <FormattedMessage id='purchase.purchase_summary.offer.title' />
            </div>
            <ParkOfferSummary
              beginDate={beginDate}
              endDate={endDate}
              offerBuilder={offerBuilder}
              park={offerBuilder.park}
              discountCode={discountCode}
              displayPriceBeforeDiscount={false}
              offerAmount={purchase.amount.totalWithOptions}
              offerType={offerType}
              showItinerary={purchaseState === 'confirmed'}
              optionsPrice={purchase.multipleEntries.optionPrice}
              showOptionPrice={showOptionPrice} />
          </div>
        </div>

        {purchaseState !== 'error' &&
          <div className='purchase-confirmation__reassurance purchase-confirmation__reassurance--small hidden-sm hidden-md hidden-lg col-xs-12'>
            {offerType === 'package' &&
              <p>
                <SvgImage svg={iconCarFace} />
                <FormattedMessage
                  id='purchase.confirmation.purchase_recover_car'
                  values={{
                    date: <FormattedMessage id='pages.header.summary.format_day' >{f => offerEndDate.format(f)}</FormattedMessage>,
                    time: <FormattedMessage id='pages.header.summary.format_hour' >{f => offerEndDate.format(f)}</FormattedMessage>
                  }}
                />
              </p>
            }
            <p>
              <SvgImage svg={iconPencil} />
              {offerType === 'package' && offerBuilder.maxModificationDelay > 0 &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.package.purchase_update'
                  values={{ duration: toHumanDuration(intl, offerBuilder.maxModificationDelay) }}
                />
              }
              {offerType === 'package' && offerBuilder.maxModificationDelay === 0 &&
                <FormattedHTMLMessage id='purchase.purchase_summary.package.purchase_update_zero' />
              }
              {offerType === 'subscription' &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.subscription.renewal'
                  values={{ date: moment(renewalDate).locale(intl.locale).format('D MMMM YYYY') }}
                />
              }
            </p>
            <p>
              <SvgImage svg={iconCoins} />
              {offerType === 'package' && offerBuilder.maxCancellationDelay > 0 &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.package.purchase_cancel'
                  values={{ duration: toHumanDuration(intl, offerBuilder.maxCancellationDelay) }}
                />
              }
              {offerType === 'package' && offerBuilder.maxCancellationDelay === 0 &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.package.purchase_cancel_zero'
                />
              }
              {offerType === 'subscription' && offerBuilder.maxModificationDelay > 0 &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.subscription.purchase_cancel'
                  values={{ duration: toHumanDuration(intl, offerBuilder.maxModificationDelay) }}
                />
              }
              {offerType === 'subscription' && offerBuilder.maxModificationDelay === 0 &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.subscription.purchase_cancel_zero'
                />
              }
            </p>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = ({
  purchase: {
    discountCode,
    ga4Events,
    offer: { beginDate, endDate, renewalDate, offerEndDate, offerBuilder, offerType }
  },
  resources: { mobilePurchaseTunnel, mobilePurchaseTunnelPaymentInside, localePrefix, termsPath },
  user
}) => ({
  mobilePurchaseTunnel,
  mobilePurchaseTunnelPaymentInside,
  beginDate,
  discountCode,
  termsPath,
  endDate,
  localePrefix,
  offerEndDate,
  offerBuilder,
  user,
  ga4Events,
  offerType,
  renewalDate
})

export default injectIntl(connect(mapStateToProps)(PurchaseConfirmationSuccess))
