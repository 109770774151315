import React from 'react'
import { MAP_MARKER_PRICE_FORMAT } from '../../../../constants/map'
import Loader from '../Loader'

const MapMarkerContent = ({
  firstParkIsPrimary,
  index,
  intl,
  park
}) => (
  <div className='map-marker__content'>
    {
      park.state &&
      park.bestOffer &&
      intl.formatNumber(
        park.bestOffer.amount.totalWithoutOptions,
        MAP_MARKER_PRICE_FORMAT
      )
    }
    {!!park.state && !park.bestOffer && '\u00a0–\u00a0'}
    {!park.state && (
      <Loader white={index === 0 && firstParkIsPrimary} />
    )}
  </div>
)

export default MapMarkerContent
