import React from 'react'

import ReviewItem from './ReviewItem'

const Reviews = ({ comments, loading, showTranslated }) => (
  <ul className={`park-reviews__list ${loading ? 'park-reviews__list--loading' : ''}`}>
    {comments.map(comment => <ReviewItem key={comment.id} comment={comment} showTranslated={showTranslated} />)}
  </ul>
)

export default Reviews
