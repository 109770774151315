import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import FormInput from '../../components/Form/FormInput'
import { performValidation, genericEmailValidation } from '../../helpers/formValidations'
import { datalayerPushGTM } from '../../helpers/application'
import { localePrefix } from '../../helpers/authentication'
import HistoryComponent from '../../components/HistoryComponent'
import Loader from '../../components/Loader'
import iconMail from '../../../../../../app/assets/images/svg-on-react/icon-mail.svg'

class Request extends Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      loading: false,
      emailError: false
    }
  }

  doSendRecover () {
    const { email } = this.state
    const { parkId, railsContext } = this.props
    const prefix = localePrefix.call(this)

    this.setState({ loading: true })
    fetch(`${prefix}/email_alerts`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        park_available_form: {
          email: email,
          park_id: parkId,
          locale: railsContext.currentLocale
        }
      })
    }).then((response) => {
      return response.json()
    }).then((json) => {
      if (json.errors) {
        this.setState({
          emailError: json.errors.email[0],
          loading: false
        })
        datalayerPushGTM('form-tracking', 'Forms', 'Availability-alert', json.errors.email[0])
      } else {
        this.props.onConfirmationSent(email)
        datalayerPushGTM('form-tracking', 'Parks', 'Availability-alert-sent/success', json.email)
      }
    })
  }

  handleSubmitForm (e) {
    e.preventDefault()
    const emailValidate = performValidation.call(this, genericEmailValidation, this.state.email, 'emailError')
    if (emailValidate) {
      this.doSendRecover()
    }
  }

  render () {
    const { email, emailError, loading } = this.state
    const { parkName, isPage } = this.props

    return (
      <div className='react-component availability-alert'>
        <div className='row'>
          <div className='col-xs-12'>
            <h1 className='text-center'>
              <FormattedMessage id='pages.park.availability_alert.title' />
            </h1>
          </div>

          <div className='col-xs-12 m-bottom-20'>
            <p className='text-center'>
              <FormattedMessage id='pages.park.availability_alert.intro' values={{ park_name: parkName }} />
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <form onSubmit={(e) => this.handleSubmitForm(e)} noValidate>

              <FormInput
                id='emailConnectField'
                setCallback={(value) => this.setState({ email: value })}
                validateCallback={(value) => performValidation.call(this, genericEmailValidation, value, 'emailError')}
                errorMessage={emailError}
                svgIcon={iconMail}
                placeholderKey='authentication.user.email'
                inputValue={email}>
                <input type='email' className='form-control' />
              </FormInput>

              <div className='text-center'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {loading && <Loader overlay white />}
                  <span className={loading ? 'invisible' : ''}>
                    <FormattedMessage id='pages.park.availability_alert.cta' />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
        { !isPage &&
          <HistoryComponent
            id='pages.park.availability_alert.title_tag'
            modal={!isPage && 'availability-alert'}
            availabilityAlert={isPage}
            closeModal />
        }
      </div>
    )
  }
}

export default Request
