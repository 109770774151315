import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

export class HoverDropDown extends React.Component {
  static propTypes = {
    ToggleButton: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = { isOpened: false }
  }

  componentDidUpdate() {
    const { isOpened } = this.state

    if (isOpened) {
      $('main').css('opacity', 0.5);
      $('footer').css('opacity', 0.5);
    } else {
      $('main').css('opacity', '');
      $('footer').css('opacity', '');
    }
  }

  render() {
    const { id, ToggleButton, children } = this.props

    const dropDownContentClass = classNames('dropdown__content', {
      'visually-hidden': !this.state.isOpened
    })

    return (
      <div id={id} className='dropdown'>
        <ToggleButton
          isOpened={this.state.isOpened}
          onMouseEnter={() => this.onMouseEnterToggleButton()}
          onMouseLeave={() => this.onMouseLeaveToggleButton()} />

        <div
          className={dropDownContentClass}
          onMouseEnter={() => this.onMouseEnterContent()}
          onMouseLeave={() => this.onMouseLeaveContent()}
        >
          {children}
        </div>
      </div>
    )
  }

  onMouseEnterToggleButton() {
    this.setState({ toggleButtonHovered: true, isOpened: true })
  }

  onMouseLeaveToggleButton() {
    this.setState({ toggleButtonHovered: false, isOpened: this.state.toggleContentHovered })
  }

  onMouseEnterContent() {
    this.setState({ toggleContentHovered: true, isOpened: true })
  }

  onMouseLeaveContent() {
    this.setState({ toggleContentHovered: false, isOpened: this.state.toggleButtonHovered })
  }
}
