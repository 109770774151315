import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'

import FormInput from '../FormInput'
import { datalayerPushGTM } from '../../../helpers/application'
import { localePrefix } from '../../../helpers/authentication'
import iconGeoloc from '../../../../../../../app/assets/images/svg-on-react/icon-geoloc.svg'
import iconMarker from '../../../../../../../app/assets/images/svg-on-react/icon-marker.svg'

class AddressSearchInput extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholderKey: PropTypes.string,
    hasError: PropTypes.bool,
    hasFocus: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  }

  state = {
    autocomplete: null,
    geolocStatus: null,
    geolocError: { title: '', content: '' },
    hasFocus: false
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.hasFocus !== 'undefined') {
      this.setState({ hasFocus: nextProps.hasFocus })
      if (nextProps.hasFocus) this._focusFormInput()
    }
  }

  _addListeners () {
    this.state.autocomplete && this.state.autocomplete.addListener('place_changed', () => this._onTransportAddressChanged())
  }

  _addPopOverListener () {
    const popOverCloseElement = document.querySelector('.popover .popover-title .close')

    if (popOverCloseElement) {
      popOverCloseElement.addEventListener('click', () => {
        this._removePopOverListener()
      })
    }
  }

  _removePopOverListener () {
    const { id } = this.props
    const popOverCloseElement = document.querySelector('.popover .popover-title .close')

    if (popOverCloseElement) {
      $(`#${id}Wrapper`).popover('hide')
      popOverCloseElement.removeEventListener('click')
    }
  }

  _tryLocation () {
    if (navigator.geolocation && this.state.geolocStatus !== 'pending') {
      this.setState({
        geolocStatus: 'pending'
      })
      navigator.geolocation.getCurrentPosition((pos) => this._successPositionCallback(pos), (err) => this._failedPositionCallback(err))
    } else {
      console.log('Unsupported browser')
    }
  }

  _successPositionCallback (position) {
    const coords = position.coords
    const prefix = localePrefix.call(this)

    if (!isNaN(coords.latitude) && !isNaN(coords.longitude)) {
      const params = `additional_params[latlng]=${coords.latitude},${coords.longitude}&additional_params[sensor]=true`
      fetch(`${prefix}/google_services/geocode?${params}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      }).then((response) => {
        return response.json()
      }).then((json) => {
        return fetch(json.geocode_route, { method: 'GET' })
      }).then((response) => {
        return response.json()
      }).then((json) => {
        this.setState({
          geolocStatus: 'success'
        })
        if (json.status === 'OK' && json.results && json.results.length) {
          this.props.onChange({
            address: json.results[0].formatted_address,
            addressCompleted: true,
            place: false
          })
        }
      })
    }
  }

  _failedPositionCallback (err) {
    const { id } = this.props
    const { formatMessage } = this.props.intl

    let error
    switch (err.code) {
      case err.PERMISSION_DENIED:
        error = {
          type: 'PERMISSION_DENIED',
          error_title: {
            id: 'pages.homepage.geolocation_errors.access_denied.title'
          },
          error_content: {
            id: 'pages.homepage.geolocation_errors.access_denied.content'
          }
        }
        break
      case err.POSITION_UNAVAILABLE:
        error = {
          type: 'POSITION_UNAVAILABLE'
        }
        break
      case err.TIMEOUT:
        error = {
          type: 'TIMEOUT',
          error_title: {
            id: 'pages.homepage.geolocation_errors.other.title'
          },
          error_content: {
            id: 'pages.homepage.geolocation_errors.other.content'
          }
        }
        break
      default:
        error = {
          type: 'UNKNOWN',
          error_title: {
            id: 'pages.homepage.geolocation_errors.unknown.title'
          },
          error_content: {
            id: 'pages.homepage.geolocation_errors.unknown.content'
          }
        }
        break
    }

    const messages = defineMessages(error)
    const formattedTitle = formatMessage(messages.error_title)
    const formattedContent = formatMessage(messages.error_content)

    this.setState({
      geolocStatus: 'error',
      geolocError: {
        title: formattedTitle,
        content: formattedContent
      }
    }, () => {
      $(`#${id}Wrapper`).popover('show')
      this._addPopOverListener()
      datalayerPushGTM('form-tracking', 'Forms', 'Geolocation', error.type)
    })
  }

  _onTransportAddressChanged () {
    const place = this.state.autocomplete ? this.state.autocomplete.getPlace() : ''
    if (place.formatted_address) {
      let address = place.formatted_address
      if (!place.formatted_address.includes(place.name)) {
        address = `${place.name} ${address}`
      }

      this.props.onChange({
        address: address,
        addressCompleted: true,
        place
      })
    }
  }

  componentDidMount () {
    const { id } = this.props
    const autoCompleteOptions = { componentRestrictions: { country: window.autosearch_countries } }

    if (typeof google !== 'undefined' && google.maps.places) {
      const autocomplete = new google.maps.places.Autocomplete(document.getElementById(id), autoCompleteOptions)
      autocomplete.setFields(['formatted_address', 'geometry', 'name'])
      this.setState({
        autocomplete
      }, this._addListeners)
    }
  }

  /**
   * Manage focus on the field
   * @param {Boolean} [hasFocus=false]
   * @private
   */
  _handleFocus (hasFocus = false, onFocus) {
    this.setState({ hasFocus }, () => onFocus(hasFocus))
  }

  /**
   * Give the focus to the FormInput component
   * @private
   */
  _focusFormInput = () => {
    document.getElementById(this.props.id).focus()
  }

  render () {
    const { id, placeholderKey, value, onChange, hasError, onFocus } = this.props
    const { geolocStatus, geolocError } = this.state

    return (
      <div id={`${id}Wrapper`}
        className={'inputAddress' + (geolocStatus ? ` geoloc-${geolocStatus}` : '')}
        data-toggle='popover'
        data-trigger='manual'
        data-placement='bottom'
        data-html='true'
        data-title={
          '<span>' + geolocError.title + '</span><div class="close">×</div>'
        }
        data-content={geolocError.content}
      >
        <FormInput
          id={`${id}FormInput`}
          withoutFloatingLabel
          svgIcon={iconGeoloc}
          svgIconLeft={iconMarker}
          iconCallback={() => this._tryLocation()}
          clearInputCallback={value ? () => onChange({ address: '', addressCompleted: false, place: null }) : null}
          errorMessage={hasError}
          hasFocus={this.state.hasFocus}
          ref={input => { this.formInput = input }}
        >
          <FormattedMessage id={placeholderKey}>
            {(message) => (
              <span className={`floating-label ${value ? 'floating-label--top' : ''}`}>{message}</span>
            )}
          </FormattedMessage>
          <input id={id}
            type='text'
            value={value}
            data-hasvalue={value ? 'true' : 'false'}
            onChange={e => onChange({ address: e.target.value, addressCompleted: false, place: null })}
            onFocus={() => this._handleFocus(true, onFocus)}
            onBlur={() => this._handleFocus(false, onFocus)}
            placeholder=''
          />
        </FormInput>
      </div>
    )
  }
}

export default injectIntl(AddressSearchInput)
