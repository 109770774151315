import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'
import { valueToMomentInTimezone } from 'Helpers/date'
import classNames from 'classnames'
import { Alert } from 'react-bootstrap'

import FormInput from 'Components/Form/FormInput'
import FormInputDateTimePicker from 'Components/Form/FormInputDateTimePicker'
import AttributeCheckbox from 'Components/VehicleInformation/AttributeCheckbox'
import { Warning } from 'Components/VehicleInformation/Warning'
import Loader from 'Components/Loader'
import { LicensePlateField } from 'Components/VehicleInformation/LicensePlateField'

import {
  performValidation,
  genericComingFromValidation,
  genericFlightNumberArrivalValidation,
  genericFlightNumberDepartureValidation,
  genericMeetingPlaceArrivalValidation,
  genericMeetingPlaceDepartureValidation,
  genericVehicleModelValidation,
  genericRealTimeArrivalValidation,
  genericPassengerNumberValidation,
  getErrorFieldName
} from 'Helpers/formValidations'

import carIcon from 'ClientAssets/images/vehicletypes/car.png'
import motorcycleIcon from 'ClientAssets/images/vehicletypes/motorcycle.png'
import { LICENSE_PLATE_COUNTRIES } from 'Components/VehicleInformation/constants'
import { scrolledToFirstInvalid } from 'Helpers/form'

const MOBILE_WIDTH = 768
class BookingInformationEditForm extends Component {
  state = {
    licensePlate: this.props.licensePlate || '',
    country: LICENSE_PLATE_COUNTRIES.find(item => item.id === this.props.countryId),
    validLicensePlate: null,
    electricVehicle: this.props.electricVehicle,
    motorcycle: this.props.motorcycle,
    unknownLicensePlate: this.props.unknownLicensePlate,
    vehicleModel: this.props.vehicleModel || '',
    redirectTo: null,
    loading: false,
    disabledBefore: valueToMomentInTimezone({ value: this.props.beginDate, timezone: this.props.timezone }),
    disabledAfter: this.props.endDate ? valueToMomentInTimezone({ value: this.props.endDate, timezone: this.props.timezone }) : null,

    ...this.props.mandatoryInfos.reduce((result, { name, type, value }) => {
      if (type === 'datetime_picker') {
        const time = valueToMomentInTimezone({ value: value, timezone: this.props.timezone, fallback: this.props.beginDate })

        result[name] = time
      } else {
        result[name] = value
      }

      return result
    }, {})
  }

  handleSet = (name, value) => this.setState({ [name]: value })

  handleCheckboxChange = (name, value) => {
    const attributeName = name.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())

    this.handleSet(attributeName, value)
  }

  handleValidate = (name, value) => this.validateField(name, value)
  handleLicensePlateValidation = (value) => this.handleValidate('licensePlate', value)

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({ loading: true })

    const validations = this.getFieldsToValidate().map(name => [ name, this.validateField(name, this.state[name]) ] )

    if (scrolledToFirstInvalid(validations)) {
      this.setState({ loading: false })
      return
    }

    const url = `${this.props.railsContext.localePrefix}/bookings/${this.props.parkingPurchaseId}`

    const response = await fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        license_plate_country_id: this.state.country.id,
        license_plate: this.state.licensePlate,
        vehicle_model: this.state.vehicleModel,
        electric_vehicle: this.state.electricVehicle,
        motorcycle: this.state.motorcycle,
        unknown_license_plate: this.state.unknownLicensePlate,
        additional_infos: this.props.mandatoryInfos.reduce((accumulator, { name, type }) => {
          if (type === 'datetime_picker') {
            const { i18nLocale } = this.props.railsContext
            const format = i18nLocale === 'en' ? 'DD/MM/YYYY hh:mm A' : 'DD/MM/YYYY HH:mm'

            accumulator[name] = this.state[name].format(format)
          } else {
            accumulator[name] = this.state[name]
          }

          return accumulator
        }, {})
      })
    })

    const parsedResponse = await response.json()

    if (parsedResponse.purchase_path) {
      window.location.href = parsedResponse.purchase_path
    }
    if (parsedResponse.errors) {
      this.setState({ loading: false, errors: parsedResponse.errors, showAlert: true })
    }
  }

  licenseRef = React.createRef()

  validateField = (name, value) => {
    if (name === 'licensePlate') {
      return this.licenseRef.current.validate()
    }

    const validator = validatorByName[name]

    return validator ? performValidation.call(this, validatorByName[name], value, getErrorFieldName(name)) : true
  }

  handleCountryChange = (newCountry) => {
    this.setState({ country: newCountry }, () => {
      this.licenseRef.current.validate()
    })
  }

  getFieldsToValidate = () => {
    const fields = []
    if (this.props.showVehicleInformation) {
      fields.push('vehicleModel')

      if (!this.state.unknownLicensePlate && LICENSE_PLATE_COUNTRIES.some(({ id }) => id === this.state.country.id)) {
        fields.push('licensePlate')
      }
    }
    return [...fields, ...this.props.mandatoryInfos.map(({ name }) => name)]
  }

  renderDateTimePicker ({ name }) {
    const { disabledBefore, disabledAfter } = this.state

    return (
      <FormInputDateTimePicker
        id={`${name}Field`}
        value={this.state[name]}
        errorMessage={this.state[getErrorFieldName(name)]}
        railsContext={this.props.railsContext}
        setCallback={(value) => this.handleSet(name, value)}
        validateCallback={(value) => this.handleValidate(name, value)}
        placeholderDateKey='purchase.travel_informations.attributes.real_time_arrival_date'
        placeholderTimeKey='purchase.travel_informations.attributes.real_time_arrival_time'
        timezone={this.props.timezone}
        disabledBefore={disabledBefore}
        disabledAfter={disabledAfter}
        required
      />
    )
  }

  renderField ({ name, iconLeft, labelId, inputType, uppercase }) {
    const modifierName = name.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
    const inputClassNames = classNames('booking-information__input', `booking-information__input--${modifierName}`,
      {
        'booking-information__input--with-icon': iconLeft
      })

    return (
      <div className={inputClassNames}>
        <FormInput
          id={`${name}Field`}
          setCallback={(value) => this.handleSet(name, value)}
          validateCallback={(value) => this.handleValidate(name, value)}
          inputValue={this.state[name] || ''}
          errorMessage={this.state[getErrorFieldName(name)]}
          iconLeft={iconLeft}
          required
          placeholderKey={`purchase.travel_informations.attributes.${labelId}`}
          uppercase={uppercase || false}
        >
          {<input type={inputType || 'text'} className='form-control' />}
        </FormInput>
      </div>
    )
  }

  renderSelect ({ name, labelId, options }) {
    const wrapperId = `${name}Field`

    return (
      <div className='booking-information__select' id={wrapperId}>
        <FormInput
          id={`${name}Field`}
          type='select-op'
          setCallback={(value) => this.handleSet(name, value)}
          validateCallback={(value) => this.handleValidate(name, value)}
          inputValue={this.state[name]}
          errorMessage={this.state[getErrorFieldName(name)]}
          required
          placeholderKey={`purchase.travel_informations.attributes.${labelId}`}
        >
          <Select
            className='select-op'
            clearable={false}
            searchable={false}
            simpleValue
            placeholder=''
            value={this.state[name]}
            options={options}
            onOpen={() => { if (window.innerWidth < MOBILE_WIDTH) { document.getElementById(wrapperId).scrollIntoView({ behavior: 'smooth', block: 'center' }) } }}
          />
        </FormInput>
      </div>
    )
  }

  handleDismissAlert = () => this.setState({ showAlert: false })

  goBackToPurchase = () => {
    const segments = window.location.href.split('/').slice(0, -3).concat(['purchases', this.props.purchaseId])
    const updatedURL = segments.join('/')

    this.setState({ loading: true }, () => { window.location.href = updatedURL })
  }

  render () {
    const { mandatoryInfos, meetingPlaces, licensePlateRecognition, showVehicleInformation, motorcycleMayChange } = this.props
    const { country, motorcycle, electricVehicle, unknownLicensePlate, loading } = this.state

    const vehicleModelIcon = motorcycle ? motorcycleIcon : carIcon
    const displayEmptyLicensePlateWarning = showVehicleInformation && licensePlateRecognition && (unknownLicensePlate || country.id === null)

    return (
      <div className='booking-information'>
        <div className='booking-information__intro'>
          <h1 className='booking-information__title'><FormattedMessage id='purchase.travel_informations.title' /></h1>
          {this.state.showAlert && (
            <Alert bsStyle='danger' onDismiss={this.handleDismissAlert}>
              <ul>
                {this.state.errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </Alert>
          )}
          {displayEmptyLicensePlateWarning && <Warning intl={this.props.intl} />}

          {showVehicleInformation &&
            (<p className='booking-information__intro-text'><FormattedMessage id='purchase.travel_informations.intro' /></p>)}
        </div>

        <div className='booking-information__form'>
          <form onSubmit={this.handleSubmit}>
            {showVehicleInformation && (
              <div className='booking-information__vehicle'>
                <div className='booking-information__checkboxes-wrapper'>
                  <AttributeCheckbox attributeName={'electric_vehicle'} isChecked={electricVehicle} handleCheckboxChange={this.handleCheckboxChange} showTooltip />
                  <AttributeCheckbox
                    attributeName={'motorcycle'}
                    isChecked={!!motorcycle}
                    readonly={!motorcycleMayChange}
                    handleCheckboxChange={this.handleCheckboxChange}
                    showTooltip
                  />
                  <AttributeCheckbox attributeName={'unknown_license_plate'} isChecked={unknownLicensePlate} handleCheckboxChange={this.handleCheckboxChange} />
                </div>
                {this.renderField({ name: 'vehicleModel', iconLeft: vehicleModelIcon, labelId: 'vehicle_model' })}
                <LicensePlateField
                  ref={this.licenseRef}
                  licensePlate={this.state.licensePlate}
                  unknownLicensePlate={unknownLicensePlate}
                  country={country}
                  handleCountryChange={this.handleCountryChange}
                  handleSet={this.handleSet}
                  handleValidate={this.handleLicensePlateValidation}
                  errorMessage={this.state.licensePlateError}
                />
              </div>
            )}
            {mandatoryInfos.length > 0 &&
              <div className='booking-information__mandatory'>
                {mandatoryInfos.map(({ name, type }) => (
                  <div key={name}>
                    {type === 'select' &&
                      this.renderSelect({ name, labelId: name, options: meetingPlaces.map((name, _index) => ({ label: name, value: name })) })
                    }
                    {type === 'datetime_picker' &&
                      this.renderDateTimePicker({ name })
                    }
                    {type !== 'datetime_picker' && type !== 'select' &&
                      this.renderField({ name, labelId: name, inputType: type })
                    }
                  </div>
                ))}
              </div>
            }
            <div className='booking-information__buttons-container'>
              {loading &&
                <div className='booking-information__loader'>
                  <Loader grey />
                </div>
              }
              {!loading &&
                <>
                  <button type='button' className='booking-information__button booking-information__button--cancel' onClick={this.goBackToPurchase} disabled={loading}>
                    <span>
                      <FormattedMessage id={'actions.cancel'} />
                    </span>
                  </button>
                  <button type='submit' className='booking-information__button booking-information__button--save' disabled={loading}>
                    <span>
                      <FormattedMessage id={'authentication.save'} />
                    </span>
                  </button>
                </>}
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const validatorByName = {
  coming_from: genericComingFromValidation,
  flight_number_arrival: genericFlightNumberArrivalValidation,
  flight_number_departure: genericFlightNumberDepartureValidation,
  meeting_place_arrival: genericMeetingPlaceArrivalValidation,
  meeting_place_departure: genericMeetingPlaceDepartureValidation,
  passenger_number: genericPassengerNumberValidation,
  vehicleModel: genericVehicleModelValidation,
  real_time_arrival: genericRealTimeArrivalValidation,
  real_time_arrival_airport: genericRealTimeArrivalValidation,
  real_time_arrival_station: genericRealTimeArrivalValidation,
  real_time_arrival_harbour: genericRealTimeArrivalValidation
}

export default injectIntl(BookingInformationEditForm)
