import React, { Component } from 'react'

export default class BreadCrumb extends Component {
  render () {
    const { items } = this.props
    return (
      <ol className='breadcrumb' itemScope itemType='http://schema.org/BreadcrumbList'>
        {items.map((item, index) => (
          <li
            key={index}
            className='breadcrumb-item'
            itemProp='itemListElement'
            itemScope
            itemType='http://schema.org/ListItem'>
            <a itemProp='item' href={item.url}>
              <p itemProp='name'>
                {item.name}
              </p>
            </a>
            <meta itemProp='position' content={index + 1} />
          </li>
        ))}
      </ol>
    )
  }
}
