import React from 'react'

import LegalNotice from './LegalNotice'
import Links from './Links'
import Newsletter from './Newsletter'

const PAGE_LIGHT = ['purchases#new']

const Footer = ({ legalNotice, footerLinks, mobileApp, socialNetwork, pageType, railsContext }) => {
  const {
    currentSite: { iso: country }
  } = railsContext

  return (
    <footer className='react-component react-component--footer'>
      {!PAGE_LIGHT.includes(pageType) && (
        <div>
          <Newsletter />
          <Links
            footerLinks={footerLinks}
            legalNotice={legalNotice}
            mobileApp={mobileApp}
            socialNetwork={socialNetwork}
            isHomepage={railsContext.isHomepage}
          />
        </div>
      )}
      <LegalNotice isHomepage={railsContext.isHomepage} country={country} legalNotice={legalNotice} />
    </footer>
  )
}

export default Footer
