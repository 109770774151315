import React from 'react'
import { FormattedMessage } from 'react-intl'

import FormInput from '../../../components/Form/FormInput'
import Loader from '../../../components/Loader'
import { performValidation, genericEmailValidation } from '../../../helpers/formValidations'
import { datalayerPushGTM } from '../../../helpers/application'
import { localePrefix } from '../../../helpers/authentication'
import HistoryComponent from '../../../components/HistoryComponent'
import iconMail from '../../../../../../../app/assets/images/svg-on-react/icon-mail.svg'

class PasswordRecovery extends React.Component {
  state = {
    email: window.Cookies.get('password_recovery_email') || '',
    loading: false,
    emailError: false
  }

  componentDidMount () {
    window.Cookies.remove('password_recovery_email')
  }

  doSendRecover () {
    const { email } = this.state
    const prefix = localePrefix.call(this)

    this.setState({ loading: true })
    fetch(`${prefix}/users/password`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        user: {
          email: email
        }
      })
    }).then((response) => {
      return response.json()
    }).then((json) => {
      if (json.errors) {
        this.setState({
          emailError: json.errors.email[0],
          loading: false
        })
        datalayerPushGTM('form-tracking', 'Forms', 'Password-forgot', 'authentication.errors.user.email.donotexist')
      } else {
        this.props.confirmationCallback(email)
        datalayerPushGTM('form-tracking', 'Users', 'password-sent/success', json.id)
      }
    })
  }

  formSubmitted (e) {
    e.preventDefault()
    if (!this.state.emailError) {
      const emailValidate = performValidation.call(this, genericEmailValidation, this.state.email, 'emailError')
      if (emailValidate) {
        this.doSendRecover()
      }
    }
  }

  render () {
    const { email, emailError, loading } = this.state
    const { isPage } = this.props

    const inputEmailConfig = {
      id: 'emailConnectField',
      setCallback: (value) => this.setState({ email: value }),
      validateCallback: (value) => performValidation.call(this, genericEmailValidation, value, 'emailError'),
      errorMessage: emailError,
      svgIcon: iconMail,
      placeholderKey: 'authentication.user.email',
      inputValue: email
    }

    return (
      <div className='react-component password-recovery'>
        <div className='row'>
          <div className='col-xs-12'>
            <h1 className='text-center'>
              <FormattedMessage id='authentication.connection.password_recovery.reset_password' />
            </h1>
          </div>

          <div className='col-xs-12 password-recovery__intro'>
            <p className='text-center'>
              <FormattedMessage id='authentication.connection.password_recovery.fill_email' />
              <FormattedMessage id='authentication.connection.password_recovery.send_email' />
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <form onSubmit={(e) => this.formSubmitted(e)} noValidate>

              <FormInput {...inputEmailConfig}>
                <input type='email' className='form-control' />
              </FormInput>

              <div className='text-center'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {loading && <Loader overlay white />}
                  <span className={loading ? 'invisible' : ''}>
                    <FormattedMessage id='authentication.send' />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
        <HistoryComponent id='authentication.connection.password_recovery.title_tag' skipSavingLocation origin={this.props.source} modal={!isPage && 'forgotten-password'} />
      </div>
    )
  }
}

export default PasswordRecovery
