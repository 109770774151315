import React from 'react'

import SvgImage from '../../components/SvgImage'
import iconApplePlay from '../../../../../../app/assets/images/svg-on-react/icon-apple-play.svg'
import iconGooglePlay from '../../../../../../app/assets/images/svg-on-react/icon-google-play.svg'

const MobileApps = () => (
  <div className='links__mobile-apps'>
    <a href='https://itunes.apple.com/fr/app/onepark-reservation-parking/id859049106?mt=8&amp;uo=4' target='itunes_store'>
      <SvgImage svg={iconGooglePlay} />
    </a>
    <a href='https://play.google.com/store/apps/details?id=com.synertic.onepark' target='google_play'>
      <SvgImage svg={iconApplePlay} />
    </a>
  </div>
)

export default MobileApps
