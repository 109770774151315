/*
* Global constants
*/

export const CURRENT_URL_COOKIE = 'current_url'
export const PURCHASE_URL_COOKIE = 'purchase_url'
export const EDIT_URL_KEY = 'edit_url'
export const ONEPX_TRANSPARENT_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
export const ADDRESS_ELEMENT_IDS = [
  'packageAddressSearchHeader',
  'packageAddressSearchHome',
  'subscriptionAddressSearchHeader',
  'subscriptionAddressSearchHome'
]
