import moment from 'moment-timezone'
import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import LocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import FormInput from '../Form/FormInput'
import iconCalendar from '../../../../../../app/assets/images/svg-on-react/icon-calendar.svg'

class FormInputDatePicker extends Component {
  state = {
    hasFocus: this.props.hasFocus
  }

  componentDidMount () {
    this.el.querySelector('input').readOnly = true
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.hasFocus !== nextProps.hasFocus) {
      this.setState({ hasFocus: nextProps.hasFocus })
    }
  }

  handleClick = () => {
    this.setState({ hasFocus: true })
    if (this.props.openCallback) {
      this.props.openCallback()
    }
  }

  handleBlur = () => {
    this.setState({ hasFocus: false })
    if (this.props.onDateBlur) {
      this.props.onDateBlur()
    }
  }

  render () {
    const { locale, disabledBefore, disabledAfter, value, setCallback, dayFormat,
      id, placeholderKey, onDayClick, inputRef, hasError, required } = this.props

    let disabledDays = [{ before: new Date(disabledBefore.format('YYYY-MM-DD')) }]

    if (disabledAfter) {
      disabledDays = [{
        before: new Date(disabledBefore.format('YYYY-MM-DD')),
        after: new Date(disabledAfter.format('YYYY-MM-DD'))
      }]
    }

    const dayPickerProps = {
      enableOutsideDays: false,
      disabledDays: disabledDays,
      fromMonth: disabledDays[0].before,
      toMonth: disabledAfter ? disabledDays[0].after : null,
      firstDayOfWeek: 1,
      localeUtils: LocaleUtils,
      locale,
      month: value ? value.toDate() : disabledDays[0].before,
      onDayClick
    }

    const inputProps = {
      'data-hasvalue': value ? 'true' : 'false',
      onFocus: this.props.onFocus,
      readOnly: true
    }

    return (
      <div className={`input-datepicker ${value ? 'input-datepicker--has-value' : ''}`}
        ref={el => { this.el = el }}
        onClick={this.handleClick}
        onBlur={this.handleBlur}>
        <FormInput
          withoutValidate
          withoutFloatingLabel
          svgIconLeft={iconCalendar}
          id={id}
          required={required}
          hasFocus={this.state.hasFocus}
          errorMessage={hasError}>
          <FormattedMessage id={placeholderKey}>
            {(message) => (
              <span className={`floating-label ${value ? 'floating-label--top' : ''}`}>{message} {required ? ' *' : ''}</span>
            )}
          </FormattedMessage>
          <DayPickerInput
            value={value ? value.toDate() : ''}
            inputProps={inputProps}
            placeholder=''
            format={dayFormat}
            formatDate={formatDate}
            parseDate={parseDate}
            onDayChange={date => setCallback(moment(date))}
            dayPickerProps={dayPickerProps}
            ref={inputRef}
          />
        </FormInput>
      </div>
    )
  }
}

const mapStateToProps = ({ railsContext: { dayFormat, i18nLocale: locale } }) => ({ dayFormat, locale })

export default connect(mapStateToProps)(FormInputDatePicker)
