import React from 'react'
import ReactDOM from 'react-dom'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Select from 'react-select'

import FormInput from '../Form/FormInput'
import iconTime from '../../../../../../app/assets/images/svg-on-react/icon-time.svg'

class FormInputTimePicker extends React.Component {
  componentDidUpdate () {
    const selectMenu = ReactDOM.findDOMNode(this).getElementsByClassName('Select-menu')[0]
    const selectedOption = ReactDOM.findDOMNode(this).getElementsByClassName('is-selected')[0]
    if (selectMenu && selectedOption) {
      selectMenu.scrollTop = selectedOption.offsetTop
    }
  }

  render () {
    const { id, value, setCallback, options, openCallback,
      inputRef, placeholderKey, errorMessage, required } = this.props

    return (
      <div className='input-timepicker'
        onBlur={(e) => this.props.onTimeBlur()}>
        <FormInput
          withoutValidate
          withoutFloatingLabel
          svgIconLeft={iconTime}
          id={id}
          errorMessage={errorMessage}>
          <FormattedMessage id={placeholderKey}>
            {(message) => (
              <Select
                instanceId={`${id}_select`}
                value={value}
                placeholder={`${message}${required ? ' *' : ''}`}
                className='select-op'
                simpleValue
                onChange={setCallback}
                onOpen={openCallback}
                autoBlur
                searchable={false}
                clearable={false}
                openOnFocus
                options={options}
                ref={inputRef}
              />
            )}
          </FormattedMessage>
        </FormInput>
      </div>
    )
  }
}

const mapStateToProps = ({ railsContext: { i18nLocale: locale } }) => ({ locale })

export default connect(mapStateToProps)(FormInputTimePicker)
