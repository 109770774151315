import { Info3dSecureDialog } from 'Components/Info3dSecureDialog'
import { OnPurchase3dSecureErrorDialog } from 'Components/OnPurchase3dSecureErrorDialog'
import { OnRegistration3dSecureErrorDialog } from 'Components/OnRegistration3dSecureErrorDialog'
import { PurchaseHelper } from 'Helpers/purchaseHelper'
import React, { Component } from 'react'
import { canEditTravelInfoInitial, scrollToElementWithFooterAdjustment } from 'Helpers/form'
import { loadStripe } from '@stripe/stripe-js'
import { bookingDuplicateCheck } from 'Helpers/api'
import { FormattedMessage } from 'react-intl'
import { DuplicateBookingModal } from './DuplicateBookingModal'
import ParkOfferSummary from './ParkOfferSummary'
import AccountSection from './Summary/AccountSection'
import DiscountPartnerSection from './Summary/DiscountPartnerSection'
import PaymentSection from './Summary/PaymentSection'
import { SummaryReassurancePanel } from './Summary/SummaryReassurancePanel'
import TravelInformationsSection from './Summary/TravelInformationsSection'
import { Warning } from './Summary/Warning'
import { isMultipleEntriesParamSet } from 'Helpers/purchaseTunnel'
import { PendingPaymentMethodModal } from 'Components/PendingPaymentMethodModal'
import { getCookieValue, removeCookie } from 'Helpers/application'

export class CommonSummary extends Component {
  static storagePrefix = (_props) => {
    return 'shared-parent-component-prefix'
  }

  locallyStored = () => {
    const storagePrefix = this.constructor.storagePrefix(this.props)
    return {
      acceptTerms: sessionStorage.getItem(`${storagePrefix}-acceptTerms`) === 'true',
      professionalUse: sessionStorage.getItem(`${storagePrefix}-professionalUse`) === 'true',
      isMultipleEntriesOption: isMultipleEntriesParamSet(storagePrefix)
    }
  }

  state = {
    ...this.locallyStored(),
    showPaymentForm: !this.props.user.billingIdentity,
    billingIdentity: this.props.user.billingIdentity,
    userConsent: this.props.userConsent,
    purchaseError: false,
    errorWithDateRedirect: false,
    threedsError: false,
    loading: false,
    travelInfoEditing: canEditTravelInfoInitial(this.props.travelInfos, this.props.mandatoryInfos),
    stripe: null,
    warningVisible: false,
    registerPaymentMethodTimedOut: false,
    registerPaymentMethodSuccess: false,
    summaryError: '',
    highlightTerms: false,
    stripeCompleted: false,
    stripeError: false
  }

  travelInfoEditModeSwitcher = (value) => {
    this.setState({ travelInfoEditing: value })
  }

  toggleWarning = (value) => {
    this.setState({ warningVisible: value })
  }

  travelInfoSectionRef = React.createRef()
  paymentSectionRef = React.createRef()
  travelFormRef = React.createRef()
  footerRef = React.createRef()
  stripeErrorRef = React.createRef()
  threedsErrorRef = React.createRef()

  sectionRefs = {
    travelInformationSection: this.travelInfoSectionRef,
    paymentSection: this.paymentSectionRef
  }

  highlightSection = (section, messageId) => {
    scrollToElementWithFooterAdjustment(this.sectionRefs[section].current, this.footerRef.current)
    if (!messageId) return

    this.toggleWarning(true)
    this.setState({ summaryError: messageId })
  }

  highlightMissingTerms = () => {
    this.setState({ highlightTerms: true })
  }

  protectFromReload = (name, value) => {
    if (window === undefined) return
    sessionStorage.setItem(`${this.constructor.storagePrefix(this.props)}-${name}`, value.toString())
  }

  componentDidMountComponentSpecific = () => { }

  componentDidMount = async () => {
    this.checkCardAddedNoticeFromCookies()
    this.componentDidMountComponentSpecific()
    const stripe = await loadStripe(this.props.stripePublicKey, { apiVersion: '2022-11-15' })
    this.setState({ stripe })
  }

  checkCardAddedNoticeFromCookies = () => {
    if (getCookieValue('card_added_on_payment_info_step')) {
      removeCookie('card_added_on_payment_info_step')
      this.toggleRegisterPaymentMethodSuccess(true)
    }
  }

  duplicateBookingUrl = () => {
    throw new Error('duplicateBookingUrl should be redefined on inheritance, returning a string')
  }

  checkDuplicateBooking = () => {
    const switchOffLoading = () => this.setState({ loading: false })
    const setDuplicateType = (type) => this.setState({ duplicateType: type })

    bookingDuplicateCheck(this.duplicateBookingUrl(), switchOffLoading, setDuplicateType, this.completeBooking)
  }

  handleSubmitPurchase = async event => {
    if (event) { event.preventDefault() }

    const { acceptTerms, travelInfoEditing, showPaymentForm } = this.state
    if (!acceptTerms) { return this.highlightMissingTerms() }

    const travelInfoIsValid = this.travelFormRef.current && this.travelFormRef.current.allFieldsAreValid()
    if (this.travelFormRef.current && ((travelInfoEditing && travelInfoIsValid) || !travelInfoIsValid)) {
      this.highlightSection('travelInformationSection', 'purchase.purchase_summary.warning_vehicle_travel')
      this.setState({ travelWarning: true })
      scrollToElementWithFooterAdjustment(this.travelFormRef.current, this.footerRef.current)
      return
    }

    if (showPaymentForm) {
      this.setState({ paymentWarning: true })
      this.highlightSection('paymentSection', 'purchase.purchase_summary.warning_payment')

      const stripeSubmitButton = document.getElementById('payment-details-submit-button')
      if (!this.state.stripeCompleted && stripeSubmitButton) { stripeSubmitButton.click() }

      return
    }

    this.toggleWarning(false)
    this.setState({ loading: true })
    await this.checkDuplicateBooking()
  }

  backendPostParams = () => {
    throw new Error('backendPostParams should be redefined on inheritance, returning an object')
  }

  handleMultipleEntriesChange = (event) => {
    const newOfferAmount = this.getOfferAmount()

    this.setState({ isMultipleEntriesOption: event.target.checked, offerAmount: newOfferAmount })
    this.protectFromReload('isMultipleEntriesOption', event.target.checked)
    this.protectFromReload('offerAmount', newOfferAmount)
  }

  getOfferAmount = () => {
    const offerAmount = this.state.isMultipleEntriesOption
      ? this.props.offerBuilder.amount.totalWithOptions : this.props.offerBuilder.amount.totalWithoutOptions
    return parseFloat(offerAmount)
  }

  handleShowPaymentForm = () => {
    const { showPaymentForm } = this.state
    this.setState({ showPaymentForm: !showPaymentForm })
  }

  renderTermsLink (type, path) {
    return (
      <a href={path} target='_blank' className='link--secondary'>
        <FormattedMessage id={`purchase.purchase_summary.${type}`} />
      </a>
    )
  }

  show3dsInfo = (event) => { event.preventDefault(); $('#info-3d-secure').modal('show') }

  backendPostUrl = () => {
    throw new Error('backendPostUrl should be redefined on inheritance, returning a string')
  }

  setErrors = ({ threedsError, purchaseError, stripeError, errorWithDateRedirect, scrollToTop }) => {
    this.setState({ threedsError, purchaseError, stripeError, errorWithDateRedirect })

    if (scrollToTop) return window.scrollTo({ top: 0, left: 0, behavior: 'smooth', block: 'start' })
    if (threedsError) return this.threedsErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    if (stripeError) return this.stripeErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    if (purchaseError) return this.highlightSection('paymentSection')
  }

  setStripeError = (stripeErrorMessage) => {
    this.setState({
      stripeError: stripeErrorMessage,
      threedsError: false,
      purchaseError: false,
      errorWithDateRedirect: false
    })
    this.stripeErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  unsetLoading = () => this.setState({ loading: false })

  completeBooking = async () => {
    const purchaseHelper = new PurchaseHelper({
      callerProps: this.props,
      stripeObject: this.state.stripe,
      setErrors: this.setErrors,
      unsetLoading: this.unsetLoading,
      backendPostUrl: this.backendPostUrl(),
      backendPostParams: this.backendPostParams()
    })
    await purchaseHelper.completeBooking()
  }

  toggleRegisterPaymentMethodSuccess = value => this.setState({ registerPaymentMethodSuccess: value })

  toggleRegisterPaymentMethodTimedOut = value => this.setState({ registerPaymentMethodTimedOut: value })

  modalWindows = () => {
    return <>
      <OnRegistration3dSecureErrorDialog />
      <OnPurchase3dSecureErrorDialog />
      <Info3dSecureDialog />
      <DuplicateBookingModal
        handleContinue={() => {
          this.setState({ loading: true })
          this.completeBooking()
        }}
        duplicateType={this.state.duplicateType} />
      {this.state.warningVisible && <Warning toggleWarning={this.toggleWarning} messageId={this.state.summaryError} />}
      {this.state.registerPaymentMethodSuccess &&
        <Warning
          bgStyle='info' className='summary-warning__info--green'
          messageId='purchase.purchase_summary.card_saved'
          toggleWarning={this.toggleRegisterPaymentMethodSuccess}
        />
      }
      {this.state.registerPaymentMethodTimedOut &&
        <Warning
          messageId='pages.credit_card.error_messages.timeout'
          toggleWarning={this.toggleRegisterPaymentMethodTimedOut}
        />
      }
      <PendingPaymentMethodModal
        theme='green'
        onFetchSuccess={this.onRegisterPaymentMethodSuccess}
        onFetchTimeoutError={this.onRegisterPaymentMethodTimeout}
      />
    </>
  }

  onRegisterPaymentMethodSuccess = (billingIdentity) => {
    this.setState({ billingIdentity })
    this.toggleRegisterPaymentMethodSuccess(true)
  }

  onRegisterPaymentMethodTimeout = () => {
    this.toggleRegisterPaymentMethodTimedOut(true)
  }

  parkOfferSummary = (additionalProps = {}) => {
    const { beginDate, discountCode, endDate, localePrefix, offerBuilder, type } = this.props
    return <ParkOfferSummary
      beginDate={beginDate}
      endDate={endDate}
      offerBuilder={offerBuilder}
      park={offerBuilder.park}
      discountCode={discountCode}
      discountPrice={offerBuilder.amount.discountPrice}
      showOptionPrice={this.state.isMultipleEntriesOption}
      offerAmount={this.getOfferAmount()}
      offerType={type}
      localePrefix={localePrefix}
      offerId={offerBuilder.id}
      optionsPrice={offerBuilder.multipleEntries.optionPrice}
      handleMultipleEntriesChange={this.handleMultipleEntriesChange}
      isMultipleEntriesChecked={isMultipleEntriesParamSet(this.constructor.storagePrefix(this.props))}
      showMultipleEntriesChoice={offerBuilder.multipleEntries.allow}
      {...additionalProps}
    />
  }

  reassurancePanel = () => {
    return <div className='purchase-summary__reassurance-panel'>
      <SummaryReassurancePanel
        maxCancellationDelay={this.props.offerBuilder.maxCancellationDelay}
        maxModificationDelay={this.props.offerBuilder.maxModificationDelay}
        renewalDate={this.props.renewalDate}
        isSubscription={this.props.type === 'subscription'}
        intl={this.props.intl}
      />
    </div>
  }

  editableSection = ({ saveUrl }) => {
    const {
      localePrefix, mandatoryInfos, offerBuilder, travelInfos, user, provider, mobilePurchaseTunnelPaymentInside, mobilePurchaseTunnel
    } = this.props
    return <>
      <DiscountPartnerSection
        isMultipleEntriesOption={this.state.isMultipleEntriesOption} />
      <TravelInformationsSection
        localePrefix={localePrefix}
        mandatoryInfos={mandatoryInfos}
        offerBuilder={offerBuilder}
        editMode={this.state.travelInfoEditing}
        travelInfoEditModeSwitcher={this.travelInfoEditModeSwitcher}
        storagePrefix={this.constructor.storagePrefix(this.props)}
        travelInfos={this.storedTravelInfos ? JSON.parse(this.storedTravelInfos) : travelInfos}
        forwardedRef={this.travelInfoSectionRef}
        formRef={this.travelFormRef}
        warning={this.state.travelWarning}
        parkTimezone={offerBuilder.park.timezoneName}
        protectFromReload={this.protectFromReload}
        turnoffWarning={() => this.setState({ travelWarning: false })} />
      <AccountSection
        localePrefix={localePrefix}
        user={user}
        offerBuilder={offerBuilder}
        saveUrl={saveUrl}
      />
      <PaymentSection
        mobilePurchaseTunnelPaymentInside={mobilePurchaseTunnelPaymentInside}
        mobilePurchaseTunnel={mobilePurchaseTunnel}
        billingIdentity={this.state.billingIdentity}
        offerBuilder={offerBuilder}
        localePrefix={localePrefix}
        handleShowPaymentForm={this.handleShowPaymentForm}
        showPaymentForm={this.state.showPaymentForm}
        stripePublicKey={this.props.stripePublicKey}
        provider={provider}
        forwardedRef={this.paymentSectionRef}
        warning={this.state.paymentWarning}
        turnoffWarning={() => this.setState({ paymentWarning: false })}
        handleStripeChange={(complete) => this.setState({ stripeCompleted: complete })}
        handleStripeError={() => this.setState({
          threedsError: false,
          purchaseError: false,
          errorWithDateRedirect: false
        })}
        green
      />
    </>
  }

  nonPaymentErrorsWithRedirectSection = () => {
    const { purchaseError, errorWithDateRedirect } = this.state
    const errorParts = purchaseError && errorWithDateRedirect && purchaseError.split('. ')
    if (!errorParts) return

    const { localePrefix, offerBuilder, type } = this.props
    const parkUrl = `${localePrefix}/parkings/${offerBuilder.park.id}${type === 'subscription' ? `/?type=${type}` : ''}`
    return (
      <div className='row purchase-summary__error-message purchase-summary__error-message--orange purchase-summary__error-message--padded'>
        <p data-automation-id='purchase-summary__payment-error'>
          <span role='alert'>
            {`${errorParts[0]}. `}
          </span>
          <a href={parkUrl} target='_blank' className='purchase-summary__link'>
            {errorParts[1]}
          </a>
        </p>
      </div>
    )
  }

  paymentErrorsSection = () => {
    const { stripeError, threedsError, purchaseError, errorWithDateRedirect } = this.state
    return <>
      {
        stripeError &&
        <div className='row purchase-summary__error-message purchase-summary__error-message--orange'
          ref={this.stripeErrorRef}>
          <p data-automation-id='purchase-summary__payment-error' className='purchase-summary__payment-error'>
            {stripeError}
          </p>
        </div>
      }

      <div className='row'>
        {threedsError && !purchaseError &&
          <div className='purchase-summary__error-message purchase-summary__error-message--orange' ref={this.threedsErrorRef}>
            <p data-automation-id='purchase-summary__threeds-error' className='purchase-summary__threeds-error'>
              <small role='alert'>
                <FormattedMessage id='authentication.registration.payment_info.3ds.error_message.payment_before_link' />
                <a className='secure-3d-link' onClick={this.show3dsErrorOnPurchase}>
                  <FormattedMessage id='authentication.registration.payment_info.3ds.error_message.link_title' />
                </a>
              </small>
            </p>
          </div>
        }
        <div>
          {!threedsError && !purchaseError &&
            <p className='purchase-summary__3ds-text'>
              <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.before_link' />
              <a href={'#'} onClick={this.show3dsInfo}>
                <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.link_text' />
              </a>
              <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.after_link' />
            </p>}
        </div>

        {purchaseError && !errorWithDateRedirect &&
          <div className='row'>
            <div className='purchase-summary__error-message purchase-summary__error-message--orange purchase-summary__error-message--padded'>
              <p data-automation-id='purchase-summary__payment-error' className='purchase-summary__payment-error'>
                {purchaseError}
              </p>
            </div>
          </div>
        }
      </div>
    </>
  }

  show3dsErrorOnPurchase = (event) => {
    event.preventDefault()
    $('#error-on-purchase-3d-secure').modal('show')
  }

  checkboxesSection = (additionalClasses) => {
    const { salesTermsPath, termsPath, optinDisplaying, partnerName, isDefaultHost } = this.props
    const { acceptTerms, professionalUse, userConsent, highlightTerms } = this.state
    return (
      <div className='purchase-summary__checks-wrapper'>
        <div className='purchase-summary__professional-use'>
          <div className={`purchase-summary__checkbox ${additionalClasses}`}>
            <input id='professional_use'
              data-automation-id='purchase-summary__professional-use'
              className='checkbox-op'
              type='checkbox'
              checked={professionalUse}
              onChange={(event) => {
                this.setState({ professionalUse: event.target.checked })
                this.protectFromReload('professionalUse', event.target.checked)
              }} />
            <label htmlFor='professional_use'>
              <FormattedMessage id='purchase.purchase_summary.is_professional_use' />
            </label>
          </div>
        </div>

        <div className={`purchase-summary__optin_consent ${optinDisplaying ? '' : 'hidden'}`}>
          <div className={`purchase-summary__checkbox ${additionalClasses}`}>
            <input id='optin_consent'
              data-automation-id='purchase-summary__optin_consent'
              className='checkbox-op'
              type='checkbox'
              checked={userConsent}
              onChange={(event) => this.setState({ userConsent: event.target.checked })} />
            <label htmlFor='optin_consent'>
              <FormattedMessage id='purchase.purchase_summary.accept_providing_personal_info'
                values={{ partnerName: partnerName }} />
            </label>
          </div>
        </div>

        <div className='purchase-summary__accept-terms'>
          <div className={`purchase-summary__checkbox ${additionalClasses}`}>
            <input id='accept_terms'
              data-automation-id='purchase-summary__accept-terms'
              className='checkbox-op'
              type='checkbox'
              checked={acceptTerms}
              onChange={(event) => {
                this.setState({ acceptTerms: event.target.checked, highlightTerms: !event.target.checked })
                this.protectFromReload('acceptTerms', event.target.checked)
              }} />
            <label htmlFor='accept_terms'>
              <FormattedMessage
                id={`purchase.purchase_summary.${isDefaultHost ? 'france_' : ''}accept_terms`}
                values={{
                  salesTerms: this.renderTermsLink('sales_terms', salesTermsPath),
                  terms: this.renderTermsLink('terms', termsPath)
                }}
              />
            </label>
          </div>
          {highlightTerms &&
            <>
              <label className='purchase-summary__warning--orange'
                data-automation-id='purchase-summary__terms-warning'>
                <FormattedMessage id='purchase.purchase_summary.must_accept_terms' />
              </label>
            </>}
        </div>
      </div>
    )
  }
}
