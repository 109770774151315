import React from 'react'
import { FormattedMessage } from 'react-intl'
import SvgImage from './SvgImage'

import logoGoogle from '../../../../../app/assets/images/svg-on-react/logo-google.svg?fill=#616161'

const TranslatedByGoogle = () => (
  <span className='park-translated-by-google'>
    <FormattedMessage id={`pages.park.translated_by`} />
    <SvgImage svg={logoGoogle} className='logo-google' />
  </span>
)

export default TranslatedByGoogle
