import PropTypes from 'prop-types'
import React from 'react'

export default class FormInputComponent extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    withoutValidate: PropTypes.bool,
    validateCallback: PropTypes.func,
    setCallback: PropTypes.func,
    inputValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }

  constructor (props) {
    super(props)

    this.state = {
      value: this.props.inputValue || (this.props.selectInput && this.props.selectInput.value) || ''
    }
  }

  handleInputChange (event) {
    let value = event

    if (event && event.target) {
      value = event.target.value

      if (this.props.uppercase) {
        value = value.toUpperCase()
      }
    }

    this.props.setCallback(value)
    this.setState({ value: value })
  }

  componentWillReceiveProps (nextProps) {
    if (this.state.val !== nextProps.inputValue) {
      this.setState({ value: nextProps.inputValue })
    }
  }

  callValidationCallback (event) {
    event.target.parentNode.classList.remove('input-focused')

    if (!this.props.withoutValidate) { this.props.validateCallback(this.state.value) }
  }

  render () {
    const { id, children, prefixValue } = this.props
    const { value } = this.state

    const attrs = {
      id,
      name: id,
      value,
      'data-hasvalue': (value ? 'true' : 'false'),
      onFocus: (e) => e.target.parentNode.classList.add('input-focused'),
      onBlur: (e) => this.callValidationCallback(e),
      onChange: (e) => this.handleInputChange(e)
    }

    if (prefixValue) {
      attrs.prefixValue = prefixValue
    }

    return React.cloneElement(children, attrs)
  }
}
