import React, { Component } from 'react'
import OpI18n from '../../components/OpI18n'
import Confirmation from './Confirmation'
import Request from './Request'

export class AvailabilityAlert extends Component {
  state = {
    email: ''
  }

  render () {
    const { email } = this.state
    const { isPage, parkName, parkId, railsContext } = this.props

    return (
      <OpI18n {...railsContext}>
        { email ? (
          <Confirmation {...this.props}
            isPage={isPage}
            onCloseConfirmation={() => this.setState({ email: '' })} />
        ) : (
          <Request {...this.props}
            isPage={isPage}
            parkName={parkName}
            parkId={parkId}
            onConfirmationSent={(val) => this.setState({ email: val })} />
        )}
      </OpI18n>
    )
  }
}

export default AvailabilityAlert
