import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import InjectedStripeForm from './StripeForm'
import classNames from 'classnames'
import { Info3dSecureDialog } from 'Components/Info3dSecureDialog'
import { OnPurchase3dSecureErrorDialog } from 'Components/OnPurchase3dSecureErrorDialog'
import { OnRegistration3dSecureErrorDialog } from 'Components/OnRegistration3dSecureErrorDialog'
import Loader from 'Components/Loader'

class PaymentInfoPanel extends Component {
  static propTypes = {
    stripePublicKey: PropTypes.string,
    handleStripeChange: PropTypes.func,
    handleStripeError: PropTypes.func,
    hideTitle: PropTypes.bool,
    turnoffWarning: PropTypes.func,
    onCancel: PropTypes.func,
    green: PropTypes.bool,
    externalErrorMessage: PropTypes.string,
    clearExternalErrorMessage: PropTypes.func,
    callingPage: PropTypes.oneOf(['registration', 'booking', 'purchase', 'profile'])
  }

  state = {
    acceptTerms: false,
    professionalUse: false,
    loading: false,
    showLoader: true,
    gatewayError: null,
    stripe: null,
    setupIntentSecret: null,
    threedsError: false
  }

  getStripeAppearance = () => {
    // https://stripe.com/docs/elements/appearance-api?platform=web
    return this.isCallingFromProfilePage() ? {
      theme: 'stripe',
      variables: {
        colorText: '#32325d',
        colorDanger: '#fa755a',
        fontFamily: 'Inter, Helvetica Neue, Helvetica, sans-serif',
        fontSmooth: 'always',
        fontSize: '16px',
        colorTextPlaceholder: '#aab7c4',
        colorIconCardError: '#fa755a'
      }
    } : {
      theme: 'stripe',
      variables: {
        colorText: '#32325d',
        colorDanger: '#FF570C',
        fontFamily: 'Inter, Helvetica Neue, Helvetica, sans-serif',
        fontSmooth: 'always',
        fontSize: '14px',
        colorTextPlaceholder: '#757575',
        colorIconCardError: '#FF570C'
      }
    }
  }

  fetchClientSecret = async () => {
    const setupIntentResponse = await fetch(`/payment/setup-intents`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    if (setupIntentResponse.ok) {
      const setupIntentJSON = await setupIntentResponse.json()
      return setupIntentJSON.data.attributes.client_secret
    } else {
      throw new Error(`Error while connecting to server (HTTP status ${setupIntentResponse.status})`)
    }
  }

  componentDidMount = async () => {
    const stripe = loadStripe(this.props.stripePublicKey, { apiVersion: '2022-11-15' })
    const clientSecret = await this.fetchClientSecret()
    this.setState({ stripe, setupIntentSecret: clientSecret })
  }

  locationBeforeSummary = () => {
    // it is a bit ugly, maybe we should switch to the props instead of analyzing window.location
    if (typeof window === 'undefined') return true

    return window.location.pathname.endsWith('member/payment-informations')
  }

  show3dsInfo = (event) => { event.preventDefault(); $('#info-3d-secure').modal('show') }

  isCallingFromProfilePage = () => this.props.callingPage === 'profile'

  renderLoader = () => {
    if (this.isCallingFromProfilePage()) return <Loader overlay blue />

    return <Loader overlay green />
  }

  render () {
    const { stripe, setupIntentSecret } = this.state
    const appearance = this.getStripeAppearance()
    const { currentLocale, handleStripeChange } = this.props

    const parentClassName = classNames('payment-info-panel', {
      'payment-info-panel--profile-page': this.isCallingFromProfilePage()
    })

    const threeDsWarningClassName = this.isCallingFromProfilePage()
      ? classNames('threeds-warning__body')
      : classNames('threeds-warning__body threeds-warning__body--green text--grey-darker', {
        'threeds-warning__body--greyed': !this.locationBeforeSummary(),
        'threeds-warning__body--mobile-exclusive': this.locationBeforeSummary()
      })

    return (
      <div className={parentClassName}>
        <OnRegistration3dSecureErrorDialog />
        <OnPurchase3dSecureErrorDialog />
        <Info3dSecureDialog />
        {!this.props.hideTitle &&
          <h1>
            <FormattedMessage id='authentication.registration.payment_info.title' />
          </h1>
        }

        {this.locationBeforeSummary() &&
          <div className='payment-info-panel__payment-cards payment-info-panel__payment-cards_3ds'>
            <small className='text--grey-darker'>
              <FormattedMessage id='authentication.registration.payment_info.secure_payment' />
            </small>
          </div>
        }

        {!this.state.threedsError &&
          <div className={threeDsWarningClassName}>
            <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.before_link' />
            <a href={'#'} onClick={this.show3dsInfo}>
              <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.link_title' />
            </a>
            <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.after_link' />
          </div>}

        <div className={`payment-info-panel__form ${this.state.showLoader ? 'payment-info-panel__form--loading' : ''}`}>
          {this.state.showLoader && this.renderLoader()}
          {stripe && setupIntentSecret &&
            <Elements
              stripe={stripe}
              options={{ clientSecret: setupIntentSecret, locale: currentLocale, appearance }}>
              <InjectedStripeForm
                {...this.props}
                stripe={stripe}
                handleElementReady={() => this.setState({ showLoader: false })}
                turnoffWarning={this.props.turnoffWarning}
                threedsError={this.state.threedsError}
                threedsErrorSetter={(value) => {
                  this.setState({ threedsError: value })
                  if (this.props.handleStripeError) this.props.handleStripeError()
                }}
                handleStripeChange={handleStripeChange}
                tabletPortraitExclusiveStyles={this.locationBeforeSummary()}
              />
            </Elements>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  railsContext: {
    currentSite: { iso: country }, localePrefix, location, currentLocale
  },
  resources: { isDefaultHost }
}) => ({ country, localePrefix, location, isDefaultHost, currentLocale })

export default connect(mapStateToProps)(PaymentInfoPanel)
