import React, { Component } from 'react'
import PaymentInfoPanel from '../Purchase/PaymentInfoPanel'
import { injectIntl } from 'react-intl'
import { PendingPaymentMethodModal } from './PendingPaymentMethodModal'
import PropTypes from 'prop-types'
import { deleteBillingIdentityById, USER_HAS_BOOKINGS_ERROR } from 'Helpers/paymentMethodsHelper'

class ProfilePaymentMethodsList extends Component {
  static propTypes = {
    stripePublicKey: PropTypes.string,
    billingIdentity: PropTypes.object
  }

  state = {
    stripeFormVisible: false,
    deletionInProgress: false,
    infoAlertMessage: null,
    errorAlertMessage: null,
    billingIdentity: this.props.billingIdentity
  }

  showStripeForm = () => this.setState({ stripeFormVisible: true, infoAlertMessage: null, errorAlertMessage: null })

  onRegisterPaymentMethodSuccess = (billingIdentity) => {
    const infoAlertMessage = this.props.intl.formatMessage({ id: 'pages.credit_card.profile.alerts.saved' })

    this.setState({ billingIdentity, infoAlertMessage, errorAlertMessage: null })
  }

  onRegisterPaymentMethodTimeout = () => {
    const errorAlertMessage = this.props.intl.formatMessage({ id: 'pages.credit_card.error_messages.timeout' })

    this.setState({ errorAlertMessage, infoAlertMessage: null })
  }

  onPaymentMethodDelete = async () => {
    if (window.confirm(this.props.intl.formatMessage({ id: 'pages.credit_card.profile.delete_confirm' }))) {
      try {
        this.setState({ deletionInProgress: true })
        await deleteBillingIdentityById(this.state.billingIdentity.id)
        const infoAlertMessage = this.props.intl.formatMessage({ id: 'pages.credit_card.profile.alerts.deleted' })
        this.setState({ errorAlertMessage: null, infoAlertMessage, billingIdentity: {}, deletionInProgress: false })
      } catch (exception) {
        if (exception.message === USER_HAS_BOOKINGS_ERROR) {
          const errorAlertMessage = this.props.intl.formatMessage({ id: 'pages.credit_card.profile.alerts.delete_error' })
          this.setState({ errorAlertMessage, infoAlertMessage: null, deletionInProgress: false })
        } else {
          this.setState({ infoAlertMessage: null, deletionInProgress: false })
        }
      }
    }
  }

  renderErrorAlert () {
    return (
      <div className='alert alert-warning alert-dismissible alert-event' role='alert' data-action='warning' data-spy='affix' data-offset-top='80'>
        <button className='close' type='button' onClick={this.closeErrorAlert}>
          <span aria-hidden='true'>&times;</span>
        </button>
        <div className='message'>
          <p>{this.state.errorAlertMessage}</p>
        </div>
      </div>
    )
  }

  closeErrorAlert = () => this.setState({ errorAlertMessage: null })

  renderInfoAlert () {
    return (
      <div className='alert alert-info alert-dismissible alert-event' role='alert' data-action='notice' data-spy='affix' data-offset-top='80'>
        <button className='close' type='button' onClick={this.closeInfoAlert}>
          <span aria-hidden='true'>&times;</span>
        </button>
        <div className='message'>
          <p>{this.state.infoAlertMessage}</p>
        </div>
      </div>
    )
  }

  closeInfoAlert = () => this.setState({ infoAlertMessage: null })

  render () {
    const { stripeFormVisible, errorAlertMessage, infoAlertMessage, billingIdentity, deletionInProgress } = this.state
    const { intl: { formatMessage }, stripePublicKey } = this.props

    return (
      <div>
        <PendingPaymentMethodModal
          theme='blue'
          onFetchSuccess={this.onRegisterPaymentMethodSuccess}
          onFetchTimeoutError={this.onRegisterPaymentMethodTimeout}
        />

        {errorAlertMessage && this.renderErrorAlert()}
        {infoAlertMessage && this.renderInfoAlert()}

        <div className='box box-shadow-custom'>
          {stripeFormVisible ? (
            <div>
              <h3>{formatMessage({ id: 'pages.credit_card.profile.title' })}</h3>
              <PaymentInfoPanel
                stripePublicKey={stripePublicKey}
                callingPage='profile'
                hideTitle
              />
            </div>
          ) : (
            <div>
              {
                billingIdentity.cardNumber ? (
                  <>
                    <pre className='alcenter'>{billingIdentity.cardNumber}</pre>
                    <a
                      className='btn btn-default pull-right'
                      onClick={this.showStripeForm}
                    >
                      {formatMessage({ id: 'pages.credit_card.profile.actions.update' })}
                    </a>
                    <button
                      disabled={deletionInProgress}
                      className='btn btn-link pull-left'
                      onClick={this.onPaymentMethodDelete}
                    >
                      {formatMessage({ id: 'pages.credit_card.profile.actions.delete' })}
                    </button>
                    <div className='clearfix' />
                  </>
                ) : (
                  <>
                    <p><strong>{formatMessage({ id: 'pages.credit_card.profile.no_card' })}</strong></p>
                    <div className='text-center'>
                      <a className='o-btn o-btn--primary' onClick={this.showStripeForm}>
                        {formatMessage({ id: 'pages.credit_card.profile.actions.add_card' })}
                      </a>
                    </div>
                  </>
                )
              }
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(ProfilePaymentMethodsList)
