import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'

import { MobileFiltersModal } from 'Components/MobileFiltersModal'
import * as SearchActions from 'Actions/SearchActions'
import DropdownButton from 'Components/DropdownButton'
import HistoryModalOp from 'Components/HistoryModalOp'
import ModalOp from 'Components/ModalOp'
import { ParkCard } from 'Components/ParkCard'
import { datalayerPushNavigationEvent, locationHasParam, removeParamFromLocation } from '../helpers/application'
import { getMapWrapperComponent } from '../../../libs/map'

const FILTERS_MODAL_NAME = 'filters_search'
const MAP_MODAL_NAME = 'map_search'
const PARK_ID_PREFIX = 'park-'

class SearchParks extends Component {
  state = {
    activeMarkerId: null,
    activeInfoWindowId: null,
    initModalMap: false,
    initModalFilters: false
  }

  componentDidMount () {
    this.loadOfferAndPromotedParks()

    if ([FILTERS_MODAL_NAME, MAP_MODAL_NAME].some(name => locationHasParam('modal', name))) {
      history.replaceState({}, '', removeParamFromLocation('modal'))
    }
  }

  loadOfferAndPromotedParks = async () => {
    await this.props.actions.loadBestOffers()
  }

  handleClickOpenMap = () => {
    datalayerPushNavigationEvent('see_on_map')
    this.setState({ initModalMap: true }, () => $('#react-map-modal').modal('show'))
  }

  handleClickMobileFilters = () => {
    this.setState({ initModalFilters: true }, () => $('#react-mobile-filters-modal').modal('show'))
  }

  handleClickOpenSearch = () => {
    $('.icon-search:not(.active)').click()
  }

  handleCloseMobileFilters = () => {
    $('#react-mobile-filters-modal').modal('hide')
  }

  handleSortBy = async (sortBy) => {
    this.props.actions.updateSorting(sortBy)
  }

  render () {
    const {
      place, type, lat, lng, clusters, bookableParks, notPromotedParks, promotedParks, orderedParks, parks, queryState,
      zoom, isUserOnMobile
    } = this.props

    const sortBy = this.props.sortBy
    const { activeMarkerId, activeInfoWindowId, initModalMap, initModalFilters, showDesktopFilters } = this.state
    const sortOptions = [
      { id: 'pertinence', label: <FormattedMessage id='pages.parks.filters.sort_by_pertinence' /> },
      { id: 'distance', label: <FormattedMessage id='pages.parks.filters.sort_by_distance' /> },
      { id: 'price', label: <FormattedMessage id='pages.parks.filters.sort_by_price' /> },
      { id: 'review', label: <FormattedMessage id='pages.parks.filters.sort_by_review' /> }
    ]

    return (
      <div className={`react-component search-page search-parks ${queryState === 'pending' ? 'search-page--searching' : ''}`}>
        <div className={
          'search-page__parks ' +
          (activeMarkerId ? 'search-page__parks--has-active ' : '') +
          (showDesktopFilters ? 'search-page__parks--show-desktop-filters' : '')
        }>

          {parks && parks.length > 0 &&
            <div className='search-place__desktop-controls hidden-xs hidden-sm '>
              <FormattedMessage id='pages.place.found_parks' values={{ n: parks.length }} />
              <DropdownButton className='btn btn-flat' value={sortBy} options={sortOptions} onChange={this.handleSortBy} />
            </div>
          }

          {parks.length === 0 &&
            <div className='search-page__no-results'>
              <FormattedMessage id='pages.parks.no_results' />
              <button className='btn btn-primary hidden-md hidden-lg' onClick={this.handleClickOpenSearch}>
                <FormattedMessage id='pages.parks.no_results_cta' />
              </button>
            </div>
          }

          {promotedParks && promotedParks.map((park, i) =>
            <ParkCard
              key={park.id}
              idPrefix={PARK_ID_PREFIX}
              index={i}
              park={park}
              isActiveMarker={park.id === activeMarkerId}
              isActiveInfoWindow={park.id === activeInfoWindowId}
              isSubscription={type === 'subscription'}
              isUserOnMobile={isUserOnMobile}
              showAddress
              showBookingButtons
              showServicesDetails
              isPromotedPark
              place={place}
              imgWidth='150'
              imgHeight='150'
            />)}

          {orderedParks && orderedParks.map((park, i) =>
            <ParkCard
              key={park.id}
              idPrefix={PARK_ID_PREFIX}
              index={i}
              park={park}
              isActiveMarker={park.id === activeMarkerId}
              isActiveInfoWindow={park.id === activeInfoWindowId}
              isSubscription={type === 'subscription'}
              showAddress
              showBookingButtons
              showServicesDetails
              place={place}
              imgWidth='150'
              imgHeight='150'
            />)}

          {notPromotedParks.map((park, i) =>
            <ParkCard
              key={park.id}
              idPrefix={PARK_ID_PREFIX}
              index={i}
              park={park}
              isActiveMarker={park.id === activeMarkerId}
              isActiveInfoWindow={park.id === activeInfoWindowId}
              isSubscription={type === 'subscription'}
              isUserOnMobile={isUserOnMobile}
              showAddress
              showBookingButtons
              showServicesDetails
              place={place}
              imgWidth='150'
              imgHeight='150'
            />)}
        </div>

        {parks.length > 0 &&
          <div className='search-page__mobile-controls hidden-md hidden-lg'>
            <button className='search-page__mobile-controls-btn' onClick={this.handleClickMobileFilters}>
              <FormattedMessage id='pages.parks.filters_btn' />
            </button>
            <button className='search-page__mobile-controls-btn' onClick={this.handleClickOpenMap}>
              <FormattedMessage id='pages.parks.map_btn' />
            </button>
          </div>
        }

        {initModalMap &&
          <HistoryModalOp id='react-map-modal' name={MAP_MODAL_NAME} mobileOnly autoHideOnResize fullScreen>
            {
              React.createElement(
                getMapWrapperComponent(),
                {
                  autoSearch: true,
                  defaultCenter: { lat, lng },
                  defaultZoom: zoom,
                  clusters,
                  isSubscription: type === 'subscription',
                  parks: bookableParks,
                  showMainMarker: true,
                  zoomControl: false
                }
              )
            }
          </HistoryModalOp>
        }

        {initModalFilters &&
          <ModalOp id='react-mobile-filters-modal' name={FILTERS_MODAL_NAME} mobileOnly autoHideOnResize hideCloseIcon >
            <MobileFiltersModal onClose={this.handleCloseMobileFilters} showAllFilters={this.props.place.isAirport} showRelevanceSort />
          </ModalOp>
        }
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  const { search: { clusters, filteredParks, bookableParks, unbookableParks, zoom, sortBy, parkFilters, params: { lat, lng, type }, queryState }, resources: { localePrefix } } = state
  const { place: { orderedParksIds, promotedParksIds } } = ownProps
  const filteredIds = filteredParks.map(park => park.id)
  const filteredBookables = bookableParks.filter(park => filteredIds.includes(park.id))
  const filterAndOrderByIds = ids => ids.map(id => filteredBookables.find(park => park.id === id)).filter(Boolean)
  const promotedParks = (sortBy === 'pertinence') ? filterAndOrderByIds(promotedParksIds).slice(0, 4) : []
  const orderedParks = (sortBy === 'pertinence') ? filterAndOrderByIds(orderedParksIds) : []
  const promotedAndOrderedParksIds = [...promotedParks, ...orderedParks].map(park => park.id)
  const allParks = [...bookableParks, ...unbookableParks]
  const notPromotedParks = allParks.filter(park => !promotedAndOrderedParksIds.find(id => park.id === id))
  return {
    localePrefix,
    clusters,
    parks: allParks,
    notPromotedParks,
    promotedParks,
    orderedParks,
    bookableParks,
    lat,
    lng,
    type,
    queryState,
    zoom,
    sortBy,
    parkFilters
  }
}

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...SearchActions }, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(SearchParks)
