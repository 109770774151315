import React from 'react'
import { FormattedMessage } from 'react-intl'

import TransportModeAccess from './TransportModeAccess'
import ParkEntryAndExit from './ParkEntryAndExit'
import Addresses from './Addresses'

const AccessSection = ({ park }) => (
  <section id='park-access'>
    <h2>
      <FormattedMessage id='pages.park.access.title' />
    </h2>

    <TransportModeAccess park={park} />

    <ParkEntryAndExit park={park} />

    <Addresses park={park} />
  </section>
)

export default AccessSection
