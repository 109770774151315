import React, { Component } from 'react'
import URLSearchParams from 'url-search-params'
import { connect } from 'react-redux'

import RegistrationBreadcrumb from '../Authentication/Registration/components/RegistrationBreadcrumb'
import PaymentInfoPanel from './PaymentInfoPanel'
import { PendingPaymentMethodModal } from 'Components/PendingPaymentMethodModal'
import { alignStepperToBox, resizeWindow } from '../Authentication/Registration/helpers/resizeWindow'

import { CURRENT_URL_COOKIE, PURCHASE_URL_COOKIE } from '../constants/application'
import { datalayerPushGTM, datalayerPush, getCookieValue, getURLFullPath, setCookieValue } from '../helpers/application'
import { emitGa4PageView } from '../../../libs/google_analytics_helpers'
import { injectIntl } from 'react-intl'

class PaymentInfoPage extends Component {
  state = {
    cardRegistrationErrorMessage: null
  }

  componentDidMount () {
    resizeWindow()
    alignStepperToBox()
    datalayerPush({ event: 'page_name', page_name: 'payment-informations' })
    datalayerPushGTM('hitVirtualPageView')
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('origin') === 'booking') {
      emitGa4PageView('cc_registration')
    }
  }

  componentDidUpdate () {
    resizeWindow()
    alignStepperToBox()
  }

  handleCancel = () => {
    const redirectUrl = getCookieValue(PURCHASE_URL_COOKIE) || getCookieValue(CURRENT_URL_COOKIE, '/')
    window.location = getURLFullPath(redirectUrl)
  }

  onRegisterPaymentMethodTimeout = () => {
    const errorMessage = this.props.intl.formatMessage({ id: 'pages.credit_card.error_messages.timeout' })
    this.setState({ cardRegistrationErrorMessage: errorMessage })
  }

  onRegisterPaymentMethodSuccess = (_) => {
    setCookieValue('card_added_on_payment_info_step', true, 1)
    window.location.href = decodeURIComponent(getCookieValue(CURRENT_URL_COOKIE, '/'))
  }

  clearCardRegistrationErrorMessage = () => {
    this.setState({ cardRegistrationErrorMessage: null })
  }

  render () {
    const { localePrefix, offer, location } = this.props
    const { cardRegistrationErrorMessage } = this.state
    const urlParams = new URLSearchParams(location.split('?')[1])
    const callingPage = urlParams.get('origin') === 'header' ? 'registration' : 'booking'

    return (
      <div className='react-component registration-payment-info-step'>
        <PendingPaymentMethodModal
          theme='blue'
          onFetchTimeoutError={this.onRegisterPaymentMethodTimeout}
          onFetchSuccess={this.onRegisterPaymentMethodSuccess}
          preventClosingOnSuccess
        />
        <div className='row'>
          <RegistrationBreadcrumb
            previousSteps={this.props.previousSteps} activeStepId='payment_details' futureSteps={this.props.futureSteps}
            location={location} localePrefix={localePrefix} offerId={offer ? offer.offerBuilder.id : null}
            stepperClasses='stepper--no-padding'
            insideLogoClasses='registration__logo--hidden-on-tablet'
            additionalClasses='registration__breadcrumb-wrapper--centered-on-tablet'
          />
        </div>

        <div className='row registration__block--right registration__block--no-height-limit col-md-12'>
          <PaymentInfoPanel
            {...this.props}
            callingPage={callingPage}
            externalErrorMessage={cardRegistrationErrorMessage}
            clearExternalErrorMessage={this.clearCardRegistrationErrorMessage}
            onCancel={callingPage === 'booking' ? null : this.handleCancel}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  purchase: {
    discountCode,
    travelInfos,
    offer,
    partnerCard
  },
  resources: { mobilePurchaseTunnel, mobilePurchaseTunnelPaymentInside, localePrefix },
  railsContext: { location },
  search: { params: { type } },
  user
}) => ({
  mobilePurchaseTunnel,
  mobilePurchaseTunnelPaymentInside,
  discountCode,
  localePrefix,
  partnerCard,
  location,
  offer,
  travelInfos,
  type,
  user
})

export default connect(mapStateToProps)(injectIntl(PaymentInfoPage))
