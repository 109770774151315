import React, { Component } from 'react'
import { addLocaleData, IntlProvider } from 'react-intl'
import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import it from 'react-intl/locale-data/it'
import nl from 'react-intl/locale-data/nl'
import pt from 'react-intl/locale-data/pt'
import { translations } from '../../../libs/i18n/translations'

const localeDatas = { de, en, es, fr, it, nl, pt }

export default class OpI18n extends Component {
  constructor (props) {
    super(props)
    const { i18nLocale, currentSite } = props

    const messages = (currentSite.iso === i18nLocale)
      ? translations[i18nLocale]
      : Object.assign(translations[i18nLocale], translations[`${i18nLocale}-${currentSite.iso}`])

    addLocaleData(localeDatas[i18nLocale])

    this.state = { messages }
  }

  render () {
    const { i18nLocale, children } = this.props
    const { messages } = this.state
    return (
      <IntlProvider locale={i18nLocale} key={i18nLocale} messages={messages}>
        { children }
      </IntlProvider>
    )
  }
}

export const withI18n = (Wrapped) => (props) => (
  <OpI18n {...props.railsContext}>
    <Wrapped {...props} />
  </OpI18n>
)
