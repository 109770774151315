import React from 'react'
import { FormattedMessage } from 'react-intl'

import SvgImage from '../../components/SvgImage'
import iconClock from '../../../../../../app/assets/images/svg-on-react/icon-clock.svg'

const OpeningHours = ({ park }) => {
  if ((park.schedules || []).length === 0) {
    return <div />
  }

  return (
    <div>
      <h3>
        <FormattedMessage id='pages.park.description.opening_hours.title' />
      </h3>

      <div className='park__description__list'>
        <ul>
          <li>
            <SvgImage svg={iconClock} />
            {park.schedules}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default OpeningHours
