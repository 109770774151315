import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import LazyLoad from 'react-lazyload'

import { getDiscount } from '../../helpers/park'

const ParkCardPhoto = ({ lazyload, park, imgWidth, imgHeight }) => lazyload ? (
  <LazyLoad once placeholder={<div className='park-card__photo--placeholder' />}>
    <Photo park={park} imgWidth={imgWidth} imgHeight={imgHeight} />
  </LazyLoad>
) : (
  <Photo park={park} imgWidth={imgWidth} imgHeight={imgHeight} />
)

const mapStateToProps = ({ resources: { defaultImage } }) => ({ defaultImage })

const Photo = connect(mapStateToProps)(({ defaultImage, seoTitle, park, imgWidth, imgHeight }) => {
  const image = park.image || (park.images || [])[0]
  const { discountRate, formattedNumberStyle } = getDiscount(park)

  return (
    <div className='park-card__photo'>
      <img width={imgWidth} height={imgHeight} title={seoTitle} alt={image ? image.alt : seoTitle} src={image ? image.url200 : defaultImage} />
      {!image && <div className='park-card__photo__caption'><FormattedMessage id='pages.photo.unavailable' /></div>}
      {discountRate < 0 && !park.onSitePrice &&
        <div className='park-card__photo__discount'>
          <FormattedNumber value={discountRate} style={formattedNumberStyle} currency={'EUR'} minimumFractionDigits={0} />
        </div>
      }
    </div>
  )
})

export default ParkCardPhoto
