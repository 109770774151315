import Supercluster from 'supercluster'
import { point } from '@turf/helpers'
import { PureComponent, createElement } from 'react'
import PropTypes from 'prop-types'
import { CLUSTER_ZOOM } from './MapView'

class Cluster extends PureComponent {
  static displayName = 'Cluster';

  static defaultProps = {
    minZoom: 0,
    maxZoom: CLUSTER_ZOOM
  };

  constructor (props) {
    super(props)

    this.state = {
      clusters: [],
      points: []
    }
  }

  componentDidMount () {
    this.createCluster()
    this.updateClusterPoints()
    this.recalculate()

    this.props.map.on('moveend', this.recalculate)
  }

  componentDidUpdate (prevProps) {
    const { clusters: prevClusters } = prevProps
    const { clusters } = this.props

    if (prevClusters.length !== clusters.length) {
      this.updateClusterPoints()
      this.recalculate()
    }
  }

  createCluster = () => {
    const {
      minZoom,
      maxZoom,
      radius,
      extent,
      nodeSize,
      innerRef
    } = this.props

    this.cluster = new Supercluster({
      map: (item) => ({ totalPointCount: item.props.pointCount }),
      reduce: (accumulated, props) => {
        accumulated.totalPointCount += props.totalPointCount
      },
      minZoom,
      maxZoom,
      radius,
      extent,
      nodeSize,
      log: true
    })

    if (innerRef) {
      innerRef(this.cluster)
    }
  };

  updateClusterPoints = () => {
    const { clusters } = this.props
    const points = clusters.map((cluster) => point(
      [cluster.props.longitude, cluster.props.latitude], cluster
    ))

    this.cluster.load(points)
  }

  recalculate = () => {
    const zoom = Math.floor(this.props.map.getZoom())
    const bounds = this.props.map.getBounds()
    const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]
    const clusters = this.cluster.getClusters(bbox, zoom)

    this.setState({ clusters })
  };

  render () {
    const clusters = this.state.clusters.map(cluster => {
      if (cluster.properties.cluster) {
        return createElement(
          this.props.element,
          {
            cluster,
            superCluster: this.cluster,
            key: `cluster-${cluster.id}`,
            pointCount: cluster.properties.totalPointCount
          }
        )
      }
      const { type, key, props } = cluster.properties
      return createElement(type, { key, ...props })
    })
    return clusters
  }
}

Cluster.propTypes = {
  /** Mapbox map object */
  map: PropTypes.object,

  /** Minimum zoom level at which clusters are generated */
  minZoom: PropTypes.number,

  /** Maximum zoom level at which clusters are generated */
  maxZoom: PropTypes.number,

  /** Cluster radius, in pixels */
  radius: PropTypes.number,

  /** (Tiles) Tile extent. Radius is calculated relative to this value */
  extent: PropTypes.number,

  /** Size of the KD-tree leaf node. Affects performance */
  nodeSize: PropTypes.number,

  /** ReactDOM element to use as a marker */
  element: PropTypes.func,

  /**
   * Callback that is called with the supercluster instance as an argument
   * after componentDidMount
   */
  /* eslint-disable react/no-unused-prop-types */
  innerRef: PropTypes.func
  /* eslint-enable react/no-unused-prop-types */
}

export default Cluster
