import React, { Component } from 'react'
import PasswordRecoveryConfirmation from './PasswordRecoveryConfirmation'
import PasswordRecovery from './PasswordRecovery'

export class PasswordRecoveryTunnel extends Component {
  state = {
    email: ''
  }

  closeConfirmationCallback () {
    this.setState({ email: '' })
    if (this.props.completedCallback) {
      this.props.completedCallback()
    }
  }

  render () {
    const { email } = this.state
    const { isPage } = this.props

    const verdict = email
      ? <PasswordRecoveryConfirmation {...this.props}
        email={email}
        isPage={isPage}
        closeConfirmationCallback={() => this.closeConfirmationCallback()} />
      : <PasswordRecovery {...this.props}
        isPage={isPage}
        confirmationCallback={(value) => this.setState({ email: value })} />

    return (
      <div>
        {verdict}
      </div>
    )
  }
}

export default PasswordRecoveryTunnel
