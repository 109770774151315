import { sendRequestToApi, transformKeysToCamelCase } from './requestsToBackend'

export async function createPendingBillingIdentity () {
  const pendingBillingIdentity = await sendRequestToApi(
    `${process.env.PAYMENT_API_HOST}/payment-methods`,
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'billing-identity',
          attributes: {},
          relationships: {
            user: {
              data: {
                uuid: window._oneparkDatas.user_uuid,
                type: 'user'
              }
            }
          }
        }
      })
    },
    billingIdentitySerializer,
    handleCreatePaymentMethodErrors
  )
  return pendingBillingIdentity
}

const billingIdentitySerializer = ({ data: { attributes } }) => ({
  ...transformKeysToCamelCase(attributes), expiresAt: attributes['expiration-date']
})

export const USER_ALREADY_HAS_PENDING_PAYMENT_METHOD_ERROR = 'user_already_has_pending_stripe_identity'

const CREATE_PAYMENT_METHOD_HANDLEABLE_ERROR_CODES = [
  USER_ALREADY_HAS_PENDING_PAYMENT_METHOD_ERROR
]

const handleCreatePaymentMethodErrors = (response) => {
  if (response) {
    if (response.errors) {
      response.errors.forEach(({ code }) => {
        if (CREATE_PAYMENT_METHOD_HANDLEABLE_ERROR_CODES.includes(code)) throw new Error(code)
      })
    }
    if (response.message) {
      captureExceptionToSentry(response.message)
    }
  }

  captureExceptionToSentry('Error occurred during creating billing identity')
}

export async function convertBillingIdentityToErrorState (billingIdentityId) {
  await sendRequestToApi(
    `${process.env.PAYMENT_API_HOST}/payment-methods/${billingIdentityId}/transition-to-error`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          type: 'billing-identity',
          attributes: {},
          relationships: {
            user: {
              data: {
                uuid: window._oneparkDatas.user_uuid,
                type: 'user'
              }
            }
          }
        }
      })
    },
    () => {},
    (response) => handlePatchPaymentMethodErrors(response, billingIdentityId)
  )
}

const BILLING_IDENTITY_CANNOT_BE_ERROR_AS_IT_IS_FINISHED = 'billing_identity_cannot_be_error_as_it_is_finished'
const BILLING_IDENTITY_ALREADY_IN_ERROR = 'billing_identity_is_already_in_error_state'

const PATCH_PAYMENT_METHOD_HANDLEABLE_ERROR_CODES = [
  BILLING_IDENTITY_CANNOT_BE_ERROR_AS_IT_IS_FINISHED,
  BILLING_IDENTITY_ALREADY_IN_ERROR
]

const handlePatchPaymentMethodErrors = (response, billingIdentityId) => {
  if (response && response.errors) {
    response.errors.forEach(({ code }) => {
      if (PATCH_PAYMENT_METHOD_HANDLEABLE_ERROR_CODES.includes(code)) {
        captureExceptionToSentry(code, billingIdentityId)
      }
    })
  }

  captureExceptionToSentry(
    'Error occurred during converting billing identity to error state',
    billingIdentityId
  )
}

export async function fetchBillingIdentityById (billingIdentityId, signal = null) {
  try {
    const userUuid = window._oneparkDatas.user_uuid
    const fetchOptions = signal ? { signal } : {}
    const billingIdentity = await sendRequestToApi(
      `${process.env.PAYMENT_API_HOST}/payment-methods/${billingIdentityId}?user_uuid=${userUuid}`,
      fetchOptions, billingIdentitySerializer
    )
    return billingIdentity
  } catch {}
}

export async function deleteBillingIdentityById (billingIdentityId) {
  const userUuid = window._oneparkDatas.user_uuid
  await sendRequestToApi(
    `${process.env.PAYMENT_API_HOST}/payment-methods/${billingIdentityId}?user_uuid=${userUuid}`,
    { method: 'DELETE' },
    () => {},
    (response) => handleDeletePaymentMethodErrors(response, billingIdentityId)
  )
}

export const USER_HAS_BOOKINGS_ERROR = 'user_has_bookings'

const handleDeletePaymentMethodErrors = (response) => {
  if (response && response.errors) {
    response.errors.forEach(({ code }) => {
      if (code === USER_HAS_BOOKINGS_ERROR) throw new Error(code)
    })
  }

  throw new Error('Could not delete billing identity')
}

function captureExceptionToSentry (message, billingIdentityId = null) {
  Sentry.withScope((scope) => {
    if (billingIdentityId) scope.setTag('billing_identity_id', billingIdentityId)
    Sentry.captureException(new Error(message))
  })
}
