import React, { Component } from 'react'
import ModalOp from './ModalOp'
import { convertBillingIdentityToErrorState, fetchBillingIdentityById } from 'Helpers/paymentMethodsHelper'
import Loader from './Loader'
import iconLightBlue from '../../../../assets/images/icon-light-blue.svg'
import iconLightGreen from '../../../../assets/images/icon-light-green.svg'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const REQUEST_TIMEOUT = 60000
const REQUEST_INTERVAL = 2000
const SUCCESS_BILLING_IDENTITY_STATE = 'finished'

const DEFAULT_SUBSEQUENT_PENDING_MESSAGE_DELAYS = [25000, 35000, 45000]
const INITIAL_PENDING_MESSAGE_ID = 'first'
const SUBSEQUENT_PENDING_MESSAGE_IDS = ['second', 'third', 'fourth']

export class PendingPaymentMethodModal extends Component {
  static propTypes = {
    onFetchSuccess: PropTypes.func.isRequired,
    onFetchTimeoutError: PropTypes.func.isRequired,
    theme: PropTypes.oneOf(['blue', 'green']),
    preventClosingOnSuccess: PropTypes.bool
  }

  static defaultProps = {
    theme: 'blue',
    preventClosingOnSuccess: false
  }

  modalId = 'pending-payment-method-modal'

  state = {
    pendingMessageId: INITIAL_PENDING_MESSAGE_ID
  }

  getSubsequentPendingMessagesDelays = () => {
    if (!process.env.PENDING_METHOD_CAROUSEL_TIMINGS_IN_SECONDS_CSV) return DEFAULT_SUBSEQUENT_PENDING_MESSAGE_DELAYS

    return process.env.PENDING_METHOD_CAROUSEL_TIMINGS_IN_SECONDS_CSV.toString()
      .split(',')
      .map(Number)
      .filter(Number.isFinite)
      .map((timing) => timing * 1000)
  }

  subsequentPendingMessagesDelays = this.getSubsequentPendingMessagesDelays()
  subsequentPendingMessagesDelaysRefs = this.subsequentPendingMessagesDelays.map(() => React.createRef())
  requestIntervalRef = React.createRef()
  hardStopTimeoutRef = React.createRef()

  fetchPendingBillingIdentityRequestController = new AbortController()

  componentDidMount () {
    this.checkPendingBillingIdentity()
  }

  componentWillUnmount () {
    this.fetchPendingBillingIdentityRequestController.abort()
    this.clearTimerForHardStop()
    this.stopPollingPendingBillingIdentity()
    this.clearTimersForDifferentPendingMessages()
  }

  checkPendingBillingIdentity = () => {
    const pendingBillingIdentityId = this.getPendingBillingIdentityIdFromCookies()

    if (pendingBillingIdentityId) {
      this.removePendingBillingIdentityIdFromCookies()
      this.startTimersForDifferentPendingMessages()
      this.startPollingPendingBillingIdentity(pendingBillingIdentityId)
      this.startTimerForHardStop(pendingBillingIdentityId)
      this.openModal()
    }
  }

  getPendingBillingIdentityIdFromCookies = () => window.Cookies.get('pending_billing_identity_id')

  removePendingBillingIdentityIdFromCookies = () => window.Cookies.remove('pending_billing_identity_id')

  startPollingPendingBillingIdentity = (pendingBillingIdentityId) => {
    this.requestIntervalRef.current = setInterval(() => this.fetchPendingBillingIdentity(pendingBillingIdentityId), REQUEST_INTERVAL)
  }

  stopPollingPendingBillingIdentity = () => {
    clearInterval(this.requestIntervalRef.current)
  }

  fetchPendingBillingIdentity = async (pendingBillingIdentityId) => {
    const billingIdentity = await fetchBillingIdentityById(
      pendingBillingIdentityId,
      this.fetchPendingBillingIdentityRequestController.signal
    )
    if (billingIdentity && billingIdentity.state === SUCCESS_BILLING_IDENTITY_STATE) {
      this.onBillingIdentitySuccessState(billingIdentity)
    }
  }

  onBillingIdentitySuccessState = (billingIdentity) => {
    this.fetchPendingBillingIdentityRequestController.abort()
    this.clearTimerForHardStop()
    this.stopPollingPendingBillingIdentity()
    this.clearTimersForDifferentPendingMessages()
    if (!this.props.preventClosingOnSuccess) this.closeModal()
    this.props.onFetchSuccess(billingIdentity)
  }

  startTimersForDifferentPendingMessages = () => {
    const { subsequentPendingMessagesDelays } = this
    for (let index = 0; index < subsequentPendingMessagesDelays.length; ++index) {
      this.subsequentPendingMessagesDelaysRefs[index].current = setTimeout(() => {
        this.setState({ pendingMessageId: SUBSEQUENT_PENDING_MESSAGE_IDS[index] })
      }, subsequentPendingMessagesDelays[index])
    }
  }

  clearTimersForDifferentPendingMessages = () => {
    for (let index = 0; index < this.subsequentPendingMessagesDelays.length; index++) {
      clearTimeout(this.subsequentPendingMessagesDelaysRefs[index].current)
    }
  }

  startTimerForHardStop = (pendingBillingIdentityId) => {
    this.hardStopTimeoutRef.current = setTimeout(() => {
      this.fetchPendingBillingIdentityRequestController.abort()
      this.stopPollingPendingBillingIdentity()
      this.closeModal()
      this.convertBillingIdentityToError(pendingBillingIdentityId)
      this.props.onFetchTimeoutError()
    }, REQUEST_TIMEOUT)
  }

  clearTimerForHardStop = () => {
    clearTimeout(this.hardStopTimeoutRef.current)
  }

  convertBillingIdentityToError = (pendingBillingIdentityId) => {
    convertBillingIdentityToErrorState(pendingBillingIdentityId)
  }

  openModal = () => {
    $(`#${this.modalId}`).modal('show')
  }

  closeModal = () => {
    $(`#${this.modalId}`).modal('hide')
  }

  render () {
    const { pendingMessageId } = this.state
    const { theme } = this.props

    const isGreen = theme === 'green'
    const isBlue = theme === 'blue'

    const parentClassName = classNames('pending-payment-method-modal',
      {
        'pending-payment-method-modal--blue': isBlue,
        'pending-payment-method-modal--green': isGreen
      }
    )

    const iconLight = isGreen ? iconLightGreen : iconLightBlue

    return (
      <ModalOp
        id={this.modalId}
        name={this.modalId}
        hideCloseIcon
        centered
        padded
        preventClosingOnEscape
        preventClosingOnClickOutside
      >
        <div className={parentClassName}>
          <div className='pending-payment-method-modal__top-section'>
            <FormattedMessage id='pages.credit_card.pending_title' />
            <div className='pending-payment-method-modal__spinner'><Loader green={isGreen} blue={isBlue} /></div>
          </div>
          <div className='pending-payment-method-modal__bottom-section'>
            <svg height='26px' width='26px'>
              <use href={iconLight} />
            </svg>
            <FormattedMessage id={`pages.credit_card.pending_messages.${pendingMessageId}`} />
          </div>
        </div>
      </ModalOp>
    )
  }
}
