import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { TopicLink } from './TopicLink'

const TopicsSection = ({ topics, className }) => {
  if (topics && topics.length > 0) {
    return (
      <section id='place-topics' className={`topics-section ${className}`}>
        <FormattedMessage id='pages.park.parking_guide.title' />

        <ul className='m-bottom-0'>
          { topics.map(topic => <li key={topic.id}><TopicLink topic={topic}></TopicLink></li>) }
        </ul>
      </section>
    )
  } else {
    return null;
  }
}

const mapStateToProps = ({ place: { topics } }) => ({ topics })
export default connect(mapStateToProps)(TopicsSection)
