import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { toHumanDuration } from 'Helpers/date'
import SvgImage from 'Components/SvgImage'
import iconCancel from 'Assets/images/svg-on-react/icon-cancel.svg'
import iconTerminate from 'Assets/images/svg-on-react/icon-terminate.svg'
import iconRenewal from 'Assets/images/svg-on-react/icon-renewal.svg'

const i18nBase = 'pages.park.description.actions'

const BookingActionsComponent = ({ intl, park, type }) => {
  const delay = park.maxCancellationDelay || 3600

  return (
    <div>
      <h3>
        <FormattedMessage id={`${i18nBase}.cancellation.title`} />
      </h3>

      <div className='row park__description__list'>
        <div className='col-md-12'>
          <ul>
            <li>
              <SvgImage svg={iconCancel} />
              <FormattedMessage
                id={`${i18nBase}.cancellation.max_cancellation_delay.${type}`}
                values={{ duration: toHumanDuration(intl, delay) }}
              />
            </li>
          </ul>
        </div>
      </div>
      {
        type === 'subscription' &&
        <>
          <h3>
            <FormattedMessage id={`${i18nBase}.termination.title`} />
          </h3>

          <div className='row park__description__list'>
            <div className='col-md-12'>
              <ul>
                <li>
                  <SvgImage svg={iconTerminate} />
                  <FormattedMessage
                    id={`${i18nBase}.termination.text`}
                  />
                </li>
              </ul>
            </div>
          </div>

          <h3>
            <FormattedMessage id={`${i18nBase}.renewal.title`} />
          </h3>

          <div className='row park__description__list'>
            <div className='col-md-12'>
              <ul>
                <li>
                  <SvgImage svg={iconRenewal} />
                  <FormattedMessage
                    id={`${i18nBase}.renewal.text`}
                  />
                </li>
              </ul>
            </div>
          </div>
        </>
      }
    </div>
  )
}

const mapStateToProps = ({ search: { params: { type } } }) => ({ type })

export const BookingActions = injectIntl(connect(mapStateToProps)(BookingActionsComponent))
