import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { resizeWindow } from '../helpers/resizeWindow'
import { localePrefix, renderLoginModal } from '../../../helpers/authentication'
import RegistrationAccountStepForm from './RegistrationAccountStepForm'
import Provider from '../../components/Provider'
import SwitchAuthentication from '../../components/SwitchAuthentication'

class RegistrationAccountStep extends Component {
  constructor (props) {
    super(props)

    this.state = { ...this.props.defaults }
  }

  componentDidMount () { resizeWindow() }

  componentDidUpdate () { resizeWindow() }

  handleClickConnectButton = (e) => {
    e.preventDefault()
    const { railsContext, source } = this.props
    renderLoginModal(railsContext, source)
  }

  renderProvider = () => {
    const { source, railsContext } = this.props
    return (
      <Provider
        providerText='authentication.header.facebook_button'
        source={source}
        railsContext={railsContext}
        authenticationType='signup'
      />
    )
  }

  render () {
    const { source, defaults, cguLink, railsContext } = this.props
    const { provider } = this.state
    const prefix = localePrefix.call(this)

    return (
      <div className='react-component registration-account row'>

        <div className='hidden-sm hidden-xs'>
          <h1 className='text-center'>
            <FormattedMessage id={`authentication.registration.account${provider === 'facebook' ? '_finalize' : ''}.title`} />
          </h1>
        </div>

        <SwitchAuthentication prefix={prefix} source={source} active='registration' />

        <RegistrationAccountStepForm
          defaults={defaults}
          source={source}
          cguLink={cguLink}
          railsContext={railsContext}
        />

        {this.renderProvider()}

      </div>
    )
  }
}

export default RegistrationAccountStep
