import React from 'react'

import NearbyPhoto from '../../../Park/common/NearbyPhoto'

const NearbyPlace = ({ place, inline, placeNameTag = 'h3', imgWidth, imgHeight }) => (
  <a className='nearby-section__item link--block row' href={place.url} title={place.seoTitle} target='_blank'>
    <div className={inline ? 'col-md-4 m-top-20 m-bottom-20' : 'col-md-12'}>
      <NearbyPhoto nearby={place} imgWidth={imgWidth} imgHeight={imgHeight} />
    </div>

    <div className={inline ? 'col-md-8 m-top-20 m-bottom-20' : 'col-md-12'}>
      {
        React.createElement(placeNameTag, null, place.name)
      }
    </div>
  </a>
)

export default NearbyPlace
