import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import JumboSearchPackage from '../../Search/components/JumboSearchPackage'
import JumboSearchSubscription from '../../Search/components/JumboSearchSubscription'
import { datalayerPushGTM } from '../../helpers/application'

import * as BookingDataActions from '../../../../actions/BookingDataActions'
import * as NearbyActions from '../../../../actions/NearbyActions'
import * as SearchActions from '../../../../actions/SearchActions'

class BookingPanelSearchTabs extends Component {
  componentDidMount () {
    this.refreshOffers()
  }

  componentDidUpdate (previousProps) {
    const { beginDate: oldBeginDate, endDate: oldEndDate, type: oldType } = previousProps
    const { beginDate, endDate, type } = this.props

    if (
      this.isSearchValid() && (
        (beginDate && beginDate.diff(oldBeginDate, 'minutes') !== 0) ||
        (endDate && endDate.diff(oldEndDate, 'minutes' !== 0)) ||
        type !== oldType
      )
    ) {
      this.refreshOffers()
    }
  }

  refreshOffers () {
    this.props.actions.loadDetailedOffer()
    this.props.actions.loadNearbyBestOffers()
  }

  isSearchValid () {
    const { beginDate, endDate, type } = this.props
    return (type === 'package' && beginDate && endDate) || (type === 'subscription' && beginDate)
  }

  handleClickTab (type) {
    if (type !== this.props.type) {
      this.props.actions.updateSearchParams({ type })
      datalayerPushGTM('hitVirtualPageView')
    }
  }

  renderTabButton (type) {
    return (
      <button
        className={type === this.props.type ? 'selected' : null}
        onClick={() => this.handleClickTab(type)}
        id={`offer_type_switcher.search.buttons.${type}`}
        disabled={this.props.queryState === 'pending'}
      >
        <FormattedMessage id={`pages.homepage.search.buttons.${type}`} />
      </button>
    )
  }

  render () {
    const { displaySubscriptionSearch, type } = this.props
    return (
      <div className='op-search'>
        {displaySubscriptionSearch &&
          <div className='op-search__tabs op-search__tabs--border-rounded'>
            <div className='switch-buttons'>
              <div className='col-xs-6 p-0'>{this.renderTabButton('package')}</div>
              <div className='col-xs-6 p-0'>{this.renderTabButton('subscription')}</div>
            </div>
          </div>
        }

        <div className={`op-search__from ${displaySubscriptionSearch ? '' : 'f-w'}`}>
          {type === 'package' && <JumboSearchPackage idSuffix='BookingPark' />}
          {type === 'subscription' && <JumboSearchSubscription idSuffix='BookingPark' />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  railsContext: { currentSite: { display_subscription_search: displaySubscriptionSearch }, localePrefix },
  search: { params: { beginDate, endDate, type } },
  bookingData: { queryState }
}) => ({
  beginDate,
  endDate,
  displaySubscriptionSearch,
  localePrefix,
  type,
  queryState
})

function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators({ ...BookingDataActions, ...NearbyActions, ...SearchActions }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingPanelSearchTabs)
