import PropTypes from 'prop-types'
import React, { Component } from 'react'
import stickybits from 'stickybits'

export default class Affix extends Component {
  static propTypes = {
    offsetTop: PropTypes.number.isRequired,
    className: PropTypes.string
  }

  sticky = null

  componentDidMount () {
    $(window).on('scroll resize', this.handleResize)
    $(window).on('orientationchange', this.handleOrientationChange)
    this.sticky = stickybits(this.el, { stickyBitStickyOffset: this.props.offsetTop, useStickyClasses: true })
  }

  componentWillUnmount () {
    $(window).off('scroll resize', this.handleResize)
    $(window).off('orientationchange', this.handleOrientationChange)
    this.sticky.cleanup()
  }

  handleResize = () => requestAnimationFrame(() => this.sticky.update())

  handleOrientationChange = () => {
    // iPad does not trigger resize event after orientation change
    if (navigator.platform === 'iPad') {
      setTimeout(() => this.sticky.update(), 250)
    }
  }

  render () {
    const { className, children } = this.props
    return (
      <div className={className} ref={el => { this.el = el }}>
        {children}
      </div>
    )
  }
}
