import * as types from '../types/PurchaseTypes'

const initialState = {
  discountCode: null,
  mandatoryInfos: [],
  meetingPlaces: [],
  offer: {},
  partnersOptions: [],
  partnerCard: null,
  travelInfos: {}
}

export default function purchaseReducer (state = initialState, action) {
  const { type, travelInfos, discountCode, partnerCard, offerBuilder } = action

  switch (type) {
    case types.TRAVEL_INFOS_UPDATE:
      return { ...state, travelInfos }
    case types.DISCOUNT_CODE_UPDATE:
      return { ...state, discountCode: discountCode, partnerCard: null }
    case types.PARTNER_CARD_UPDATE:
      return { ...state, discountCode: null, partnerCard: partnerCard }
    case types.OFFER_WITH_MULTIPLE_ENTRIES_UPDATE:
      const oldOffer = state.offer
      oldOffer.offerBuilder = offerBuilder

      return { ...state, offer: oldOffer }
    case types.DISCOUNT_CODE_AND_OFFER_WITH_MULTIPLE_ENTRIES_UPDATE:
      return {
        ...state,
        discountCode: discountCode,
        offer: {
          ...state.offer,
          offerBuilder: offerBuilder
        },
        partnerCard: null
      }
    case types.PARTNER_CARD_AND_OFFER_WITH_MULTIPLE_ENTRIES_UPDATE:
      return {
        ...state,
        discountCode: null,
        offer: {
          ...state.offer,
          offerBuilder: offerBuilder
        },
        partnerCard: partnerCard
      }
    default:
      return state
  }
}
