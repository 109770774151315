export const requestFullscreen = (targetElement) => {
  const { documentElement } = document
  const requestFullscreen =
    documentElement.requestFullscreen ||
    documentElement.mozRequestFullScreen ||
    documentElement.webkitRequestFullscreen ||
    documentElement.msRequestFullscreen ||
    (() => null)
  requestFullscreen.call(targetElement)
}

export const exitFullscreen = () => {
  if (!fullScreenEnabled()) return
  const exitFullscreen =
    document.exitFullscreen ||
    document.mozCancelFullScreen ||
    document.webkitExitFullscreen ||
    document.msExitFullscreen ||
    (() => null)
  exitFullscreen.call(document)
}

export const fullScreenEnabled = () => {
  return !!(
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  )
}
