import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AddressSearchInput from '../../components/Form/AddressSearchInput'
import FormInputDatePicker from '../../components/Form/FormInputDatePicker'
import { SearchSubmitButton } from './SearchSubmitButton'
import {
  getDateAtCurrentTime,
  getCeilQuarterOf,
  getEndDateAfterBeginDateChange,
  clearSearchFromCookie,
  isEnterKeyPressed
} from '../../helpers/search'
import { datalayerPushGTM } from '../../helpers/application'
import { ADDRESS_ELEMENT_IDS } from '../../constants/application'
import * as SearchActions from '../../../../actions/SearchActions'

class JumboSearchSubscription extends Component {
  state = {
    // Address field
    addressFocus: false,
    addressError: false,
    // Begin date field
    beginDateTimeFocus: false,
    beginDateTimeError: false
  }
  handleAddressChange = ({ address, addressCompleted, place }) => {
    this.setState({ addressError: false })

    if (addressCompleted && !this.getParams().beginDate && this.props.searchMode) {
      this.focusBeginDatePicker()
    } else if (!address) {
      clearSearchFromCookie()
      this.setState({ addressFocus: true, beginDateTimeFocus: false })
    }

    datalayerPushGTM('user-interaction', 'Search', 'change-query-address')

    return this.updateParams({
      address,
      ...(place && place.geometry ? { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() } : {})
    })
  }

  handleBeginDateChange = (beginDate) => {
    datalayerPushGTM('user-interaction', 'Search', 'change-begin-date')
    const endDate = getEndDateAfterBeginDateChange(this.getParams().endDate, beginDate)
    this.updateParams({ beginDate, endDate })
  }

  getParams = () => this.props.searchMode ? this.props.nextParams : this.props.params

  updateParams = (params) =>
    (this.props.searchMode ? this.props.actions.updateNextSearchParams : this.props.actions.updateSearchParams)(params)

  focusBeginDatePicker = () => {
    this.dayPickerBeginInput.input.focus()
    this.focusBeginDateTimeGroup()
  }

  focusBeginDateTimeGroup () {
    if (!this.getParams().beginDate) {
      this.handleBeginDateChange(getCeilQuarterOf(moment()))
    }
    this.setState({ addressFocus: false, beginDateTimeFocus: true })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const isFormValid = this.validate() // This also highlights fields on error

    if (isFormValid && this.props.onSubmit) {
      this.props.onSubmit()
    }
  }

  focusHandler = (hasFocus) => {
    this.setState({ addressFocus: hasFocus })
  }

  handleKeyDown = async e => {
    const { address, beginDate } = this.getParams()
    const { addressFocus, beginDateTimeFocus } = this.state
    if (isEnterKeyPressed(e)) {
      e.preventDefault()

      if (beginDate && beginDateTimeFocus) {
        this.dayPickerBeginInput.hideDayPicker()
        this.setState({ beginDateTimeFocus: false })
      }

      if (addressFocus) {
        this.setState({ addressFocus: false })
      }

      if (beginDate && address) {
        if (ADDRESS_ELEMENT_IDS.includes(e.target.id) && e.target.value !== address) {
          await this.handleAddressChange({ address: e.target.value, addressCompleted: true })
        }

        this.handleSubmit(e)
      }
    }
  }

  validate () {
    const { address, beginDate } = this.getParams()
    const { searchMode } = this.props
    const addressError = !address && searchMode
    const beginDateTimeError = !beginDate

    if (addressError) {
      datalayerPushGTM('form-tracking', 'Forms', 'Subscriptions', 'Please enter an address')
    }
    this.setState({ addressError, beginDateTimeError })

    return !addressError && !beginDateTimeError
  }

  handleDateTimeBlurring () {
    this.setState({ beginDateTimeFocus: false })
  }

  render () {
    const { address, beginDate } = this.getParams()
    const { idSuffix, railsContext, loading, searchMode, submitButtonClass = {}, showSearchIcon = true } = this.props
    const { beginDateTimeError, addressError, addressFocus, beginDateTimeFocus } = this.state

    const datetimePickerClass = classNames('form-elem', {
      'col-xs-12 col-sm-8 col-md-3': searchMode,
      'col-xs-12 col-sm-12 col-md-12': !searchMode
    })

    return (
      <div className='row row--condensed'>
        <form onSubmit={this.handleSubmit} onKeyDown={this.handleKeyDown}>
          <div className='op-search__form-inputs'>
            {searchMode &&
              <div className='form-elem col-xs-12 col-sm-12 col-md-9'>
                <AddressSearchInput
                  id={`subscriptionAddressSearch${idSuffix}`}
                  value={address || ''}
                  placeholderKey='pages.homepage.search.address'
                  onChange={this.handleAddressChange}
                  hasError={addressError}
                  hasFocus={addressFocus}
                  railsContext={railsContext}
                  onFocus={this.focusHandler}
                />
              </div>}
            <div className={datetimePickerClass}>
              <FormInputDatePicker
                id={`searchSubBeginDate${idSuffix}`}
                value={beginDate}
                disabledBefore={moment()}
                placeholderKey='pages.homepage.search.subs_begindate'
                onFocus={this.focusBeginDatePicker}
                openCallback={() => this.focusBeginDateTimeGroup()}
                setCallback={date => {
                  this.handleBeginDateChange(getDateAtCurrentTime(date))
                  this.setState({ beginDateTimeFocus: false })
                }}
                inputRef={el => { this.dayPickerBeginInput = el }}
                onDateBlur={() => this.handleDateTimeBlurring()}
                hasError={beginDateTimeError}
                hasFocus={beginDateTimeFocus}
              />
            </div>
          </div>
          {searchMode &&
            <SearchSubmitButton showSearchIcon={showSearchIcon} loading={loading} submitButtonClass={submitButtonClass}/>}
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({
  bookingData: { state },
  search: { nextParams, params },
  railsContext
}) => ({
  nextParams,
  params,
  parkState: state,
  railsContext
})

function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators({ ...SearchActions }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(JumboSearchSubscription)
