import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import ReactOnRails from 'react-on-rails'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import PaymentInfoPage from './PaymentInfoPage'
import Summary from './Summary'
import PurchaseConfirmationPage from './Confirmation/index'
import { EditChanges } from './EditDates/EditChanges'

const PurchaseTunnel = ({ provider, stripePublicKey, ...otherProps }) =>
  <PersistGate persistor={persistStore(ReactOnRails.getStore('SharedReduxStore'))}>
    <BrowserRouter>
      <div>
        <Route path='/:locale/purchases/summary' component={() => <Summary stripePublicKey={stripePublicKey} provider={provider} {...otherProps} />} />
        <Route path='/purchases/summary' component={() => <Summary stripePublicKey={stripePublicKey} provider={provider} {...otherProps} />} />
        <Route path='/:locale/bookings/:booking_id/changes/:change_id/edit' component={() => <EditChanges stripePublicKey={stripePublicKey} provider={provider} {...otherProps} />} />
        <Route path='/bookings/:booking_id/changes/:change_id/edit' component={() => <EditChanges stripePublicKey={stripePublicKey} provider={provider} {...otherProps} />} />
        <Route path='/:locale/purchases/payment-informations' component={() => <PaymentInfoPage stripePublicKey={stripePublicKey} provider={provider} />} />
        <Route path='/purchases/payment-informations' component={() => <PaymentInfoPage stripePublicKey={stripePublicKey} provider={provider} />} />
        <Route path='/:locale/purchases/:id/confirmation' component={PurchaseConfirmationPage} />
        <Route path='/purchases/:id/confirmation' component={PurchaseConfirmationPage} />
        <Route path='/:locale/purchases/confirmation' component={PurchaseConfirmationPage} />
        <Route path='/purchases/confirmation' component={PurchaseConfirmationPage} />
      </div>
    </BrowserRouter>
  </PersistGate>

export default PurchaseTunnel
