import ClusterMarkerSvg from '../../../../../assets/images/cluster-marker.svg'

export const googleMapStyles = [{
  featureType: 'all',
  elementType: 'labels.text.fill',
  stylers: [{ color: '#727272' }]
}, {
  featureType: 'administrative.country',
  elementType: 'geometry',
  stylers: [{ visibility: 'on' }]
}, {
  featureType: 'administrative.country',
  elementType: 'geometry.stroke',
  stylers: [{ color: '#727272' }]
}, {
  featureType: 'poi',
  elementType: 'all',
  stylers: [{ visibility: 'off' }]
}, {
  featureType: 'poi.park',
  elementType: 'geometry',
  stylers: [{ visibility: 'on' }]
}, {
  featureType: 'road',
  elementType: 'labels.icon',
  stylers: [{ visibility: 'off' }]
}, {
  featureType: 'transit',
  elementType: 'labels.icon',
  stylers: [{ saturation: '-50' }]
}]

export const markerClustererStyles = [
  { height: 40, width: 40, url: ClusterMarkerSvg },
  { height: 48, width: 48, url: ClusterMarkerSvg },
  { height: 56, width: 56, url: ClusterMarkerSvg },
  { height: 64, width: 64, url: ClusterMarkerSvg },
  { height: 72, width: 72, url: ClusterMarkerSvg }
]
