import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { CURRENT_URL_COOKIE } from '../../constants/application'
import { getCookieValue, getURLFullPath } from '../../helpers/application'

import HistoryComponent from '../../components/HistoryComponent'
import SvgImage from '../../components/SvgImage'
import iconSend from '../../../../../../app/assets/images/svg-on-react/icon-send.svg'

class Confirmation extends Component {
  handleCloseConfirmation = () => {
    this.props.onCloseConfirmation()
  }

  render () {
    const { isPage } = this.props
    return (
      <div className='react-component availability-alert-confirmation'>
        <div className='row'>
          <div className='col-xs-12 m-bottom-10 text-center'>
            <SvgImage svg={iconSend} />
          </div>
          <div className='col-xs-12 m-bottom-20'>
            <p className='text-center'>
              <FormattedMessage id='pages.park.availability_alert.confirmation' />
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 text-center'>
            { isPage ? (
              <a href={getURLFullPath(getCookieValue(CURRENT_URL_COOKIE))} className='btn btn-primary'>
                  OK
              </a>
            ) : (
              <a href='#' className='btn btn-primary' data-dismiss='modal' onClick={this.handleCloseConfirmation}>
                  OK
              </a>
            )}
          </div>
        </div>
        <HistoryComponent
          id='pages.park.availability_alert.confirmation_title_tag'
          skipSavingLocation
          modal={!isPage && 'sent-availability-alert'}
          sentAvailabilityAlert={isPage}
          closeModal />
      </div>
    )
  }
}

export default Confirmation
