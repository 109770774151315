import React, { Component } from 'react'
import Select from 'react-select'
import URLSearchParams from 'url-search-params'
import { FormattedMessage } from 'react-intl'
import FormInput from '../../../components/Form/FormInput'
import TooltipComponent from '../../../components/TooltipComponent'
import FormInputPhone from '../../../components/Form/FormInputPhone'
import Loader from '../../../components/Loader'
import { datalayerPush, datalayerPushGTM, getCookieValue, getURLFullPath } from 'Helpers/application'
import { localePrefix } from '../../../helpers/authentication'
import {
  performValidation,
  genericUserBlankValidation,
  genericPhoneNumberValidation
} from '../../../helpers/formValidations'
import { CURRENT_URL_COOKIE, PURCHASE_URL_COOKIE, EDIT_URL_KEY } from 'Constants/application'
import iconUser from '../../../../../../../app/assets/images/svg-on-react/icon-user.svg'

export default class RegistrationBillingInfoStepForm extends Component {
  constructor (props) {
    super(props)

    this.state = { ...{
      firstNameError: false,
      lastNameError: false,
      addressError: false,
      zipCodeError: false,
      cityError: false,
      countryError: false,
      phoneNumberError: false,
      phoneNumberSkipError: false,
      loading: false
    },
    ...this.props }
  }

  doBillingInfos (skipped) {
    const { firstName, lastName, address, company, zipCode, city, country,
      prefixPhoneId, phoneNumber, email, phoneNumberSkipError } = this.state
    const prefix = localePrefix.call(this)
    const { source, mobilePurchaseTunnel } = this.props

    const user = {
      address: address,
      customer_company_name: company,
      zip_code: zipCode,
      city: city,
      country_id: country,
      email: email,
      phone_country_id: prefixPhoneId }

    if (!skipped) {
      this.setState({ loading: true })
      user['first_name'] = firstName
      user['last_name'] = lastName
      user['mobile_phone'] = phoneNumber
    } else {
      performValidation.call(this, genericPhoneNumberValidation, [this.state.prefixPhoneIso, this.state.phoneNumber], 'phoneNumberSkipError')
      if (!phoneNumberSkipError) {
        user['mobile_phone'] = phoneNumber
      }
    }

    if (company) {
      datalayerPush({ event: 'data_op', company: company })
    }

    fetch(`${prefix}/users`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        skip_step: skipped,
        user: user
      })
    }).then((response) => {
      return response.json()
    }).then((json) => {
      const errors = json.errors
      if (errors) {
        this.setState({
          firstNameError: (errors.first_name || [])[0],
          lastNameError: (errors.last_name || [])[0],
          addressError: (errors.address || [])[0],
          zipCodeError: (errors.zip_code || [])[0],
          cityError: (errors.city || [])[0],
          countryError: (errors.country_id || [])[0],
          phoneNumberError: (errors.mobile_phone || [])[0],
          loading: false
        })
        for (const error in json.errors) {
          datalayerPushGTM('form-tracking', 'Forms', 'Billing-informations', json.errors[error][0])
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search)
        datalayerPushGTM(
          'form-tracking',
          'Users',
          `${source === 'booking' && mobilePurchaseTunnel ? 'booking/' : ''}billing-informations/success`,
          '')
        if (sessionStorage.getItem(EDIT_URL_KEY)) {
          window.location = sessionStorage.getItem(EDIT_URL_KEY)
        } else if (urlParams.has('offer_id') && urlParams.get('action') === 'edit') {
          window.location = `${prefix}/purchases/summary?offer_id=${urlParams.get('offer_id')}`
        } else if (source === 'booking') {
          const redirectUrl = getCookieValue(PURCHASE_URL_COOKIE) || getCookieValue(CURRENT_URL_COOKIE, '/')
          window.location = getURLFullPath(redirectUrl)
        } else if (source === 'header') { // registration without booking/offer
          window.location.href = `${prefix}/`
        } else {
          window.location.href = `${prefix}/member/payment-informations?origin=${this.props.source}`
        }
      }
    })
  }

  formSubmitted (e) {
    e.preventDefault()
    const { countryError } = this.state

    // Todo: refactor code
    const inputValidations = [
      performValidation.call(this, genericUserBlankValidation, this.state.firstName, 'firstNameError', 'first_name'),
      performValidation.call(this, genericUserBlankValidation, this.state.lastName, 'lastNameError', 'last_name'),
      performValidation.call(this, genericUserBlankValidation, this.state.address, 'addressError', 'address'),
      performValidation.call(this, genericUserBlankValidation, this.state.zipCode, 'zipCodeError', 'zipcode'),
      performValidation.call(this, genericUserBlankValidation, this.state.city, 'cityError', 'city'),
      performValidation.call(this, genericPhoneNumberValidation, [this.state.prefixPhoneIso, this.state.phoneNumber], 'phoneNumberError')
    ]

    if (!inputValidations.includes(false) && !countryError) {
      this.doBillingInfos(false)
    }
  }

  skipFormSubmitted (e) {
    e.preventDefault()
    this.doBillingInfos(true)
  }

  cancelCtaClass () {
    if (this.props.editMode) return ''
    if (this.props.hideLaterButton === 'true') return 'hidden'
    if (this.props.source === 'booking') return 'hidden'
    return ''
  }

  render () {
    const { firstName, firstNameError, lastName, lastNameError, address,
      addressError, company, zipCode, zipCodeError, city, cityError,
      country, countrySearch, countryError, prefixPhoneId, prefixPhoneIso, phoneNumber, phoneNumberError,
      loading, countrySelect } = this.state

    const { source, editMode, mobilePurchaseTunnel } = this.props

    const prefix = localePrefix.call(this)

    const inputFirstNameConfig = {
      id: 'user_first_name',
      setCallback: (value) => this.setState({ firstName: value }),
      validateCallback: (value) => performValidation.call(this, genericUserBlankValidation, value, 'firstNameError', 'first_name'),
      required: true,
      svgIcon: iconUser,
      placeholderKey: 'authentication.user.first_name'
    }

    const inputLastNameConfig = {
      id: 'user_last_name',
      setCallback: (value) => this.setState({ lastName: value }),
      validateCallback: (value) => performValidation.call(this, genericUserBlankValidation, value, 'lastNameError', 'last_name'),
      required: true,
      svgIcon: iconUser,
      placeholderKey: 'authentication.user.last_name'
    }

    const inputAddressConfig = {
      id: 'user_address',
      setCallback: (value) => this.setState({ address: value }),
      validateCallback: (value) => performValidation.call(this, genericUserBlankValidation, value, 'addressError', 'address'),
      required: true,
      placeholderKey: 'authentication.user.address'
    }

    const inputCompanyConfig = {
      id: 'user_company',
      setCallback: (value) => this.setState({ company: value }),
      withoutValidate: true,
      placeholderKey: 'authentication.user.company'
    }

    const inputZipCodeConfig = {
      id: 'user_zip_code',
      setCallback: (value) => this.setState({ zipCode: value }),
      validateCallback: (value) => performValidation.call(this, genericUserBlankValidation, value, 'zipCodeError', 'zipcode'),
      required: true,
      placeholderKey: 'authentication.user.zip_code'
    }

    const inputCityConfig = {
      id: 'user_city',
      setCallback: (value) => this.setState({ city: value }),
      validateCallback: (value) => performValidation.call(this, genericUserBlankValidation, value, 'cityError', 'city'),
      required: true,
      placeholderKey: 'authentication.user.city'
    }

    const inputCountryConfig = {
      type: 'select-op',
      id: 'user_country',
      setCallback: (value) => this.setState({ country: value }),
      onSearchChange: (value) => this.setState({ countrySearch: value }),
      withoutValidate: true,
      placeholderKey: 'authentication.user.country'
    }

    const inputPhoneNumberConfig = {
      type: 'phone-input-op',
      id: 'user_mobile_phone',
      setCallback: (value) => this.setState({ phoneNumber: value }),
      setPrefixIdCallback: (value) => this.setState({ prefixPhoneId: value }),
      setPrefixIsoCallback: (value) => this.setState({ prefixPhoneIso: value }),
      validateCallback: (value) => performValidation.call(this, genericPhoneNumberValidation, value, 'phoneNumberError', null),
      required: true,
      placeholderKey: 'authentication.user.mobile_phone'
    }

    const bookingInUrl = source === 'booking'
    const cancelCtaMessage = bookingInUrl ? 'actions.cancel' : 'authentication.later'
    const ctaMessage = mobilePurchaseTunnel && !editMode
      ? 'authentication.continue'
      : (bookingInUrl ? 'authentication.validate' : 'authentication.save')

    return (
      <form onSubmit={(e) => this.formSubmitted(e)} action={`${prefix}/users`} method='put' noValidate>

        <FormInput inputValue={firstName} errorMessage={firstNameError} {...inputFirstNameConfig}>
          <input type='text' className='form-control' />
        </FormInput>

        <FormInput inputValue={lastName} errorMessage={lastNameError} {...inputLastNameConfig}>
          <input type='text' className='form-control' />
        </FormInput>

        <FormInput inputValue={address} errorMessage={addressError} {...inputAddressConfig}>
          <input type='text' className='form-control' />
        </FormInput>

        <FormInput inputValue={company} errorMessage={null} {...inputCompanyConfig}>
          <input type='text' className='form-control' />
        </FormInput>

        <div className='row'>
          <div className='col-xs-12 col-sm-4'>
            <FormInput inputValue={zipCode} errorMessage={zipCodeError} {...inputZipCodeConfig}>
              <input type='text' className='form-control' />
            </FormInput>
          </div>
          <div className='col-xs-12 col-sm-8'>
            <FormInput inputValue={city} errorMessage={cityError} {...inputCityConfig}>
              <input type='text' className='form-control' />
            </FormInput>
          </div>
        </div>

        <FormInput inputValue={country} searchValue={countrySearch} errorMessage={countryError} {...inputCountryConfig}>
          <Select
            className='select-op'
            clearable={false}
            searchable
            simpleValue
            placeholder=''
            value={country}
            onInputChange={inputCountryConfig.onSearchChange}
            options={countrySelect.map((country, index) => ({ label: country.name, value: country.id }))}
          />
        </FormInput>

        <div className='registration__phone-number row'>
          <div className='col-sm-12'>
            <FormInput withoutFloatingLabel inputValue={phoneNumber} errorMessage={phoneNumberError} {...inputPhoneNumberConfig}>
              <FormInputPhone
                id='user'
                prefixValue={`${prefixPhoneId}-${prefixPhoneIso}`}
                countrySelect={countrySelect}
                setPrefixIdCallback={inputPhoneNumberConfig.setPrefixIdCallback}
                setPrefixIsoCallback={inputPhoneNumberConfig.setPrefixIsoCallback}
                validateCallback={inputPhoneNumberConfig.validateCallback}
                setCallback={inputPhoneNumberConfig.setCallback}
                placeholderKey={inputPhoneNumberConfig.placeholderKey}
              />
            </FormInput>
          </div>
          <TooltipComponent isOpen message='authentication.registration.billing_info.phone_tooltip' />
        </div>

        <ul>
          <li>
            <button type='submit' className='btn btn-primary registration__btn-form' disabled={loading}>
              {loading && <Loader overlay white />}
              <span className={loading ? 'invisible' : ''}>
                <FormattedMessage id={ctaMessage} />
              </span>
            </button>
          </li>
          <li className={this.cancelCtaClass()}>
            <div className='registration__billing-details-avoid-action'>
              <a href='#'
                className='link--secondary link--uppercase registration__billing-details-avoid-action-link text-small'
                onClick={(e) => {
                  this.skipFormSubmitted(e); this.props.onCancel !== undefined && this.props.onCancel()
                }}>
                <FormattedMessage id={cancelCtaMessage} />
              </a>
            </div>
          </li>
        </ul>
      </form>
    )
  }
}
