import { datalayerPush, datalayerPushGTM, datalayerPushTransaction } from 'Helpers/application'
import { moveToConfirmationPage, translatedErrorMessage } from 'Helpers/purchaseTunnel'
import { persistStore } from 'redux-persist'

export class PurchaseHelper {
  constructor ({ callerProps, stripeObject, setErrors, unsetLoading, backendPostUrl, backendPostParams }) {
    this.props = callerProps
    this.stripeObject = stripeObject
    this.unsetLoading = unsetLoading
    this.setErrors = setErrors

    // Summary/index: `${localePrefix}/purchases/create_react`
    // EditChanges: `${localePrefix}/offers/${offerBuilder.id}/purchases`
    this.backendPostUrl = backendPostUrl
    this.backendPostParams = backendPostParams
  }

  setThreedsError = () => {
    this.setErrors({ threedsError: true, purchaseError: false, stripeError: false, errorWithDateRedirect: false })
  }

  setPaymentError = ({ purchaseError, errorWithDateRedirect, scrollToTop }) => {
    this.setErrors({ purchaseError, errorWithDateRedirect, threedsError: false, stripeError: false, scrollToTop })
  }

  setStripeError = (stripeErrorMessage) => {
    this.setErrors({ stripeError: stripeErrorMessage, threedsError: false, purchaseError: false, errorWithDateRedirect: false })
  }

  fetchCompleteBookingResponse = () => {
    return fetch(this.backendPostUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify(this.backendPostParams)
    }).then(backendResponse => backendResponse.json())
  }

  gtmPushParamsOnError = (json) => {
    const park = (json.purchase && json.purchase.park) || {}
    const { offerBuilder } = this.props
    const pushParams = {
      event: 'payment_error',
      ep_vehicle_type: '4W',
      ep_new_customer: `${json.new_customer}`,
      currency: 'EUR',
      value: (offerBuilder.multipleEntries.allowSelected ? offerBuilder.amount.totalWithOptions : offerBuilder.amount.totalWithoutOptions),
      transaction_id: `${json.purchase && json.purchase.booking && json.purchase.booking.id}`,
      item_id: `${park.id}`,
      item_name: park.name,
      up_language: (window._oneparkDatas.up_language || null),
      up_locale: (window._oneparkDatas.up_locale || null)
    }
    if (window._oneparkDatas.up_user_id != null && window._oneparkDatas.up_user_id.length !== 0) {
      pushParams.up_user_id = window._oneparkDatas.up_user_id
    }
    return pushParams
  }

  proceedPurchase = async (json) => {
    const transactionResult = await fetch(`${this.props.localePrefix}/payment/transactions/${json.billable_id}`, {
      method: 'PATCH',
      body: JSON.stringify({ payment_intent_id: json.payment_intent && json.payment_intent.id }),
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    const transactionJson = await transactionResult.json()
    if (transactionResult.ok && transactionJson.purchase) {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/success', transactionJson.purchase.id)
      if (window.Cookies.get('new_purchase_confirmation') !== undefined) {
        datalayerPushTransaction(transactionJson.purchase)
        datalayerPushGTM('serverEventTransaction')
        window.ga('send', 'event', 'purchase', 'transaction/success', this.props.user.id)
      }
      persistStore(this.props).purge()

      window.scrollTo(0, 0)

      return transactionJson
    } else {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/error', transactionJson.error)
      datalayerPush(this.gtmPushParamsOnError(json))

      this.setPaymentError({
        purchaseError: transactionJson.error, errorWithDateRedirect: transactionJson.error_with_date_redirect, scrollToTop: true
      })
      this.unsetLoading()

      return false
    }
  }

  cancelTransaction = async (transactionId) => {
    await fetch(`${this.props.localePrefix}/payment/transactions/${transactionId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.unsetLoading()
  }

  completeBooking = async () => {
    const { history, localePrefix, type } = this.props

    const jsonBackendResponse = await this.fetchCompleteBookingResponse()

    if (jsonBackendResponse.error || !jsonBackendResponse.payment_intent) {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/error', jsonBackendResponse.error || 'no payment_intent')
      datalayerPush(this.gtmPushParamsOnError(jsonBackendResponse))

      this.setPaymentError({ purchaseError: jsonBackendResponse.error, errorWithDateRedirect: jsonBackendResponse.error_with_date_redirect })
      this.unsetLoading()

      return
    }

    if (jsonBackendResponse.payment_intent.status === 'succeeded') {
      const purchaseConfirmedResult = await this.proceedPurchase(jsonBackendResponse)
      if (purchaseConfirmedResult) moveToConfirmationPage(purchaseConfirmedResult, history, localePrefix, type)
      return
    }

    if (jsonBackendResponse.payment_intent.status !== 'requires_action') {
      Sentry.captureException(new Error(`payment intent has unexpected status ${jsonBackendResponse.payment_intent.status}.`))
      return
    }

    const handleError = async () => {
      await this.cancelTransaction(jsonBackendResponse.billable_id)
      datalayerPush(this.gtmPushParamsOnError(jsonBackendResponse))
    }

    try {
      const stripeResponse = await this.stripeObject.handleNextAction({ clientSecret: jsonBackendResponse.payment_intent.client_secret })

      if (stripeResponse && stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
        const purchaseConfirmedResult = await this.proceedPurchase(jsonBackendResponse)
        moveToConfirmationPage(purchaseConfirmedResult, history, localePrefix, type)
        return
      }

      if (stripeResponse.error && stripeResponse.error.code === 'payment_intent_authentication_failure') {
        this.setThreedsError()
        await handleError()
      } else {
        this.setStripeError(translatedErrorMessage(stripeResponse.error, this.props.intl))
        await handleError()
      }
    } catch (error) {
      await handleError()
      Sentry.captureException(error)
    }
  }
}
