import moment from 'moment'
import URLSearchParams from 'url-search-params'

import { getCookieValue, setCookieValue } from './application'

export function buildUrl (pathname, params) {
  const nextUrlParams = new URLSearchParams()
  Object.entries(params).forEach(([key, val]) => val && nextUrlParams.set(key, val))
  const qs = nextUrlParams.toString()
  return pathname + (qs ? `?${qs}` : '')
}

export function buildSearchApiParams (searchParams) {
  const { address, beginDate, endDate, lat, lng, placeId, type } = searchParams
  const apiParams = {
    q: address,
    begin_date: beginDate.toISOString(true),
    end_date: endDate ? endDate.toISOString(true) : null,
    lat,
    lng,
    place_id: placeId,
    type: type === 'subscription' ? type : null
  }
  Object.entries(apiParams).forEach(([key, value]) => { if (!value) { delete apiParams[key] } })
  return apiParams
}

export function clearSearchParamsFromUrl () {
  const params = new URLSearchParams(window.location.search)
  for (const key of ['begin_date', 'begin_time', 'end_date', 'end_time', 'offset',
    'duration', 'address_types', 'region', 'address', 'lat', 'lng']) {
    params.delete(key)
  }

  const queryString = Array.from(params).length ? `?${params.toString()}` : ''
  history.replaceState({}, null, `${window.location.pathname}${window.location.hash}${queryString}`)
}

export function clearSearchFromCookie () {
  const userSearch = getCookieValue('user_search_params')
  if (userSearch) {
    const json = JSON.parse(decodeURIComponent(userSearch))
    json.query = ''
    setCookieValue('user_search_params', encodeURIComponent(JSON.stringify(json)))
  }
}

export function getTimePickerOptions (date, timeFormat, upperLimit) {
  const stepMinutes = 15
  const value = date
    .clone()
    .minutes(Math.ceil(date.minutes() / stepMinutes) * stepMinutes)
    .second(0)
    .millisecond(0)

  const end = date.clone().endOf('day')
  const options = []

  let prevUtcOffset = value.utcOffset() // in minutes

  while (upperLimit ? value.isSameOrBefore(upperLimit) : value.isBefore(end)) {
    options.push({ value: value.toDate().toISOString(), label: value.format(timeFormat) })
    value.add(stepMinutes, 'minutes')
    const deltaOffset = prevUtcOffset - value.utcOffset()
    if (deltaOffset > 0) {
      value.add(deltaOffset, 'minutes')
    }
    prevUtcOffset = value.utcOffset()
  }
  return options
}

export function getCeilQuarterOf (date) {
  const stepMinutes = 15
  return date
    .clone()
    .minutes(Math.ceil(date.minutes() / stepMinutes) * stepMinutes)
    .second(0)
    .millisecond(0)
}

export function getDateAtCurrentTime (date) {
  const now = getCeilQuarterOf(moment())
  return date
    .clone()
    .hours(now.hours())
    .minutes(now.minutes())
}

export function getEndDateAfterBeginDateChange (endDate, nextBeginDate) {
  const minimumEndDate = nextBeginDate.clone().add(1, 'hour')
  if (!endDate || minimumEndDate.isAfter(endDate)) {
    return minimumEndDate
  }
  return endDate
}

export function getEndDateAfterEndDateChange (beginDate, endDate, nextEndDate) {
  const defaultMinimumEndDate = getDateAtCurrentTime(nextEndDate).add(1, 'hour')
  const minimumEndDate = beginDate ? beginDate.clone().add(1, 'hour') : defaultMinimumEndDate
  const hasDayChanged = !!endDate && (nextEndDate.dayOfYear() !== endDate.dayOfYear())
  const nextEndDateTime = hasDayChanged ? copyTime(endDate, nextEndDate) : nextEndDate

  if (minimumEndDate.isAfter(nextEndDateTime)) {
    return minimumEndDate
  }
  return nextEndDateTime
}

function copyTime (srcDate, targetDate) {
  return targetDate.clone().set({
    hour: srcDate.get('hour'),
    minute: srcDate.get('minute')
  })
}

export function isEnterKeyPressed (event) {
  return ((event.key === 'Enter' || (!event.key && event.keyCode === 13)))
}

export function setCurrentLocationType (type) {
  setCurrentLocationParams({ type: type === 'subscription' ? 'subscription' : null })
}

export function setCurrentLocationParams (params) {
  const qs = changeQueryStringParams(window.location.search, params)
  const url = `${window.location.pathname}${window.location.hash}${qs.length > 0 ? '?' + qs : ''}`
  history.replaceState({}, null, url)
}

function changeQueryStringParams (queryString, params) {
  queryString = queryString.startsWith('?') ? queryString.slice(1) : queryString
  const urlSearchParams = new URLSearchParams(queryString)
  Object.entries(params).forEach(([key, val]) => val ? urlSearchParams.set(key, val) : urlSearchParams.delete(key))
  return urlSearchParams.toString()
}
