import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import SlideShow from 'Components/SlideShow'
import { MobileSlideShow } from 'Components/MobileSlideShow'
import TransparentModal from 'Components/TransparentModal'
import ShareLinks from './ShareLinks'
import { datalayerPushNavigationEvent } from '../../helpers/application'

class Photo extends Component {
  state = {
    showModal: false
  }

  handleClickImage = () => {
    datalayerPushNavigationEvent('see_all_pictures')

    this.setState({ showModal: true }, () => {
      this.$el.find('.modal').modal('show')
    })
  }

  handleModalClosed = () => {
    this.setState({ showModal: false })
  }

  renderPhotos = (park, images, title, isMobile) => {
    const imagesSlice = Array.isArray(images) ? images.slice(0, 4) : []

    return (
      isMobile ? this.renderMobilePhotos(park, imagesSlice, title) : this.renderDesktopPhotos(imagesSlice, title)
    )
  }

  renderMobilePhotos = (park, images, title) => {
    if (images.length > 0) {
      return (
        <div className='park-overview__photo-container--mobile'>
          <div className='park-overview-photo-container-mobile__share-container'>
            <ShareLinks parkName={park.name} parkAddress={park.address.fullAddress} />
          </div>
          <img
            title={title}
            alt={images[0].alt}
            className='park-overview__photo-single--mobile'
            width={`414px`}
            height={`736px`}
            src={images[0].url736}
            srcSet={`${images[0].url736} 736w`}
            onClick={this.handleClickImage}
            fetchpriority='high'
          />
          { images.length > 1 &&
            <div className='park-overview__see-all' onClick={this.handleClickImage}>
              <FormattedMessage id={'pages.photo.view_more'} />
            </div>
          }
        </div>
      )
    }
  }

  renderDesktopPhotos = (images, title) => {
    switch (images.length) {
      case 1:
        return (
          <div className='park-overview__photos-block--left'>
            <img
              title={title}
              alt={images[0].alt}
              className='park-overview__photo-single--clickable'
              src={images[0].url736}
              srcSet={`${images[0].url736} 736w`}
              onClick={this.handleClickImage}
              fetchpriority='high'
            />
          </div>
        )

      case 2:
        return (
          <div className='park-overview__photos-block--left'>
            <img
              title={title}
              alt={title}
              className='park-overview__photo-single--clickable'
              width={`414px`}
              height={`736px`}
              src={images[0].url736}
              srcSet={`${images[0].url736} 736w`}
              onClick={this.handleClickImage}
              fetchpriority='high'
            />
          </div>
        )

      case 3:
      case 4:
        return (
          <>
            <div className='park-overview__photos-block--left'>
              <img
                title={title}
                alt={images[0].alt}
                className='park-overview__photo-single--clickable'
                width={`${414 * 0.75}px`}
                height={`${736 * 0.75}px`}
                src={images[0].url736}
                srcSet={`${images[0].url736} 736w`}
                onClick={this.handleClickImage}
                fetchPriority='high'
              />
            </div>
            <div className='park-overview__photos-block--right'>
              <img
                title={title}
                alt={images[1].alt}
                className='park-overview__photos-block-photo'
                width={`${414 / 4}px`}
                height={`${736 / 4}px`}
                src={images[1].url736}
                srcSet={`${images[1].url736} 736w`}
                onClick={this.handleClickImage}
              />
              <div className='park-overview__photos-block--overlaid' onClick={this.handleClickImage}>
                <div className='park-overview__overlay-text'>{<FormattedMessage id={'pages.photo.view_more'} />}</div>
                <img
                  title={title}
                  alt={images[2].alt}
                  className='park-overview__overlay-photo'
                  width={`${414 / 4}px`}
                  height={`${736 / 4}px`}
                  src={images[2].url736}
                  srcSet={`${images[2].url736} 736w`}
                />
              </div>
            </div>
          </>
        )
    }
  }

  render () {
    const { park, isMobile, park: { images } } = this.props
    const { showModal } = this.state
    const title = `${park.name} ${park.address.city}`

    return (
      <div ref={el => { this.$el = $(el) }}>
        <div className='park-overview__photos'>
          {this.renderPhotos(park, images, title, isMobile)}
          {images.length === 0 && <div className='photo__caption'><FormattedMessage id='pages.photo.unavailable' /></div>}
        </div>

        {showModal && (
          <TransparentModal onModalClosed={this.handleModalClosed}>
            {isMobile &&
              <MobileSlideShow images={images.map(image => ({ url: image.url736, alt: image.alt }))} />
            }
            {!isMobile &&
              <SlideShow images={images.map(image => ({ url: image.url736, alt: image.alt }))} />
            }
          </TransparentModal>
        )}
      </div>
    )
  }
}

export default Photo
