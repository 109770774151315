import React from 'react'

import AddressItem from './AddressItem'
import DistanceItem from '../common/DistanceItem'
import Photo from './Photo'
import RatingLink from './RatingLink'
import ShareLinks from './ShareLinks'
import { getRatingIndicators } from '../../helpers/park'

export const MobileOverviewSection = ({ park }) => (
  <div className='mobile-park-overview'>
    <Photo park={park} isMobile />

    <div className='mobile-park-overview__info-container'>
      <div className='mobile-park-overview__name'>
        <h1>{park.isSeo ? `${park.name} - ${park.address.city}` : park.name}</h1>
        {park.images.length === 0 && <ShareLinks parkName={park.name} parkAddress={park.address.fullAddress} />}
      </div>
      <div className='mobile-park-overview__additional-info-container'>
        <div className='mobile-park-overview__rating-distance-info-container'>
          <RatingLink {...getRatingIndicators(park)} />

          <ul className='small-icon-list small-icon-list--overview'>
            {park.distance && <DistanceItem {...park.distance} />}
          </ul>
        </div>

        <AddressItem park={park} />
      </div>
    </div>
  </div>
)
