import { cloneDeep } from 'lodash';

export class MegaMenuHelper {
  static adaptMegaMenuContentForDesktop(megaMenuContent) {
    // TODO: cache it
    return this.adaptMegaMenuContentCommon(megaMenuContent, 'desktop')
  }

  static adaptMegaMenuContentForMobile(megaMenuContent) {
    // TODO: cache it
    return this.adaptMegaMenuContentCommon(megaMenuContent, 'mobile')
  }

  static adaptMegaMenuContentCommon(megaMenuContent, mode) {
    if (!megaMenuContent) return null

    const adaptedMegaMenuContent = JSON.parse(JSON.stringify(megaMenuContent));
    adaptedMegaMenuContent.forEach(content => {
      for (let sectionIndex = content.sections.length - 1; sectionIndex > 0; sectionIndex--) {
        const currentSection = content.sections[sectionIndex]
        const previousSection = content.sections[sectionIndex - 1]

        const lastLinkGroupOfPreviousSection = previousSection.linkGroups[previousSection.linkGroups.length - 1]

        switch (mode) {
          case 'desktop':
            if (lastLinkGroupOfPreviousSection.title === currentSection.linkGroups[0].title) {
              currentSection.linkGroups[0].title = ''
            }
            break
          case 'mobile':
            if (lastLinkGroupOfPreviousSection.title === currentSection.linkGroups[0].title) {
              lastLinkGroupOfPreviousSection.links = [...lastLinkGroupOfPreviousSection.links, ...currentSection.linkGroups[0].links]
              lastLinkGroupOfPreviousSection.seeMoreLink = currentSection.linkGroups[0].seeMoreLink
              currentSection.linkGroups.splice(0, 1) // remove first link group as it was merged to another link group
            }
            break
        }
      }
    })

    return adaptedMegaMenuContent
  }
}
