import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as FilterActions from 'Actions/FilterActions'
import { FiltersPanel } from 'Components/FiltersPanel'

class ConnectedDesktopFiltersModal extends Component {
  handleClickApplyFilters = async (newFilters) => {
    const { updateParkFilters } = this.props.actions
    await updateParkFilters(newFilters)
    this.props.onClose()
  }

  render () {
    const { showAllFilters } = this.props.showAllFilters

    return (
      <div className='filters-desktop-modal'>
        <button className='filters-desktop-modal__close-button' onClick={this.props.onClose} aria-label='close'>
          <span aria-hidden='true'>&times;</span>
        </button>
        <FiltersPanel handleApply={this.handleClickApplyFilters} showAllFilters={showAllFilters} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...FilterActions }, dispatch) })

export const DesktopFiltersModal = connect(null, mapDispatchToProps)(ConnectedDesktopFiltersModal)
