import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import MultipleEntriesSection from '../../Purchase/Summary/MultipleEntriesSection'

import TooltipComponent from '../../components/TooltipComponent'
import { CustomFormattedCurrency } from 'Components/CustomFormattedCurrency'

const BestOfferAndAmountComponent = ({
  onSitePrice,
  parkBestOffer,
  discountCode,
  displayPriceBeforeDiscount = true,
  showOptionPrice,
  offerAmount,
  type,
  bigTotal,
  showMultipleEntriesChoice,
  localePrefix,
  offerId,
  optionsPrice,
  handleMultipleEntriesChange,
  isMultipleEntriesChecked,
  showNewAmount,
  totalAmountSectionClasses,
  toolTipBemModifier
}) => {
  const bookingAmount = parkBestOffer.originalOffer ? parkBestOffer.originalOffer.bookingAmount : parkBestOffer.amount.bookingAmount
  const serviceFeeAmount = parkBestOffer.originalOffer ? parkBestOffer.originalOffer.serviceFeeAmount : parkBestOffer.amount.serviceFee
  let totalAmount = 0

  if (parkBestOffer.originalOffer) {
    totalAmount = parkBestOffer.originalOffer.totalAmount
  } else {
    if (!discountCode && parkBestOffer.amount.discountPrice) {
      totalAmount = parseFloat(parkBestOffer.amount.discountPrice, 10) + offerAmount
    } else {
      totalAmount = offerAmount
    }
  }

  const displayMultiplePriceText = (showOptionPrice && parkBestOffer.multipleEntries.allow) ||
      parkBestOffer.multipleEntries.include || parkBestOffer.multipleEntries.allowSelected
  const entriesCountMessageId = `pages.park.description.caracteristics.${displayMultiplePriceText ? 'multiple_entries' : 'single_entry'}`
  const entriesTooltipMessageId = `pages.park.booking_panel.${displayMultiplePriceText ? 'multiple_entries_tooltip' : 'single_entry_tooltip'}`

  return (
    <div className='best-offer-amount'>
      <div className='offer'>
        <div className='offer__info-container'>
          {parkBestOffer.amount.ref
            ? <>
              {displayPriceBeforeDiscount &&
                <div className='best-offer row'>
                  <div className='col-xs-8 best-offer__title best-offer__title--discount'>
                    {onSitePrice ? <FormattedMessage id='pages.park.on_site_price' /> : parkBestOffer.title}
                  </div>

                  <div className={`col-xs-4 best-offer__price ${parkBestOffer.amount.ref ? 'best-offer__price--discount' : ''}`}>
                    <FormattedNumber value={parkBestOffer.amount.ref} style='currency' currency='EUR' />
                  </div>
                </div>
              }

              <div className='best-offer row'>
                <div className='col-xs-8 best-offer__title best-offer__title--onepark-price'>
                  <FormattedMessage id='pages.park.onepark_price' />
                </div>

                <div className='col-xs-4 best-offer__price best-offer__price--onepark-price'>
                  <FormattedNumber value={parkBestOffer.amount.bookingAmount} style='currency' currency='EUR' />
                </div>
              </div>
            </>
            : <div className='best-offer row'>
              <div className='col-xs-8 best-offer__title'>
                {type === 'package' && parkBestOffer.title}
                {type === 'subscription' && <FormattedMessage id='pages.park.subscription_offer_title' />}
              </div>

              <div className='col-xs-4 best-offer__price'>
                <FormattedNumber value={bookingAmount} style='currency' currency='EUR' />
              </div>
            </div>
          }

          <div className='service-fee row'>
            <div className='col-xs-8 service-fee__title'>
              <FormattedMessage id='pages.park.service_fee'>
                {message => <span className='service-fee__title-text'>{message}</span>}
              </FormattedMessage>
              <TooltipComponent isOpen={false} message='pages.park.service_fee_tooltip' bemModifier={toolTipBemModifier} fitWindow />
            </div>

            <div className='col-xs-4 service-fee__price'>
              {serviceFeeAmount > 0
                ? <FormattedNumber value={serviceFeeAmount} style='currency' currency='EUR' />
                : <FormattedMessage id='pages.park.service_fee_free' />
              }

            </div>
          </div>

          <div className='multiple-entries row'>
            {!showMultipleEntriesChoice &&
              <>
                <div className='col-xs-9 multiple-entries__title'>
                  <FormattedMessage id={entriesCountMessageId}>
                    {message => <span className='multiple-entries__title-text'>{message}</span>}
                  </FormattedMessage>
                  <TooltipComponent isOpen={false} message={entriesTooltipMessageId} bemModifier={toolTipBemModifier} fitWindow />
                </div>

                <div className='col-xs-3 multiple-entries__include'>
                  {(showOptionPrice && optionsPrice > 0) && (parkBestOffer.multipleEntries.allow || parkBestOffer.multipleEntries.include) &&
                    <FormattedNumber value={parkBestOffer.multipleEntries.optionPrice} style='currency' currency='EUR' />
                  }
                  {parkBestOffer.multipleEntries.include &&
                    <FormattedMessage id='pages.park.booking_panel.free_entry' />
                  }
                </div>
              </>
            }
            {
              showMultipleEntriesChoice &&
              <MultipleEntriesSection
                localePrefix={localePrefix}
                offerId={offerId}
                discountCode={discountCode}
                optionsPrice={optionsPrice}
                handleMultipleEntriesChange={handleMultipleEntriesChange}
                isMultipleEntriesChecked={isMultipleEntriesChecked} />
            }
          </div>

          {discountCode && discountCode.name &&
            <div className='discount-code row'>
              <div className='col-xs-8'>
                {discountCode.name}
              </div>
              <div className='col-xs-4 discount-code__value'>
                {
                  discountCode.type === 'service_fee'
                    ? <FormattedMessage id='pages.park.service_fee_free' />
                    : <> - <FormattedNumber value={parkBestOffer.amount.discountPrice} style='currency' currency='EUR' /></>
                }
              </div>
            </div>
          }
        </div>
        <div className='amount row'>
          {
            !bigTotal &&
            <>
              <div className='col-xs-5 amount__title'>
                {showNewAmount && <FormattedMessage id='booking.edit.new_offer_amount' />}
                {!showNewAmount && <FormattedMessage id='pages.park.booking_panel.amount' />}
              </div>

              <div className={`col-xs-7 amount__prices ${parkBestOffer.amount.ref ? 'amount__prices--discount' : ''} ${onSitePrice ? 'amount__prices--on-site' : ''}`}>

                {parkBestOffer.amountWithSecondaryCurrency &&
                  <span className='amount__other-currency-price'>
                    <FormattedNumber
                      value={parkBestOffer.amountWithSecondaryCurrency.total}
                      style='currency'
                      currency={parkBestOffer.amountWithSecondaryCurrency.unit} />
                    {type === 'subscription' && <FormattedMessage id='pages.park.frequency' />}
                  </span>
                }

                <span className='amount__price'>
                  <FormattedNumber value={totalAmount} style='currency' currency='EUR' />
                  {type === 'subscription' && <FormattedMessage id='pages.park.frequency' />}
                </span>
              </div>
            </>
          }
          {
            bigTotal &&
            <div className={`amount__price-container ${totalAmountSectionClasses || ''}`}>
              <div className='amount__title'>
                {showNewAmount && <FormattedMessage id='booking.edit.new_offer_amount' />}
                {!showNewAmount && <FormattedMessage id='purchase.purchase_summary.amount' />}
              </div>

              <span className='amount__price amount__price--right'>
                {parkBestOffer.amountWithSecondaryCurrency &&
                  CustomFormattedCurrency({
                    currency: parkBestOffer.amountWithSecondaryCurrency.unit,
                    value: parkBestOffer.amountWithSecondaryCurrency.total
                  })}
                &nbsp;
                { <FormattedNumber value={totalAmount} style='currency' currency='EUR' /> }
                {type === 'subscription' && <FormattedMessage id='pages.park.frequency' />}
              </span>
            </div>}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ search: { params: { type } } }) => ({ type })

export const BestOfferAndAmount = connect(mapStateToProps)(BestOfferAndAmountComponent)
