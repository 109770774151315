import React from 'react'
import { FormattedMessage } from 'react-intl'

import SvgImage from '../../components/SvgImage'
import TooltipComponent from '../../components/TooltipComponent'
import iconMoto from '../../../../../../app/assets/images/svg-on-react/moto.svg'
import iconCrossedMoto from '../../../../../../app/assets/images/svg-on-react/crossed-moto.svg'
import iconCityCar from '../../../../../../app/assets/images/svg-on-react/city-car.svg'
import iconCrossedCityCar from '../../../../../../app/assets/images/svg-on-react/crossed-city-car.svg'
import iconSedanCar from '../../../../../../app/assets/images/svg-on-react/sedan-car.svg'
import iconCrossedSedanCar from '../../../../../../app/assets/images/svg-on-react/crossed-sedan-car.svg'
import iconUtilityCar from '../../../../../../app/assets/images/svg-on-react/utility-car.svg'
import iconCrossedUtilityCar from '../../../../../../app/assets/images/svg-on-react/crossed-utility-car.svg'
import iconFamilyCar from '../../../../../../app/assets/images/svg-on-react/family-car.svg'
import iconCrossedFamilyCar from '../../../../../../app/assets/images/svg-on-react/crossed-family-car.svg'

const I18N_BASE = 'pages.park.description.allowed_vehicles'

const AVAILABLE_VEHICLE_ICONS = {
  moto: iconMoto,
  city_car: iconCityCar,
  sedan_car: iconSedanCar,
  family_car: iconFamilyCar,
  utility_car: iconUtilityCar
}

const NOT_AVAILABLE_VEHICLE_ICONS = {
  moto: iconCrossedMoto,
  city_car: iconCrossedCityCar,
  sedan_car: iconCrossedSedanCar,
  family_car: iconCrossedFamilyCar,
  utility_car: iconCrossedUtilityCar
}

const VehicleItem = ({ vehicleType, available }) => {
  const getProperVehicleIcon = () => available ? AVAILABLE_VEHICLE_ICONS[vehicleType] : NOT_AVAILABLE_VEHICLE_ICONS[vehicleType]

  return (
    <li className='col-xs-6'>
      <div className={`car-type ${vehicleType} ${available ? 'available' : null}`}>
        <SvgImage svg={getProperVehicleIcon()} />
        <div className='vehicle-item__title'>
          <FormattedMessage id={`${I18N_BASE}.${vehicleType}.title`} />
          {vehicleType !== 'moto' &&
            <TooltipComponent isOpen={false} message={`${I18N_BASE}.${vehicleType}.description`} />
          }
        </div>
      </div>
    </li>
  )
}

export default VehicleItem
