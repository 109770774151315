import React from 'react'
import { getMapProvider } from '../../../../libs/mapProvider'

const MainMarker = ({ mapRef, position }) => {
  return (
    mapRef &&
    mapRef.getMap
      ? (
        React.createElement(
          getMapProvider().Marker,
          {
            latitude: parseFloat(position.lat),
            longitude: parseFloat(position.lng),
            className: 'main-marker'
          }
        )
      )
      : null
  )
}

export default MainMarker
