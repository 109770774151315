import React from 'react'
import SvgImage from './SvgImage'

import iconArrowDown from '../../../../../app/assets/images/svg-on-react/icon-arrow-down.svg'

const DropdownButton = ({ className, options, value, onChange }) => (
  <div className='dropdown dropdown-button'>
    <button data-target='#' className={className} data-toggle='dropdown'>
      {options.find(option => option.id === value).label}
      <SvgImage svg={iconArrowDown} />
    </button>

    <ul className='dropdown-menu'>
      {options.map(({ id, label }) => (
        <li key={id} onClick={() => onChange(id)}>
          <a>{label}</a>
        </li>
      ))}
    </ul>
  </div>
)

export default DropdownButton
