import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ModalOp from '../components/ModalOp'

class EditorialHtml extends Component {
  static propTypes = {
    html: PropTypes.string
  }

  state = {
    showImage: null // { src, alt }
  }

  handleClickImage = (e) => {
    if ($(window).width() >= 992) {
      const { src, alt } = e.target
      this.setState({ showImage: { src, alt } })
      this.$el.find('.modal').modal('show')
    }
  }

  handleModalClosed = () => {
    this.setState({ showImage: null })
  }

  componentDidMount = () => {
    this.$el
      .find('.img-responsive')
      .on('click', this.handleClickImage) // No need to off those event listeners. They will disappear with the DOM
  }

  render () {
    const { html } = this.props
    const { showImage } = this.state
    return (
      <div ref={el => { this.$el = $(el) }}>
        <div className='back-office-html with-styled-list' dangerouslySetInnerHTML={{ __html: html }} />
        { showImage &&
          <ModalOp desktopOnly autoHideOnResize onModalClosed={this.handleModalClosed}>
            <img src={showImage.src} alt={showImage.alt} />
          </ModalOp>
        }
      </div>
    )
  }
}

export default EditorialHtml
