import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import AccountOffIcon from '../../../../assets/images/icon-account-off.svg'
import AccountOnIcon from '../../../../assets/images/icon-account-on.svg'
import BurgerOffIcon from '../../../../assets/images/icon-burger-off.svg'
import BurgerOnIcon from '../../../../assets/images/icon-burger-on.svg'
import HelpOffIcon from '../../../../assets/images/icon-help-off.svg'
import HelpOnIcon from '../../../../assets/images/icon-help-on.svg'
import SearchOffIcon from '../../../../assets/images/icon-search-off.svg'
import SearchOnIcon from '../../../../assets/images/icon-search-on.svg'
import { HoverDropDown } from '../components/HoverDropDown'
import SvgImage from '../components/SvgImage'
import { renderLoginModal } from '../helpers/authentication'
import { countryFlagIcons } from '../helpers/countryIconsHelper'
import { datalayerPushNavigationEvent } from '../helpers/application'

const HeaderRight = ({
  userSignedIn,
  userFullName,
  userInitials,
  userSpaceLinks,
  countryWebsiteLinks,
  headerLinks,
  onNavItemClick,
  openedMenu,
  railsContext,
  isSearchMenuItemHidden,
  isOneparkProMenuShown,
  isProMenuItemHovered,
  hoverProMenuItem
}) => (
  <>
    <div className='header__navbar pull-right header_visible_less_than_lg'>
      {isOneparkProMenuShown &&
        <a className='header__nav-item header__nav-item--link' href={headerLinks.rentMultipleSpots} onMouseEnter={() => hoverProMenuItem(true)} onMouseLeave={() => hoverProMenuItem(false)} onClick={() => datalayerPushNavigationEvent('multiple-carparks')}>
          <div><span className='header__nav-item--pro-label'>PRO</span></div>
        </a>
      }

      {!isSearchMenuItemHidden &&
        <button className='header__nav-item' type='button' onClick={() => onNavItemClick('search')}
          aria-label='Toggle navigation'>
          {openedMenu === 'search' ? <SvgImage svg={SearchOnIcon}/> : <SvgImage svg={SearchOffIcon}/>}
        </button>
      }

      <button className='header__nav-item' type='button' onClick={() => onNavItemClick('main menu')} aria-label='Toggle navigation'>
        {openedMenu === 'main menu' ? <SvgImage svg={BurgerOnIcon} /> : <SvgImage svg={BurgerOffIcon} />}
      </button>
    </div>

    <div className='header__navbar pull-right header_visible_more_than_lg'>
      {isOneparkProMenuShown &&
        <a className='header__nav-item header__nav-item--link' href={headerLinks.rentMultipleSpots} onMouseEnter={() => hoverProMenuItem(true)} onMouseLeave={() => hoverProMenuItem(false)} onClick={() => datalayerPushNavigationEvent('multiple-carparks')}>
          <FormattedMessage id='pages.header.rent_places_for_my_company' />
          <div><span className={`header__nav-item--pro-label${isProMenuItemHovered ? ' active_pro_label' : ''}`}>PRO</span></div>
        </a>
      }
      {isOneparkProMenuShown &&
        <HoverDropDown ToggleButton={NavItemToggleMenu('pages.header.partner')}>
          <div className='header__links-container header__links-container--onepark-pro'>
            <a className='header__link' href={headerLinks.becomePartner}><FormattedMessage id='pages.header.become_partner' /></a>
            <a className='header__link' href={headerLinks.accessMyPartnerArea}><FormattedMessage id='pages.header.access_my_partner_area' /></a>
          </div>
        </HoverDropDown>
      }

      <HoverDropDown ToggleButton={NavItemToggleButton('pages.header.help', HelpOffIcon, HelpOnIcon)}>
        <div className='header__links-container header__links-container--help'>
          <div className='header__links-title'><FormattedMessage id='pages.header.need_help' /></div>
          <a className='header__link' href={headerLinks.howItWorks}><FormattedMessage id='pages.header.how_it_works' /></a>
          <a className='header__link' href={headerLinks.support}><FormattedMessage id='pages.header.help_center' /></a>
          <a className='header__link' href={headerLinks.guide}><FormattedMessage id='pages.header.parking_guide' /></a>
          <a className={classNames('header__link', 'header__link--highlighted', { 'header__link--with-separator': headerLinks.blog })}
            href={headerLinks.contact}>
            <FormattedMessage id='pages.header.contact_us' />
          </a>
          {headerLinks.blog &&
            <a
              className={classNames('header__link', { ' header__link--with-separator': railsContext.currentLocale === 'fr' })}
              href={headerLinks.blog}>
              Blog
            </a>
          }
          {railsContext.currentLocale === 'fr' &&
            <a className='header__link' href={headerLinks.mobileApplication}>Notre application mobile</a>
          }
        </div>
      </HoverDropDown>

      <HoverDropDown ToggleButton={NavItemToggleButton(countryWebsiteLinks.title.label, countryFlagIcons[countryWebsiteLinks.title.country])}>
        <div className='header__links-container header__links-container--languages'>
          <div className='header__links-title'><FormattedMessage id='pages.header.language' /></div>
          {countryWebsiteLinks.links.map(link => (
            <a className='header__link' href={link.url} rel={link.rel} key={link.url}>
              <SvgImage svg={countryFlagIcons[link.country]} />
              <span>{link.label}</span>
            </a>
          ))}
        </div>
      </HoverDropDown>

      {userSignedIn ?
        <HoverDropDown ToggleButton={NavItemToggleButton('pages.header.my_account', AccountOffIcon, AccountOnIcon, userInitials)}>
          <div className='header__links-container header__links-container--login'>
            <div className='header__links-title'> {userFullName}</div>
            <a className='header__link' href={headerLinks.myProfile}><FormattedMessage id='pages.header.my_profile' /></a>
            {userSpaceLinks // case when user is supervisor or partner
              ?
              userSpaceLinks.map((link, linkIndex) => {
                const linkClasses = classNames('header__link', {
                  'header__link--with-separator': linkIndex === userSpaceLinks.length - 1
                })

                return (<a className={linkClasses} href={link.url} key={linkIndex}>{link.label}</a>)
              })

              :

              <>
                <a className='header__link' href={headerLinks.myBookings}><FormattedMessage id='pages.header.my_bookings' /></a>
                <a className='header__link' href={headerLinks.myPaymentDetails}><FormattedMessage id='pages.header.my_payment_details' /></a>
                <a className='header__link header__link--with-separator' href={headerLinks.myInvoices}><FormattedMessage id='pages.header.my_invoices' /></a>
              </>
            }

            <a className='header__link header__link--highlighted' data-method='delete' href={headerLinks.signOut}>
              <FormattedMessage id='pages.header.log_out' />
            </a>
          </div>
        </HoverDropDown>

        :

        <HoverDropDown ToggleButton={NavItemToggleButton('pages.header.sign_up_sign_in', AccountOffIcon, AccountOnIcon)}>
          <div className='header__links-container header__links-container--login'>
            <div className='header__links-title'> <FormattedMessage id='pages.header.my_account' /></div>
            <a className='header__link header__link--button btn btn-primary' onClick={(event) => onLoginClick(event, railsContext)}>
              <FormattedMessage id='pages.header.log_in' />
            </a>
            <div className='header__sign-up-link header__link--with-separator'>
              <FormattedMessage id='pages.header.sing_up_question' /> <br />
              <a href={headerLinks.signUp}><FormattedMessage id='pages.header.sing_up' /> </a>
            </div>
            <a className='header__link' onClick={(event) => onLoginClick(event, railsContext)}>
              <FormattedMessage id='pages.header.my_profile' />
            </a>
            <a className='header__link' onClick={(event) => onLoginClick(event, railsContext)}>
              <FormattedMessage id='pages.header.my_bookings' />
            </a>
            <a className='header__link' onClick={(event) => onLoginClick(event, railsContext)}>
              <FormattedMessage id='pages.header.my_payment_details' />
            </a>
            <a className='header__link' onClick={(event) => onLoginClick(event, railsContext)}>
              <FormattedMessage id='pages.header.my_invoices' />
            </a>
          </div>
        </HoverDropDown>
      }
    </div>
  </>
)

const onLoginClick = (event, railsContext) => {
  event.preventDefault()
  renderLoginModal(railsContext, 'header')
}

const NavItemToggleMenu = (title) => {
  return ({ isOpened, onMouseEnter, onMouseLeave }) => {
    const navItemClasses = classNames('header__nav-item', {
      'header__nav-item--active': isOpened
    })

    return (
      <div className={navItemClasses} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className='header__nav-item-title header__nav-item-title--dropdown'>
          {title.includes('.') // the way to understand whether it is translation code or plain text
            ? <FormattedMessage id={title} />
            : title
          }
        </div>
      </div>
    )
  }
}

const NavItemToggleButton = (title, iconOff, iconOn, userInitials) => {
  return ({ isOpened, onMouseEnter, onMouseLeave }) => {
    const navItemClasses = classNames('header__nav-item', {
      'header__nav-item--active': isOpened
    })

    return (
      <div className={navItemClasses} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className='header__nav-item-icon'>
          {userInitials
            ? <div className='header__nav-item-icon--user-initials'>{userInitials}</div>
            : isOpened ? <SvgImage svg={iconOn || iconOff} /> : <SvgImage svg={iconOff} />
          }
        </div>
        <div className='header__nav-item-title'>
          {title.includes('.') // the way to understand whether it is translation code or plain text
            ? <FormattedMessage id={title} />
            : title
          }
        </div>
      </div>
    )
  }
}

HeaderRight.propTypes = {
  userSignedIn: PropTypes.bool,
  countryWebsiteLinks: PropTypes.object.isRequired,
  headerLinks: PropTypes.object.isRequired,
  userSpaceLinks: PropTypes.array,
}

export default HeaderRight
