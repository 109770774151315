import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import moment from 'moment'

import { toHumanDuration } from 'Helpers/date'
import SvgImage from 'Components/SvgImage'

import iconCancel from 'Assets/images/svg-on-react/icon-cancel.svg'
import iconCalendar from 'Assets/images/svg-on-react/icon-calendar3.svg'
import iconSecuredPayment from 'Assets/images/svg-on-react/icon-secure-payment.svg'
import iconCoins from 'Assets/images/svg-on-react/icon-coins.svg'
import iconCancelSubscription from 'Assets/images/svg-on-react/icon-cancel-subscription.svg'

export const SummaryReassurancePanel = ({ maxCancellationDelay, maxModificationDelay, renewalDate, isSubscription, intl }) => {
  const locale = intl.locale
  const timeFormat = 'D MMMM YYYY'

  return (
    <div className='summary-reassurance-panel panel-default'>
      <div className='panel-body'>
        {isSubscription &&
          <>
            <p>
              <SvgImage svg={iconCalendar} />
              <FormattedMessage
                id='purchase.purchase_summary.subscription.renewal'
                values={{ date: moment(renewalDate).locale(locale).format(timeFormat) }} />
            </p>

            <p>
              <SvgImage svg={iconCancelSubscription} />
              {maxModificationDelay === 0 &&
                <FormattedMessage
                  id='purchase.purchase_summary.subscription.purchase_cancel_zero'
                />
              }
              {maxModificationDelay > 0 &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.subscription.purchase_cancel'
                  values={{ duration: toHumanDuration(intl, maxModificationDelay) }}
                />
              }
            </p>
          </>
        }

        {!isSubscription &&
          <>
            <p>
              <SvgImage svg={iconCoins} />
              {maxCancellationDelay > 0 &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.package.purchase_cancel'
                  values={{ duration: toHumanDuration(intl, maxCancellationDelay) }}
                />
              }
              {maxCancellationDelay === 0 &&
                <FormattedMessage
                  id='purchase.purchase_summary.package.purchase_cancel_zero'
                />
              }
            </p>
            <p>
              <SvgImage svg={iconCancel} />
              {maxModificationDelay > 0 &&
                <FormattedHTMLMessage
                  id='purchase.purchase_summary.package.purchase_update'
                  values={{ duration: toHumanDuration(intl, maxModificationDelay) }}
                />
              }
              {maxModificationDelay === 0 &&
                <FormattedMessage
                  id='purchase.purchase_summary.package.purchase_update_zero'
                />
              }
            </p>
          </>
        }

        <p>
          <SvgImage svg={iconSecuredPayment} />
          <FormattedMessage id='pages.park.reassurance.secured_payment' />
        </p>
      </div>
    </div>
  )
}
