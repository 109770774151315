import React from 'react'

import AddressItem from './AddressItem'
import DistanceItem from '../common/DistanceItem'
import Photo from './Photo'
import RatingLink from './RatingLink'
import ShareLinks from './ShareLinks'
import { getRatingIndicators } from '../../helpers/park'

export const OverviewSection = ({ park }) => (
  <div id='park-overview'>
    <div className='park-overview__name'>
      <h1>{park.isSeo ? `${park.name} - ${park.address.city}` : park.name}</h1>
      <ShareLinks parkName={park.name} parkAddress={park.address.fullAddress} />
    </div>

    <div className='park-overview__block'>
      <div className='park-overview__block-info'>
        <div className='park-overview__block-info--left'>
          <AddressItem park={park} />

          <div className='park-overview-block-info__rating-distance-info'>
            <RatingLink {...getRatingIndicators(park)} />

            <ul className='small-icon-list small-icon-list--overview'>
              {park.distance && <DistanceItem {...park.distance} />}
            </ul>
          </div>
        </div>
      </div>

      <div className='park-overview__block-photos'>
        <Photo park={park} />
      </div>
    </div>
  </div>
)
