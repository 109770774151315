import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import ModalOp from './ModalOp'

export class OnRegistration3dSecureErrorDialog extends Component {
  onCloseDialog = e => {
    // TODO: Remove jQuery from React
    e.preventDefault()
    $(`#${this.modalId}`).modal('hide')
  }

  modalId = 'error-on-register-3d-secure'

  render () {
    const translationsPath = 'authentication.registration.payment_info.3ds.popup'

    return (
      <ModalOp id={this.modalId} name='what-is-3d-secure' hideCloseIcon>
        <h3> <FormattedMessage id={`${translationsPath}.error_not_registered`} /></h3>
        <div className='divider m-top-20 m-bottom-20' />

        <div className={'modal__header'}> <FormattedMessage id={`${translationsPath}.what_is_title`} /></div>
        <div className={'modal__content'}><FormattedMessage id={`${translationsPath}.what_is_body`} /></div>

        <div className={'modal__header'}> <FormattedMessage id={`${translationsPath}.how_to_authenticate_title`} /></div>
        <div className={'modal__content'}><FormattedMessage id={`${translationsPath}.how_to_authenticate_body`} /></div>

        <div className={'modal__header'}> <FormattedMessage id={`${translationsPath}.how_to_retry_title`} /></div>
        <div className={'modal__content'}><FormattedMessage id={`${translationsPath}.how_to_retry_body`} /></div>

        <div className='divider m-top-20 m-bottom-20' />
        <button className='modal__button o-btn o-btn--primary' onClick={this.onCloseDialog}>
          <FormattedMessage id={`${translationsPath}.confirm_button`} />
        </button>
      </ModalOp>
    )
  }
}
