import { combineReducers, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import moment from 'moment'
import thunk from 'redux-thunk'

import { getCeilQuarterOf } from '../bundles/Onepark/helpers/search'
import reducers from '../reducers/index'

export default (props, railsContext) => {
  // Setup moment locale. This is kind of a hack to do it here, but it was just convenient
  moment.locale(railsContext.i18nLocale)

  const combinedReducer = combineReducers(reducers)
  const initialState = {
    ...props,
    purchase: rehydratePurchase(props),
    search: rehydrateSearch(props),
    railsContext
  }
  return composeWithDevTools(applyMiddleware(thunk))(createStore)(combinedReducer, initialState)
}

function rehydrateSearch (props) {
  const { beginDate, endDate } = props.search.params
  const searchParams = {
    ...props.search.params,
    beginDate: beginDate ? getCeilQuarterOf(moment(beginDate)) : null,
    endDate: endDate ? getCeilQuarterOf(moment(endDate)) : null
  }
  return { ...props.search, params: searchParams, nextParams: searchParams }
}

function rehydratePurchase (props) {
  if (!props.purchase) {
    return {}
  }

  const { beginDate, endDate } = props.purchase.offer
  return { ...props.purchase, offer: { ...props.purchase.offer, beginDate: moment(beginDate), endDate: moment(endDate) } }
}
