import React, { Component } from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import ModalOp from './ModalOp'

export class PerformanceReportSentDialog extends Component {
  onCloseDialog = e => {
    e.preventDefault()
    document.getElementById('performance-report-sent-modal').modal('hide')
  }

  render () {
    return (
      <ModalOp id='performance-report-sent-modal' name='performance-report-sent'>
        <div className='performance-report-sent-dialog__title'>
          <FormattedHTMLMessage id='pages.performances.dialog.title_html' />
        </div>
        <br />
        <div className='performance-report-sent-dialog__text'>
          <FormattedHTMLMessage id='pages.performances.dialog.text_html' values={{ user_email: this.props.userEmail }} />
        </div>
      </ModalOp>
    )
  }
}
