import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Map from './Map'
import * as SearchActions from '../../../../actions/SearchActions'
import { ParkCard } from '../ParkCard'

const CLUSTER_ZOOM = 10
const MIN_PARKS_ZOOM = 11

class MapView extends Component {
  state = {
    activeInfoWindow: null,
    activeMarker: null,
    showClusters: false
  }

  static getDerivedStateFromProps(props, state) {
    const { activeInfoWindowId, autoOpenParkCard, parks } = props
    const { activeInfoWindow, activeMarker } = state
    const firstParkId = (parks[0] || {}).id
    return {
      activeInfoWindow: activeInfoWindowId === undefined ? (activeInfoWindow || (autoOpenParkCard && firstParkId) || null) : activeInfoWindowId,
      activeMarker: activeMarker || (autoOpenParkCard && firstParkId) || null
    }
  }

  componentDidUpdate(prevProps) {
    const { lat, lng } = this.props.defaultCenter
    if (lat && lng && (lat !== prevProps.defaultCenter.lat || lng !== prevProps.defaultCenter.lng)) {
      this.map.panTo(new google.maps.LatLng(parseFloat(lat), parseFloat(lng)))
    }
  }

  handleIdle = () => this.props.autoSearch ? this.search() : null

  handleMapClick = () => {
    this.setState({ activeMarker: null })
    if (this.props.activeInfoWindowId === undefined) {
      this.setState({ activeInfoWindow: null })
    }
    const { onUnselectPark } = this.props
    if (onUnselectPark) {
      onUnselectPark(null)
    }
  }

  handleMapMounted = (ref) => {
    this.map = ref
    if (this.props.onMapMounted) {
      this.props.onMapMounted(ref)
    }
  }

  handleMarkerClick = (e, id) => {
    // Since we have to capture both onClick and onMouseDown, this handler is sometimes triggered twice
    // But we don't want the component to be rendered twice...
    if (this.state.activeInfoWindow !== id) {
      this.setState({ activeInfoWindow: id, activeMarker: id })
      if (this.props.activeInfoWindowId === undefined) {
        this.setState({ activeInfoWindow: id })
      }
      const { onSelectPark } = this.props
      if (onSelectPark) {
        onSelectPark(id, true)
      }
    }
  }

  handleMarkerMouseOut = (id) => {
    if (this.state.activeMarker === id) {
      this.setState({ activeMarker: null })
      if (this.props.onMarkerMouseOut) {
        this.props.onMarkerMouseOut(id)
      }
    }
  }

  handleZoomChanged = () => {
    // To avoid clusters to flicker on map, we call setState only when state realy has to change
    if (this.map.getZoom() >= MIN_PARKS_ZOOM && this.state.showClusters) {
      this.setState({ showClusters: false })
    } else if (this.map.getZoom() < MIN_PARKS_ZOOM && !this.state.showClusters) {
      this.setState({ showClusters: true })
    }
  }

  search = () => {
    const { loadBestOffers, searchClustersInRect, searchParksInRect } = this.props.actions
    const bounds = this.map.getBounds()
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    const rect = [sw.lat(), ne.lat(), sw.lng(), ne.lng()]
    const zoom = this.map.getZoom()

    if (zoom >= MIN_PARKS_ZOOM) {
      searchParksInRect(rect).then(loadBestOffers)
    } else {
      searchClustersInRect(rect, CLUSTER_ZOOM)
    }
  }

  renderMap = () => {
    const { defaultZoom } = this.props
    const { activeInfoWindow, activeMarker, showClusters } = this.state

    return (
      <Map
        {...this.props}
        containerElement={<div className='map-container-div' style={{ width: '100%', height: '100%' }} />}
        loadingElement={<div style={{ height: '100%' }} />}
        mapElement={<div className='map' style={{ height: '100%' }} />}
        activeInfoWindow={activeInfoWindow}
        activeMarker={activeMarker}
        currentZoom={this.map ? this.map.getZoom() : defaultZoom}
        onIdle={this.handleIdle}
        onMarkerClick={this.handleMarkerClick}
        onMapClick={this.handleMapClick}
        onMapMounted={this.handleMapMounted}
        onMarkerMouseOut={this.handleMarkerMouseOut}
        onZoomChanged={this.handleZoomChanged}
        showClusters={showClusters} />
    )
  }

  render() {
    const { isSubscription, parks, isUserOnMobile } = this.props
    const { activeInfoWindow } = this.state
    const activePark = parks.find(p => p.id === activeInfoWindow)
    return (
      <div>
        {this.renderMap()}

        {activePark && (
          <div className='map__park-card-container'>
            <ParkCard park={activePark} isSubscription={isSubscription} isUserOnMobile={isUserOnMobile} />
          </div>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...SearchActions }, dispatch) })

export default connect(null, mapDispatchToProps)(MapView)
