import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import URLSearchParams from 'url-search-params'
import { datalayerPush, datalayerPushGTM } from '../helpers/application'

class HistoryComponent extends React.Component {
  static defaultProps = {
    closeModal: false
  }

  componentWillMount () {
    const { skipSavingLocation } = this.props

    // We save the original document location and title to restore them when page returns to initial state
    if (!skipSavingLocation) {
      this.saveOriginalTitleLocation()
    }

    $('.modal-op').off('hidden.bs.modal')
  }

  componentDidMount () {
    const { closeModal } = this.props
    $('.modal-op').on('hidden.bs.modal', function () {
      if (window.originalTitleLocation && (!window.previousModal || closeModal)) {
        document.title = window.originalTitleLocation.title
        history.replaceState({}, window.originalTitleLocation.title, window.originalTitleLocation.location)
        datalayerPush({ event: 'page_name', page_name: window._oneparkDatas.page_name })
        datalayerPush({ event: 'closeVirtualPageView' })
      } else {
        window.previousModal = null
      }
    })
  }

  saveOriginalTitleLocation () {
    if (window.originalTitleLocation) {
      return
    }
    window.originalTitleLocation = { title: document.title, location: document.location.pathname + document.location.search }
  }

  addParamsToSearch () {
    const { origin, modal, sentPassword } = this.props

    const params = new URLSearchParams(window.location.search.slice(1))
    if (origin) {
      params.set('origin', origin)
    } else {
      params.delete('origin')
    }
    if (modal && params.get('modal') !== modal) {
      window.previousModal = params.get('modal')
      params.set('modal', modal)
    }
    if (sentPassword) {
      params.set('sent-password', sentPassword)
    }

    return params.toString()
  }

  injectUrl () {
    const { formatMessage } = this.props.intl
    const { modal } = this.props
    const paramsUrl = this.addParamsToSearch()
    const url = paramsUrl ? `?${paramsUrl}` : null
    const messages = defineMessages({
      title_tag: {
        id: this.props.id
      }
    })

    const newTitle = formatMessage(messages.title_tag)
    if (newTitle !== document.title) {
      document.title = newTitle
    }
    if (url !== location.search) {
      history.replaceState({}, formatMessage(messages.title_tag), url)
      if (modal) {
        datalayerPush({ event: 'page_name', page_name: `modal_${modal}` })
        datalayerPushGTM('hitVirtualPageView')
      }
    }
  }

  render () {
    this.injectUrl()

    return null
  }
}

export default injectIntl(HistoryComponent)
