import React from 'react'
import { FormattedNumber, FormattedMessage } from 'react-intl'

const ParkPrice = ({ isPromotedParks, onSitePrice, bestOffer: { amount, amountWithSecondaryCurrency }, prefix, type }) => (
  <div className={`${prefix}__price`}>
    {onSitePrice && amount.ref &&
      <div className={`${prefix}__on-site-price`}>
        <FormattedMessage id='pages.park.on_site_price' />
      </div>
    }
    {amount.ref && (
      <div className={`${prefix}__price__ref`}>
        <FormattedNumber value={amount.ref} style='currency' currency='EUR' />
      </div>
    )}
    <div className={`${prefix}__price__total ${prefix}__price__total${(isPromotedParks && onSitePrice) ? '--on-site' : (!onSitePrice && amount.ref) ? '--discount' : ''}`}>
      <FormattedNumber value={amount.totalWithoutOptions} style='currency' currency='EUR' />
      {type === 'subscription' &&
        <FormattedMessage id='pages.park.frequency' />
      }
    </div>
    {amountWithSecondaryCurrency && (
      <div className={`${prefix}__price__secondary`}>
        <FormattedNumber value={amountWithSecondaryCurrency.total} style='currency' currency={amountWithSecondaryCurrency.unit} />
        {type === 'subscription' &&
          <FormattedMessage id='pages.park.frequency' />
        }
      </div>
    )}
  </div>
)

export default ParkPrice
