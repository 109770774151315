import React from 'react'

export const CustomFormattedCurrency = ({ value, currency }) => {
  const formattedNumber = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)

  const formattedWithCurrencyAtStart = `${currency}:${'\u00A0'}${formattedNumber.replace(currency, '').trim()}`

  return <span className='amount__other-currency-price--at-summary'>({formattedWithCurrencyAtStart})</span>
}
