import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import VehicleItem from './VehicleItem'

const i18nBase = 'pages.park.description.allowed_vehicles'

const AllowedVehicles = ({ park }) => {
  // TODO: Refactor this line when all parks migrate moto vehicle type from services to vehicle_types
  const getVehicleTypes = park => [...(park.services.includes('moto') ? new Set(park.vehicleTypes).add('moto') : park.vehicleTypes)]
  const vehicleTypes = getVehicleTypes(park)

  return (
    <div>
      <h3>
        <FormattedMessage id={`${i18nBase}.title`} />
      </h3>

      <p className='m-bottom-10 text--grey-darker'>
        {park.maxHeight ? (
          <>
            <FormattedMessage id={`${i18nBase}.max_height`} />
            <span className='park__max-height'>
              <FormattedNumber maximumFractionDigits={2} value={park.maxHeight / 100} /> m
            </span>
          </>
        ) : (
          <FormattedMessage id={`${i18nBase}.no_max_height`} />
        )}
      </p>

      <ul className='row park__description__thumbnails-list'>
        {['moto', 'city_car', 'sedan_car', 'family_car', 'utility_car'].map(type => (
          <VehicleItem key={type} vehicleType={type} available={vehicleTypes.includes(type)} />
        ))}
      </ul>
    </div>
  )
}

export default AllowedVehicles
