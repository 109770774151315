import React from 'react'
import { FormattedMessage } from 'react-intl'

import SvgImage from '../../components/SvgImage'
import { splitHalf } from '../../helpers/collections'
import { getServices } from '../../helpers/park'
import iconBike from '../../../../../../app/assets/images/svg-on-react/icon-bike.svg'
import iconCarwash from '../../../../../../app/assets/images/svg-on-react/icon-carwash.svg'
import iconOnepark from '../../../../../../app/assets/images/svg-on-react/icon-onepark.svg'
import iconPlug from '../../../../../../app/assets/images/svg-on-react/icon-plug.svg'
import iconNumpad from '../../../../../../app/assets/images/svg-on-react/icon-numpad.svg'
import iconWc from '../../../../../../app/assets/images/svg-on-react/icon-wc.svg'
import iconWheelchair from '../../../../../../app/assets/images/svg-on-react/icon-wheelchair.svg'

const i18nBase = 'pages.park.description.services'

const Services = ({ park }) => {
  const services = getServices(park)

  if (services.length === 0) {
    return <div />
  }

  return (
    <div>
      <h3>
        <FormattedMessage id={`${i18nBase}.title`} />
      </h3>

      <div className='row park__description__list'>
        {splitHalf(services).map((services, i) => services.length > 0 && (
          <div key={i} className='col-md-6'>
            <ul>
              {services.map(getItem)}
            </ul>
          </div>
        ))}
      </div>

      {services.filter(isChargedByPartner).length > 0 && (
        <p className='text--grey-darker m-top-20'>
          <sup>*</sup><FormattedMessage id={`${i18nBase}.charged_by_partner`} />
        </p>
      )}
    </div>
  )
}

const getItem = service => (
  <li key={service}>
    <SvgImage svg={iconsByService[service]} />
    <FormattedMessage id={`${i18nBase}.${service}`} />
    {isChargedByPartner(service) && <sup>*</sup>}
  </li>
)

const isChargedByPartner = service => ['car_wash', 'oil'].includes(service)

const iconsByService = {
  handicappedaccess: iconWheelchair,
  car_wash: iconCarwash,
  access_control: iconNumpad,
  toilets: iconWc,
  recharge: iconPlug,
  oil: iconOnepark,
  bike: iconBike
}

export default Services
