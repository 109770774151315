import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import SideAccount from './SideAccount'

import CloseIcon from '../../../../../assets/images/icon-close.svg'
import SvgImage from '../../components/SvgImage'
import LogoSvg from '../../../../../assets/images/onepark-header-logo.svg'
import PopularDestinationsSvg from '../../../../../assets/images/icon-popular-destinations.svg'
import PartnerSvg from '../../../../../assets/images/icon-partner.svg'
import HelpSvg from '../../../../../assets/images/icon-help.svg'
import AppPreviewImage from '../../../../../assets/images/app-preview.webp'
import AppStoreImage from 'Assets/images/emails/mobile_app_promotion/app-store.jpg'
import GooglePlayImage from 'Assets/images/emails/mobile_app_promotion/google-play.jpg'
import FlowImage from '../../../../../assets/images/icon-flow.webp'
import { DropDown } from '../../components/DropDown'
import { countryFlagIcons } from '../../helpers/countryIconsHelper'

class SideMainMenu extends PureComponent {
  constructor (props) {
    super(props)

    if (props.currentUserName && props.userSpaceLinks) {
      this.userTempArray = props.userSpaceLinks.slice(0, -1)
      this.disconnectLink = props.userSpaceLinks.slice(-1).pop()
    } else {
      this.userTempArray = []
      this.disconnectLink = null
    }
  }

  toggleButton (titleId, svgImage) {
    return ({ isOpened, onClick }) => {
      return (
        <div className={`side-menu__toggle-menu ${isOpened ? 'side-menu__toggle-menu--active' : ''}`} onClick={onClick}>
          <SvgImage svg={svgImage} />
          <span className='side-main-menu-item__title'><FormattedMessage id={titleId} /></span>
        </div>
      )
    }
  }

  appStoreLink (locale) {
    switch (locale) {
      case 'fr':
        return 'https://apps.apple.com/fr/app/onepark-r%C3%A9servez-un-parking/id1528537709'
      case 'es':
        return 'https://apps.apple.com/es/app/onepark-reserva-tu-parking/id1528537709'
      default:
        return 'https://apps.apple.com/us/app/onepark-book-a-parking-space/id1528537709'
    }
  }

  googlePlayLink (locale) {
    switch (locale) {
      case 'fr':
        return 'https://play.google.com/store/apps/details?id=onepark.app&hl=fr&gl=US'
      case 'es':
        return 'https://play.google.com/store/apps/details?id=onepark.app&hl=es&gl=US'
      default:
        return 'https://play.google.com/store/apps/details?id=onepark.app&gl=FR'
    }
  }

  paymentFlowLink (locale) {
    return `https://oneparkflow.com/checkout/#/checkout?lang=${locale === 'fr' || locale === 'es' ? locale : 'en'}`
  }

  render () {
    const {
      userSignedIn,
      userInitials,
      userFullName,
      userSpaceLinks,
      countryWebsiteLinks,
      headerLinks,
      onSideMenuClose,
      onNavItemClick,
      intl
    } = this.props

    return (
      <div className='side-main-menu'>
        <div className='side-main-menu__title'>
          <SvgImage svg={LogoSvg} />
          <SvgImage svg={CloseIcon} onClick={() => onSideMenuClose(null)} />
        </div>

        <div className='side-main-menu__item side-main-menu__item-user'>
          <SideAccount
            onSideMenuClose={onSideMenuClose}
            userSignedIn={userSignedIn}
            userInitials={userInitials}
            userFullName={userFullName}
            userSpaceLinks={userSpaceLinks}
            headerLinks={headerLinks} />
        </div>

        <div className='side-main-menu__item side-main-menu__item-popular-destinations' onClick={() => onNavItemClick('mega menu')}>
          <SvgImage svg={PopularDestinationsSvg} />
          <span className='side-main-menu-item__title'><FormattedMessage id='pages.header.popular_destinations' /></span>
        </div>

        <div className='side-main-menu__item side-main-menu__item-partner'>
          <DropDown id='partner-dropdown' key='partner-dropdown' ToggleButton={this.toggleButton('pages.header.partner', PartnerSvg)}>
            <a className='side-main-menu__link' href={headerLinks.becomePartner}><FormattedMessage id='pages.header.become_partner' /></a>
            <a className='side-main-menu__link' href={headerLinks.accessMyPartnerArea}><FormattedMessage id='pages.header.access_my_partner_area' /></a>
          </DropDown>
        </div>

        <div className='side-main-menu__item side-main-menu__item-help'>
          <DropDown id='help-dropdown' key='help-dropdown' ToggleButton={this.toggleButton('pages.header.help', HelpSvg)}>
            <a className='side-main-menu__link' href={headerLinks.howItWorks}><FormattedMessage id='pages.header.how_it_works' /></a>
            <a className='side-main-menu__link' href={headerLinks.support}><FormattedMessage id='pages.header.help_center' /></a>
            <a className='side-main-menu__link' href={headerLinks.guide}><FormattedMessage id='pages.header.parking_guide' /></a>
            <a className='side-main-menu__link' href={headerLinks.contact}><FormattedMessage id='pages.header.contact_us' /></a>
            {headerLinks.blog &&
              <a className='side-main-menu__link' href={headerLinks.blog}>Blog</a>
            }
          </DropDown>
        </div>

        <div className='side-main-menu__item side-main-menu__item-language'>
          <DropDown id='language-dropdown' key='language-dropdown' ToggleButton={this.toggleButton(countryWebsiteLinks.title.label, countryFlagIcons[countryWebsiteLinks.title.country])}>
            {countryWebsiteLinks.links.map(link => (
              <a className='side-main-menu__link' href={link.url} rel={link.rel} key={link.url}>
                <SvgImage svg={countryFlagIcons[link.country]} />
                <span className='side-main_menu__language-label'>{link.label}</span>
              </a>
            ))}
          </DropDown>
        </div>

        <div className='side-main-menu__item side-main-menu__item-promo'>
          <a className='side-main-menu__item-pro-link' href={headerLinks.rentMultipleSpots}>
            <span className='side-main-menu__item-pro-link--pro-label-container'>
              <span className='side-main-menu__item-pro-link--pro-label'>PRO</span>
            </span>
            <span className='side-main-menu__item-pro-link--text'>
              <FormattedMessage id='pages.header.rent_places_for_my_company' />
            </span>
          </a>

          <div className='side-main-menu__app_preview'>
            <div className='side-main-menu__app_preview_image'>
              <img src={AppPreviewImage} alt={intl.formatMessage({ id: 'pages.header.app_preview_title' })} />
            </div>
            <div className='side-main-menu__app_preview_links'>
              <div className='side-main-menu__app_preview_title'>
                <FormattedMessage id='pages.header.download_onepark_app' />
              </div>
              <div className='side-main-menu__store-links'>
                <a className='side-main-menu__googleplay-link' href={this.googlePlayLink(intl.locale)}>
                  <img src={GooglePlayImage} alt={intl.formatMessage({ id: 'pages.header.google_play_image_alt' })} />
                </a>
                <a className='side-main-menu__appstore-link' href={this.appStoreLink(intl.locale)}>
                  <img src={AppStoreImage} alt={intl.formatMessage({ id: 'pages.header.app_store_image_alt' })} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='side-main-menu__item-payment-flow'>
          <a href={this.paymentFlowLink(intl.locale)}>
            <FormattedMessage id='pages.header.payment_flow' />
          </a>
          <a href={this.paymentFlowLink(intl.locale)}>
            <img src={FlowImage} alt={intl.formatMessage({ id: 'pages.header.flow_image_alt' })} />
          </a>
        </div>
      </div>
    )
  }
}

export default injectIntl(SideMainMenu)
