import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { isMobile } from '../helpers/browser'

class PlaceChildrenPlacesItem extends Component {
  state = {
    mobileDevice: false
  }

  componentDidMount () {
    this.setState({ mobileDevice: isMobile() })
  }

  render () {
    const { place, localePrefix, index } = this.props
    const { mobileDevice } = this.state
    const placeUrl = localePrefix && place.url.indexOf(localePrefix) === -1 ? `${localePrefix}${place.url}` : place.url
    return (
      <div className='place-children-places-item'>
        <a href={placeUrl} title={place.seoTitle}>
          {index > 8 ? (
            <LazyLoad once placeholder={<div className='place-children-places-item__placeholder' />}>
              <img title={place.seoTitle} alt={place.seoTitle} src={mobileDevice ? place.imageMobile : place.imageDesktop} width='280' height='190' />
            </LazyLoad>
          ) : (
            <img title={place.seoTitle} alt={place.seoTitle} src={mobileDevice ? place.imageMobile : place.imageDesktop} width='280' height='190' />
          )}
          <p>{place.name}</p>
        </a>
        {place.topPlaces &&
          <div className='place-children-places-item__top-places'>
            <ul>
              {place.topPlaces.map((topPlace, i) => (
                <li key={i}>
                  <a href={topPlace.url}>{topPlace.title}</a>
                </li>
              ))}
            </ul>
          </div>
        }
      </div>
    )
  }
}

export default PlaceChildrenPlacesItem
