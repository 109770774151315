import moment from 'moment'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Pagination from 'rc-pagination'

import SvgImage from '../../components/SvgImage'
import Reviews from './Reviews'
import Rating from '../common/Rating'
import { smoothScrollTo } from '../NavTabs'
import { jsonFetch } from '../../helpers/api'
import { getRatingIndicators } from '../../helpers/park'
import Loader from '../../components/Loader'
import logoGoogle from '../../../../../../app/assets/images/svg-on-react/logo-google.svg?fill=#616161'

const i18nBase = 'pages.park.reviews'
const pollIntervalMs = 5 * 1000
const pollMaxDurationSecs = 5 * 60

class ReviewsSection extends Component {
  state = {
    reviewsEnabled: this.props.park.reviewsEnabled,
    comments: this.props.park.reviewsEnabled
                ? this.props.park.reviews
                : this.props.park.comments, // TBD: remove old comments later
    loading: false, // true when changing page
    page: 1,
    showTranslated: false,
    translating: false // true when polling translations
  }

  handlePageChange = async page => {
    this.setState({ loading: true, page })

    smoothScrollTo('park-reviews')
    const comments = this.state.reviewsEnabled
                       ? await this.getReviews(page)
                       : await this.getComments(page) // TBD: remove old comments later

    this.setState({ loading: false, comments })

    if (this.state.showTranslated && this.missingTranslations()) {
      this.setState({ translating: true })
      this.pollTranslations()
    }
  }

  render () {
    const { isUserOnMobile, newUserReview, park, railsContext } = this.props
    const { comments, loading, page, showTranslated } = this.state
    const { rating } = getRatingIndicators(park)

    return (
      <section id='park-reviews' className='park-reviews'>
        <div className='park-reviews__title'>
          <h2>
            <FormattedMessage id={`${i18nBase}.title`} />
          </h2>

          {park.recommendationsCount > 0 && (
            <small className='text-muted'>
              <FormattedMessage id={`${i18nBase}.description`} />
              <a href={`${railsContext.localePrefix}/reviews_policy`} target='_blank'>
                <FormattedMessage id={`${i18nBase}.policy_link`} />
              </a>
            </small>
          )}
        </div>

        <div className='park-reviews__overview'>
          {park.recommendationsCount > 0 && (
            <div>
              <h3>
                <FormattedMessage id={`${i18nBase}.client_recommendations`} values={{ n: park.recommendationsCount }} />
              </h3>
              <Rating className='park-reviews__rating-stars' rating={rating} />
            </div>
          )}
          {park.ratingCount === 0 ? (
            <h3>
              <FormattedMessage id={`${i18nBase}.no_review`} />
            </h3>
          ) : (
            <div>
              {this.renderTranslateButton()}
              <Reviews comments={comments} showTranslated={showTranslated} loading={loading} />
            </div>
          )}
        </div>
        {park.ratingCount > 5 && (
          <div className='park-reviews__pagination'>
            <Pagination
              current={page}
              pageSize={5}
              total={park.ratingCount}
              showLessItems
              showTitle={false}
              onChange={this.handlePageChange}
            />
          </div>
        )}
      </section>
    )
  }

  renderTranslateButton () {
    const { loading, translating, showTranslated } = this.state
    return (
      <button className='btn btn-flat' disabled={loading || translating} onClick={this.handleClickTranslate} >
        {showTranslated ? (
          <FormattedMessage id={`${i18nBase}.show_original_reviews`} />
        ) : (
          <span>
            <FormattedMessage id={`${i18nBase}.translate_with`} />
            <SvgImage svg={logoGoogle} className='logo-google' />
          </span>
        )}
        {(loading || translating) && <span> <Loader grey /></span>}
      </button>
    )
  }

  handleClickTranslate = () => {
    const { showTranslated } = this.state

    if (showTranslated) {
      this.setState({ showTranslated: false })
    } else if (this.missingTranslations()) {
      this.postTranslateRequest()
    } else {
      this.setState({ showTranslated: true })
    }
  }

  async postTranslateRequest () {
    const { park, railsContext } = this.props

    const url = this.state.reviewsEnabled
                  ? `${railsContext.localePrefix}/parkings/${park.id}/reviews/translate`
                  : `${railsContext.localePrefix}/parkings/${park.id}/comments/translate` // TBD: remove old comments later

    this.setState({ translating: true })
    const res = await jsonFetch(url, { method: 'POST', body: { lang: railsContext.i18nLocale } })

    if (res.status === 200) {
      this.pollTranslations()
    }
  }

  pollTranslations () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    const endTime = moment().add(pollMaxDurationSecs, 'seconds')
    const poll = async () => {
      const { page } = this.state

      const comments = this.state.reviewsEnabled
                         ? await this.getReviews(page)
                         : await this.getComments(page) // TBD: remove old comments later

      if (page === this.state.page) { // in case page has changed while waiting for response
        this.setState({ comments }, () => {
          if (this.missingTranslations() && moment().isBefore(endTime)) {
            this.timeoutId = setTimeout(poll, pollIntervalMs)
          } else {
            this.setState({ translating: false, showTranslated: true })
          }
        })
      }
    }

    poll()
  }

  // TBD: remove later
  async getComments (page) {
    const { park, railsContext } = this.props
    const res = await jsonFetch(`${railsContext.localePrefix}/parkings/${park.id}/comments?page=${page}`)
    return res.json()
  }

  // Should be used if reviewsEnabled is true
  async getReviews (page) {
    const { park, railsContext } = this.props
    const res = await jsonFetch(`${railsContext.localePrefix}/parkings/${park.id}/reviews?page=${page}`)
    return res.json()
  }

  missingTranslations = () => this.state.comments.some(c => c.message && !c.translatedMessage)
}

export default ReviewsSection
