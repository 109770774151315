import React, { Component } from 'react'

import ModalOp from '../components/ModalOp'
import { datalayerPush, datalayerPushGTM, appendParamToLocation, locationHasParam } from '../helpers/application'

class HistoryModalOp extends Component {
  componentDidMount () {
    const { id, name } = this.props
    const $modal = $(`#${id}`)

    window.onpopstate = (event) => $modal.modal(locationHasParam('modal', name) ? 'show' : 'hide')

    $modal.on('show.bs.modal', () => {
      if (!locationHasParam('modal', name)) {
        history.pushState({}, '', appendParamToLocation('modal', name))
      }

      datalayerPush({ event: 'page_name', page_name: name })
      datalayerPushGTM('hitVirtualPageView')
    })

    $modal.on('hidden.bs.modal', () => {
      if (locationHasParam('modal', name)) {
        history.back()
      }

      datalayerPush({ event: 'page_name', page_name: window._oneparkDatas.page_name })
      datalayerPushGTM('closeVirtualPageView')
    })
  }

  render () {
    return (<ModalOp {...this.props} />)
  }
}

export default HistoryModalOp
