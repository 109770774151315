const initialState = {
  address: null,
  city: null,
  company: null,
  country: null,
  email: null,
  name: null,
  mobilePhone: null,
  zipCode: null,
  billingIdentity: null
}

export default function userReducer (state = initialState, action) {
  return state
}
