import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

class PurchaseConfirmationError extends Component {
  render () {
    const { localePrefix, history, offerBuilder } = this.props
    return (
      <div className='row'>
        <h1>
          <FormattedMessage id='purchase.purchase_error.title' />
        </h1>
        <p>
          <FormattedMessage id='purchase.purchase_error.error_message' />
          { history.location.state && history.location.state.error }
          <br />
          <FormattedMessage id='purchase.purchase_error.try_again_or_sav' />
        </p>

        <a href={`${localePrefix}/parkings/${offerBuilder.park.id}`} className='btn btn-primary'>
          <FormattedMessage id='purchase.purchase_error.try_again' />
        </a>

        <a href={`${localePrefix}/contact`} className='btn btn-tertiary'>
          <FormattedMessage id='purchase.purchase_error.contact_sav' />
        </a>
      </div>
    )
  }
}

const mapStateToProps = ({
  purchase: {
    offer: { offerBuilder }
  },
  resources: { localePrefix }
}) => ({
  localePrefix,
  offerBuilder
})

export default connect(mapStateToProps)(PurchaseConfirmationError)
