import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import ModalOp from 'Components/ModalOp'
import franceNewFormat from 'ClientAssets/images/plates/franceNewFormat.png'
import franceOldFormat from 'ClientAssets/images/plates/franceOldFormat.png'
import belgiumNewFormat from 'ClientAssets/images/plates/belgiumNewFormat.png'
import belgiumOldFormat from 'ClientAssets/images/plates/belgiumOldFormat.png'
import spainNewFormat from 'ClientAssets/images/plates/spainNewFormat.png'
import spainOldFormat from 'ClientAssets/images/plates/spainOldFormat.png'
import germanyNewFormat from 'ClientAssets/images/plates/germanyNewFormat.png'
import switzerlandNewFormat from 'ClientAssets/images/plates/switzerlandNewFormat.png'
import italyNewFormat from 'ClientAssets/images/plates/italyNewFormat.png'
import italyOldFormat from 'ClientAssets/images/plates/italyOldFormat.png'
import netherlandsNewFormat from 'ClientAssets/images/plates/netherlandsNewFormat.png'
import netherlandsOldFormat from 'ClientAssets/images/plates/netherlandsOldFormat.png'
import portugalNewFormat from 'ClientAssets/images/plates/portugalNewFormat.png'
import portugalOldFormat from 'ClientAssets/images/plates/portugalOldFormat.png'
import {
  COUNTRIES_WITH_CUSTOM_TOOLTIP,
  FRANCE_COUNTRY_ID,
  BELGIUM_COUNTRY_ID,
  GERMANY_COUNTRY_ID,
  ITALY_COUNTRY_ID,
  LICENSE_PLATE_NEW_FORMATS,
  NETHERLANDS_COUNTRY_ID,
  PORTUGAL_COUNTRY_ID,
  SPAIN_COUNTRY_ID,
  SWITZERLAND_COUNTRY_ID
} from 'Components/VehicleInformation/constants'

class LicensePlateHelpModalWithoutIntl extends Component {
  render () {
    const { country, intl, padded } = this.props
    const newFormat = LICENSE_PLATE_NEW_FORMATS[country.id]
    const newLicensePlateFormats = {
      [FRANCE_COUNTRY_ID]: franceNewFormat,
      [BELGIUM_COUNTRY_ID]: belgiumNewFormat,
      [SPAIN_COUNTRY_ID]: spainNewFormat,
      [NETHERLANDS_COUNTRY_ID]: netherlandsNewFormat,
      [PORTUGAL_COUNTRY_ID]: portugalNewFormat,
      [ITALY_COUNTRY_ID]: italyNewFormat,
      [GERMANY_COUNTRY_ID]: germanyNewFormat,
      [SWITZERLAND_COUNTRY_ID]: switzerlandNewFormat
    }

    const oldLicensePlateFormats = {
      [FRANCE_COUNTRY_ID]: franceOldFormat,
      [BELGIUM_COUNTRY_ID]: belgiumOldFormat,
      [SPAIN_COUNTRY_ID]: spainOldFormat,
      [NETHERLANDS_COUNTRY_ID]: netherlandsOldFormat,
      [PORTUGAL_COUNTRY_ID]: portugalOldFormat,
      [ITALY_COUNTRY_ID]: italyOldFormat
    }
    return (
      <ModalOp id='license-plate-help-modal' name='license-plate-help' centered padded={padded}>
        <div>
          {
            COUNTRIES_WITH_CUSTOM_TOOLTIP.includes(country.id) ? (
              <>
                <div>
                  <FormattedMessage id={`booking.${country.key}_lp_format_tooltip`} values={{ br: <br /> }} />
                </div>
                {newLicensePlateFormats[country.id] && (
                  <img src={newLicensePlateFormats[country.id]} alt={country.key} />
                )}
              </>
            ) : (
              <>
                <div>
                  <FormattedMessage
                    id='booking.license_plate_format_tooltip'
                    values={{
                      date: intl.formatMessage({ id: `booking.${country.key}_new_lp_format_date` }),
                      format: newFormat
                    }}
                  />
                </div>
                <div>
                  <FormattedMessage id='booking.new_license_plate_examples' />
                </div>
                <img src={newLicensePlateFormats[country.id]} alt={country.key} />
                <div>
                  <FormattedMessage id='booking.old_license_plate_examples' />
                </div>
                <img src={oldLicensePlateFormats[country.id]} alt={country.key} />
              </>
            )
          }
        </div>
      </ModalOp>
    )
  }
}

export const LicensePlateHelpModal = injectIntl(LicensePlateHelpModalWithoutIntl)

LicensePlateHelpModal.propTypes = {
  country: PropTypes.object.isRequired
}
