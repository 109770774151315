import classNames from 'classnames'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import CloseIcon from '../../../../../assets/images/icon-close.svg'
import { DropDown } from '../../components/DropDown'
import SvgImage from '../../components/SvgImage'
import { MegaMenuHelper } from '../../helpers/MegaMenuHelper'
import PopularDestinationsSvg from '../../../../../assets/images/icon-popular-destinations.svg'

class SideMegaMenu extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      selectedMenu: null
    }
  }

  render () {
    const { megaMenuContent, onNavItemClick } = this.props
    const adaptedMegaMenuContent = MegaMenuHelper.adaptMegaMenuContentForMobile(megaMenuContent)

    if (!megaMenuContent) return null

    const megaMenuTitleClasses = classNames('side-menu__title', {
      'side-menu__title--menu-item-active': this.state.selectedMenu !== null
    })

    return (
      <div className='side-menu side-menu__mega-menu'>
        <div className={megaMenuTitleClasses} onClick={() => this.setState({ selectedMenu: null })}>
          <SvgImage svg={PopularDestinationsSvg} />
          <span className='side-menu__title-text'><FormattedMessage id='pages.header.popular_destinations' /></span>
          <SvgImage svg={CloseIcon} onClick={() => onNavItemClick('main menu')} />
        </div>

        <div className='side-menu__content'>
          {
            adaptedMegaMenuContent.map(content => (
              <div className={this.menuItemClasses(content.title)} onClick={() => this.onMenuClick(content.title)} key={content.title}>
                {content.title}
              </div>
            ))
          }

          {
            adaptedMegaMenuContent.map((content) => (
              <div className={this.linksClasses(content.title)} key={content.title}>
                {
                  content.sections.map((section, sectionIndex) => (
                    section.linkGroups.map((linkGroup, linkGroupIndex) => (
                      <DropDown
                        id={`linkGroup${content.title}${sectionIndex}${linkGroupIndex}`}
                        key={`linkGroup${content.title}${sectionIndex}${linkGroupIndex}`}
                        ToggleButton={this.linksGroupToggleButton(linkGroup.title)}
                      >
                        {linkGroup.links.map((link, index) => (
                          <a className='side-menu__link side-menu__link--indented' key={index} href={link.url}>{link.label}</a>
                        ))}

                        {linkGroup.seeMoreLink &&
                          <div className='side-menu__link side-menu__link--indented side-menu__link--highlighted'>
                            <a href={linkGroup.seeMoreLink.url}> {linkGroup.seeMoreLink.label} </a>
                          </div>
                        }
                      </DropDown>
                    ))
                  ))
                }

                {content.seeMoreLink &&
                  <div className='side-menu__see-more-link'>
                    <a href={content.seeMoreLink.url}> {content.seeMoreLink.label} </a>
                  </div>
                }
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  onMenuClick (menuTitle) {
    const { selectedMenu } = this.state

    if (selectedMenu === menuTitle) {
      this.setState({ selectedMenu: null })
    } else {
      this.setState({ selectedMenu: menuTitle })
    }
  }

  menuItemClasses () {
    const { selectedMenu } = this.state

    const menuItemClasses = classNames('side-menu__item', {
      'visually-hidden': selectedMenu !== null
    })

    return menuItemClasses
  }

  linksClasses (menuTitle) {
    const { selectedMenu } = this.state

    const linkGroupsClasses = classNames('side-menu__links', {
      'visually-hidden': selectedMenu === null || selectedMenu !== menuTitle
    })

    return linkGroupsClasses
  }

  linksGroupToggleButton (title) {
    return ({ isOpened, onClick }) => {
      return (
        <div className={`side-menu__links-group-title ${isOpened ? 'side-menu__links-group-title--active' : ''}`} onClick={onClick}>
          {title}
        </div>
      )
    }
  }
}

export default SideMegaMenu
