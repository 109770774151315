import React from 'react'
import { getMapProvider } from '../../../../libs/mapProvider'

const NavigationControl = ({ onViewportChange }) => {
  return (
    React.createElement(
      getMapProvider().NavigationControl,
      {
        className: 'map-navigation-control',
        captureScroll: true,
        showCompass: false,
        onViewportChange
      }
    )
  )
}

export default NavigationControl
