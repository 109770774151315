import React from 'react'
import FooterLinkObfuscated from '../../components/FooterLinkOfuscated'

const LinksList = ({ isHomepage, legalNotice: { cguPath, cookiesPath, contactPath } = {}, links }) => (
  <ul>
    {links.map(({ url, encodedUrl, label }, i) => (
      <li key={i}>
        {[cguPath, contactPath].includes(url) || isHomepage
          ? <a href={url} title={label} target={[cguPath, cookiesPath].includes(url) ? '_blank' : null}>{label}</a>
          : <FooterLinkObfuscated message={label} link={encodedUrl} target={cookiesPath === url ? '_blank' : null} title={label} />
        }
      </li>
    ))}
  </ul>
)

export default LinksList
