import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import Observed from 'react-observed'
import _scrollIntoView from 'scroll-into-view-if-needed'
import _smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

import { MobileFiltersModal } from 'Components/MobileFiltersModal'
import { DesktopFiltersModal } from 'Components/DesktopFiltersModal'
import * as SearchActions from '../../../actions/SearchActions'
import DropdownButton from '../components/DropdownButton'
import HistoryModalOp from '../components/HistoryModalOp'
import ModalOp from 'Components/ModalOp'
import { ParkCard } from 'Components/ParkCard'
import { isMobile } from '../helpers/browser'
import { locationHasParam, removeParamFromLocation } from '../helpers/application'
import { getMapWrapperComponent } from '../../../libs/map'
import { getDiscount } from '../helpers/park'

const FILTERS_MODAL_NAME = 'filters_search'
const MAP_MODAL_NAME = 'map_search'
const PARK_ID_PREFIX = 'park-'

class SearchPage extends Component {
  state = {
    activeMarkerId: null,
    activeInfoWindowId: null,
    initModalMap: false,
    initModalFilters: false
  }

  componentDidMount () {
    this.calculatePosition()
    $(window).on('scroll', this.handleScroll)
    $(window).on('resize', this.handleResize)
    $(window).on('toggleSearch', this.handleToogleSearch)
    $(window).on('orientationchange', this.handleOrientationChange)

    this.props.actions.loadBestOffers()

    if ([FILTERS_MODAL_NAME, MAP_MODAL_NAME].some(name => locationHasParam('modal', name))) {
      history.replaceState({}, '', removeParamFromLocation('modal'))
    }
  }

  componentWillUnmount () {
    $(window).off('scroll', this.handleScroll)
    $(window).off('resize', this.handleResize)
    $(window).off('toggleSearch', this.handleToogleSearch)
    $(window).off('orientationchange', this.handleOrientationChange)
  }

  handleResize = () => requestAnimationFrame(this.calculatePosition)

  handleScroll = () => requestAnimationFrame(this.calculatePosition)

  handleToogleSearch = () => requestAnimationFrame(this.calculatePosition)

  handleOrientationChange = () => {
    if (navigator.platform === 'iPad') { // iPad does not trigger resize event after orientation change
      setTimeout(() => this.handleResize(), 250)
    }
  }

  calculatePosition = () => {
    const controlsElement = document.querySelector('.search-page__parks__desktop-controls')
    if (controlsElement) {
      // Top and bottom positions from getBoundingClientRect have negative values...
      const { top: navTop, bottom: navBottom } = document.querySelector('#header-on-react .header__nav').getBoundingClientRect()
      const { top: searchTop, bottom: searchBottom } = document.getElementById('search-wrapper').getBoundingClientRect()
      const navHeight = navBottom - navTop
      const searchHeight = searchBottom - searchTop

      const searchOpen = searchHeight > 0

      const top = searchOpen ? searchHeight + navHeight : navHeight
      controlsElement.style.top = `${top}px`

      const isStuck = searchOpen ? $(window).scrollTop() > navHeight : $(window).scrollTop() > 0
      if (isStuck) {
        controlsElement.style['border-bottom'] = 'solid 1px #E3E3E3'
        controlsElement.style['box-shadow'] = '-1px 0px 3px rgba(0, 0, 0, .15)'
      } else {
        controlsElement.style['border-bottom'] = null
        controlsElement.style['box-shadow'] = null
      }
    }
  }

  handleClickOpenMap = () => {
    this.setState({ initModalMap: true }, () => $('#react-map-modal').modal('show'))
  }

  handleClickFilters = () => {
    this.setState({ initModalFilters: true }, () => $('#react-filters-modal').modal('show'))
  }

  handleClickMobileFilters = () => {
    this.setState({ initModalFilters: true }, () => $('#react-mobile-filters-modal').modal('show'))
  }

  handleClickOpenSearch = () => {
    $('.icon-search:not(.active)').click()
  }

  handleCloseMobileFilters = () => {
    $('#react-mobile-filters-modal').modal('hide')
  }

  handleCloseFilters = () => {
    $('#react-filters-modal').modal('hide')
  }

  handleMarkerMouseOver = (id) => {
    if (!this.state.activeInfoWindowId) {
      this.setState({ activeMarkerId: id })
    }
  }

  handleMarkerMouseOut = (id) => {
    if (!this.state.activeInfoWindowId) {
      this.setState({ activeMarkerId: null })
    }
  }

  handleMapMounted = (ref) => {
    this.map = ref
  }

  handleSelectPark = async (id, shouldScrollIntoView) => {
    const { activeMarkerId } = this.state
    const activeCard = document.querySelector(`#${PARK_ID_PREFIX}${id}`)
    if (activeCard) {
      if (shouldScrollIntoView) {
        const scrollIntoView = 'scrollBehavior' in document.documentElement.style ? _scrollIntoView : _smoothScrollIntoView
        scrollIntoView(activeCard, { behavior: 'smooth', block: 'center' })
      } else if (!shouldScrollIntoView && id !== activeMarkerId) {
        this.centerMapOnPark(id)
      }
    }

    this.setState({ activeMarkerId: id, activeInfoWindowId: id })

    if (!this.props.parks.find(park => park.id === id).services) {
      this.props.actions.loadParkSummary(id)
    }
  }

  handleUnselectPark = (id) => {
    this.setState({ activeMarkerId: null, activeInfoWindowId: null })
  }

  handleSortBy = (sortBy) => this.props.actions.updateSorting(sortBy)

  centerMapOnPark (id) {
    const park = this.props.parks.find(park => park.id === id)
    if (park && this.map) {
      const { lat, lng } = park.address
      // GMap
      if (this.map.panTo) {
        this.map.panTo({ lat, lng })
      }
      // Mapbox
      if (this.map.flyTo) {
        this.map.flyTo({
          center: [lng, lat],
          easing: (t) => t
        })
      }
    }
  }

  render () {
    const { lat, lng, clusters, bookableParks, parks, queryState, type, zoom, isUserOnMobile } = this.props
    const sortBy = this.props.sortBy
    const { activeMarkerId, activeInfoWindowId, initModalMap, initModalFilters } = this.state
    const sortOptions = [
      { id: 'distance', label: <FormattedMessage id='pages.parks.filters.sort_by_distance' /> },
      { id: 'price', label: <FormattedMessage id='pages.parks.filters.sort_by_price' /> },
      { id: 'review', label: <FormattedMessage id='pages.parks.filters.sort_by_review' /> }
    ]

    return (
      <div className={`react-component search-page ${queryState === 'pending' ? 'search-page--searching' : ''}`}>
        <div className={
          'search-page__parks ' +
          (activeMarkerId ? 'search-page__parks--has-active ' : '')
        }>
          <div className='search-page__parks__desktop-controls'>
            <div className='search-page__parks-desktop-controls-buttons'>
              <button className='search-page__filters-button' onClick={this.handleClickFilters}>
                <FormattedMessage id='pages.parks.filters_btn' />
              </button>

              <DropdownButton className='btn btn-flat' value={sortBy} options={sortOptions} onChange={this.handleSortBy} />
            </div>
          </div>

          {parks.length === 0 &&
            <div className='search-page__no-results'>
              <FormattedMessage id='pages.parks.no_results' />
              <button className='btn btn-primary hidden-md hidden-lg' onClick={this.handleClickOpenSearch}>
                <FormattedMessage id='pages.parks.no_results_cta' />
              </button>
            </div>
          }
          {parks.map((park, i) =>
            <ParkCard
              key={park.id}
              idPrefix={PARK_ID_PREFIX}
              index={i}
              park={park}
              isActiveMarker={park.id === activeMarkerId}
              isActiveInfoWindow={park.id === activeInfoWindowId}
              isSubscription={type === 'subscription'}
              isUserOnMobile={isUserOnMobile}
              onMouseEnter={(e) => {
                if (!isMobile()) {
                  e.preventDefault()
                  this.handleSelectPark(park.id)
                }
              }}
              showAddress
              showBookingButtons
            />)}

          {parks.some(park => getDiscount(park).discountRate < 0) &&
            <div className='search-page__discount-disclaimer'>
              <FormattedMessage id='pages.park.discount_disclaimer' />
            </div>
          }
        </div>

        <div className='search-page__mobile-controls'>
          <button className='search-page__mobile-controls-btn' onClick={isUserOnMobile ? this.handleClickMobileFilters : this.handleClickFilters}>
            <FormattedMessage id='pages.parks.filters_btn' />
          </button>
          <button className='search-page__mobile-controls-btn' onClick={this.handleClickOpenMap}>
            <FormattedMessage id='pages.parks.map_btn' />
          </button>
        </div>

        {
          initModalMap &&
          <HistoryModalOp id='react-map-modal' name={MAP_MODAL_NAME} mobileOnly autoHideOnResize fullScreen>
            {
              React.createElement(
                getMapWrapperComponent(),
                {
                  autoSearch: true,
                  defaultCenter: { lat, lng },
                  defaultZoom: zoom,
                  clusters,
                  isSubscription: type === 'subscription',
                  isUserOnMobile: isUserOnMobile,
                  parks: bookableParks,
                  showMainMarker: true,
                  zoomControl: false
                }
              )
            }
          </HistoryModalOp>
        }

        {
          initModalFilters && isUserOnMobile &&
          <ModalOp id='react-mobile-filters-modal' name={FILTERS_MODAL_NAME} mobileOnly autoHideOnResize hideCloseIcon>
            <MobileFiltersModal onClose={this.handleCloseMobileFilters} showAllFilters />
          </ModalOp>
        }

        {
          initModalFilters && !isUserOnMobile &&
          <ModalOp id='react-filters-modal' name={FILTERS_MODAL_NAME} desktopOnly autoHideOnResize hideCloseIcon>
            <DesktopFiltersModal onClose={this.handleCloseFilters} showAllFilters />
          </ModalOp>
        }

        <div className='search-page__map'>
          <Observed once>
            {({ isInView, mapRef }) => (
              <div ref={mapRef} style={{ height: '100%' }}>
                {isInView && (
                  React.createElement(
                    getMapWrapperComponent(),
                    {
                      activeInfoWindowId,
                      autoSearch: true,
                      defaultCenter: { lat, lng },
                      defaultZoom: zoom,
                      clusters,
                      isSubscription: type === 'subscription',
                      isUserOnMobile: isUserOnMobile,
                      onMarkerMouseOver: this.handleMarkerMouseOver,
                      onMarkerMouseOut: this.handleMarkerMouseOut,
                      onMapMounted: this.handleMapMounted,
                      onSelectPark: this.handleSelectPark,
                      onUnselectPark: this.handleUnselectPark,
                      parks,
                      showMainMarker: true,
                      zoomControl: true
                    }
                  )
                )}
              </div>
            )}
          </Observed>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  resources: { localePrefix },
  search: { clusters, bookableParks, unbookableParks, zoom, sortBy, params: { lat, lng, type }, queryState }
}) => {
  return {
    clusters,
    parks: [...bookableParks, ...unbookableParks],
    bookableParks,
    lat,
    lng,
    localePrefix,
    type,
    zoom,
    sortBy,
    queryState
  }
}

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...SearchActions }, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)
