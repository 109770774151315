import React, { Component } from 'react'
import ModalOp from 'Components/ModalOp'
import { FormattedMessage } from 'react-intl'

const SAME_PARK_TYPE = 'same_park'
const OTHER_PARKS_TYPE = 'other_parks'

export class DuplicateBookingModal extends Component {
  handleCancel = () => {
    $('#duplicate-booking-modal').modal('hide')
  }

  handleContinue = () => {
    this.props.handleContinue()
    $('#duplicate-booking-modal').modal('hide')
  }

  render () {
    const duplicateType = this.props.duplicateType

    return (
      <ModalOp id='duplicate-booking-modal' name='duplicate-booking-warning'>
        <div className='duplicate-booking'>
          <div className='duplicate-booking__text-container row m-bottom-30'>
            <div className='duplicate-booking__title col-xs-12'>
              <h1 className='title--primary text-center'>
                <FormattedMessage id='purchase.purchase_summary.duplicate_booking.title' /></h1>
            </div>
            <div className='duplicate-booking__warning-text col-xs-12'>
              {duplicateType === SAME_PARK_TYPE &&
                <p className='text--style'>
                  <FormattedMessage id='purchase.purchase_summary.duplicate_booking.first_case' />
                </p>
              }
              {duplicateType === OTHER_PARKS_TYPE &&
                <p className='text--style'>
                  <FormattedMessage id='purchase.purchase_summary.duplicate_booking.second_case' /></p>
              }
              <p className='text--style'>
                <FormattedMessage id='purchase.purchase_summary.duplicate_booking.continue_advice' /></p>
            </div>
          </div>
          <div className='duplicate-booking__buttons-container row'>
            <ul>
              <li className='m-bottom-20'>
                <a className='duplicate-booking__cancel-button btn btn-primary pull-right' aria-label='close' data-dismiss='modal' onClick={this.handleCancel} data-automation-id='duplicate-booking__cancel-button'>
                  <FormattedMessage id='purchase.purchase_summary.duplicate_booking.cancel' />
                </a>
              </li>
              <li className='text-center'>
                <a className='duplicate-booking__continue-button link--secondary link--uppercase pull-right btn-left' onClick={this.handleContinue} data-automation-id='duplicate-booking__continue-button'>
                  <FormattedMessage id='purchase.purchase_summary.duplicate_booking.continue' />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </ModalOp >
    )
  }
}
