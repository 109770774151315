export const clearPreviousEcommerceObject = () => {
  window.dataLayer.push({ ecommerce: null })
}

export const gaUserTags = () => {
  return {
    up_user_id: window._oneparkDatas.up_user_id,
    up_language: window._oneparkDatas.up_language,
    up_locale: window._oneparkDatas.up_locale
  }
}

export const amountWithoutTax = (amount, vatPercent) => roundedToCents(parseFloat(amount) / (1.0 + vatPercent))

export const roundedToCents = (amount) => (+amount.toFixed(2))

export const emitGa4PageView = (epPageTypeLevel1) => {
  window.dataLayer.push({
    ep_page_type_level1: epPageTypeLevel1,
    event: 'page_view',

    ep_logged_status: window._oneparkDatas.user_signed_in,
    ep_page_name: document.getElementsByTagName('title')[0].innerText,
    ep_page_type: 'ecommerce',
    ep_site: window._oneparkDatas.ep_site,
    up_language: window._oneparkDatas.up_language,
    up_locale: window._oneparkDatas.up_locale,
    up_user_id: window._oneparkDatas.up_user_id
  })
}
