import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { FormattedMessage } from 'react-intl'

import OpI18n from '../../../components/OpI18n'
import SvgImage from '../../../components/SvgImage'
import iconCompare from '../../../../../../../app/assets/images/svg-on-react/icon-compare.svg'
import iconFindParks from '../../../../../../../app/assets/images/svg-on-react/icon-findparks.svg'
import iconTarif from '../../../../../../../app/assets/images/svg-on-react/icon-tarif.svg'

const RegistrationCarouselWithContext = (props, railsContext) => (
  <RegistrationCarouselComponent {...{ ...props, railsContext }} />
)

const sliderSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false
}

class RegistrationCarouselComponent extends React.Component {
  render () {
    return (
      <OpI18n {...this.props.railsContext}>
        <Slider {...sliderSettings}>
          <div>
            <SvgImage svg={iconCompare} />
            <p><FormattedMessage id='authentication.registration.carousel.compare' /></p>
          </div>
          <div>
            <SvgImage svg={iconFindParks} />
            <p><FormattedMessage id='authentication.registration.carousel.find' /></p>
          </div>
          <div>
            <SvgImage svg={iconTarif} />
            <p><FormattedMessage id='authentication.registration.carousel.advantages' /></p>
          </div>
        </Slider>
      </OpI18n>
    )
  }
}

export default RegistrationCarouselWithContext
