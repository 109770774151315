import chunk from 'lodash/chunk'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import NearbyPark from './NearbyPark'

const NearbyParksSection = ({ parks, type }) => parks.length > 0 && (
  <section id='park-nearby-parks' className='nearby-section'>
    <h2>
      <FormattedMessage id='pages.park.nearby_parks.title' />
    </h2>

    {chunk(parks, 2).map((pair, i) => (
      <div className='row hidden-xs hidden-sm' key={i}>
        {pair.map((park, j) => (
          <div key={j} className='col-md-6'>
            <NearbyPark park={park} isSubscription={type === 'subscription'} imgWidth='180' imgHeight='180' />
          </div>
        ))}
      </div>
    ))}

    <div className='nearby-section__slider hidden-md hidden-lg'>
      {parks.map((park, i) => (
        <NearbyPark key={i} park={park} isSubscription={type === 'subscription'} imgWidth='140' imgHeight='140' />
      ))}
    </div>
  </section>
)

const mapStateToProps = ({ nearby: { parks }, search: { params: { type } } }) => {
  const bookableParks = parks.filter(park => park.bestOffer)
  const unbookableParks = parks.filter(park => !park.bestOffer)
  return {
    parks: [...bookableParks, ...unbookableParks].slice(0, 8),
    type
  }
}

export default connect(mapStateToProps)(NearbyParksSection)
