import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Loader from 'Components/Loader'
import SvgImage from 'Components/SvgImage'

import iconSuccess from 'Assets/images/svg-on-react/icon-success.svg'

const ERROR_STATE = 'error'
const PROGRESS_STATES = ['created', 'recovering']
const CONFIRMED_STATE = 'confirmed'

const DESCRIPTIONS = {
  [ERROR_STATE]: ['purchase.confirmation.description_error_first'],
  [PROGRESS_STATES[0]]: ['purchase.confirmation.description_created'],
  [PROGRESS_STATES[1]]: ['purchase.confirmation.description_recovering'],
  [CONFIRMED_STATE]: ['purchase.confirmation.description_confirmed']
}

export class RegistrationProgress extends Component {
  render () {
    const { purchaseState, parkUrl, bookingId, email, localePrefix } = this.props
    const titleID = `purchase.confirmation.title_${purchaseState}`

    const containerClasses = classNames('registration-progress', {
      'registration-progress--error': ERROR_STATE.includes(purchaseState),
      'registration-progress--confirmed': CONFIRMED_STATE.includes(purchaseState)
    })

    const titleClasses = classNames('registration-progress__title', {
      'registration-progress__title--error': ERROR_STATE.includes(purchaseState),
      'registration-progress__title--confirmed': CONFIRMED_STATE.includes(purchaseState)
    })

    const pictureClasses = classNames('registration-progress__picture', {
      'registration-progress__picture--progress': PROGRESS_STATES.includes(purchaseState)
    })

    const descriptionClasses = classNames('registration-progress__description', {
      'registration-progress__description--error': ERROR_STATE.includes(purchaseState),
      'registration-progress__description--confirmed': CONFIRMED_STATE.includes(purchaseState)
    })

    return (
      <div className={containerClasses}>
        <div className={pictureClasses}>
          {
            PROGRESS_STATES.includes(purchaseState) &&
            <Loader overlay green />
          }
        </div>

        <div className='registration-progress__title-container'>
          {
            purchaseState === CONFIRMED_STATE &&
            <div>
              <SvgImage svg={iconSuccess} className='registration-progress__success-icon' />
            </div>
          }
          <div>
            <h3 className={titleClasses}>
              <FormattedMessage id={titleID} values={{ booking_id: bookingId }} />
            </h3>
          </div>
        </div>

        <div className={descriptionClasses}>
          {DESCRIPTIONS[purchaseState] &&
            DESCRIPTIONS[purchaseState].map((messageId) => {
              const descriptionTextClasses = classNames('registration-progress__textblock--longword', {
                'registration-progress__textblock--left': CONFIRMED_STATE.includes(purchaseState),
                'registration-progress__textblock--centered': !CONFIRMED_STATE.includes(purchaseState)
              })
              return (<div className={descriptionTextClasses}>
                <FormattedMessage
                  key={`index-${messageId}`}
                  id={messageId}
                  values={{ email: email }}
                />
              </div>
              )
            })
          }
        </div>

        {
          ERROR_STATE.includes(purchaseState) &&
          <>
            <a href={parkUrl} className='btn btn-primary purchase-confirmation__renew-btn'>
              <FormattedMessage id='purchase.confirmation.renew_booking' />
            </a>
            <div className='registration-progress__textblock--centered'>
              <FormattedMessage id='purchase.confirmation.customer_service_intro' />
              <span> </span>
              <a href={`${localePrefix}/contact`} className='registration-progress__customer-service'>
                <FormattedMessage id='purchase.confirmation.customer_service_link' />
              </a>
            </div>
          </>
        }
      </div>
    )
  }
}
