import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

const DEFAULT_DIRECTION = 'top-left'

export class TooltipContentComponent extends Component {
  state = {
    direction: DEFAULT_DIRECTION,
    contentRef: React.createRef()
  }

  componentDidMount () {
    const direction = this.tooltipDirection()
    this.setState({ direction: direction })

    const content = this.state.contentRef.current

    content.classList.remove(`tooltip-op__box--${DEFAULT_DIRECTION}`)
    content.classList.add(`tooltip-op__box--${direction}`)
  }

  tooltipDirection () {
    if (!this.props.containerRef && !this.props.fitWindow) return DEFAULT_DIRECTION

    const tooltip = this.state.contentRef.current
    const tooltipRect = tooltip.getBoundingClientRect()

    if (this.props.containerRef) return this.directionInContainer(tooltipRect)
    if (this.props.fitWindow) return this.directionInWindow(tooltipRect)
  }

  directionInContainer (tooltipRect) {
    const containerRect = this.props.containerRef.current.getBoundingClientRect()
    if (!containerRect) return DEFAULT_DIRECTION

    const top = tooltipRect.top >= containerRect.top
    const bottom = tooltipRect.bottom <= containerRect.bottom
    const left = tooltipRect.left >= containerRect.left
    const right = tooltipRect.right <= containerRect.right

    if (top && left) return 'top-left'
    if (top && right) return 'top-right'
    if (bottom && left) return 'bottom-left'
    if (bottom && right) return 'bottom-right'

    return DEFAULT_DIRECTION
  }

  directionInWindow (tooltipRect) {
    const top = tooltipRect.top >= 0
    const bottom = tooltipRect.bottom <= window.innerHeight
    const left = tooltipRect.left >= 0
    const right = tooltipRect.right <= window.innerWidth

    if (top && left) return 'top-left'
    if (top && right) return 'top-right'
    if (bottom && left) return 'bottom-left'
    if (bottom && right) return 'bottom-right'

    return DEFAULT_DIRECTION
  }

  render () {
    const tooltipClasses = classNames('tooltip-op__box', 'tootltip-op-arrow', `tooltip-op__box--${DEFAULT_DIRECTION}`)

    return (
      <div className={tooltipClasses} ref={this.state.contentRef}>
        <div className='tooltip-op__box__content'>
          <FormattedMessage id={this.props.message} values={this.props.values} />
        </div>
        <div className='tooltip-op__box__close'
          onClick={this.props.handleCloseTooltip}>
          <span>&times;</span>
        </div>
      </div>
    )
  }
}
