import React from 'react'
import { connect } from 'react-redux'
import { updateParkFilters } from 'Actions/FilterActions'
import snakeCase from 'lodash/snakeCase'
import URLSearchParams from 'url-search-params'
import { applyParkFilters } from '../helpers/park'

import TooltipComponent from './TooltipComponent'

import { FormattedMessage } from 'react-intl'
class ConnectedFiltersPanel extends React.Component {
  state = {
    filters: this.props.parkFilters
  }

  appendParamToLocation = (name, value) => {
    const params = new URLSearchParams(location.search.slice(1))
    params.has(name) ? params.set(name, `${params.get(name)},${value}`) : params.append(name, value)
    history.replaceState({}, '', location.pathname + '?' + params.toString())
  }

  removeParamFromLocation = (name, value) => {
    const params = new URLSearchParams(location.search.slice(1))
    const newValue = params.get(name).replace(new RegExp(`,?${value},?`), '')
    newValue.length > 0 ? params.set(name, newValue) : params.delete(name)

    history.replaceState({}, '', location.pathname + (Array.from(params).length ? '?' : '') + params.toString())
  }

  removeAllFilterParams = () => {
    const params = new URLSearchParams(location.search.slice(1))
    const allSections = [...filtersSections, ...airportFiltersSections]
    allSections.forEach((section) => params.delete(section.category))
    history.replaceState({}, '', location.pathname + (Array.from(params).length ? '?' : '') + params.toString())
  }

  onSelectFilter = event => {
    const isSelected = this.state.filters.includes(event.target.id)
    const filtered = isSelected ? this.state.filters.filter(option => option !== event.target.id) : [...this.state.filters, event.target.id]

    this.setState({ filters: filtered })
    this.props.automateFilters && this.props.updateParkFilters(filtered)
    isSelected ? this.removeParamFromLocation(event.target.dataset.category, event.target.id) : this.appendParamToLocation(event.target.dataset.category, event.target.id)
  }

  renderFilters = (onSelectFilter, selectedFilters, displayedSections, sectionWrapperClass) => {
    const categoriesSectionRef = React.createRef()

    return (
      <div className='filters-panel__categories-section' ref={categoriesSectionRef}>
        {displayedSections.map(
          ({ category, filtersInfo }, index) =>
            <FiltersSection key={category} category={category} filtersInfo={filtersInfo}
              onSelectFilter={onSelectFilter} selectedFilters={selectedFilters}
              sectionWrapperClass={sectionWrapperClass} containerRef={categoriesSectionRef}
              lastSection={index === displayedSections.length - 1}
            />
        )
        }
      </div>
    )
  }

  handleApply = () => {
    this.props.handleApply ? this.props.handleApply(this.state.filters) : this.props.updateParkFilters(this.state.filters)
  }

  handleReset = () => {
    this.props.updateParkFilters([])
    this.removeAllFilterParams()
    this.setState({ filters: [] })
  }

  resetFiltersAmounts = (displayedSections) => {
    displayedSections.forEach(({ filtersInfo }) => {
      filtersInfo.forEach((filter) => {
        filter.amount = 0
      })
    })
  }

  calculateFiltersAmounts = (displayedSections) => {
    const { parks } = this.props
    const filteredParks = applyParkFilters(parks, this.state.filters)

    this.resetFiltersAmounts(displayedSections)

    filteredParks.forEach((park) => {
      displayedSections.forEach(({ filtersInfo }) => {
        filtersInfo.forEach((filter) => {
          filter.amount += park[filter.title] ? 1 : 0
        })
      })
    })
  }

  render () {
    const displayedSections = this.props.showAllFilters ? [...filtersSections, ...airportFiltersSections] : filtersSections

    const panelClass = this.props.isMobile ? 'filters-panel filters-panel--mobile' : 'filters-panel'
    const sectionWrapperClass = `filters-panel__section-wrapper${this.props.isMobile ? '--mobile' : ''}`

    this.calculateFiltersAmounts(displayedSections)

    return (
      <div className={panelClass}>
        <div className='filters-panel__header filters-panel__row'>
          <div className='filters-panel__header-title'>
            <FormattedMessage id={'pages.filters.title'} />
          </div>
          <a onClick={this.handleReset} className='filters-panel__header-reset-link'>
            <FormattedMessage id={'pages.filters.remove_button'} />
          </a>
        </div>
        <hr className='filters-panel__borderline' />
        {this.renderFilters(this.onSelectFilter, this.state.filters, displayedSections, sectionWrapperClass)}
        {!this.props.automateFilters &&
          <div className='filters-panel__footer'>
            <hr className='filters-panel__borderline filters-panel__borderline-footer' />
            <a className='btn btn-primary filters-panel__search-button' target='_blank' onClick={this.handleApply}>
              <FormattedMessage id={'pages.filters.cta_button'} />
            </a>
          </div>
        }
      </div>
    )
  }
}

const FiltersSection = ({ category, filtersInfo, onSelectFilter, selectedFilters, sectionWrapperClass, containerRef, lastSection }) => {
  return (
    <>
      <div className='filters-panel__row filters-panel__category'>
        <FormattedMessage id={`pages.filters.categories.${snakeCase(category)}`} />
      </div>

      <div className={sectionWrapperClass}>
        {filtersInfo.map(({ title, hasTooltip, amount }) => <FiltersRow key={`${category} ${title}`} title={title} category={category} hasTooltip={hasTooltip} amount={amount} onSelectFilter={onSelectFilter} isSelected={selectedFilters.includes(title)} containerRef={containerRef} />)}
      </div>

      {!lastSection && <hr className='filters-panel__borderline' />}
    </>
  )
}

const FiltersRow = ({ title, category, amount, hasTooltip, onSelectFilter, isSelected, containerRef }) => {
  return (
    <div className='filters-panel__row'>
      <div className='filters-panel__checkbox-wrapper'>
        <div className='filters-panel__checkbox'>
          <input type='checkbox' id={title} name={title} data-category={category} checked={isSelected}
            className='checkbox-op checkbox-op--green' onChange={onSelectFilter} disabled={amount === 0} />
          <label htmlFor={title} className='filters-panel__label'>
            <FormattedMessage id={`pages.filters.filter_name.${snakeCase(title)}`} />
          </label>
        </div>
        {hasTooltip &&
          <TooltipComponent isOpen={false} message={`pages.filters.tooltips.${snakeCase(title)}`} containerRef={containerRef} />
        }
      </div>
      <div className='filters-panel__amount'>{amount}</div>
    </div>
  )
}

const filtersSections = [
  {
    category: 'car_park_type',
    filtersInfo: [{
      title: 'covered',
      hasTooltip: false,
      amount: 0
    },
    {
      title: 'external',
      hasTooltip: false,
      amount: 0
    }]
  },
  {
    category: 'vehicle_types',
    filtersInfo: [{
      title: 'moto',
      hasTooltip: false,
      amount: 0
    },
    {
      title: 'cityCar',
      hasTooltip: true,
      amount: 0
    },
    {
      title: 'sedanCar',
      hasTooltip: true,
      amount: 0
    },
    {
      title: 'familyCar',
      hasTooltip: true,
      amount: 0
    },
    {
      title: 'utilityCar',
      hasTooltip: true,
      amount: 0
    }]
  },
  {
    category: 'car_park_height',
    filtersInfo: [{
      title: 'over190',
      hasTooltip: false,
      amount: 0
    }]
  },
  {
    category: 'services',
    filtersInfo: [{
      title: 'recharge',
      hasTooltip: true,
      amount: 0
    },
    {
      title: 'allowsMultipleEntriesOption',
      hasTooltip: true,
      amount: 0
    },
    {
      title: 'quickAccess',
      hasTooltip: true,
      amount: 0
    },
    {
      title: 'elevator',
      hasTooltip: false,
      amount: 0
    }
    ]
  }
]

const airportFiltersSections = [
  {
    category: 'transfer_to_the_airport',
    filtersInfo: [
      {
        title: 'walk',
        hasTooltip: true,
        amount: 0
      },
      {
        title: 'shuttle',
        hasTooltip: true,
        amount: 0
      },
      {
        title: 'publicTransport',
        hasTooltip: true,
        amount: 0
      },
      {
        title: 'taxi',
        hasTooltip: true,
        amount: 0
      }
    ]
  }
]

const mapStateToProps = ({ search: { parkFilters, parks } }) => {
  return {
    parkFilters,
    parks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateParkFilters: (filters) => dispatch(updateParkFilters(filters))
  }
}

export const FiltersPanel = connect(mapStateToProps, mapDispatchToProps)(ConnectedFiltersPanel)
