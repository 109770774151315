import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import LazyLoad from 'react-lazyload'

import MapView from './MapView'
import { datalayerPushNavigationEvent } from '../../helpers/application'

export default class MapWrapper extends Component {
  handleClickItinary = () => {
    datalayerPushNavigationEvent('itinerary')

    const address = this.props.parks[0].address.fullAddress.replace(/\s/g, '+')
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${address}`)
  }

  render () {
    const {
      lazyload,
      itinaryControl,
      openModalControl,
      onOpenInModalClick,
      ...mapProps
    } = this.props
    return (
      <div className='react-map-container'>
        {lazyload ? <LazyLoad height='100%'><MapView {...mapProps} /></LazyLoad> : <MapView {...mapProps} />}

        {itinaryControl && (
          <button
            className='map-direction-control map-custom-control'
            onClick={this.handleClickItinary}>
            <FormattedMessage id='pages.park.location.itinerary' />
          </button>
        )}

        {openModalControl && (
          <button
            className='map-modal-control map-custom-control'
            onClick={onOpenInModalClick}>
            <FormattedMessage id='pages.park.location.open_in_modal' />
          </button>
        )}
      </div>
    )
  }
}
