import React from 'react'

import EditorialHtml from '../../components/EditorialHtml'
import TranslatedByGoogle from '../../components/TranslatedByGoogle'

const TransportModeAccess = ({ park }) => {
  if (!park.transportAccess) {
    return <div />
  }

  return (
    <div>
      {park.editorialContentTranslatedByGoogle &&
        <h3>
          <aside><TranslatedByGoogle /></aside>
        </h3>
      }

      <EditorialHtml html={park.transportAccess} />
    </div>
  )
}

export default TransportModeAccess
