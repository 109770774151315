import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import JumboSearchPackage from '../Search/components/JumboSearchPackage'
import JumboSearchSubscription from '../Search/components/JumboSearchSubscription'
import { datalayerPushGTM } from '../helpers/application'
import { isMobile } from '../helpers/browser'
import { hideBackdropOverlay } from '../helpers/backdropOverlay'
import { clearSearchParamsFromUrl, setCurrentLocationType } from '../helpers/search'
import * as SearchActions from '../../../actions/SearchActions'
import debounce from 'lodash/debounce'
class HeaderSearchTabs extends Component {
  state = {
    initialAddress: this.props.address
  }

  componentDidMount () {
    clearSearchParamsFromUrl()

    const pageName = (window._oneparkDatas || {}).page_name
    if (['parks_index', 'places_index', 'places_show'].includes(pageName)) {
      setCurrentLocationType(this.props.type)
    }
  }

  handleSubmit = async () => {
    const {
      address,
      lat,
      legacySearch,
      lng,
      onToggleHeaderSearchSummary,
      type
    } = this.props

    datalayerPushGTM('search-tracking', 'Search', `map/success/${type}s/hp`, address)

    if (isMobile()) {
      onToggleHeaderSearchSummary()
    }

    const pageName = (window._oneparkDatas || {}).page_name
    const isSearchPage = pageName === 'parks_index'
    const isPlacePage = pageName === 'places_show'
    if (!legacySearch && lat && lng && (isSearchPage || (isPlacePage && address === this.state.initialAddress))) {
      await this.props.actions.applySearch('header')
    } else {
      this.props.actions.navigateToSearchPage('header')
    }
  }

  debouncedHandleSubmit = debounce(this.handleSubmit, 1500)

  handleClickTab = (type) => {
    if (type !== this.props.type) {
      if (type === 'subscription') {
        hideBackdropOverlay('place-backdrop')
      }

      this.props.actions.updateNextSearchParams({ type })
      datalayerPushGTM('hitVirtualPageView')
      this.debouncedHandleSubmit()
    }
  }

  renderTabButton (type) {
    return (
      <button
        className={type === this.props.type ? 'selected' : null}
        onClick={() => this.handleClickTab(type)}
        id={`offer_type_switcher.search.buttons.${type}`}
      >
        <FormattedMessage id={`pages.homepage.search.buttons.${type}`} />
      </button>
    )
  }

  render () {
    const { displaySubscriptionSearch, queryState, type } = this.props
    const jumboSearchProps = {
      idSuffix: 'Header',
      searchMode: true,
      submitButtonClass: { fixedSize: true },
      loading: queryState === 'pending',
      onSubmit: this.handleSubmit,
      showSearchIcon: false
    }
    return (
      <div className='op-search row row--condensed h-100p'>
        {displaySubscriptionSearch &&
          <div className='col-xs-12 col-xl-3'>
            <div className='op-search__tabs op-search__tabs--border-rounded'>
              <div className='switch-buttons'>
                <div className='col-xs-6 p-0'>{this.renderTabButton('package')}</div>
                <div className='col-xs-6 p-0'>{this.renderTabButton('subscription')}</div>
              </div>
            </div>
          </div>
        }
        <div className='col-xs-12 col-xl-9'>
          <div className={`op-search__from op-search__form--without-borders ${displaySubscriptionSearch ? '' : 'f-w'}`}>
            {type === 'package' && <JumboSearchPackage {...jumboSearchProps} /> }
            {type === 'subscription' && <JumboSearchSubscription {...jumboSearchProps} /> }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  place,
  railsContext: { currentSite: { display_subscription_search: displaySubscriptionSearch } },
  search: { sortBy, parks, nextParams: { address, lat, lng, type }, queryState },
  site: { legacySearch }
}) => ({ displaySubscriptionSearch, address, legacySearch, lat, lng, parks, place, queryState, type, sortBy })

function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators({ ...SearchActions }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearchTabs)
