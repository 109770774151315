import URLSearchParams from 'url-search-params'

import * as types from '../types/NearbyTypes'

export const updateNearbyBestOffers = (parkBestOffers) => ({ type: types.BEST_OFFERS_UPDATE, parkBestOffers })

export const loadNearbyBestOffers = () => (dispatch, getState) => {
  const {
    nearby: { parks },
    search: { params: { beginDate, endDate, type } },
    railsContext: { localePrefix }
  } = getState()

  const internalIds = parks.filter(p => !p.external).map(p => p.id)
  const externalIds = parks.filter(p => p.external).map(p => p.id)
  const idGroups = [internalIds.join(','), ...externalIds].filter(idGroup => !!idGroup)

  const params = new URLSearchParams()
  params.set('begin_date', beginDate.toISOString(true))
  params.set('type', type)
  const urlParams = new URLSearchParams(window.location.search)
  const placeId = urlParams.get('place_id')
  if (placeId) { params.set('place_id', placeId) }
  if (type === 'package') {
    params.set('end_date', endDate.toISOString(true))
  }
  const qs = params.toString()

  for (const idGroup of idGroups) {
    const url = `${localePrefix}/parkings/${idGroup}/best-offer?${qs}`
    $.ajax({ url, dataType: 'json' })
      .then(parkBestOffers => dispatch(updateNearbyBestOffers(parkBestOffers)))
    // If an error occurs, we just don't update the offers. That's why we don't catch error here.
  }
}
