const initialState = {
  isLegacySearch: false,
  searchPopularDestinations: null,
  subscriptionEnabled: null,
  supportPath: null,
  topComments: null
}

export default function siteReducer (state = initialState, action) {
  return state
}
