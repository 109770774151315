/* eslint-disable no-useless-escape */

export const FRANCE_COUNTRY_ID = 1
export const SPAIN_COUNTRY_ID = 4
export const BELGIUM_COUNTRY_ID = 2
export const GERMANY_COUNTRY_ID = 6
export const ITALY_COUNTRY_ID = 3
export const SWITZERLAND_COUNTRY_ID = 5
export const PORTUGAL_COUNTRY_ID = 17
export const NETHERLANDS_COUNTRY_ID = 8
export const OTHER_COUNTRY = null

export const LICENSE_PLATE_COUNTRIES = [
  {
    id: FRANCE_COUNTRY_ID,
    key: 'france',
    iso: 'fr'
  },
  {
    id: SPAIN_COUNTRY_ID,
    key: 'spain',
    iso: 'es'
  },
  {
    id: BELGIUM_COUNTRY_ID,
    key: 'belgium',
    iso: 'be'
  },
  {
    id: GERMANY_COUNTRY_ID,
    key: 'germany',
    iso: 'de'
  },
  {
    id: ITALY_COUNTRY_ID,
    key: 'italy',
    iso: 'it'
  },
  {
    id: SWITZERLAND_COUNTRY_ID,
    key: 'switzerland',
    iso: 'ch'
  },
  {
    id: PORTUGAL_COUNTRY_ID,
    key: 'portugal',
    iso: 'pt'
  },
  {
    id: NETHERLANDS_COUNTRY_ID,
    key: 'netherlands',
    iso: 'nl'
  },
  {
    id: OTHER_COUNTRY,
    key: 'other',
    iso: ''
  }
]

export const LICENSE_PLATE_SAMPLES = {
  [FRANCE_COUNTRY_ID]: 'BB-123-CC, NP 123 TV, AA 123LM',
  [BELGIUM_COUNTRY_ID]: '123-BCD, GHJ.123, 1-ABC-123',
  [SPAIN_COUNTRY_ID]: '1234BCD, 3333-GHJ, 4567 VWX',
  [GERMANY_COUNTRY_ID]: 'AB-AB12, ABC AB123, ABCAB1234',
  [ITALY_COUNTRY_ID]: 'AB-123AB, AB 123AB, AB123AB',
  [SWITZERLAND_COUNTRY_ID]: 'ZH-4543, ZH-454, ZH44579',
  [PORTUGAL_COUNTRY_ID]: '23-45-AB, 23-AB-45, AB-56-CD',
  [NETHERLANDS_COUNTRY_ID]: 'AB-99-99, 9CD999, TFH-99-G',
  [OTHER_COUNTRY]: 'BB-123-CC, 3333-GHJ, TFH-99-G'
}

export const COUNTRY_LICENSE_PLATE_REGEX = {
  [FRANCE_COUNTRY_ID]: /^(?!SS)[A-HJ-NP-TV-Z]{2}[\s-]{0,}[0-9]{3}[\s-]{0,}(?!SS)[A-HJ-NP-TV-Z]{2}$/,
  [BELGIUM_COUNTRY_ID]: [
    /^[A-Z]{3}[\s.-]{0,}\d{3}$/,
    /|^\d{3}[\s.-]{0,}[A-Z]{3}$/,
    /|^\d{1}[\s.-]{0,}[A-Z]{3}[\s.-]{0,}\d{3}$/
  ].map(function (regex) { return regex.source }).join(''),
  [SPAIN_COUNTRY_ID]: /^[0-9]{4}[\s-]{0,}[BCDFGHJKLMNPRSTVWXYZ]{3}$/,
  [GERMANY_COUNTRY_ID]: /^[A-ZÄÖÜ]{1,3}[\s-]{0,}[A-ZÄÖÜ]{1,2}[\s-]{0,}\d{1,4}$/,
  [ITALY_COUNTRY_ID]: /^[A-Z]{2}[\s-]{0,}\d{3}[\s-]{0,}[A-Z]{2}$/,
  [SWITZERLAND_COUNTRY_ID]: /^[A-Z]{2}[\s-]{0,}\d{1,6}$/,
  [PORTUGAL_COUNTRY_ID]: [
    /^[0-9]{2}[\s-]{0,}[0-9]{2}[\s-]{0,}[A-Z]{2}$/,
    /|^[0-9]{2}[\s-]{0,}[A-Z]{2}[\s-]{0,}[0-9]{2}$/,
    /|^[A-Z]{2}[\s-]{0,}[0-9]{2}[\s-]{0,}[A-Z]{2}$/
  ].map(function (regex) { return regex.source }).join(''),
  [NETHERLANDS_COUNTRY_ID]: [
    /^(([A-Z]|\d){2}[\s-]{1,}([A-Z]|\d){2}[\s-]{1,}([A-Z]|\d){2})$/,
    /|^(([A-Z]|\d){3}[\s-]{1,}([A-Z]|\d){2}[\s-]{1,}([A-Z]|\d){1})$/,
    /|^(([A-Z]|\d){1}[\s-]{1,}([A-Z]|\d){2}[\s-]{1,}([A-Z]|\d){3})$/,
    /|^(([A-Z]|\d){1}[\s-]{1,}([A-Z]|\d){2}[\s-]{1,}([A-Z]|\d){3})$/,
    /|^(([A-Z]|\d){2}[\s-]{1,}([A-Z]|\d){3}[\s-]{1,}([A-Z]|\d){1})$/,
    /|^(([A-Z]|\d){1}[\s-]{1,}([A-Z]|\d){3}[\s-]{1,}([A-Z]|\d){2})$/,
    /|^(([A-Z]|\d){6})$/
  ].map(function (regex) { return regex.source }).join(''),
  [OTHER_COUNTRY]: /^[A-Z0-9ÄÖÜ]{1}[A-Z0-9ÄÖÜ\s.-]{3,11}$/
}

export const LICENSE_PLATE_NEW_FORMATS = {
  [FRANCE_COUNTRY_ID]: 'AB-123-CD',
  [BELGIUM_COUNTRY_ID]: '1-ABC-234',
  [SPAIN_COUNTRY_ID]: '1234 BCD',
  [GERMANY_COUNTRY_ID]: [],
  [ITALY_COUNTRY_ID]: 'AB 123 CD',
  [PORTUGAL_COUNTRY_ID]: '12-34-AB, 12-AB-34, AB-12-CD',
  [NETHERLANDS_COUNTRY_ID]: '12-34-XX, 12-XXX-3, 1-XXX-23, XX-XX-12, 12-XX-34, 12-XX-XX, XX-12-XX, XX-123-X',
  [SWITZERLAND_COUNTRY_ID]: [],
  [OTHER_COUNTRY]: []
}

export const COUNTRIES_WITH_CUSTOM_TOOLTIP = [GERMANY_COUNTRY_ID, SWITZERLAND_COUNTRY_ID, OTHER_COUNTRY]

export const VEHICLE_MODEL_FORMAT_REGEX = /^[a-zA-Z0-9\s\-+.'ÀÁÂÃÄÅåàáâãäÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖòóôõöÙÚÛÜùúûüÝýÿÇçÑñŠš]+$/
