import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SvgImage from './SvgImage'
import iconArrowDown from '../../../../../app/assets/images/svg-on-react/icon-arrow-down-white.svg'

const modalAnimationDuration = 300 // ms

export default class SlideShow extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string.isRequired })).isRequired
  }

  arrows = { left: null, right: null }

  state = {
    showArrows: { left: false, right: false },
    currentIndex: 0,
    currentImg: null,
    hideImage: true
  }

  componentDidMount () {
    const newShowArrows = {
      left: this.props.images.length > 1 && this.state.currentIndex !== 0,
      right: this.props.images.length > 1 && this.props.images.length !== (this.state.current_index + 1)
    }

    setTimeout(() => this.setState({ ...this.state, showArrows: newShowArrows }), modalAnimationDuration)
  }

  render () {
    const { images } = this.props
    const { url, alt } = images[this.state.currentIndex]

    return (
      <div className='slideshow'>
        <div className='slideshow__current' onClick={e => e.stopPropagation()}>
          <div className='slideshow__arrow-container'>
            {this.renderArrow('left')}
          </div>

          <div className='slideshow__central-container'>
            <div className='slideshow__central-image-container'>
              <img src={url} onLoad={this.handleLoadCurrent} alt={alt} className={`slideshow__image ${(this.state.hideImage ? ' hidden' : '')}`} />
            </div>

            <div className='slideshow__counter-container'>
              <p>{`${this.state.currentIndex + 1} / ${images.length}`}</p>
            </div>
          </div>
          <div className='slideshow__arrow-container'>
            {this.renderArrow('right')}
          </div>
        </div>
      </div>
    )
  }

  renderArrow (direction) {
    const { showArrows } = this.state

    return (
      <button className={`slideshow__arrow slideshow__arrow--${direction}`} disabled={!showArrows[direction]} onClick={direction === 'right' ? this.handleClickNext : this.handleClickPrev} >
        <SvgImage svg={iconArrowDown} />
      </button>
    )
  }

  handleClickNext = () => {
    const nextIndex = this.state.currentIndex + 1

    this.loadImage(nextIndex)
  }

  handleClickPrev = () => {
    const prevIndex = this.state.currentIndex - 1

    this.loadImage(prevIndex)
  }

  handleLoadCurrent = (e) => {
    this.setState({ ...this.state, hideImage: false })
  }

  loadImage = (index) => {
    const { url, alt } = this.props.images[index]

    const newShowArrows = {
      left: this.props.images.length > 1 && index !== 0,
      right: this.props.images.length > 1 && this.props.images.length !== (index + 1)
    }

    this.setState({ ...this.state, currentImg: { ...this.state.currentImg, alt: alt, src: url }, currentIndex: index, showArrows: newShowArrows, hideImage: true })
  }
}
