import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import FormInput from 'Components/Form/FormInput'

import france from 'ClientAssets/images/plates/france.png'
import spain from 'ClientAssets/images/plates/spain.png'
import belgium from 'ClientAssets/images/plates/belgium.png'
import germany from 'ClientAssets/images/plates/germany.png'
import netherlands from 'ClientAssets/images/plates/netherlands.png'
import switzerland from 'ClientAssets/images/plates/switzerland.png'
import portugal from 'ClientAssets/images/plates/portugal.png'
import italy from 'ClientAssets/images/plates/italy.png'

import { LicensePlateHelpModal } from 'Components/VehicleInformation/LicensePlateHelpModal'
import { Dropdown } from 'Components/VehicleInformation/Dropdown'
import {
  FRANCE_COUNTRY_ID,
  BELGIUM_COUNTRY_ID,
  GERMANY_COUNTRY_ID,
  ITALY_COUNTRY_ID,
  NETHERLANDS_COUNTRY_ID,
  PORTUGAL_COUNTRY_ID,
  SPAIN_COUNTRY_ID,
  SWITZERLAND_COUNTRY_ID,
  LICENSE_PLATE_COUNTRIES
} from 'Components/VehicleInformation/constants'

import {
  performValidation,
  genericLicensePlateValidation,
  getErrorFieldName
} from 'Helpers/formValidations'

const COUNTRY_IMAGE_PAIRS = {
  [FRANCE_COUNTRY_ID]: france,
  [SPAIN_COUNTRY_ID]: spain,
  [GERMANY_COUNTRY_ID]: germany,
  [ITALY_COUNTRY_ID]: italy,
  [BELGIUM_COUNTRY_ID]: belgium,
  [NETHERLANDS_COUNTRY_ID]: netherlands,
  [SWITZERLAND_COUNTRY_ID]: switzerland,
  [PORTUGAL_COUNTRY_ID]: portugal
}

export class LicensePlateField extends Component {
  componentDidUpdate (previousProps) {
    const { unknownLicensePlate } = this.props
    const unknownLicensePlateBecameFalse = previousProps.unknownLicensePlate && !unknownLicensePlate

    if (unknownLicensePlateBecameFalse) {
      const { licensePlate, country, handleValidate } = this.props

      if (licensePlate && !unknownLicensePlate && LICENSE_PLATE_COUNTRIES.some(({ id }) => id === country.id)) {
        handleValidate(licensePlate)
      }
    }
  }

  state = {
    [getErrorFieldName('licensePlate')]: null
  }

  validate = () => {
    const { unknownLicensePlate, licensePlate, country } = this.props

    if (!unknownLicensePlate && LICENSE_PLATE_COUNTRIES.some(({ id }) => id === country.id)) {
      return performValidation.call(this, genericLicensePlateValidation, licensePlate, getErrorFieldName('licensePlate'))
    } else {
      return this.setState({ [getErrorFieldName('licensePlate')]: null })
    }
  }

  openLicensePlateHelpModal (event) {
    event.preventDefault()
    $(`#license-plate-help-modal`).modal('show')
  }

  render () {
    const { unknownLicensePlate, country, handleCountryChange, handleSet, licensePlate, handleValidate, paddedModal } = this.props
    const countryImage = COUNTRY_IMAGE_PAIRS[country.id]
    const inputValue = unknownLicensePlate ? '' : licensePlate
    const validatePlate = !unknownLicensePlate && LICENSE_PLATE_COUNTRIES.some(({ id }) => id === country.id)

    return (
      <div className='license-plate-field'>
        <div className='license-plate-field__input-wrapper'>
          <FormInput
            id={'licensePlateField'}
            setCallback={(value) => handleSet('licensePlate', value)}
            inputValue={inputValue || ''}
            errorMessage={unknownLicensePlate ? '' : this.state[getErrorFieldName('licensePlate')]}
            iconLeft={countryImage}
            required
            placeholderKey={'purchase.travel_informations.attributes.license_plate'}
            uppercase
            disabled={unknownLicensePlate}
            withoutValidate={!validatePlate}
            validateCallback={handleValidate}
            highlightValidity
            additionalErrorClass='input-error--reduced-height'
            onFocusSpecial={event => event.target.scrollIntoView({ behavior: 'smooth', block: 'start' })}
          >
            {<input type='text' className='form-control' disabled={unknownLicensePlate} data-automation-id='license-plate-field__text-input' />}
          </FormInput>
        </div>
        <div className='license-plate-field__helper-elements'>
          {!unknownLicensePlate &&
            <div className='license-plate-field__dropdown'>
              <Dropdown country={country} onCountryChange={handleCountryChange} />
            </div>
          }
          {!unknownLicensePlate &&
            <div className='license-plate-field__button-link license-plate-field__button-link--right'
              onClick={this.openLicensePlateHelpModal}>
              <FormattedMessage id={'booking.need_more_help'} />
            </div>
          }
        </div>
        <LicensePlateHelpModal country={country} padded={paddedModal} />
      </div>
    )
  }
}

LicensePlateField.propTypes = {
  unknownLicensePlate: PropTypes.bool,
  country: PropTypes.object,
  handleCountryChange: PropTypes.func,
  handleSet: PropTypes.func,
  licensePlate: PropTypes.string
}
