import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import SvgImage from '../../components/SvgImage'
import iconShuttle from '../../../../../../app/assets/images/svg-on-react/icon-shuttle.svg'
import iconWalking from '../../../../../../app/assets/images/svg-on-react/icon-walking.svg'

const DistanceItem = ({ time, by, distance = 0 }) => {
  const hours = Math.floor(time / 3600)
  const mins = Math.round((time / 60)) % 60
  const km = distance / 1000
  return (
    <li>
      {(by === 'shuttle' || by === 'taxi' || by === 'public_transport') && <SvgImage svg={iconShuttle} />}
      {by === 'walk' && <SvgImage svg={iconWalking} />}
      <FormattedMessage id='pages.park.overview.time' values={{ hours, mins }} />
      {km >= 1 && <span> (<FormattedNumber maximumFractionDigits={1} value={km} />&nbsp;km)</span>}
      {km < 1 && distance > 0 && <span> (<FormattedNumber maximumFractionDigits={1} value={distance} />&nbsp;m)</span>}
    </li>
  )
}

DistanceItem.propTypes = {
  time: PropTypes.number.isRequired,
  by: PropTypes.oneOf(['shuttle, taxi', 'public_transport', 'walk']).isRequired,
  distance: PropTypes.number
}

export default DistanceItem
