export const moveToConfirmationPage = (transactionJson, history, localePrefix, type) => {
  history.push({
    pathname: `${localePrefix}/purchases/${transactionJson.purchase.id}/confirmation`,
    search: `?utm_nooverride=true&success=true${type === 'subscription' ? '&type=subscription' : ''}`,
    state: { purchase: transactionJson.purchase, purchaseState: transactionJson.purchase_state }
  })
}

export const translatedErrorMessage = (error, intl) => {
  const prefix = 'stripe.errors'
  const translations = intl.messages
  if (error.code === 'card_declined') {
    const specificDeclineMessage = translations[`${prefix}.${error.decline_code}`]
    if (specificDeclineMessage) return specificDeclineMessage
  }

  return translations[`${prefix}.${error.code}`] ||
    translations[`${prefix}.${error.type}`] ||
    error.message
}

export const isMultipleEntriesParamSet = (storagePrefix) => {
  const valueFromStorage = sessionStorage.getItem(`${storagePrefix}-isMultipleEntriesOption`)
  if (valueFromStorage !== null) return (valueFromStorage === 'true')

  const urlParams = new URLSearchParams(location.search)
  return urlParams.get('multiple_entries') === 'true'
}
