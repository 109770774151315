import PropTypes from 'prop-types'
import React from 'react'

import Rating from '../common/Rating'
import { smoothScrollTo } from '../NavTabs'
import { FormattedMessage } from 'react-intl'

const RatingLink = ({ rating = 0, ratingCount = 0 }) => (
  <a
    href='#park-reviews'
    className='park-overview__rating link-unstyled'
    onClick={e => {
      e.preventDefault()
      smoothScrollTo('park-reviews')
    }}
  >
    <Rating className='park-overview__rating-stars' rating={rating} />

    {ratingCount > 0 && <span>({ratingCount} {<FormattedMessage id={'pages.park.overview.comments'} />})</span>}
  </a>
)

RatingLink.propTypes = {
  rating: PropTypes.number,
  ratingCount: PropTypes.number
}

export default RatingLink
