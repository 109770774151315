import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import SvgImage from '../../components/SvgImage'

import { datalayerPushGTM, datalayerPush } from '../../helpers/application'
import { localePrefix } from '../../helpers/authentication'

import iconGoogle from '../../../../../../app/assets/images/svg-on-react/icon-logo-google.svg'
import iconFacebook from '../../../../../../app/assets/images/svg-on-react/icon-logo-facebook.svg'

class Provider extends React.Component {
  static propTypes = {
    providerText: PropTypes.string.isRequired,
    authenticationType: PropTypes.string.isRequired
  }

  state = {
    providerError: false,
    providerAccountExist: false
  }

  handleProviderResponse = (provider, response) => {
    const prefix = localePrefix.call(this)
    const { authenticationType } = this.props
    if (response && !response.error && response.accessToken !== undefined) {
      const formatQueryLocaleParams = (prefix && prefix !== '/') ? `?locale=${prefix.replace('/', '')}` : ''
      let url = `/users/auth/${provider}/callback${formatQueryLocaleParams}`

      let options = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
      }

      if (provider === 'google') {
        url = `${prefix}/users/auth/${provider}/callback`
        options = {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${response.accessToken}`,
            'access_token': `${response.accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
          body: JSON.stringify(response.getAuthResponse())
        }
      }

      fetch(url, options)
        .then((response) => {
          return response.json()
        }).then((json) => {
          if (json.status === 500) {
            datalayerPushGTM('form-tracking', 'Users', `${authenticationType}/error/${provider}`, `erreur ${json.status} : ${json.statusText}`)
            this.setState({ providerError: true, providerAccountExist: false })
          } else if (json.status === 'not_created') {
            datalayerPushGTM('form-tracking', 'Users', `signup/error/${provider}`, json.errors)
            this.setState({ providerError: true, providerAccountExist: false })
          } else if (json.status === 'unknown') {
            datalayerPushGTM('form-tracking', 'Users', `signup/success/${provider}`, json.user_id)

            datalayerPush({
              event: 'sign_up',
              ep_method: provider,
              up_language: (window._oneparkDatas.up_language || null),
              up_locale: (window._oneparkDatas.up_locale || null),
              up_user_id: json.google_analytics_user_id
            })

            window.location = `${prefix}/registration-complete?origin=${this.props.source}`
          } else if (json.status === 'created') {
            datalayerPushGTM('form-tracking', 'Users', `signup/success/${provider}`, json.user_id)

            datalayerPush({
              event: 'sign_up',
              ep_method: provider,
              up_language: (window._oneparkDatas.up_language || null),
              up_locale: (window._oneparkDatas.up_locale || null),
              up_user_id: json.google_analytics_user_id
            })

            window.location = `${prefix}/member/billing-informations?origin=${this.props.source}`
          } else if (json.status === 'other_provider') {
            datalayerPushGTM('form-tracking', 'Users', `${authenticationType}/error/${provider}`, `erreur ${json.status}`)
            this.setState({ providerAccountExist: json.email, providerError: false })
          } else {
            datalayerPushGTM('form-tracking', 'Users', `login/success/${provider}`, json.user_id)

            const pushParams = {
              event: 'login',
              ep_method: provider,
              up_language: (window._oneparkDatas.up_language || null),
              up_locale: (window._oneparkDatas.up_locale || null),
              up_user_id: json.google_analytics_user_id
            }
            datalayerPush(pushParams)

            window.location = json.redirect_to
          }
        })
    } else {
      if (response.error !== 'popup_closed_by_user' && response.status !== undefined) {
        this.setState({ providerError: true, providerAccountExist: false })
        datalayerPushGTM('form-tracking', 'Users', `${authenticationType}/error/${provider}`, 'popup_closed_by_user')
      } else {
        datalayerPushGTM('form-tracking', 'Users', `${authenticationType}/error/${provider}`, `${response.error} : ${response.details}`)
      }
    }
  }

  handleClickProvider = (provider) => {
    const { authenticationType } = this.props
    datalayerPushGTM('form-tracking', 'Users', `${authenticationType}/click/${provider}`, null)
  }

  renderOrDivider = () => {
    return (
      <div className='provider__or-divider row'>
        <div className='col-xs-5 p-0 divider' />
        <div className='col-xs-2 text-center or-text text-small'>
          <FormattedMessage id='authentication.header.or' />
        </div>
        <div className='col-xs-5 p-0 divider' />
      </div>
    )
  }

  render () {
    const { providerText } = this.props
    const { currentSite } = this.props.railsContext
    const { providerAccountExist, providerError } = this.state

    return (
      <div className='provider'>
        { currentSite.display_facebook_connect &&
          <div>
            <div className='provider__link'>

              {this.renderOrDivider()}

              <div className='provider__link__cta'>
                <FacebookLogin
                  appId={process.env.FACEBOOK_APP_ID}
                  autoLoad={false}
                  scope='email,public_profile'
                  fields='first_name,last_name,gender,email,birthday'
                  textButton={
                    <div>
                      <SvgImage svg={iconFacebook} />
                      <FormattedMessage id={providerText} values={{ provider: 'Facebook' }} />
                    </div>}
                  cssClass='provider__link__facebook'
                  callback={(response) => this.handleProviderResponse('facebook', response)}
                  onClick={() => this.handleClickProvider('facebook')}
                  cookie
                  xfbml
                />

                <span>
                  <GoogleLogin
                    clientId={process.env.GOOGLE_OAUTH_APP_ID}
                    accessType='offline'
                    className='provider__link__google'
                    icon={false}
                    buttonText={
                      <div>
                        <SvgImage svg={iconGoogle} />
                        <FormattedMessage id={providerText} values={{ provider: 'Google' }} />
                      </div>}
                    onSuccess={(response) => this.handleProviderResponse('google', response)}
                    onFailure={(response) => this.handleProviderResponse('google', response)}
                    onRequest={() => this.handleClickProvider('google')}
                  />
                </span>
              </div>
            </div>

            { providerAccountExist &&
              <p className='text-center'><small className='text--orange' role='alert'>
                <FormattedMessage id='authentication.errors.user.provider.other_exist' values={{ email: providerAccountExist }} />
              </small></p>
            }

            { providerError &&
              <p className='text-center'><small className='text--orange' role='alert'>
                <FormattedMessage id='authentication.errors.user.provider.error' />
              </small></p>
            }

          </div>
        }
      </div>
    )
  }
}

export default Provider
