import React from 'react'
import { FormattedMessage } from 'react-intl'

import EditorialHtml from '../../components/EditorialHtml'
import TranslatedByGoogle from '../../components/TranslatedByGoogle'

const Information = ({ park }) => {
  if (!park.htmlDescription) {
    return <div />
  }

  return (
    <div>
      <h3>
        <FormattedMessage id='pages.park.description.information.title' />
        {park.editorialContentTranslatedByGoogle && <aside><TranslatedByGoogle /></aside>}
      </h3>

      <EditorialHtml html={park.htmlDescription} />
    </div>
  )
}

export default Information
