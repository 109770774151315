import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Select from 'react-select'
import { datalayerPushGTM } from '../../helpers/application'

import FormInput from 'Components/Form/FormInput'
import {
  performValidation,
  genericDiscountCodeValidation,
  genericPartnerValidation,
  genericPartnerNumberValidation,
  getErrorFieldName
} from '../../helpers/formValidations'
import { isMultipleEntriesParamSet } from 'Helpers/purchaseTunnel'

import * as PurchaseActions from 'Actions/PurchaseActions'

class DiscountPartnerForm extends Component {
  state = {
    discountCode: this.props.discountCode ? this.props.discountCode.code : '',
    partner: '',
    partnerNumber: '',
    loading: false
  }

  handleSet = (name, value) => this.setState({ [name]: value })

  validateField = (name, value) => performValidation.call(this, validatorByName[name], value, getErrorFieldName(name))

  handleCheckDiscountCode = async () => {
    this.setState({ loading: true })

    const { discountCode, partnerNumber } = this.state
    const { offerBuilder, railsContext: { localePrefix } } = this.props
    const validation = this.validateField('discountCode', discountCode)
    const multipleEntriesParam = this.props.isMultipleEntriesOption ? '&multiple_entries=true' : ''

    if (validation && !partnerNumber) {
      const res = await fetch(`${localePrefix}/check_discount_code`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({
          discount_code: discountCode,
          user_duration: offerBuilder.userDuration,
          offer_id: offerBuilder.id
        })
      })

      const json = await res.json()

      if (json.error) {
        this.setState({
          partnerError: null,
          partnerNumberError: null,
          discountCodeError: `purchase.discount_partner.errors.${json.error}`,
          loading: false
        })
        datalayerPushGTM('form-tracking', 'Forms', 'Discount-informations', `errors.${json.error}`)
      } else {
        const action = (offerBuilder) => {
          this.props.actions.updateDiscountCodeAndOfferBuilder({ ...json.discount }, { ...offerBuilder })
        }

        this.handleUpdateOfferBuilder(
          `discount_code=${json.discount.code}${multipleEntriesParam}`,
          action
        ).then(this.props.validateCallback).then(() => {
          this.setState({ loading: false })
        })
        datalayerPushGTM('form-tracking', 'Users', 'discount-informations/success', offerBuilder.id)
      }
    } else if (partnerNumber) {
      this.setState({
        partnerError: null,
        partnerNumberError: null,
        discountCodeError: 'purchase.discount_partner.errors.dont_cumulate_discount',
        loading: false
      })
      datalayerPushGTM('form-tracking', 'Forms', 'Discount-informations', `errors.dont_cumulate_discount`)
    }
  }

  handleCheckPartnerNumber = async () => {
    this.setState({ loading: true })

    const { discountCode, partner, partnerNumber } = this.state
    const { offerBuilder, railsContext: { localePrefix } } = this.props

    const partnerValidation = this.validateField('partner', partner)
    const partnerNumberValidation = this.validateField('partnerNumber', partnerNumber)

    if (partnerValidation && partnerNumberValidation && !discountCode) {
      const res = await fetch(`${localePrefix}/partner_loyalty_programs/${partner}/check_partner_loyalty_code`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({
          partner_loyalty_number: partnerNumber.replace(/\W/g, '')
        })
      })

      const json = await res.json()

      if (json.error) {
        this.setState({
          discountCodeError: null,
          partnerNumberError: `purchase.discount_partner.errors.${json.error}`,
          loading: false
        })
        datalayerPushGTM('form-tracking', 'Forms', 'Partnership-informations', `errors.${json.error}`)
      } else {
        const action = (offerBuilder) => {
          this.props.actions.updatePartnerCardAndOfferBuilder({ ...json.partner }, { ...offerBuilder })
        }

        this.handleUpdateOfferBuilder(`partner=${json.partner.id}&partner_loyalty_number=${partnerNumber}`, action)
          .then(this.props.validateCallback)
          .then(() => {
            this.setState({ loading: false })
          })

        datalayerPushGTM('form-tracking', 'Users', 'partnership-informations/success', offerBuilder.id)
      }
    } else if (discountCode) {
      this.setState({
        discountCodeError: null,
        partnerNumberError: 'purchase.discount_partner.errors.dont_cumulate_discount',
        loading: false
      })
      datalayerPushGTM('form-tracking', 'Forms', 'Partnership-informations', `errors.dont_cumulate_discount`)
    }
  }

  handleEnterPartnerCardNumber = (name, value) => {
    if (this.getCurrentPartner() === null) { return this.handleSet(name, '') }
    let formattedValue
    const re = new RegExp(this.getCurrentPartner()['validation_regex'])
    if (value && re.test(value)) {
      // Split input string into chunks by 4 symbols, then insert whitespaces between them
      formattedValue = value.match(new RegExp('.{1,4}', 'g')).join(' ')
    } else {
      // Remove all non-word or non-numeric symbols if user not finished editing partner number yet
      const guardRegexp = this.getCurrentPartner()['input_type'] === 'number' ? /\D/g : /\W/g
      formattedValue = value.replace(guardRegexp, '')
    }
    this.handleSet(name, formattedValue)
  };

  handleUpdateOfferBuilder = async (params, action) => {
    const { railsContext: { localePrefix }, offerBuilder } = this.props

    const res = await fetch(`${localePrefix}/purchases/summary?offer_id=${offerBuilder.id}&${params}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    const json = await res.json()

    await action(json.offerBuilder)
  }

  getCurrentPartner = () => {
    return this.props.partnersOptions.find((e) => { return e['id'] === this.state.partner }) || null
  }

  render () {
    const { discountCode, partner, partnerNumber, loading } = this.state
    const { partnersOptions } = this.props

    return (
      <div className='discount-partner-form'>
        <div className='discount-partner-form__block'>
          <h4><FormattedMessage id='purchase.discount_partner.discount_title' /></h4>
          <div className='discount-partner-form__control'>
            <FormInput
              id='discountField'
              setCallback={(value) => this.handleSet('discountCode', value)}
              withoutValidate
              inputValue={discountCode}
              errorMessage={this.state[getErrorFieldName('discountCode')]}
              placeholderKey='purchase.discount_partner.label.discount'
            >
              {<input type='text' className='form-control' />}
            </FormInput>
            <button data-automation-id='discount-partner-form__promo-code-button' className='discount-partner-form__button btn btn-secondary form-group' onClick={this.handleCheckDiscountCode} disabled={loading}>
              <FormattedMessage id='purchase.discount_partner.ok' />
            </button>
          </div>
        </div>
        <div className='discount-partner-form__block'>
          <h4><FormattedMessage id='purchase.discount_partner.partner_title' /></h4>
          <FormInput
            id='partnerField'
            type='select-op'
            setCallback={(value) => this.handleSet('partner', value)}
            withoutValidate
            inputValue={partner}
            errorMessage={this.state[getErrorFieldName('partner')]}
            placeholderKey='purchase.discount_partner.label.partner'
          >
            <Select
              className='select-op'
              clearable={false}
              searchable={false}
              simpleValue
              placeholder=''
              options={partnersOptions.map((partner, _index) => ({ label: partner['name'], value: partner['id'] }))}
            />
          </FormInput>
          <div className='discount-partner-form__control'>
            <FormInput
              id='partnerNumberField'
              setCallback={(value) => this.handleEnterPartnerCardNumber('partnerNumber', value)}
              withoutValidate
              inputValue={partnerNumber}
              errorMessage={this.state[getErrorFieldName('partnerNumber')]}
              placeholderKey='purchase.discount_partner.label.partner_loyalty_number'
              placeholder={this.getCurrentPartner() ? this.getCurrentPartner()['placeholder'] : null}
            >
              {<input type='text' className='form-control' />}
            </FormInput>
            <button data-automation-id='discount-partner-form__loyalty-card-button' className='discount-partner-form__button btn btn-secondary form-group' onClick={this.handleCheckPartnerNumber} disabled={loading}>
              <FormattedMessage id='purchase.discount_partner.ok' />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const validatorByName = {
  discountCode: genericDiscountCodeValidation,
  partner: genericPartnerValidation,
  partnerNumber: genericPartnerNumberValidation
}

const mapStateToProps = ({
  purchase: { discountCode, partnersOptions, offer: { offerBuilder } }, railsContext
}) => ({
  discountCode, offerBuilder, partnersOptions, railsContext
})

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...PurchaseActions }, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(DiscountPartnerForm)
