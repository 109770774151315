import classNames from 'classnames'
import React, { PureComponent } from 'react'

import searchOffIcon from '../../../../assets/images/icon-search-off-mm.svg'
import searchOnIcon from '../../../../assets/images/icon-search-on-mm.svg'
import SvgImage from '../components/SvgImage'
import { MegaMenuHelper } from '../helpers/MegaMenuHelper'

class MegaMenu extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      openedMenuTitle: null,
    }
  }

  componentDidUpdate() {
    const { openedMenuTitle } = this.state

    if (openedMenuTitle) {
      $('main').css('opacity', 0.5);
      $('footer').css('opacity', 0.5);
    } else {
      $('main').css('opacity', '');
      $('footer').css('opacity', '');
    }
  }

  render() {
    const { searchBarActive, isSearchMenuItemHidden, openSearchCallback, megaMenuContent } = this.props
    const { searchIconActive } = this.state
    const adaptedMegaMenuContent = MegaMenuHelper.adaptMegaMenuContentForDesktop(megaMenuContent)

    const searchButtonClasses = classNames('mega-menu__item', 'mega-menu__search-icon', {
      'mega-menu__search-icon--active': searchBarActive,
      'hide': isSearchMenuItemHidden
    })

    return (
      <div className='mega-menu' onMouseLeave={() => this.setState({ ...this.state, openedMenuTitle: null })}>
        <div className='mega-menu__items'>
          <div
            className={searchButtonClasses}
            onClick={openSearchCallback}
            onMouseEnter={() => this.setState({ ...this.state, searchIconActive: true })}
            onMouseLeave={() => this.setState({ ...this.state, searchIconActive: false })}
          >
            {searchBarActive || searchIconActive
              ? <SvgImage svg={searchOnIcon} />
              : <SvgImage svg={searchOffIcon} />
            }
          </div>

          {
            adaptedMegaMenuContent.map(content => (
              <div className={this.menuItemClasses(content.title)}
                key={content.title}
                onClick={() => this.onMenuItemClick(content.title)}
              >
                <span> {content.title} </span>
              </div>
            ))
          }

        </div>

        {
          adaptedMegaMenuContent.map(content => (
            <div className={`mega-menu__links ${this.shouldShowMenuContent(content.title) ? '' : 'mega-menu__links--hidden'}`}
              key={content.title}
            >
              <>
                {
                  content.sections.map((section, sectionIndex) => (
                    <div className='mega-menu__links-section' key={sectionIndex}>
                      {
                        section.linkGroups.map((linkGroup, linkGroupIndex) => (
                          <div className='mega-menu__links-group' key={linkGroupIndex}>
                            <div className='mega-menu__links-group-title'>
                              {linkGroup.title}
                            </div>

                            {
                              linkGroup.links.map((link, linkIndex) => (
                                <div className='mega-menu__link' key={linkIndex}>
                                  <a href={link.url}> {link.label} </a>
                                </div>
                              ))
                            }

                            {linkGroup.seeMoreLink &&
                              <div className='mega-menu__link mega-menu__link--closure'>
                                <a href={linkGroup.seeMoreLink.url}> {linkGroup.seeMoreLink.label} </a>
                              </div>
                            }
                          </div>
                        ))
                      }
                    </div>
                  ))
                }

                {content.seeMoreLink &&
                  <div className='mega-menu__link mega-menu__link--closure mega-menu__see-more-link'>
                    <a href={content.seeMoreLink.url}> {content.seeMoreLink.label} </a>
                  </div>
                }
              </>
            </div>
          ))
        }
      </ div>
    )
  }

  onMenuItemClick(menuTitle) {
    const openedMenuTitle = this.state.openedMenuTitle === menuTitle ? null : menuTitle
    this.setState({ ...this.state, openedMenuTitle })
  }

  menuItemClasses(menuTitle) {
    const { openedMenuTitle } = this.state

    const linkGroupsClasses = classNames('mega-menu__item', {
      'mega-menu__item--active': openedMenuTitle === menuTitle,
    })

    return linkGroupsClasses
  }

  shouldShowMenuContent(menuTitle) {
    const { openedMenuTitle } = this.state

    return openedMenuTitle === menuTitle
  }
}

export default MegaMenu
