import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  defaultPaymentMethods,
  paymentMethodsByCountry,
  paymentIconByName
} from '../../constants/payment'
import SvgImage from '../SvgImage'
import iconSecuredPayment from 'Assets/images/svg-on-react/icon-secure-payment.svg'
import iconFreeCancel from 'Assets/images/svg-on-react/icon-free-cancel.svg'
import iconNoCommitment from 'Assets/images/svg-on-react/icon-no-commitment.svg'

const ReassurancePanel = ({ country, isSubscription }) => {
  const availablesPaymentMethod = paymentMethodsByCountry[country] || defaultPaymentMethods

  return (
    <div className='reassurance-panel panel panel-default'>
      <div className='panel-body'>
        <ul>
          {isSubscription &&
            <li className='p-bottom-10'>
              <SvgImage svg={iconNoCommitment} />
              <FormattedMessage id='pages.park.reassurance.commitment' />
            </li>
          }
          <li className='p-bottom-10'>
            <SvgImage svg={iconFreeCancel} />
            <FormattedMessage id='pages.park.reassurance.free_cancel' />
          </li>
          <li className='p-bottom-10'>
            <SvgImage svg={iconSecuredPayment} />
            <FormattedMessage id='pages.park.reassurance.secured_payment' />
            <div>
              {availablesPaymentMethod.map(name =>
                <div className='reassurance-panel__cards-icon'><SvgImage key={name} svg={paymentIconByName[name].src} /></div>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ReassurancePanel
