import React from 'react'

import iconLoaderColor from 'Assets/images/svg-on-react/icon-loader-color.svg'
import iconLoaderWhite from 'Assets/images/svg-on-react/icon-loader-white.svg'
import iconLoaderGreen from 'Assets/images/svg-on-react/icon-loader-green.svg'
import iconLoaderBlue from 'Assets/images/svg-on-react/icon-loader-blue.svg'

const Loader = ({ overlay, ...imageProps }) =>
  overlay ? (
    <div className='svg-loader-overlay'>
      <Image {...imageProps} />
    </div>
  ) : (
    <Image {...imageProps} />
  )

const Image = ({ grey, white, green, blue }) => {
  const imageClass = `svg-loader ${grey ? 'svg-loader--grey' : ''}`
  let imageSource = iconLoaderColor

  if (white) {
    imageSource = iconLoaderWhite
  } else if (green) {
    imageSource = iconLoaderGreen
  } else if (blue) {
    imageSource = iconLoaderBlue
  }

  return (
    <img className={imageClass} src={imageSource} />
  )
}

export default Loader
