/*
* Toggle visible text for password
 */
export function togglePasswordField (pwdFieldType, pwdIconClass) {
  this.setState({
    [pwdFieldType]: this.state[[pwdFieldType]] === 'password' ? 'text' : 'password',
    [pwdIconClass]: this.state[[pwdFieldType]] === 'password' ? 'icon-op-pwd-visible' : 'icon-op-pwd-hidden'
  })
}

export function scrolledToFirstInvalid (fieldValidations, footer = null) {
  const firstInvalidField = fieldValidations.find(([_name, valid]) => !valid)
  if (!firstInvalidField) return false

  let name = firstInvalidField[0]
  if (name === 'vehicle_model') name = 'vehicleModel'
  if (name === 'license_plate') name = 'licensePlate'

  const badElement =
    document.getElementById(`${name}Field`) || // license plate
    document.getElementById(`${name}Field_element`) || // text field or dropdown list in travelinfo
    document.getElementById(`${name}FieldTime_element`) // or datetime in travelinfo

  const scrollFunction = footer ? (element) => scrollToElementWithFooterAdjustment(element, footer) : scrollToElementIfNotVisible

  if (badElement) scrollFunction(badElement)
  return true
}

export function canEditTravelInfoInitial (travelInfos, mandatoryInfos) {
  if (travelInfos.vehicle_model === null) return true
  if (travelInfos.license_plate === null && !travelInfos.unknown_license_plate) return true
  return mandatoryInfos.some((field) => travelInfos[field.name] === undefined)
}

export function scrollToElementIfNotVisible (element) {
  const rectangle = element.getBoundingClientRect()
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth

  const isOutOfView = (
    rectangle.top < 0 || rectangle.top > viewportHeight ||
    rectangle.right < 0 || rectangle.left > viewportWidth
  )

  if (isOutOfView) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    return true
  }
}

export function scrollToElementWithFooterAdjustment (element, footerElement) {
  if (scrollToElementIfNotVisible(element)) { return }

  const elementRectangle = element.getBoundingClientRect()

  const footerHeight = footerElement.offsetHeight

  const elementIsCoveredByFooter = (elementRectangle.bottom > window.innerHeight - footerHeight)

  if (elementIsCoveredByFooter) { element.scrollIntoView({ behavior: 'smooth', block: 'center' }) }
}
