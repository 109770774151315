import React, { Component } from 'react'
import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js'
import { FormattedMessage, injectIntl } from 'react-intl'
import Loader from '../components/Loader'
import classNames from 'classnames'
import { convertBillingIdentityToErrorState, createPendingBillingIdentity, USER_ALREADY_HAS_PENDING_PAYMENT_METHOD_ERROR } from 'Helpers/paymentMethodsHelper'

class StripeForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      gatewayError: null,
      invisible: true,
      buttonNameId: 'authentication.continue_booking'
    }
  }

  displayCardError (error) {
    if (error.type === 'validation_error') {
      // the form fields should have the error messages below them
      this.setState({ loading: false, stripeToken: null, gatewayError: null })
      this.props.threedsErrorSetter(false)
    } else if (error.code === 'setup_intent_authentication_failure') {
      this.setState({ loading: false, stripeToken: null, gatewayError: null })
      this.props.threedsErrorSetter(true)
    } else {
      let errorMessageToDisplay
      switch (error.message) {
        case USER_ALREADY_HAS_PENDING_PAYMENT_METHOD_ERROR:
          errorMessageToDisplay = this.props.intl.formatMessage({ id: 'pages.credit_card.error_messages.multiple_payment_methods' })
          break
        default:
          errorMessageToDisplay = error.message
      }

      this.setState({ loading: false, stripeToken: null, gatewayError: errorMessageToDisplay })
      this.props.threedsErrorSetter(false)
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    // We need to clear external error message to prevent combining with possible stripe error messages
    if (this.props.clearExternalErrorMessage) this.props.clearExternalErrorMessage()

    const { stripe, elements } = this.props
    if (!elements) { return }

    try {
      this.setState({ loading: true })
      const urlParams = new URLSearchParams(window.location.search)
      const country = window._oneparkDatas.user_country
      const zipcode = window._oneparkDatas.user_zipcode
      const pendingBillingIdentity = await createPendingBillingIdentity()
      if (pendingBillingIdentity && pendingBillingIdentity.id) {
        const urlParamsForReturnUrlAsObject = {
          origin: window.location.pathname,
          action: urlParams.get('action'),
          pending_billing_identity_id: pendingBillingIdentity.id
        }
        if (urlParams.get('offer_id')) urlParamsForReturnUrlAsObject.offer_id = urlParams.get('offer_id')

        const confirmation = await stripe.confirmSetup({
          elements,
          confirmParams: {
            payment_method_data: { billing_details: { address: { country, postal_code: zipcode } } },
            return_url: `${window.location.origin}/payment/setup-intents/callback` + '?' +
              new URLSearchParams(urlParamsForReturnUrlAsObject).toString()
          }
        })
        // for example, when the card does not have sufficient funds
        if (confirmation.error) {
          await convertBillingIdentityToErrorState(pendingBillingIdentity.id)
          this.displayCardError(confirmation.error)
        } else {
          if (this.props.turnoffWarning) this.props.turnoffWarning()
        }
      }

      // ELSE should not happen, it should always redirect:
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    } catch (error) {
      this.displayCardError(error)
    }
  }

  componentDidMount () {
    if (!this.isCallingFromProfilePage()) {
      const innerTextSpan = document.getElementById('payment-details-submit-button-inner-text').childNodes[0]
      const outerButton = document.getElementById('payment-details-submit-button')
      if (outerButton.offsetWidth - innerTextSpan.offsetWidth < 40) {
        this.setState({ buttonNameId: 'authentication.continue_booking_short' })
      }
    }
  }

  isCallingFromProfilePage = () => this.props.callingPage === 'profile'

  renderActionsButtons = () => {
    const { loading, buttonNameId } = this.state
    const { onCancel, tabletPortraitExclusiveStyles, callingPage } = this.props

    const buttonsContainerClassName = classNames('payment-info-panel__buttons', {
      'payment-info-panel__buttons--tablet-portrait-exclusive': tabletPortraitExclusiveStyles,
      'payment-info-panel__buttons--profile-page': this.isCallingFromProfilePage()
    })

    const submitButtonClassName = classNames({
      'btn btn-primary': !this.isCallingFromProfilePage()
    }, 'payment-info-panel__submit-button', {
      'payment-info-panel__submit-button--profile-page': this.isCallingFromProfilePage()
    })

    const submitButtonInnerDivClassName = classNames({
      'col-xs-12 col-sm-12': !this.isCallingFromProfilePage()
    }, 'payment-details-submit-button')

    return (
      <div className={buttonsContainerClassName}>
        <button
          type='submit'
          className={submitButtonClassName}
          disabled={loading}
          id='payment-details-submit-button'
          data-automation-id='payment-details__submit-button'
        >
          {loading && <Loader overlay white />}
          <span className={loading ? 'invisible' : ''}>
            <div
              className={submitButtonInnerDivClassName}
              id='payment-details-submit-button-inner-text'
              data-automation-id='payment-details__submit-button__inner-text'
            >
              <FormattedMessage id={buttonNameId} />
            </div>
          </span>
        </button>
        {!this.isCallingFromProfilePage() && onCancel && (
          <button className='btn btn-secondary-alt' type='button' onClick={onCancel}>
            <FormattedMessage id={callingPage === 'purchase' ? 'actions.cancel' : 'authentication.later'} />
          </button>
        )}
      </div>
    )
  }

  show3dsError = (event) => {
    event.preventDefault()
    $('#error-on-register-3d-secure').modal('show')
  }

  render () {
    const { gatewayError, invisible } = this.state
    const { handleElementReady, handleStripeChange, localePrefix, threedsError, externalErrorMessage } = this.props

    const legalNoticeClassName = classNames('payment-info-panel__legal-notice', {
      'payment-info-panel__legal-notice--profile-page': this.isCallingFromProfilePage()
    })

    return (
      <>
        <form onSubmit={this.handleSubmit} className={invisible ? 'invisible' : ''}>
          <PaymentElement
            options={{ fields: { billingDetails: { address: { country: 'never', postalCode: 'never' } } }, terms: { card: 'never' } }}
            onReady={() => setTimeout(() => {
              handleElementReady()
              this.setState({ invisible: false })
            }, 1000)}
            onChange={(event) => { if (handleStripeChange) { handleStripeChange(event.complete) } }} />

          <div className={legalNoticeClassName}>
            <FormattedMessage id='stripe.legal_terms_before_link' />
            <a href={`${localePrefix}/privacy-policy`} >
              <FormattedMessage id='stripe.legal_terma_link' />
            </a>
          </div>

          <div className='payment-info-panel__error-message payment-info-panel__error-message--orange'
            data-automation-id='payment-info-panel__error-message'>
            {externalErrorMessage && (
              <div>{externalErrorMessage}</div>
            )}
            {gatewayError &&
              <div>
                {gatewayError}
              </div>}
            {threedsError &&
              <div>
                <FormattedMessage id='authentication.registration.payment_info.3ds.error_message.before_link' />
                <a className='secure-3d-link' onClick={this.show3dsError}>
                  <FormattedMessage id='authentication.registration.payment_info.3ds.error_message.link_title' />
                </a>
              </div>}
          </div>

          {this.renderActionsButtons()}
        </form>
      </>
    )
  }
}

const IntlInjectedStripeForm = injectIntl(StripeForm)

export default function InjectedStripeForm (props) {
  const { stripe, elements, ...otherProps } = props
  // The stripe and elements are promises, the special format below is for the Class components.
  // Without extracting otherProps the onCancel is not passed down to the StripeForm.
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <IntlInjectedStripeForm stripe={stripe} elements={elements} {...otherProps} />
      )}
    </ElementsConsumer>
  )
}
