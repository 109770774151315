import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import BookingPanelSearchTabs from './BookingPanelSearchTabs'
import { BestOfferAndAmount } from './BestOfferAndAmount'
import AvailabilityAlert from '../AvailabilityAlert'
import ModalOp from '../../components/ModalOp'
import OpI18n from '../../components/OpI18n'
import Loader from '../../components/Loader'
import TooltipComponent from '../../components/TooltipComponent'
import NoOfferReason, { hasNoOfferReason, noOfferReasonMessage } from '../../components/NoOfferReason'
import { datalayerPushGTM, setCookieValue } from 'Helpers/application'
import { renderLoginModal } from 'Helpers/authentication'
import { PURCHASE_URL_COOKIE } from '../../constants/application'

import * as BookingDataActions from '../../../../actions/BookingDataActions'
import * as NearbyActions from '../../../../actions/NearbyActions'
import { parameterize } from '../../../../libs/string'
import { clearPreviousEcommerceObject, gaUserTags, amountWithoutTax, roundedToCents } from '../../../../libs/google_analytics_helpers'

const FEW_SPACES = 20

class BookingPanel extends Component {
  state = {
    isMultipleEntriesOption: false,
    offerAmount: 0
  }

  componentDidMount () {
    this.checkBookingError()
  }

  gaPlaceInfo (place) {
    if (place === undefined || Object.keys(place).length === 0) return {}
    return {
      item_category: place.categories[0],
      item_category2: place.categories[1],
      item_category3: place.categories[2],
      item_category4: place.categories[3],
      item_list_id: place.id.toString(),
      item_list_name: place.name
    }
  }

  gaQuantity () {
    const { bestOffer } = this.props
    return bestOffer ? bestOffer.gaQuantity : 1
  }

  amountGaComponents (vatPercent) {
    const amounts = { currency: 'EUR' }
    const { bestOffer } = this.props
    if (!bestOffer) return amounts

    return {
      ...amounts,
      value: amountWithoutTax(bestOffer.amount.totalWithoutOptions, vatPercent),
      sf_pa: amountWithoutTax(bestOffer.amount.serviceFee, vatPercent)
      // note that it may end up with price != value + sf_pa because of rounding but it's same as in the Purchase object now.
    }
  }

  gaOfferType () {
    const { park, bestOffer } = this.props
    const offer = bestOffer || park.bestOffer
    if (offer) return { item_type: offer.offerType }

    const urlParams = new URLSearchParams(window.location.search)
    return { item_type: urlParams.get('type') || 'package' }
  }

  pushGaEvent () {
    try {
      const { park } = this.props
      const placeInfo = this.gaPlaceInfo(this.props.place)
      // eslint-disable-next-line camelcase
      const { item_list_id, item_list_name } = placeInfo
      const coverage = (park.services.includes('covered') ? 'covered' : 'uncovered')
      const vatPercent = parseFloat(park.vat)
      const price = amountWithoutTax(this.props.bestOffer.amount.bookingAmount, vatPercent)
      const gaEvent = {
        event: 'view_item',
        ...gaUserTags(),
        ecommerce: {
          item_list_id,
          item_list_name,
          ...this.amountGaComponents(vatPercent),
          items: [{
            item_id: park.id.toString(),
            item_name: parameterize(park.name),
            ...this.gaOfferType(),
            item_brand: 'onepark',
            item_variant: coverage,
            price,
            quantity: 1,
            quantity_hours: this.gaQuantity(),
            ...placeInfo
          }]
        }
      }
      clearPreviousEcommerceObject()
      window.dataLayer.push(gaEvent)
    } catch (error) {
      if (!(error instanceof TypeError)) { // Network errors
        Sentry.captureException(error)
      }
    }
  }

  componentDidUpdate (previousProps) {
    const { queryState } = this.props
    if (previousProps.queryState === 'pending' && queryState === 'success') {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isMultipleEntriesOption: false,
        offerAmount: 0
      })
      this.pushGaEvent()
    }
  }

  checkBookingError = () => {
    const { beginDate, endDate, intl, noOfferReason, parkState, type } = this.props
    if (hasNoOfferReason(parkState)) {
      const value = {
        url: window.location.href,
        begin_date: beginDate ? beginDate.toISOString() : null,
        end_date: endDate ? endDate.toISOString() : null,
        type
      }
      const error = noOfferReasonMessage(intl, parkState, noOfferReason, type === 'subscription')
      datalayerPushGTM('form-tracking', 'Forms', 'Parking', error, value)
    }
  }

  handleNotModalClick = (e) => {
    e.preventDefault()
    window.location.href = this.getPurchaseUrl()
  }

  handleModalClick = (e) => {
    e.preventDefault()
    setCookieValue(PURCHASE_URL_COOKIE, this.getPurchaseUrl(), 10)
    renderLoginModal(this.props.railsContext, 'booking')
    $('#react-booking-modal').modal('hide')
  }

  handleAvailabilityAlertClick = (e) => {
    const { railsContext, park } = this.props
    ReactDOM.render(
      <OpI18n {...railsContext}>
        <ModalOp id='availability-alert-modal'>
          <AvailabilityAlert railsContext={railsContext} isPage={false} parkId={park.id} parkName={park.name} />
        </ModalOp>
      </OpI18n>,
      document.getElementById('availability-alert')
    )
    $('#availability-alert-modal').modal('show')
  }

  handleSubscriptionChange = (offerId) => {
    this.props.bookingDataActions.loadDetailedOffer(offerId)
  }

  handleMultipleEntriesChange = (e) => {
    const optionPrice = parseFloat(this.props.bestOffer.multipleEntries.optionPrice)

    this.setState({
      isMultipleEntriesOption: e.target.checked,
      offerAmount: this.getOfferAmount() + (e.target.checked ? optionPrice : -optionPrice)
    })
  }

  getOfferAmount = () => {
    return parseFloat(this.state.offerAmount) || parseFloat(this.props.bestOffer.amount.totalWithOptions)
  }

  getBookingAmount = () => {
    return parseFloat(this.state.bookingAmount || this.props.bestOffer.amount.bookingAmount)
  }

  getPurchaseUrl () {
    const { beginDate, endDate, bestOffer: { id }, type, railsContext: { localePrefix } } = this.props
    let url = `${localePrefix}/purchases/summary?offer_id=${id}&begin_date=${beginDate.toISOString(true)}`
    url += (type === 'subscription') ? '&type=subscription' : `&end_date=${endDate.toISOString(true)}`
    url += (this.state.isMultipleEntriesOption) ? '&multiple_entries=true' : ''
    return url
  }

  render () {
    const { availabilityRate, isUserOnMobile, userSignedIn, park, parentComponent,
      bestOffer, noOfferReason, parkState, type, queryState } = this.props
    const bookingDisabled = !parkState || parkState !== 'available'
    const loading = queryState === 'pending'
    const multipleEntryCheckboxId = `multiple-entries-option-checkbox-${parentComponent}`

    return (
      <div id='BookingParkSearch' className='panel panel-default booking-park-search'>
        <div>
          {['soon_available', 'unavailable', 'invisible'].includes(parkState) && (
            <div>
              <NoOfferReason baseClassName={'search__park-error'} state={parkState} reason={noOfferReason} isSubscription={type === 'subscription'} />

              <div className='search__park-alert'>
                <button className='btn btn-primary' onClick={this.handleAvailabilityAlertClick}>
                  <FormattedMessage id='pages.park.booking_panel.cta_alert' />
                </button>
              </div>
            </div>
          )}

          {!['soon_available', 'unavailable', 'invisible'].includes(parkState) && (
            <div>
              <BookingPanelSearchTabs onQuerying={this.handleQuerying} onQueryingDone={this.handleQueryingDone} />

              {availabilityRate > 0 && availabilityRate <= FEW_SPACES && (
                <div className='search__park-availability'>
                  <FormattedMessage id='pages.park.booking_panel.occupancy.few' />
                </div>
              )}

              {bestOffer &&
                <BestOfferAndAmount
                  onSitePrice={park.onSitePrice}
                  parkBestOffer={bestOffer}
                  showOptionPrice={this.state.isMultipleEntriesOption}
                  offerAmount={this.getOfferAmount()}
                />
              }

              {bestOffer && bestOffer.multipleEntries.allow &&
                <div className='multiple-entries-option row'>
                  <div className='col-xs-9 multiple-entries-option__title'>
                    <input id={multipleEntryCheckboxId}
                      className='checkbox-op checkbox-op--blue'
                      type='checkbox'
                      onChange={(e) => { this.handleMultipleEntriesChange(e) }} />
                    <label htmlFor={multipleEntryCheckboxId}>
                      <FormattedMessage id='pages.park.booking_panel.multiple_entries_option' />
                    </label>

                    <TooltipComponent
                      isOpen={false}
                      message='pages.park.booking_panel.multiple_entries_option_tooltip' />
                  </div>

                  {!this.state.isMultipleEntriesOption &&
                    <div className='col-xs-3 multiple-entries-option__price'>
                      + <FormattedNumber value={bestOffer.multipleEntries.optionPrice} style='currency' currency='EUR' />
                    </div>
                  }
                </div>
              }

              <div className='search__park-booking'>
                {loading && (
                  <button disabled className='btn btn-primary'>
                    <Loader white />
                  </button>
                )}
                {!loading &&
                  <button
                    disabled={bookingDisabled}
                    className={`btn btn-primary ${bookingDisabled ? 'btn-deactivated' : ''}`}
                    onClick={userSignedIn || isUserOnMobile ? this.handleNotModalClick : this.handleModalClick}>
                    <FormattedMessage id={`pages.park.booking_panel.cta.${type}`} />
                  </button>
                }
              </div>

              {hasNoOfferReason(parkState) && (
                <NoOfferReason baseClassName='search__park-errors' state={parkState} reason={noOfferReason} isSubscription={type === 'subscription'} />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  bookingData: { availabilityRate, bestOffer, noOfferReason, queryState, selectedSubscriptionId, state },
  nearby,
  railsContext,
  search: { params: { beginDate, endDate, type } }
}) => ({
  availabilityRate,
  bestOffer,
  nearbyParks: nearby.parks,
  noOfferReason,
  beginDate,
  endDate,
  type,
  parkState: state,
  queryState,
  railsContext,
  selectedSubscriptionId
})

const mapDispatchToProps = dispatch => ({
  bookingDataActions: bindActionCreators(BookingDataActions, dispatch),
  nearbyActions: bindActionCreators(NearbyActions, dispatch)
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BookingPanel))
