import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import ReactDOM from 'react-dom'

import FormInput from '../FormInput'
import { datalayerPushGTM } from '../../../helpers/application'
import { localePrefix } from '../../../helpers/authentication'
import iconGeoloc from '../../../../../../../app/assets/images/svg-on-react/icon-geoloc.svg'
import iconMarker from '../../../../../../../app/assets/images/svg-on-react/icon-marker.svg'
import { searchForLocation } from '../../../../../libs/searchAutocomplete'
import SearchAutocompleteItem from '../../SearchAutocomplete/SearchAutocompleteIntem'
import { processPositionSuccessResponse, processPositionErrorResponse } from './service/geocode'

class AddressSearchInputMapbox extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholderKey: PropTypes.string,
    hasError: PropTypes.bool,
    hasFocus: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  }

  state = {
    autocomplete: null,
    geolocError: { title: '', content: '' },
    geolocStatus: null,
    hasFocus: false,
    results: {}
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.hasFocus !== 'undefined') {
      this.setState({ hasFocus: nextProps.hasFocus })
      if (nextProps.hasFocus) this._focusFormInput()
    }
  }

  _addListeners () {
    this.state.autocomplete && this.state.autocomplete.addListener('place_changed', () => this._onTransportAddressChanged())
  }

  _renderGeoLocErrorModal (errorTitle, errorContent) {
    ReactDOM.render(
      (
        <div>
          <strong style={{ fontSize: '18px' }}>{errorTitle}</strong>
          <p style={{ fontSize: '14px', marginTop: '10px' }}>{errorContent}</p>
        </div>
      ),
      document.getElementById('geo_loc_error_modal_body'),
      () => $('#geo_loc_error_modal').modal()
    )
  }

  _tryLocation () {
    if (navigator.geolocation && this.state.geolocStatus !== 'pending') {
      this.setState({
        geolocStatus: 'pending'
      })
      navigator.geolocation.getCurrentPosition((position) => {
        processPositionSuccessResponse({
          onChange: this.props.onChange,
          position,
          prefix: localePrefix.call(this)
        }).then(() => {
          this.setState({ geolocStatus: 'success' })
        }).catch(() => this.setState({ geolocStatus: 'error' }))
      }, (err) => {
        const { formattedTitle, formattedContent, error } = processPositionErrorResponse({ err, intl: this.props.intl })
        this.setState({ geolocStatus: 'error' }, () => {
          this._renderGeoLocErrorModal(formattedTitle, formattedContent)
          datalayerPushGTM('form-tracking', 'Forms', 'Geolocation', error.type)
        })
      })
    } else {
      this.setState({ geolocStatus: 'error' })
      console.log('Unsupported browser')
    }
  }

  _onTransportAddressChanged () {
    const place = this.state.autocomplete ? this.state.autocomplete.getPlace() : ''
    if (place.formatted_address) {
      let address = place.formatted_address
      if (!place.formatted_address.includes(place.name)) {
        address = `${place.name} ${address}`
      }

      this.props.onChange({
        address: address,
        addressCompleted: true,
        place
      })
    }
  }

  handleOnChangeInput = (e) => {
    const { onChange } = this.props
    const inputValue = e.target.value
    onChange({ address: inputValue, addressCompleted: false, place: null })
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    this.timeoutId = setTimeout(() => {
      this.performSearch(inputValue)
    }, 500)
  }

  performSearch = async (query = '') => {
    try {
      const response = await searchForLocation({
        countries: window.autosearch_countries,
        query,
        types: [
          'address',
          'locality',
          'place',
          'poi',
          'postcode'
        ]
      })
      this.setState({
        results: response
      })
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Manage focus on the field
   * @param {Boolean} [hasFocus=false]
   * @private
   */
  _handleFocus (hasFocus = false, onFocus) {
    this.setState({ hasFocus }, () => onFocus(hasFocus))
  }

  /**
   * Give the focus to the FormInput component
   * @private
   */
  _focusFormInput = () => {
    document.getElementById(this.props.id).focus()
  }

  render () {
    const { id, placeholderKey, value, onChange, hasError, onFocus } = this.props
    const { geolocStatus, results } = this.state

    return (
      <div id={`${id}Wrapper`}
        className={
          'inputAddress' +
          (geolocStatus ? ` geoloc-${geolocStatus}` : '') +
          (results && Object.keys(results).length ? ' has-results' : '')
        }
      >
        <FormInput
          id={`${id}FormInput`}
          withoutFloatingLabel
          svgIcon={iconGeoloc}
          svgIconLeft={iconMarker}
          iconCallback={() => this._tryLocation()}
          clearInputCallback={value ? () => onChange({ address: '', addressCompleted: false, place: null }) : null}
          errorMessage={hasError}
          hasFocus={this.state.hasFocus}
          ref={input => { this.formInput = input }}
        >
          <FormattedMessage id={placeholderKey}>
            {(message) => (
              <span className={`floating-label ${value ? 'floating-label--top' : ''}`}>{message}</span>
            )}
          </FormattedMessage>
          <input id={id}
            type='text'
            value={value}
            data-hasvalue={value ? 'true' : 'false'}
            onChange={this.handleOnChangeInput}
            onFocus={() => this._handleFocus(true, onFocus)}
            onBlur={() => this._handleFocus(false, onFocus)}
            placeholder=''
          />

          {
            !!results &&
              !!Object.keys(results).length && (
              <div className='pac-container'>
                {
                  Object.keys(results)
                    .map(placeId => {
                      const place = results[placeId]
                      return (
                        <SearchAutocompleteItem
                          place={place}
                          onChange={({
                            address,
                            addressCompleted,
                            place
                          }) => {
                            this.setState({
                              results: {}
                            }, () => {
                              this.props.onChange({
                                address,
                                addressCompleted,
                                place
                              })
                            })
                          }}
                        />
                      )
                    }
                    )}
              </div>
            )
          }
        </FormInput>
      </div>
    )
  }
}

export default injectIntl(AddressSearchInputMapbox)
