import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactDOM from 'react-dom'
import URLSearchParams from 'url-search-params'
import moment from 'moment'

import OpI18n from '../../../components/OpI18n'
import Provider from '../../components/Provider'
import SwitchAuthentication from '../../components/SwitchAuthentication'
import FormInput from '../../../components/Form/FormInput'
import FormInputPassword from '../../../components/Form/FormInputPassword'
import { PasswordRecoveryTunnel } from './PasswordRecoveryTunnel'
import HistoryComponent from '../../../components/HistoryComponent'
import Loader from '../../../components/Loader'
import { performValidation,
  genericEmailValidation,
  genericPasswordValidation
} from '../../../helpers/formValidations'
import { errorBox, removeParamFromLocation, datalayerPushGTM, datalayerPush } from '../../../helpers/application'
import { localePrefix } from '../../../helpers/authentication'

import iconMail from '../../../../../../../app/assets/images/svg-on-react/icon-mail.svg'

export class Login extends Component {
  state = {
    email: '',
    password: '',
    responseError: '',
    rememberMe: true,
    emailError: false,
    passwordError: false,
    loading: false
  }

  submitSignIn = () => {
    const { email, password, rememberMe } = this.state
    const prefix = localePrefix.call(this)

    this.setState({ loading: true })
    let location = `${prefix}`
    fetch(`${window.location.origin}${prefix}/users/sign_in`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        user: {
          email: email,
          password: password,
          remember_me: rememberMe
        }
      })
    }).then((response) => {
      location = response.headers.get('Location')
      return response.json()
    }).then((json) => {
      if (json.error) {
        this.setState({
          responseError: json.error,
          emailError: true,
          passwordError: true,
          loading: false
        })
        datalayerPushGTM('form-tracking', 'Forms', 'Login', 'activerecord.errors.user.email_password.dontmatch')
      } else {
        datalayerPushGTM('form-tracking', 'Users', 'login/success/email', json.id)

        datalayerPush({
          event: 'login',
          ep_method: 'email',
          up_language: (window._oneparkDatas.up_language || null),
          up_locale: (window._oneparkDatas.up_locale || null),
          up_user_id: json.google_analytics_user_id
        })

        const params = new URLSearchParams(window.location.search.slice(1))
        if (params.hasOwnProperty('modal') || !location) {
          window.location.href = removeParamFromLocation('modal', 'origin')
        } else {
          window.location = location
        }
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const inputValidations = [
      performValidation.call(this, genericEmailValidation, this.state.email, 'emailError'),
      performValidation.call(this, genericPasswordValidation, this.state.password, 'passwordError')
    ]
    if (!inputValidations.includes(false)) {
      this.submitSignIn()
    }
  }

  unmountPasswordTunnel () {
    ReactDOM.unmountComponentAtNode(document.getElementById('password-recovery-modal-body'))
  }

  handleClickResetPassword = () => {
    const { railsContext, source } = this.props
    const { email } = this.state

    window.Cookies.set('password_recovery_email', email, { expires: moment().add(15, 'minutes').toDate() })

    ReactDOM.render(
      (<OpI18n {...railsContext}>
        <PasswordRecoveryTunnel
          completedCallback={this.unmountPasswordTunnel}
          railsContext={railsContext}
          source={source}
          email={email}
        />
      </OpI18n>),
      document.getElementById('password-recovery-modal-body')
    )
  }

  renderProvider = () => {
    const { source, railsContext } = this.props
    return (
      <Provider
        providerText='authentication.header.auth_provider'
        source={source}
        railsContext={railsContext}
        authenticationType='login'
      />
    )
  }

  render () {
    const { email, password, loading, rememberMe, responseError, emailError, passwordError } = this.state
    const { isPage, source } = this.props
    const prefix = localePrefix.call(this)

    return (
      <>
        <div className='login__top-filler' />
        <div className={`react-component login row ${isPage ? 'login--is-page' : ''}`}>
          <div className='hidden-sm hidden-xs'>
            <h1 className='text-center'>
              <FormattedMessage id='authentication.header.title' />
            </h1>
          </div>

          <SwitchAuthentication prefix={prefix} source={source} active='connect' />

          {responseError && errorBox(responseError)}

          <div className='col-sm-12 p-0'>
            <form onSubmit={this.handleSubmit} action={`${prefix}/users/sign_in`} method='post' noValidate>

              <FormInput
                id='login_user_email'
                setCallback={(value) => this.setState({ email: value })}
                validateCallback={(value) => performValidation.call(this, genericEmailValidation, value, 'emailError')}
                errorMessage={emailError}
                svgIcon={iconMail}
                placeholderKey='authentication.user.email'
                inputValue={email}
              >
                <input type='email' className='form-control' />
              </FormInput>

              <FormInputPassword
                id='login_user_password'
                setCallback={(value) => this.setState({ password: value })}
                validateCallback={(value) => performValidation.call(this, genericPasswordValidation, value, 'passwordError')}
                errorMessage={passwordError}
                placeholderKey='authentication.user.password'
                inputValue={password}
              />

              <div className='row text-small'>
                <div className='col-xs-12 col-md-6 login__remember-me'>
                  <input id='user_remember_me'
                    className='checkbox-op'
                    type='checkbox'
                    checked={rememberMe}
                    onChange={(e) => this.setState({ rememberMe: e.target.checked })} />
                  <label htmlFor='user_remember_me'>
                    <FormattedMessage id='authentication.user.remember_me' />
                  </label>
                </div>

                <div className='col-xs-12 col-md-6'>
                  <div className='pull-right'>
                    <div className='hidden-md hidden-lg'>
                      <a href={`${prefix}/forgotten-password?origin=${source}`}
                        className='link--secondary'
                        onClick={this.handleClickResetPassword}>
                        <FormattedMessage id='authentication.connection.login.forgotten_password' />
                      </a>
                    </div>
                    <div className='hidden-sm hidden-xs'>
                      <a href='#'
                        aria-label='close'
                        data-dismiss='modal'
                        data-target='#password-recovery-modal'
                        data-toggle='modal'
                        className='link--secondary'
                        onClick={this.handleClickResetPassword}>
                        <FormattedMessage id='authentication.connection.login.forgotten_password' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className='text-center p-0'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {loading && <Loader overlay white />}
                  <span className={loading ? 'invisible' : ''}>
                    <FormattedMessage id='authentication.connection.login.signin' />
                  </span>
                </button>
              </div>
            </form>

            {this.renderProvider()}
          </div>

          {!isPage &&
            <HistoryComponent origin={source} modal={!isPage && 'connect'} id='authentication.connection.title_tag' />
          }
        </div>
      </>
    )
  }
}

export default Login
