import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

export class DropDown extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    ToggleButton: PropTypes.func.isRequired,
    children: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = { isOpened: false }
  }

  render() {
    const { id, ToggleButton, children } = this.props

    const dropDownContentClass = classNames('dropdown__content', {
      'visually-hidden': !this.state.isOpened
    })

    return (
      <div id={id} className='dropdown'>
        <ToggleButton isOpened={this.state.isOpened} onClick={() => this.setState({ isOpened: !this.state.isOpened })}/>

        <div className={dropDownContentClass}>
          {children}
        </div>
      </div>
    )
  }
}
