import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import JumboSearchPackage from '../Search/components/JumboSearchPackage'
import JumboSearchSubscription from '../Search/components/JumboSearchSubscription'
import { datalayerPushGTM } from '../helpers/application'
import { clearSearchParamsFromUrl, setCurrentLocationType } from '../helpers/search'
import * as SearchActions from '../../../actions/SearchActions'

class HomeSearchTabs extends Component {
  componentDidMount() {
    clearSearchParamsFromUrl()
    setCurrentLocationType(this.props.type)
  }

  handleClickTab = (type) => {
    if (type !== this.props.type) {
      this.props.actions.updateNextSearchParams({ type })
      datalayerPushGTM('hitVirtualPageView')
    }
  }

  handleSubmit = () => {
    this.props.actions.navigateToSearchPage('hp')
  }

  renderTabButton (type) {
    return (
      <button
        className={type === this.props.type ? 'selected' : null}
        onClick={() => this.handleClickTab(type)}
        id={`offer_type_switcher.search.buttons.${type}`}
      >
        <FormattedMessage id={`pages.homepage.search.buttons.${type}`} />
      </button>
    )
  }

  render() {
    const { displaySubscriptionSearch, queryState, type } = this.props
    const jumboSearchProps = {
      idSuffix: 'Home',
      searchMode: true,
      loading: queryState === 'pending',
      submitButtonClass: { halfBorderRounded: true },
      onSubmit: this.handleSubmit
    }
    return (
      <div className='op-search'>
        {displaySubscriptionSearch &&
          <div className='op-search__tabs op-search__tabs--top-border-rounded op-search__tabs--fixed-size'>
            <div className='switch-buttons'>
              <div className='col-xs-6 p-0'>{this.renderTabButton('package')}</div>
              <div className='col-xs-6 p-0'>{this.renderTabButton('subscription')}</div>
            </div>
          </div>
        }
        <div className={`op-search__from op-search__from--fixed-height op-search__form--without-borders
                        ${displaySubscriptionSearch ? '' : 'f-w'}`}>
          {type === 'package' && <JumboSearchPackage {...jumboSearchProps} />}
          {type === 'subscription' && <JumboSearchSubscription {...jumboSearchProps} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  railsContext: { currentSite: { display_subscription_search: displaySubscriptionSearch } },
  search: { nextParams: { type }, queryState }
}) => ({ displaySubscriptionSearch, queryState, type })

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ ...SearchActions }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchTabs)
