import { MapController } from 'react-map-gl'

const USER_TIMEOUT_INTERACTION_IN_MS = 100

class MapControllerCustom extends MapController {
  constructor (props) {
    super(props)
    const { onUserMapNavigation, onZoomChanged, onMapClick } = props
    if (onUserMapNavigation) {
      this.onUserMapNavigation = onUserMapNavigation
    }
    if (onZoomChanged) {
      this.onZoomChanged = onZoomChanged
    }
    if (onMapClick) {
      this.onMapClick = onMapClick
    }
    this.events = ['click']
  }

  handleEvent (event) {
    if (
      event.type === 'click' &&
      event.target &&
      event.target.className === 'overlays' &&
      this.onMapClick
    ) {
      this.onMapClick()
    }
    return super.handleEvent(event)
  }

  _onWheel (e) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    // I know it's ugly but we don't have _onWheelEnd event
    this.timeoutId = setTimeout(() => {
      this.onUserMapNavigation()
    }, USER_TIMEOUT_INTERACTION_IN_MS)

    if (this.onZoomChanged) {
      this.onZoomChanged()
    }
    super._onWheel(e)
  }

  _onPanEnd () {
    if (this.onUserMapNavigation) {
      this.onUserMapNavigation()
    }
  }
}

export default MapControllerCustom
