import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import ParkCardPhoto from './ParkCardPhoto'
import ParkPrice from './ParkPrice'
import NoOfferReason, { hasNoOfferReason } from '../NoOfferReason'
import DistanceItem from '../../Park/common/DistanceItem'
import Rating from '../../Park/common/Rating'
import Loader from 'Components/Loader'
import { parameterize } from '../../../../libs/string'
import { amountWithoutTax, clearPreviousEcommerceObject, gaUserTags } from '../../../../libs/google_analytics_helpers'
import { fullScreenEnabled } from 'Helpers/fullscreen'
import SvgImage from 'Components/SvgImage'

import iconItinerary from 'Assets/images/svg-on-react/icon-map-arrow.svg'

const openParkPage = url => {
  if (fullScreenEnabled()) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }

  window.open(url, '_blank')
}

const urlWithPlaceId = (parkId, place, isSubscription) => {
  const localeRegex = /^(([a-z]{2}-[a-z]{2})|([a-z]{2}))$/
  const possibleLocale = window.location.pathname.split('/')[1]

  const locale = localeRegex.test(possibleLocale) ? `/${possibleLocale}` : ''
  const pathName = `${locale}/parkings/${parkId}`

  const url = new URL(window.location.origin + pathName)

  if (place) url.searchParams.append('place_id', place.id)
  if (isSubscription) url.searchParams.append('type', 'subscription')
  return url.toString()
}

function offerType (park) {
  if (park && park.bestOffer) {
    return park.bestOffer.offerType
  }
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('type') || 'package'
}

const pushGaEvent = (index, park, place) => {
  try {
    const placeName = place ? parameterize(place.seoHeading) : undefined
    const placeId = place ? place.id.toString() : undefined
    const categories = place ? place.categories : []
    const vatPercent = parseFloat(park.vat)
    let price = 'no_offer'
    let quantity = 'no_offer'
    let durationQuantity = 'no_offer'
    if (park && park.bestOffer) {
      // options: bookingAmount, totalWithoutOptions, totalWithOptions, serviceFee
      price = amountWithoutTax(park.bestOffer.amount.bookingAmount, vatPercent)
      quantity = 1
      durationQuantity = park.bestOffer.gaQuantity
    }
    const gaEvent = {
      event: 'select_item',
      ...gaUserTags(),
      ecommerce: {
        index: index + 1,
        item_list_id: placeId,
        item_list_name: placeName,
        items: [{
          item_id: park.id.toString(),
          item_name: parameterize(park.seoTitle),
          item_type: offerType(park),
          item_brand: 'onepark',
          item_variant: park.covered ? 'covered' : 'uncovered',
          price,
          quantity,
          quantity_hours: durationQuantity,
          item_category: categories[0],
          item_category2: categories[1],
          item_category3: categories[2],
          item_category4: categories[3],
          item_list_id: placeId,
          item_list_name: placeName
        }]
      }
    }
    clearPreviousEcommerceObject()
    window.dataLayer.push(gaEvent)
  } catch (e) {
    if (!(e instanceof TypeError)) { // Network errors
      Sentry.captureException(e)
    }
  }
}

const handleParkCardClick = (event, index, park, place, isSubscription) => {
  if (!(event.target.className.includes('itinerary') || event.target.parentElement.className.includes('itinerary'))) {
    event.preventDefault()
    pushGaEvent(index, park, place)
    openParkPage(urlWithPlaceId(park.id, place, isSubscription))
  }
}

const ParkCardComponent = ({
  park,
  idPrefix,
  index,
  isActiveInfoWindow,
  isActiveMarker,
  isSubscription,
  isUserOnMobile,
  isPromotedPark,
  onMouseEnter,
  showAddress,
  showItinerary,
  showServicesDetails,
  showBookingButtons,
  place,
  type,
  imgWidth = 100,
  imgHeight = 100
}) => {
  const itineraryLink = `https://www.google.com/maps/dir/?api=1&destination=${park.address.fullAddress.replace(/\s/g, '+')}`

  return (
    <a
      {...{ 'data-cs-override-id': '' }}
      id={idPrefix ? `${idPrefix}${park.id}` : park.id}
      className={
        'park-card' +
        (isActiveInfoWindow || isActiveMarker ? ' park-card--active' : '') +
        (isPromotedPark ? ' park-card--promoted' : '')
      }
      onClick={(event) => { handleParkCardClick(event, index, park, place, isSubscription) }}
      onMouseEnter={onMouseEnter}
      title={park.seoTitle}
    >
      {isPromotedPark && <div className='park-card__promoted-block'><FormattedMessage id='pages.parks.promoted' /></div>}
      <ParkCardPhoto park={park} lazyload={index > 4} imgWidth={imgWidth} imgHeight={imgHeight} />

      <div>
        <div>
          <h4 className='park-card__title'>{park.name}</h4>

          <Rating className='park-card__rating-stars' rating={park.rating} />
          <span className='park-card__rating__count'>({park.ratingCount})</span>

          {showItinerary &&
            <>
              <br />
              <a className='park-card__itinerary' href={itineraryLink} target='_blank'>
                <SvgImage className='park-card__itinerary-icon' svg={iconItinerary} />
                <FormattedMessage id='pages.park.location.itinerary' />
              </a>
            </>
          }

          {showAddress &&
            <div className='park-card__address'>
              {park.address.address},
              <br />
              {park.address.zipcode} {park.address.city}
            </div>
          }

          {showServicesDetails && park.servicesDetails &&
            <ul className='park-card__services-details'>
              {park.servicesDetails.map((service, i) =>
                <li key={i}>
                  <div dangerouslySetInnerHTML={{ __html: service }} />
                </li>
              )}
            </ul>
          }

          {park.distance && (
            <ul className='park-card__distance tiny-icon-list'>
              <DistanceItem
                by={park.distance.by}
                time={park.distance.time}
                distance={type === 'subscription' && isUserOnMobile ? 0 : park.distance.distance}
              />
            </ul>
          )}

          {park.bestOffer &&
            <div className='park-card__right-block'>
              <ParkPrice
                isPromotedPark={isPromotedPark}
                onSitePrice={park.onSitePrice}
                bestOffer={park.bestOffer}
                prefix='park-card'
                type={type}
              />
              {showBookingButtons &&
                <div className='park-card__booking-buttons hidden-sm hidden-xs'>
                  <a href={park.url} target='_blank' className='btn btn-secondary-alt'>
                    <FormattedMessage id='pages.parks.more_details_link' />
                  </a>
                  <a href={park.url} className='btn btn-primary' target='_blank'>
                    <FormattedMessage id={`pages.park.booking_banner.cta_book.${type}`} />
                  </a>
                </div>
              }
            </div>
          }

          {!park.state && <Loader />}
        </div>

        {hasNoOfferReason(park.state) && (
          <NoOfferReason baseClassName='park-card__message' state={park.state} reason={park.noOfferReason} isSubscription={isSubscription} />
        )}
      </div>
    </a>
  )
}

const mapStateToProps = ({ search: { params: { type } } }) => ({ type })

export const ParkCard = connect(mapStateToProps)(ParkCardComponent)
