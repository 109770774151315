import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import HomeSearch from './HomeSearch'
import PlacesSection from './PlacesSection'
import SvgImage from '../components/SvgImage'
import IconArrowRight from '../../../../assets/images/icon-arrow-right.svg'
import IconMoney from '../../../../assets/images/icon-money.svg'
import IconPlace from '../../../../assets/images/icon-place.svg'
import IconRelax from '../../../../assets/images/icon-relax.svg'
import IconSearch from '../../../../assets/images/icon-search--home.svg'
import IconTick from '../../../../assets/images/icon-tick.svg'
import IconTime from '../../../../assets/images/icon-time--home.svg'
import subscriptionImage from '../../../../assets/images/bon-plan.jpg'
import subscriptionMobileImage from '../../../../assets/images/bon-plan-mobile.jpg'

class HomePage extends Component {
  componentDidMount () {
    $(window).trigger('op_homepage_init')
  }

  render () {
    const { inventory, subscriptionEnabled, supportPath, intl } = this.props

    return (
      <div className='home-page'>
        <HomeSearch />

        <section className='o-section o-section--col o-section--road l-container u-center lazyload'>
          <h2><FormattedMessage id='pages.homepage.how_it_works.title' /></h2>

          <p><FormattedMessage id='pages.homepage.how_it_works.subtitle' /></p>

          <div className='l-row'>
            <article className='l-col l-col--xs-12 l-col--md-4'>
              <div className='o-icon-cirle'>
                <SvgImage svg={IconSearch} />
              </div>

              <h3><FormattedMessage id='pages.homepage.how_it_works.ideal_car_park.h3' /></h3>
              <p><FormattedMessage id='pages.homepage.how_it_works.ideal_car_park.subtitle' values={{ inventory }} /></p>
            </article>

            <article className='l-col l-col--xs-12 l-col--md-4'>
              <div className='o-icon-cirle'>
                <SvgImage svg={IconTick} />
              </div>

              <h3><FormattedMessage id='pages.homepage.how_it_works.book_your_place.h3' /></h3>
              <p><FormattedMessage id='pages.homepage.how_it_works.book_your_place.subtitle' /></p>
            </article>

            <article className='l-col l-col--xs-12 l-col--md-4'>
              <div className='o-icon-cirle'>
                <SvgImage svg={IconPlace} />
              </div>

              <h3><FormattedMessage id='pages.homepage.how_it_works.park_yourself.h3' /></h3>
              <p><FormattedMessage id='pages.homepage.how_it_works.park_yourself.subtitle' /></p>
            </article>
          </div>

          <p>
            <a href='/how-it-works' className='o-btn o-btn--primary'>
              <FormattedMessage id='pages.homepage.how_it_works.more_infos' />
              <SvgImage className='o-btn__icon-right' svg={IconArrowRight} />
            </a>
          </p>

          <a href={supportPath} className='u-link'>
            <FormattedMessage id='pages.homepage.how_it_works.faq' />
          </a>
        </section>

        {subscriptionEnabled &&
          <section className='l-container'>
            <div className='c-forward c-forward--deal'>
              <img src={subscriptionImage} className='c-forward__desktop-background-image' alt={intl.formatMessage({ id: 'pages.homepage.subscription_section.alt' })} />
              <img src={subscriptionMobileImage} className='c-forward__mobile-background-image' alt={intl.formatMessage({ id: 'pages.homepage.subscription_section.alt' })} />
              <div className='c-forward__wrapper'>
                <span className='strong title-4 u-caps'>
                  <FormattedMessage id='pages.homepage.subscription_section.title' />
                </span>
                <h2><FormattedMessage id='pages.homepage.subscription_section.h2' /></h2>
                <p><FormattedMessage id='pages.homepage.subscription_section.p1' /></p>
                <p><FormattedMessage id='pages.homepage.subscription_section.p2' /></p>
                <p><FormattedMessage id='pages.homepage.subscription_section.p3' /></p>
                <a className='js-search-subscription o-btn o-btn--white' href='?type=subscription#'>
                  <FormattedMessage id='pages.homepage.subscription_section.cta' />
                  <SvgImage className='o-btn__icon-right' svg={IconArrowRight} />
                </a>
              </div>
            </div>
          </section>
        }

        <section className='o-section o-section--col l-container u-center'>
          <p className='p title-4 u-caps'><FormattedMessage id='pages.homepage.advantages_section.title' /></p>
          <h2><FormattedMessage id='pages.homepage.advantages_section.h2' /></h2>
          <p><FormattedMessage id='pages.homepage.advantages_section.subtitle' /></p>

          <div className='l-row'>
            <article className='l-col l-col--xs-12 l-col--md-4'>
              <div className='o-icon-cirle'>
                <SvgImage svg={IconMoney} />
              </div>
              <h3><FormattedMessage id='pages.homepage.advantages_section.save_money.title' /></h3>
              <p><FormattedMessage id='pages.homepage.advantages_section.save_money.subtitle' /></p>
            </article>

            <article className='l-col l-col--xs-12 l-col--md-4'>
              <div className='o-icon-cirle'>
                <SvgImage svg={IconTime} />
              </div>
              <h3><FormattedMessage id='pages.homepage.advantages_section.save_time.title' /></h3>
              <p><FormattedMessage id='pages.homepage.advantages_section.save_time.subtitle' /></p>
            </article>

            <article className='l-col l-col--xs-12 l-col--md-4'>
              <div className='o-icon-cirle'>
                <SvgImage svg={IconRelax} />
              </div>
              <h3><FormattedMessage id='pages.homepage.advantages_section.great_support.title' /></h3>
              <p><FormattedMessage id='pages.homepage.advantages_section.great_support.subtitle' /></p>
            </article>
          </div>

          <a className='o-btn o-btn--primary' href='#'>
            <FormattedMessage id='pages.homepage.advantages_section.cta' />
            <SvgImage className='o-btn__icon-right' svg={IconArrowRight} />
          </a>
        </section>

        <PlacesSection />
      </div>
    )
  }
}

const mapStateToProps = ({
  resources: { inventory },
  site: { subscriptionEnabled, supportPath }
}) => ({ inventory, subscriptionEnabled, supportPath })

export default injectIntl(connect(mapStateToProps)(HomePage))
