import React from 'react'
import { getMapProvider } from '../../../../libs/mapProvider'

const PinGroup = ({
  latitude,
  longitude,
  onClick,
  pointCount
}) => (
  pointCount
    ? (
      React.createElement(
        getMapProvider().Marker,
        {
          latitude,
          longitude
        },
        <div
          className='cluster-mapbox'
          onClick={() => {
            if (onClick) {
              onClick({ latitude, longitude })
            }
          }}
        >
          <div className='cluster-mapbox--content'>
            {pointCount}
          </div>
        </div>
      )
    )
    : null
)

export default PinGroup
