import React from 'react'
import { injectIntl } from 'react-intl'

import { toHumanDuration } from '../helpers/date'

const NoOfferReason = injectIntl(({ intl, baseClassName, state, reason = {}, isSubscription }) => {
  return (
    <div className={`${baseClassName} ${baseClassName}--${state}`}>
      {noOfferReasonMessage(intl, state, reason, isSubscription)}
      {state === 'closed' && reason.schedules ? `. ${reason.schedules}` : null}
    </div>
  )
})

export const noOfferReasonMessage = (intl, state, reason = {}, isSubscription) => {
  const duration = reason.minDuration || reason.maxDuration || reason.minTime || reason.maxTime
  const messageId = isSubscription && state === 'full' ? 'full_subscription' : state
  return intl.formatMessage({ id: `booking.message.${messageId}` }, { duration: duration ? toHumanDuration(intl, duration) : null })
}

export const hasNoOfferReason = (state) => state && state !== 'available'

export default NoOfferReason
