import BelgiumIcon from '../../../../assets/images/countries/belgium.svg';
import FranceIcon from '../../../../assets/images/countries/france.svg';
import GermanyIcon from '../../../../assets/images/countries/germany.svg';
import ItalyIcon from '../../../../assets/images/countries/italy.svg';
import NetherlandIcon from '../../../../assets/images/countries/netherland.svg';
import PortugalIcon from '../../../../assets/images/countries/portugal.svg';
import SpainIcon from '../../../../assets/images/countries/spain.svg';
import SwitzerlandIcon from '../../../../assets/images/countries/switzerland.svg';
import UnitedKingdomIcon from '../../../../assets/images/countries/united_kingdom.svg';

export const countryFlagIcons = {
  fr: FranceIcon,
  ch: SwitzerlandIcon,
  gb: UnitedKingdomIcon,
  es: SpainIcon,
  de: GermanyIcon,
  pt: PortugalIcon,
  be: BelgiumIcon,
  nl: NetherlandIcon,
  it: ItalyIcon,
}

export const languageTitles = {
  fr: 'Français',
  en: 'English',
  es: 'Español',
  de: 'Deutsch',
  pt: 'Português',
  nl: 'Nederlands',
  it: 'Italiano',
}
