import React from 'react'

import { smoothScrollTo } from '../NavTabs'

const AddressItem = ({ park }) => (
  <a
    href='#park-location'
    className='link-unstyled'
    onClick={e => {
      e.preventDefault()
      smoothScrollTo('park-location')
    }}
  >
    <p>{park.address.address}, {park.address.zipcode} {park.address.city}</p>
  </a>
)

export default AddressItem
