export const jsonFetch = (url, { body, ...options } = {}) =>
  fetch(url, {
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      ...(body ? { 'Content-Type': 'application/json' } : {})
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
    ...options
  })

export const bookingDuplicateCheck = (url, switchOffLoading, setDuplicateType, completeBooking) => {
  fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok.')
      }
      return response.json()
    })
    .then((data) => {
      if (data.duplicate) {
        setDuplicateType(data.duplicate)
        $('#duplicate-booking-modal').modal('show')
        window.dataLayer.push({
          event: 'form-tracking',
          gaCategory: 'General',
          gaAction: 'Payment',
          gaLabel: 'Double booking warning'
        })
        switchOffLoading()
      } else {
        completeBooking()
      }
    })
    .catch((error) => {
      console.error('Error:', error)
      switchOffLoading()
    })
}
