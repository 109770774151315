import React from 'react'
import { slide as Menu } from 'react-burger-menu'

import SideMainMenu from './SideMainMenu'
import SideSearch from './SideSearch'
import SideMegaMenu from './SideMegaMenu'

export class SideMenu extends React.Component {
  render () {
    const {
      openedMenu,
      onSideMenuClose,
      onSearchClick,
      onNavItemClick,
      megaMenuContent,
      headerLinks
    } = this.props

    return (
      <Menu
        right
        isOpen={Boolean(openedMenu)}
        width={'100%'}
        customBurgerIcon={false}
        customCrossIcon={false}
        onStateChange={({ isOpen }) => { if (!isOpen) onSideMenuClose(null) }}
      >
        {openedMenu === 'search' &&
          <SideSearch
            onSideMenuClose={onSideMenuClose}
            onSearchClick={onSearchClick} />
        }

        {openedMenu === 'main menu' &&
          <SideMainMenu
            onNavItemClick={onNavItemClick}
            {...this.props} />
        }

        {openedMenu === 'mega menu' &&
          <SideMegaMenu
            megaMenuContent={megaMenuContent}
            onNavItemClick={onNavItemClick}
            headerLinks={headerLinks} />
        }

      </Menu>
    )
  }
}

export default SideMenu
