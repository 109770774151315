import * as types from '../types/BookingDataTypes'

const initialState = {
  availabilityRate: null,
  noOfferReason: null,
  bestOffer: null,
  selectedSubscriptionId: null,
  state: null,
  queryState: 'pending'
}

export default function bookingDataReducer (state = initialState, action) {
  const { type, bookingData } = action

  switch (type) {
    case types.BOOKING_DATA_QUERY_PENDING:
      return { ...state, queryState: 'pending' }
    case types.BOOKING_DATA_QUERY_SUCCESS:
      return { ...state, queryState: 'success' }
    case types.BOOKING_DATA_UPDATE:
      return { ...state, ...bookingData }
    case types.BOOKING_DATA_RESET:
      return initialState
    default:
      return state
  }
}
