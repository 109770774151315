import pick from 'lodash/pick'

const FLAT_DISCOUNT_TYPE = 'flat_discount'
const PERSANTAGE_DISCOUNT_TYPE = 'percent'

export const getRatingIndicators = park => ({
  rating: park.rating,
  ratingCount: park.ratingCount,
  reviewCount: park.reviewCount,
  recommendationsCount: park.recommendationsCount
})

export const hasDescription = park => !park.isSeo || (park.schedules || []).length > 0 || getServices(park).length > 0

export const getDiscount = (park) => {
  if (park.bestOffer) {
    const { ref, serviceFee, totalWithOptions } = park.bestOffer.amount
    const discountType = park.bestOffer.discountType

    if (discountType === FLAT_DISCOUNT_TYPE) {
      return { discountRate: totalWithOptions - ref, formattedNumberStyle: 'currency' }
    }

    if (discountType === PERSANTAGE_DISCOUNT_TYPE) {
      return { discountRate: (totalWithOptions - ref) / (ref - serviceFee), formattedNumberStyle: 'percent' }
    }
  }

  return 0
}

export const getServices = park => {
  const services = park.services.map(service => service.split(':')[0])
  const maybeGetService = (service, ...aliases) => [service, ...aliases].some(s => services.includes(s)) ? service : null
  return [
    maybeGetService('handicappedaccess'),
    maybeGetService('car_wash'),
    park.withAccessControl ? 'access_control' : null,
    maybeGetService('toilets', 'toilet'),
    maybeGetService('recharge', 'rechage', 'charging'),
    maybeGetService('oil'),
    maybeGetService('bike')
  ].filter(service => !!service)
}

export const applyParkFilters = (parks, parkFilters) => parks.filter(park => !Object.values(pick(park, parkFilters)).includes(false))
