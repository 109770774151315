import React from 'react'
import { FormattedMessage } from 'react-intl'

import { defaultPaymentMethods, paymentMethodsByCountry, paymentIconByName } from '../constants/payment'
import SvgImage from '../components/SvgImage'
import LogoSvg from '../../../../assets/images/onepark-header-logo.svg'
import FooterLinkObfuscated from '../components/FooterLinkOfuscated'

const LegalNotice = ({ isHomepage, legalNotice, country }) => {
  const availablesPaymentMethod = paymentMethodsByCountry[country] || defaultPaymentMethods

  return (
    <div className='legal-notice'>
      <div className='container'>
        <div className='row'>
          <div className='hidden-xs hidden-sm col-md-2'>
            <span className='legal-notice__logo'>
              <SvgImage svg={LogoSvg} />
            </span>
          </div>

          <div className='col-xs-12 col-md-8'>
            <div className='legal-notice__links'>
              <div>
                {isHomepage
                  ? <a href={legalNotice.privacyPolicyPath} target='_blank'><FormattedMessage id='pages.footer.privacy_policy' /></a>
                  : <FooterLinkObfuscated message='pages.footer.privacy_policy' link={legalNotice.privacyPolicyPathEncoded} target='_blank' />
                }
                <span className='legal-notice__links__separator'>|</span>
                <a href={legalNotice.cguPath} target='_blank'>
                  <FormattedMessage id='pages.footer.cgu' />
                </a>
                <span className='legal-notice__links__separator'>|</span>
                {isHomepage
                  ? <a href={legalNotice.rankingCriteriaPath} target='_blank'><FormattedMessage id='pages.footer.ranking_criteria' /></a>
                  : <FooterLinkObfuscated message='pages.footer.ranking_criteria' link={legalNotice.rankingCriteriaPathEncoded} target='_blank' />
                }
                <span className='legal-notice__links__separator'>|</span>
                {country === 'fr' && (
                  isHomepage
                    ? <a href={legalNotice.preContractualInformationPath} target='_blank'><FormattedMessage id='pages.footer.pre_contractual_information' /></a>
                    : <FooterLinkObfuscated message='pages.footer.pre_contractual_information' link={legalNotice.preContractualInformationPathEncoded} target='_blank' />
                )}
                {country === 'fr' && <span className='legal-notice__links__separator'>|</span>}
                {isHomepage
                  ? <a href={legalNotice.cookiesPath} target='_blank'><FormattedMessage id='pages.footer.cookies_title' /></a>
                  : <FooterLinkObfuscated message='pages.footer.cookies_title' link={legalNotice.cookiesPathEncoded} target='_blank' />
                }
                <span className='legal-notice__links__separator'>|</span>
                <FormattedMessage
                  id='pages.footer.organization.copyright'
                  values={{ year: new Date().getFullYear() }}
                />
              </div>
            </div>
          </div>

          <div className='col-xs-12 col-md-2 legal-notice__cards'>
            {availablesPaymentMethod.map((name) => (
              <div className='legal-notice__cards-icon'><SvgImage key={name} svg={paymentIconByName[name].src} /></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegalNotice
