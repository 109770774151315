import React, { Component } from 'react'

import germany from '../../../../../assets/images/plates/germany.png'
import france from '../../../../../assets/images/plates/france.png'
import belgium from '../../../../../assets/images/plates/belgium.png'
import italy from '../../../../../assets/images/plates/italy.png'
import netherlands from '../../../../../assets/images/plates/netherlands.png'
import portugal from '../../../../../assets/images/plates/portugal.png'
import spain from '../../../../../assets/images/plates/spain.png'
import switzerland from '../../../../../assets/images/plates/switzerland.png'
import {
  FRANCE_COUNTRY_ID,
  BELGIUM_COUNTRY_ID,
  GERMANY_COUNTRY_ID,
  ITALY_COUNTRY_ID,
  NETHERLANDS_COUNTRY_ID,
  PORTUGAL_COUNTRY_ID,
  SPAIN_COUNTRY_ID,
  SWITZERLAND_COUNTRY_ID
} from './constants'

export class CountryFlag extends Component {
  render () {
    const country = this.props.country

    const countryImagePairs = {
      [FRANCE_COUNTRY_ID]: france,
      [BELGIUM_COUNTRY_ID]: belgium,
      [GERMANY_COUNTRY_ID]: germany,
      [ITALY_COUNTRY_ID]: italy,
      [SPAIN_COUNTRY_ID]: spain,
      [PORTUGAL_COUNTRY_ID]: portugal,
      [NETHERLANDS_COUNTRY_ID]: netherlands,
      [SWITZERLAND_COUNTRY_ID]: switzerland
    }

    const image = countryImagePairs[country.id]

    return (
      <>
        { image &&
          <img src={image} alt={country.key} className={this.props.className} /> }
      </>
    )
  }
}
