import React from 'react'
import { connect } from 'react-redux'

import { OverviewSection } from './OverviewSection'
import { MobileOverviewSection } from './OverviewSection/MobileOverviewSection'
import NavTabs from './NavTabs'
import DescriptionSection from './DescriptionSection'
import AccessSection from './AccessSection'
import LocationSection from './LocationSection'
import ReviewsSection from './ReviewsSection'
import Affix from '../components/Affix'
import BookingBanner from './BookingBanner'
import BookingPanel from './BookingPanel'
import ReassurancePanel from '../components/BlockSections/ReassurancePanel'
import NearbyParksSection from './NearbyParksSection'
import NearbyPlacesSection from '../components/BlockSections/NearbyPlacesSection'
import AndAlsoSection from '../components/BlockSections/AndAlsoSection'
import BreadCrumb from '../components/BreadCrumb'
import { hasDescription } from '../helpers/park'

const ParkComponent = ({
  breadCrumbItems,
  categories,
  isUserOnMobile,
  userSignedIn,
  newUserReview,
  park,
  place,
  subscriptionOffers,
  railsContext,
  topics,
  abtestParkMobile,
  type
}) => {
  const {
    currentSite: { iso: country }
  } = railsContext
  return (
    <div className='react-component react-component--park container'>
      {breadCrumbItems && (
        <div id='breadcrumb' className='row'>
          <div className='col-md-8'>
            <BreadCrumb items={breadCrumbItems} />
          </div>
        </div>
      )}

      <div className='row'>
        <div className='col-md-8'>
          {!isUserOnMobile && <OverviewSection park={park} />}
          {isUserOnMobile && <MobileOverviewSection park={park} />}
          <div>
            <NavTabs park={park} />
            {hasDescription(park) && <DescriptionSection park={park} />}
            {!park.isSeo && <AccessSection park={park} />}
            <LocationSection park={park} />
            {!park.isSeo && (
              <ReviewsSection
                park={park}
                isUserOnMobile={isUserOnMobile}
                newUserReview={newUserReview}
                railsContext={railsContext}
              />
            )}
          </div>
        </div>

        <Affix
          className='col-md-4 hidden-xs hidden-sm affixPanel'
          offsetTop={20}
        >
          <BookingPanel
            park={park}
            isUserOnMobile={isUserOnMobile}
            userSignedIn={userSignedIn}
            subscriptionOffers={subscriptionOffers}
            place={place}
            parentComponent='park'
          />
          <ReassurancePanel country={country} isSubscription={type === 'subscription'} />
        </Affix>
      </div>

      <NearbyParksSection />

      <NearbyPlacesSection inline imgWidthDesktop='180' imgHeightDesktop='180' imgWidthMobile='140' imgHeightMobile='140' />

      {categories.length + topics.length > 0 && (
        <AndAlsoSection andAlsoLinks={[...categories, ...topics]} inline />
      )}

      <div className='hidden-md hidden-lg'>
        <BookingBanner
          railsContext={railsContext}
          park={park}
          isUserOnMobile={isUserOnMobile}
          userSignedIn={userSignedIn}
          subscriptionOffers={subscriptionOffers}
          abtestParkMobile={abtestParkMobile}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ search: { params: { type } } }) => ({ type })

export const Park = connect(mapStateToProps)(ParkComponent)
