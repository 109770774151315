export function resizeWindow () {
  if ($('.registration__block--right').length && $('.registration__block--left').length) {
    const rightHeight = $('.registration__block--right').innerHeight()
    $('.registration__block--left').height(rightHeight)
  }
}

export function alignStepperToBox () {
  const inner = document.querySelector('.stepper__column')
  const outer = document.querySelector('.stepper__step')
  const target = document.querySelector('.registration__breadcrumb')
  if (inner && outer && target) {
    const shift = (parseFloat(window.getComputedStyle(inner).width) - parseFloat(window.getComputedStyle(outer).width)) / 2
    target.style.marginLeft = `${shift}px`
  }
}
