import classNames from 'classnames'
import React, { PureComponent } from 'react'

import LogoSvg from '../../../../assets/images/onepark-header-logo.svg'
import SvgImage from '../components/SvgImage'
import { datalayerPushGTM } from '../helpers/application'
import { localePrefix } from '../helpers/authentication'
import { isMobile } from '../helpers/browser'
import HeaderRight from './HeaderRight'
import HeaderSearchSummary from './HeaderSearchSummary'
import HeaderSearchTabs from './HeaderSearchTabs'
import MegaMenu from './MegaMenu'
import { SideMenu } from './SideMenu/'

const deltaTravellingHeaderHeight = 10

class HeaderComponent extends PureComponent {
  lastScroll = 0

  state = {
    searchOpened: this.props.searchOpened,
    displaySummary: ['parks#index', 'places#show', 'places#index'].includes(this.props.pageType),
    summaryOpened: true,
    lastSearchOpenScroll: 0,
    headerStuck: false,
    headerTraveling: false,
    openedMenu: null,
    isProMenuItemHovered: false
  }

  componentDidMount () {
    if (!this.shouldHeaderScrollWithPage()) {
      this.lastScroll = this.getScroll()
      window.addEventListener('scroll', this.handleScroll)
      window.addEventListener('resize', this.handleResize)
      window.addEventListener('orientationchange', this.handleOrientationChange)
      this.handleScroll()
    }

    if (this.state.searchOpened && this.props.searchOpened && isMobile()) {
      this.setState({ searchOpened: false })
    }
  }

  getScroll () {
    return $(window).scrollTop()
  }

  shouldHeaderScrollWithPage () {
    const { pageType } = this.props
    return pageType.startsWith('parks#show')
  }

  handleScroll = () => {
    if (this.state.openedMenu) {
      return
    }
    const { searchOpened, headerTraveling, summaryOpened } = this.state
    const headerTravelingScrollBreakPoint = 200
    const summaryHeight = $('#summary-wrapper').outerHeight() || 0
    const scroll = this.getScroll()
    const isMobileScrolledUp = isMobile() && scroll < this.lastScroll
    this.lastScroll = scroll

    if (headerTraveling) {
      if (scroll <= headerTravelingScrollBreakPoint - deltaTravellingHeaderHeight - summaryHeight || (isMobileScrolledUp && !summaryOpened)) {
        this.setState({ headerTraveling: false })
      }
    } else if (scroll > headerTravelingScrollBreakPoint && !searchOpened && !isMobileScrolledUp) {
      this.setState({ headerTraveling: true })
    }
  }

  handleNavItemClick = (navItemName = 'main menu') => {
    if (!navItemName || this.state.openedMenu === navItemName) {
      this.closeMenu()
    } else {
      this.openMenu(navItemName)
    }
  }

  openMenu (navItemName) {
    this.setState({ openedMenu: navItemName, headerTraveling: false })
    this.disableScroll()
  }

  closeMenu () {
    this.setState({ openedMenu: null })
    this.enableScroll()
  }

  handleClickSearch = () => {
    const searchOpened = !this.state.searchOpened
    const summaryOpened = !this.state.summaryOpened
    const headerTraveling = this.state.headerTraveling && !searchOpened
    const lastSearchOpenScroll = searchOpened ? this.getScroll() : 0
    this.setState({ headerTraveling, searchOpened, summaryOpened, lastSearchOpenScroll }, () => {
      datalayerPushGTM('hitVirtualPageView')
      $(window).trigger('toggleSearch')
    })
  }

  handleMobileSearchClick = () => {
    this.setState({
      openedMenu: null,
      summaryOpened: true,
      headerTraveling: this.state.headerTraveling,
      lastSearchOpenScroll: 0
    })
  }

  handleMobileSearchSummaryClick = () => {
    this.setState({
      openedMenu: 'search',
      summaryOpened: true,
      headerTraveling: false,
      lastSearchOpenScroll: 0
    })
  }

  handleResize = () => {
    if (this.state.openedMenu && !isMobile()) {
      this.closeMenu()
    }
  }

  handleOrientationChange = () => {
    // iPad does not trigger resize event after orientation change
    if (navigator.platform === 'iPad') {
      setTimeout(() => this.handleResize(), 250)
    }
  }

  disableScroll () {
    // Scroll is basically disabled by preventing overflow on body.
    // To prevent iOS to bounce the page and thence scroll the page behind the burger menu,
    // we have to prevent overflow on HTML.
    // Unfortunatly the side effect of this is to reset the scroll position to top.

    // So we backup the scroll position...
    this.scrollTop = window.pageYOffset
    this.scrollDisabled = true

    // ... and *after* the burger menu animation (500ms) is done, we freeze the background
    // If we do it immediatly, the user would see the background scroll while the menu opens. Quite ugly.
    setTimeout(() => {
      if (this.scrollDisabled) {
        // Actually freeze the background only if the burger menu is still open
        $('html,body').css('overflow', 'hidden')
      }
    }, 500)
  }

  enableScroll () {
    this.scrollDisabled = false
    $('html,body').css('overflow', '')
    $('#header-on-react').css('position', '')
    window.scrollTo(0, this.scrollTop)
  }

  shouldShowMegaMenu () {
    const { pageType, megaMenuContent } = this.props
    return !['purchases#new', 'purchases#confirmation', 'changes#edit'].includes(pageType) && megaMenuContent
  }

  isSearchMenuItemHidden () {
    const { pageType } = this.props
    return ['pages#homepage', 'purchases#new'].includes(pageType)
  }

  isOneparkProMenuShown () {
    const { pageType } = this.props
    return pageType.split('#')[0] !== 'purchases'
  }

  render () {
    const { railsContext, pageType, megaMenuContent } = this.props
    const { searchOpened, headerTraveling, openedMenu, summaryOpened, displaySummary, isProMenuItemHovered } = this.state
    const alwaysScroll = this.shouldHeaderScrollWithPage()
    const prefix = localePrefix.call(this)
    const shouldShowMegaMenu = this.shouldShowMegaMenu()
    const headerClasses = classNames('header', {
      'is-always-scroll': alwaysScroll,
      'is-search-open': searchOpened,
      'is-summary-open': (displaySummary && summaryOpened && !searchOpened),
      'is-subscription-enabled': railsContext.currentSite.display_subscription_search,
      'is-traveling': headerTraveling && !alwaysScroll,
      'header--with-mega-menu': shouldShowMegaMenu,
    })

    const headerSearchClasses = classNames('header__search', {
      'header__search--open': searchOpened
    })

    const headerSearchSummaryClasses = classNames('header__search-summary', {
      'header__search-summary--open': !searchOpened
    })

    return (
      <div className='react-component'>
        <div id='header-on-react' className={headerClasses}>
          <nav className='header__nav'>
            <a className='header__nav__logo' href={`${prefix}/`}>
              <SvgImage svg={LogoSvg} />
            </a>
            <HeaderRight
              {...this.props}
              isSearchMenuItemHidden={this.isSearchMenuItemHidden()}
              isOneparkProMenuShown={this.isOneparkProMenuShown()}
              searchOpened={searchOpened}
              openSearchCallback={this.handleClickSearch}
              onNavItemClick={this.handleNavItemClick}
              openedMenu={openedMenu}
              isProMenuItemHovered={isProMenuItemHovered}
              hoverProMenuItem={(isHovered) => { this.setState({ isProMenuItemHovered: isHovered }) }}
            />

            {shouldShowMegaMenu &&
              <MegaMenu
                pageType={pageType}
                searchBarActive={searchOpened}
                openSearchCallback={this.handleClickSearch}
                isSearchMenuItemHidden={this.isSearchMenuItemHidden()}
                megaMenuContent={megaMenuContent}
              />
            }
          </nav>

          {!this.isSearchMenuItemHidden() &&
            <div>
              <div id='search-wrapper' className={headerSearchClasses}>
                <HeaderSearchTabs onToggleHeaderSearchSummary={this.handleClickSearch} />
              </div>
              {displaySummary && summaryOpened &&
                <div id='summary-wrapper' className={headerSearchSummaryClasses}>
                  <HeaderSearchSummary onClickSummary={this.handleMobileSearchSummaryClick} />
                </div>
              }
            </div>
          }
        </div>

        <SideMenu
          openedMenu={openedMenu}
          onSideMenuClose={this.handleNavItemClick}
          onSearchClick={this.handleMobileSearchClick}
          onNavItemClick={this.handleNavItemClick}
          {...this.props}
        />
      </div>
    )
  }
}

export default HeaderComponent
