import React, { Component } from 'react'
import { getMapProvider } from '../../../../libs/mapProvider'
import { MIN_PARKS_ZOOM } from './MapView'
import Markers from './Markers'
import NavigationControl from './NavigationControl'
import ClusterWrapper from './ClusterWrapper'
import MainMarker from './MainMarker'

const defaultHeight = '100%'
const defaultWidth = '100%'

class Map extends Component {
  constructor (props) {
    super(props)
    const { defaultCenter, defaultZoom } = props
    const { lat, lng } = defaultCenter

    this.state = {
      isClientReady: false,
      latitude: lat,
      longitude: lng,
      zoom: defaultZoom
    }
  }

  componentDidMount () {
    const { onMapMounted } = this.props
    this.setState({
      isClientReady: true
    }, () => {
      if (onMapMounted) {
        onMapMounted()
      }
    })
  }

  onViewportChange = (viewport) => {
    const { latitude, longitude, zoom } = viewport
    this.setState({
      latitude,
      longitude,
      zoom
    })
  }

  handleClickPinGroup = ({ latitude, longitude }) => {
    const { onZoomChanged } = this.props
    this.setState({
      latitude,
      longitude,
      zoom: MIN_PARKS_ZOOM
    }, () => {
      onZoomChanged()
    })
  }

  onNavigationControlViewportChange = (viewport) => {
    const { latitude, longitude, zoom } = viewport
    this.setState({
      latitude,
      longitude,
      zoom
    }, () => this.props.onZoomButtonsClick())
  }

  handleViewportChangeByNavigationControl = (viewport) => {
    this.onNavigationControlViewportChange(viewport)
  }

  render () {
    const {
      activeInfoWindow,
      activeMarker,
      clusters,
      firstParkIsPrimary,
      onIdle,
      onMapClick,
      onMapMounted,
      onMarkerClick,
      onMarkerMouseOut,
      onMarkerMouseOver,
      onZoomChanged,
      parks,
      showClusters,
      showMainMarker,
      defaultCenter
    } = this.props
    const { isClientReady, latitude, longitude, zoom } = this.state

    return (
      isClientReady
        ? (
          React.createElement(
            getMapProvider().default,
            {
              controller: (() => {
                const MapControllerCustom = require('./MapControllerCustom').default
                return new MapControllerCustom({
                  onMapClick,
                  onUserMapNavigation: onIdle,
                  onZoomChanged
                })
              })(),
              ref: (ref) => {
                this.map = ref
                return onMapMounted(ref)
              },
              height: defaultHeight,
              latitude,
              longitude,
              mapboxApiAccessToken: process.env.MAPBOX_API_KEY,
              mapStyle: process.env.MAPBOX_MAPSTYLE,
              onViewportChange: this.onViewportChange,
              width: defaultWidth,
              zoom
            },
            <>
              {showMainMarker && (<MainMarker mapRef={this.map} position={defaultCenter} />)}
              <ClusterWrapper
                mapRef={this.map}
                showClusters={showClusters}
                clusters={clusters}
              />
              <Markers
                activeInfoWindow={activeInfoWindow}
                activeMarker={activeMarker}
                clusters={clusters}
                firstParkIsPrimary={firstParkIsPrimary}
                mapRef={this.map}
                onMarkerClick={onMarkerClick}
                onMarkerMouseOut={onMarkerMouseOut}
                onMarkerMouseOver={onMarkerMouseOver}
                parks={parks}
                showClusters={showClusters}
              />
              <NavigationControl onViewportChange={this.handleViewportChangeByNavigationControl} />
            </>
          )
        )
        : null
    )
  }
}

export default Map
