import React, { Component } from 'react'
import URLSearchParams from 'url-search-params'
import { Stepper } from './Stepper'

import SvgImage from 'Components/SvgImage'
import LogoSvg from '../../../../../../assets/images/onepark-header-logo.svg'

class RegistrationBreadcrumb extends Component {
  handleBack = () => { history.back() }

  editModeWrapperrClasses = 'registration__breadcrumb-wrapper registration__breadcrumb-wrapper--aligned-up ' +
    'registration__breadcrumb-wrapper--no-padding registration__breadcrumb-wrapper--border-bottom-on-tablet ' +
    'registration__breadcrumb-wrapper--hidden-on-tablet'

  render () {
    const numberOfSteps = 1 + this.props.previousSteps.length + this.props.futureSteps.length
    let editMode = false
    if (this.props.location) {
      const urlParams = new URLSearchParams(this.props.location.split('?')[1])
      editMode = urlParams.get('action') === 'edit'
    }

    return (
      <>
        {editMode &&
          <div className={this.editModeWrapperrClasses}>
            <div className='registration__breadcrumb registration__breadcrumb--centered'>
              <div className='registration__logo registration__logo--blue registration__logo--hidden-on-tablet'>
                <SvgImage svg={LogoSvg} />
              </div>
            </div>

            <button className='header-purchase__edit-close' onClick={this.props.handleCancel}
              data-automation-id='registration__breadcrumb-close-button'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>}

        {!editMode &&
          <div className={`registration__breadcrumb ${this.props.breadcrumbClasses || ''}`}>
            <div className={`registration__breadcrumb-wrapper ${this.props.additionalClasses || ''}`}>
              <div className={`registration__logo registration__logo--blue ${this.props.insideLogoClasses || ''}`}>
                <SvgImage svg={LogoSvg} />
              </div>

              {numberOfSteps > 1 &&
                <Stepper
                  previousSteps={this.props.previousSteps}
                  activeStepId={this.props.activeStepId}
                  futureSteps={this.props.futureSteps}
                  stepperClasses={this.props.stepperClasses}
                />
              }
            </div>
          </div>}
      </>
    )
  }
}

export default RegistrationBreadcrumb
