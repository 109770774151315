import React from 'react'
import { FormattedMessage } from 'react-intl'

import CloseIcon from '../../../../../assets/images/icon-close.svg'
import SvgImage from '../../components/SvgImage'
import HeaderSearchTabs from '../HeaderSearchTabs'

const SideSearch = ({ onSideMenuClose, onSearchClick }) => {
  return (
    <div className='side-menu'>
      <div className='side-menu__title'>
        <span> <FormattedMessage id='pages.header.search_panel_title' /> </span>
        <SvgImage svg={CloseIcon} onClick={() => onSideMenuClose(null)}></SvgImage>
      </div>

      <div>
        <HeaderSearchTabs onToggleHeaderSearchSummary={onSearchClick} />
      </div>
    </div>
  )
}

export default SideSearch
