import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'

import * as SearchActions from 'Actions/SearchActions'
import * as FilterActions from 'Actions/FilterActions'
import { FiltersPanel } from 'Components/FiltersPanel'

const DISTANCE_TAB = 'distance'
const PRICE_TAB = 'price'
const PERTINENCE_TAB = 'pertinence'
const REVIEW_TAB = 'review'

class ConnectedMobileFiltersModal extends Component {
  state = {
    sortBy: this.props.sortBy
  }

  handleClickApplyFilters = async (newFilters) => {
    const { sortBy } = this.state
    const { updateSorting, updateParkFilters } = this.props.actions
    await updateSorting(sortBy)
    await updateParkFilters(newFilters)
    this.props.onClose()
  }

  render () {
    const { sortBy } = this.state
    const { showAllFilters, showRelevanceSort } = this.props

    return (
      <>
        <div className='filters-mobile-modal'>
          <div className='filters-mobile-modal__header'>
            <div className='filters-mobile-modal__title'>
              <div className='filters-mobile-modal__title-sorting'>
                <FormattedMessage id='pages.parks.filters.sort_by.title' />
              </div>
              <button className='filters-mobile-modal__title-close-button' onClick={this.props.onClose} aria-label='close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='filters-mobile-modal__switch-buttons-wrapper'>
              <div className='filters-mobile-modal__switch-buttons'>
                { showRelevanceSort &&
                  <div className='col-xs-6'>
                    <button
                      className={'filters-mobile-modal__switch-button' + (sortBy === PERTINENCE_TAB ? ' filters-mobile-modal__switch-buttons--selected' : '')}
                      onClick={() => this.setState({ sortBy: PERTINENCE_TAB })}
                    >
                      <FormattedMessage id='pages.parks.filters.sort_by.pertinence' />
                    </button>
                  </div>
                }
                <div className={showRelevanceSort ? 'col-xs-6' : 'col-xs-4 p-0'}>
                  <button
                    className={'filters-mobile-modal__switch-button' + (sortBy === DISTANCE_TAB ? ' filters-mobile-modal__switch-buttons--selected' : '')}
                    onClick={() => this.setState({ sortBy: DISTANCE_TAB })}
                  >
                    <FormattedMessage id='pages.parks.filters.sort_by.distance' />
                  </button>
                </div>
                <div className={showRelevanceSort ? 'col-xs-6' : 'col-xs-4 p-0'}>
                  <button
                    className={'filters-mobile-modal__switch-button' + (sortBy === PRICE_TAB ? ' filters-mobile-modal__switch-buttons--selected' : '')}
                    onClick={() => this.setState({ sortBy: PRICE_TAB })}
                  >
                    <FormattedMessage id='pages.parks.filters.sort_by.price' />
                  </button>
                </div>
                <div className={showRelevanceSort ? 'col-xs-6' : 'col-xs-4 p-0'}>
                  <button
                    className={'filters-mobile-modal__switch-button' + (sortBy === REVIEW_TAB ? ' filters-mobile-modal__switch-buttons--selected' : '')}
                    onClick={() => this.setState({ sortBy: REVIEW_TAB })}
                  >
                    <FormattedMessage id='pages.parks.filters.sort_by.review' />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <FiltersPanel isMobile handleApply={this.handleClickApplyFilters} showAllFilters={showAllFilters} />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  search: { sortBy, params: { placeId }, parks }
}) => {
  return { sortBy, parks, placeId }
}

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...SearchActions, ...FilterActions }, dispatch) })

export const MobileFiltersModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedMobileFiltersModal)
