import * as bookingTypes from '../types/BookingDataTypes'
import * as nearbyTypes from '../types/NearbyTypes'

const initialState = {
  parks: [],
  places: []
}

export default function nearbyReducer (state = initialState, action) {
  const { type, parkBestOffers } = action

  switch (type) {
    case bookingTypes.BOOKING_DATA_RESET: {
      const parks = state.parks.map(park => ({ ...park, bestOffer: null, state: null }))
      return { ...state, parks }
    }
    case nearbyTypes.BEST_OFFERS_UPDATE: {
      const parks = state.parks.map(park => {
        const pbo = parkBestOffers.find(pbo => pbo.id === park.id)
        return pbo ? { ...park, bestOffer: pbo.bestOffer, state: pbo.state } : park
      })
      return { ...state, parks }
    }
    default: {
      return state
    }
  }
}
