import React, { Component } from 'react'

import SvgImage from './SvgImage'
import iconInfo from '../../../../../app/assets/images/svg-on-react/icon-info.svg'

import { TooltipContentComponent } from './Tooltip/TooltipContentComponent'

export class TooltipComponent extends Component {
  state = {
    isOpen: this.props.isOpen || false,
    tooltipRef: React.createRef(),
    fitWindow: this.props.fitWindow || false
  }

  componentWillUnmount () {
    if (this.state.isOpen) {
      document.body.removeEventListener('click', this.handleOutsideTooltipClick)
    }
  }

  handleOutsideTooltipClick = (event) => {
    if (this.state.tooltipRef.current && this.state.tooltipRef.current.contains(event.target)) return

    this.handleCloseTooltip()
  }

  handleClickTooltip = (isOpen) => {
    if (isOpen) {
      document.body.addEventListener('click', this.handleOutsideTooltipClick)
    } else {
      document.body.removeEventListener('click', this.handleOutsideTooltipClick)
    }

    this.setState({ isOpen: isOpen })
  }

  handleCloseTooltip = () => {
    this.handleClickTooltip(false)
  }

  render () {
    return (
      <div id='tooltip-op' ref={this.state.tooltipRef} className={this.props.bemModifier ? `tooltip-op--${this.props.bemModifier}` : ''}>
        <div onClick={() => this.handleClickTooltip(!this.state.isOpen)}>
          <SvgImage svg={iconInfo} className='tooltip-op__icon' />
        </div>
        { this.state.isOpen &&
        <TooltipContentComponent
          containerRef={this.props.containerRef}
          fitWindow={this.state.fitWindow}
          message={this.props.message}
          values={this.props.values}
          handleCloseTooltip={this.handleCloseTooltip} />}
      </div>
    )
  }
}

export default TooltipComponent
