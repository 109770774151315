import classNames from 'classnames'
import {
  MAP_PROVIDER_GOOGLE,
  PROVIDER_MAP_WRAPPER_MAPPING,
} from '../constants/map'

export const getMapWrapperComponent = () => (
  PROVIDER_MAP_WRAPPER_MAPPING[process.env.MAP_PROVIDER] ||
  PROVIDER_MAP_WRAPPER_MAPPING[MAP_PROVIDER_GOOGLE]
)()

export const getMarkerLabelClassname = ({
  activeInfoWindow,
  activeMarker,
  firstParkIsPrimary,
  index,
  park,
}) => classNames(
  'map-marker',
  index === 0 && firstParkIsPrimary ? 'primary' : 'secondary',
  [activeInfoWindow, activeMarker].includes(park.id) ? 'active' : undefined,
  park.state && park.state !== 'available' ? 'error' : undefined,
)

export const getMarkerZIndex = (park, index, activeMarker, activeInfoWindow, firstParkIsPrimary) =>
  park.id === activeMarker
    ? 10001
    : park.id === activeInfoWindow
      ? 10000
      : (firstParkIsPrimary && index === 0)
        ? 1000
        : (park.state && park.state !== 'available')
          ? 1
          : 2
