import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactDOM from 'react-dom'
import moment from 'moment'
import FormInput from '../../../components/Form/FormInput'
import FormInputPassword from '../../../components/Form/FormInputPassword'
import Loader from '../../../components/Loader'
import { datalayerPushGTM, datalayerPush } from '../../../helpers/application'
import { localePrefix } from '../../../helpers/authentication'
import { performValidation, performPasswordValidation, genericUserBlankValidation, genericEmailValidation,
  genericNewPasswordValidation, genericConfirmPasswordValidation
} from '../../../helpers/formValidations'
import iconMail from '../../../../../../../app/assets/images/svg-on-react/icon-mail.svg'
import iconUser from '../../../../../../../app/assets/images/svg-on-react/icon-user.svg'
import OpI18n from '../../../components/OpI18n'
import { PasswordRecoveryTunnel } from '../../Connection/components/PasswordRecoveryTunnel'

class RegistrationAccountStepForm extends Component {
  constructor (props) {
    super(props)

    this.state = { ...{
      firstName: '',
      firstNameError: false,
      lastName: '',
      lastNameError: false,
      email: '',
      emailError: false,
      newPassword: '',
      newPasswordError: false,
      matchPasswordError: false,
      confirmPassword: '',
      confirmPasswordError: false,
      loading: false
    },
    ...this.props.defaults }
  }

  componentDidMount () {
    if (this.state.provider === 'facebook') { this.performValidations() }
  }

  performValidations () {
    // Todo: refactor code
    const inputValidations = [
      performValidation.call(this, genericUserBlankValidation, this.state.firstName, 'firstNameError', 'first_name'),
      performValidation.call(this, genericUserBlankValidation, this.state.lastName, 'lastNameError', 'last_name'),
      performValidation.call(this, genericEmailValidation, this.state.email, 'emailError')
    ]

    if (this.state.provider !== 'facebook') {
      inputValidations.push(performPasswordValidation.call(this, genericNewPasswordValidation, this.state.newPassword, ['newPasswordError', 'confirmPasswordError']))
      inputValidations.push(performPasswordValidation.call(this, genericConfirmPasswordValidation, this.state.confirmPassword, ['confirmPasswordError', 'newPasswordError']))
    }

    return inputValidations
  }

  submitSignUp = () => {
    const { firstName, lastName, email, newPassword, confirmPassword } = this.state
    const { source } = this.props
    const prefix = localePrefix.call(this)

    this.setState({ loading: true })
    return fetch(`${prefix}/users`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        user: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: newPassword,
          password_confirmation: confirmPassword,
          terms_of_service: '1'
        }
      })
    }).then((response) => {
      return response.json()
    }).then((json) => {
      const errors = json.errors
      if (errors) {
        this.setState({
          emailError: errors.email[0],
          loading: false
        })
        for (const error in json.errors) {
          if (error !== 'email') {
            datalayerPushGTM('form-tracking', 'Forms', 'Registration', json.errors[error][0])
          } else {
            datalayerPushGTM('form-tracking', 'Forms', 'Registration', errors.email[0])
          }
        }
      } else {
        datalayerPushGTM('form-tracking', 'Users', 'signup/success/email', json.id)

        const pushParams = {
          event: 'sign_up',
          ep_method: 'email',
          up_language: (window._oneparkDatas.up_language || null),
          up_locale: (window._oneparkDatas.up_locale || null),
          up_user_id: json.google_analytics_user_id
        }
        datalayerPush(pushParams)

        window.location.href = `${prefix}/member/billing-informations?origin=${source}`
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const inputValidations = this.performValidations()

    if (!inputValidations.includes(false)) {
      this.submitSignUp(this.state)
    }
  }

  isEmailTakenError = (emailError) => {
    return emailError && emailError.indexOf('{email_taken}') >= 0
  }

  handleClickResetPassword = () => {
    const { railsContext, source } = this.props
    const { email } = this.state

    window.Cookies.set('password_recovery_email', email, { expires: moment().add(15, 'minutes').toDate() })

    ReactDOM.render(
      (<OpI18n {...railsContext}>
        <PasswordRecoveryTunnel
          completedCallback={this.unmountPasswordTunnel}
          railsContext={railsContext}
          source={source}
          email={email}
        />
      </OpI18n>),
      document.getElementById('password-recovery-modal-body')
    )
  }

  render () {
    const { firstName, firstNameError, lastName, lastNameError,
      email, emailError, newPassword, newPasswordError, confirmPassword,
      confirmPasswordError, loading, provider, source } = this.state
    const { cguLink } = this.props
    const prefix = localePrefix.call(this)

    return (
      <form onSubmit={this.handleSubmit} action={`${prefix}/users`} method='post' noValidate>

        <FormInput
          errorMessage={firstNameError}
          inputValue={firstName}
          id='user_first_name'
          setCallback={(value) => this.setState({ firstName: value })}
          validateCallback={(value) => performValidation.call(this, genericUserBlankValidation, value, 'firstNameError', 'first_name')}
          svgIcon={iconUser}
          placeholderKey='authentication.user.first_name'
        >
          <input type='text' className='form-control' />
        </FormInput>

        <FormInput
          errorMessage={lastNameError}
          inputValue={lastName}
          id='user_last_name'
          setCallback={(value) => this.setState({ lastName: value })}
          validateCallback={(value) => performValidation.call(this, genericUserBlankValidation, value, 'lastNameError', 'last_name')}
          svgIcon={iconUser}
          placeholderKey='authentication.user.last_name'
        >
          <input type='text' className='form-control' />
        </FormInput>

        <FormInput
          errorMessage={this.isEmailTakenError(emailError) ? !!emailError : emailError}
          inputValue={email}
          id='user_email'
          setCallback={(value) => this.setState({ email: value })}
          validateCallback={(value) => performValidation.call(this, genericEmailValidation, value, 'emailError', null)}
          svgIcon={iconMail}
          placeholderKey='authentication.user.email'
        >
          <input type='email' className='form-control' />
        </FormInput>

        {this.isEmailTakenError(emailError) &&
          <div className='email-taken-error'>
            <span>{emailError.split('{email_taken}')[0]}</span>
            <span>
              <span className='hidden-md hidden-lg'>
                <a href={`${prefix}/forgotten-password?origin=${source}`}
                  className='link--secondary reset-password-link'
                  onClick={this.handleClickResetPassword}>
                  {emailError.split('{email_taken}')[1]}
                </a>
              </span>
              <span className='hidden-sm hidden-xs'>
                <a href='#'
                  aria-label='close'
                  data-dismiss='modal'
                  data-target='#password-recovery-modal'
                  data-toggle='modal'
                  className='link--secondary reset-password-link'
                  onClick={this.handleClickResetPassword}>
                  {emailError.split('{email_taken}')[1]}
                </a>
              </span>.
            </span>
          </div>
        }

        { provider !== 'facebook' &&
        <div className='row'>
          <div className='col-xs-12 col-sm-6'>
            <FormInputPassword
              errorMessage={newPasswordError}
              inputValue={newPassword}
              id='user_password'
              setCallback={(value) => this.setState({ newPassword: value })}
              validateCallback={(value) => {
                performPasswordValidation.call(this, genericNewPasswordValidation, value, ['newPasswordError', 'confirmPasswordError'])
              }}
              placeholderKey='authentication.user.password'
            />
          </div>
          <div className='col-xs-12 col-sm-6'>
            <FormInputPassword
              errorMessage={confirmPasswordError}
              inputValue={confirmPassword}
              id='user_confirm_password'
              setCallback={(value) => this.setState({ confirmPassword: value })}
              validateCallback={(value) => {
                performPasswordValidation.call(this, genericConfirmPasswordValidation, value, ['confirmPasswordError', 'newPasswordError'])
              }}
              placeholderKey='authentication.user.confirm_password'
            />
          </div>
        </div>
        }

        <div className='form-group text-small'>
          <div className='col-sm-12 p-0 m-top-10 text--grey-darker'>
            <FormattedMessage
              id='authentication.registration.account.accept_term'
              values={{ cgu: (<a href={cguLink} target='_blank' className='link--secondary'>
                <FormattedMessage id='authentication.registration.account.cgu' />
              </a>)
              }}
            />
          </div>
        </div>

        <div className='text-center p-0'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {loading && <Loader overlay white />}
            <span className={loading ? 'invisible' : ''}>
              <FormattedMessage
                id={`authentication.registration.account${this.state.provider === 'facebook' ? '_finalize' : ''}.signup`}
              />
            </span>
          </button>
        </div>
      </form>
    )
  }
}

export default RegistrationAccountStepForm
