import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const OnMapSection = (props) => {
  const { className, onClick } = props

  return (
    <section
      id='park-map-places'
      className={`map-section ${className}`}
      onClick={onClick}
    >
      <FormattedMessage id='pages.park.map_places.title' />
    </section>
  )
}

OnMapSection.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default OnMapSection
