import { defineMessages } from 'react-intl'

export const processPositionSuccessResponse = ({
  onChange,
  position,
  prefix
}) => {
  const coords = position.coords
  if (!isNaN(coords.latitude) && !isNaN(coords.longitude)) {
    const params = `additional_params[latlng]=${coords.latitude},${coords.longitude}&additional_params[sensor]=true`
    return fetch(`${prefix}/google_services/geocode?${params}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
      .then((response) => response.json())
      .then((json) => fetch(json.geocode_route, { method: 'GET' }))
      .then((response) => response.json())
      .then((json) => {
        const hasResults = json.status === 'OK' && json.results && json.results.length

        if (hasResults) {
          onChange({
            address: json.results[0].formatted_address,
            addressCompleted: true,
            place: false
          })
        }
      })
  }

  return Promise.reject(new Error('Cannot get user latitude / longitude'))
}

export const processPositionErrorResponse = ({ err, intl }) => {
  let error
  switch (err.code) {
    case err.PERMISSION_DENIED:
      error = {
        type: 'PERMISSION_DENIED',
        error_title: {
          id: 'pages.homepage.geolocation_errors.access_denied.title'
        },
        error_content: {
          id: 'pages.homepage.geolocation_errors.access_denied.content'
        }
      }
      break
    case err.POSITION_UNAVAILABLE:
      error = {
        type: 'POSITION_UNAVAILABLE'
      }
      break
    case err.TIMEOUT:
      error = {
        type: 'TIMEOUT',
        error_title: {
          id: 'pages.homepage.geolocation_errors.other.title'
        },
        error_content: {
          id: 'pages.homepage.geolocation_errors.other.content'
        }
      }
      break
    default:
      error = {
        type: 'UNKNOWN',
        error_title: {
          id: 'pages.homepage.geolocation_errors.unknown.title'
        },
        error_content: {
          id: 'pages.homepage.geolocation_errors.unknown.content'
        }
      }
      break
  }
  const messages = defineMessages(error)
  const formattedTitle = intl ? intl.formatMessage(messages.error_title) : messages.error_title
  const formattedContent = intl ? intl.formatMessage(messages.error_content) : messages.error_content

  return {
    error,
    formattedContent,
    formattedTitle
  }
}
