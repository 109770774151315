import PropTypes from 'prop-types'
import React from 'react'
import { formatNumber } from 'libphonenumber-js'
import phoneExamples from 'libphonenumber-js/examples.mobile.json'
import Select from 'react-select'
import { FormattedMessage } from 'react-intl'

export default class FormInputPhone extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    validateCallback: PropTypes.func,
    setCallback: PropTypes.func
  }

  /**
   * @param props - Comes from your rails view.
   * @param _railsContext - Comes from React on Rails
   */
  constructor (props) {
    super(props)

    this.state = {
      phoneNumberValue: this.props.value || '',
      prefixValue: this.props.prefixValue || ''
    }
  }

  handleSelectChange (newPrefix) {
    const prefixIdIso = newPrefix.split('-')
    const newPrefixId = prefixIdIso[0]
    const newPrefixIso = prefixIdIso[1]
    this.props.setPrefixIdCallback(newPrefixId)
    this.props.setPrefixIsoCallback(newPrefixIso)
    this.setState({ prefixValue: newPrefix })
    this.callValidationCallback(newPrefixIso, 0)
  }

  handlePhoneInputChange (e) {
    this.props.setCallback(e.target.value)
    this.setState({ phoneNumberValue: e.target.value })
  }

  callValidationCallback (e, position) {
    if (position) {
      this.props.validateCallback([this.state.prefixValue.split('-')[1], this.state.phoneNumberValue])
    } else {
      this.props.validateCallback([e, this.state.phoneNumberValue])
    }
  }

  render () {
    const { id, countrySelect, placeholderKey } = this.props
    const { prefixValue, phoneNumberValue } = this.state
    const prefixValueIso = prefixValue.split('-')[1]
    const placeholder = formatNumber({ country: prefixValueIso, phone: phoneExamples[prefixValueIso] }, 'National').split(' ').join('')
    const selectOptions =
      countrySelect.map((country, index) => {
        return { label: (<div className='input-prefix-flag'>
          <div className={country.iso.toLowerCase()} />
          <span className='phone-input-prefix-name'>{country.name}</span>
                          &nbsp;
          <span className='phone-input-prefix-number'>{country.phone_prefix}</span>
        </div>),
        value: `${country.id}-${country.iso}` }
      })

    return (
      <div className='phone-input-op row'>
        <div className='phone-input-prefix'>
          <div className='select-op'>
            <Select
              clearable={false}
              searchable={false}
              simpleValue
              id={id + '_phone_country_id'}
              name={id + '_phone_country_id'}
              options={selectOptions}
              value={prefixValue}
              onChange={(newValue) => this.handleSelectChange(newValue)}
            />
          </div>
        </div>
        <div className='phone-input-number'>
          <span className={`floating-label ${phoneNumberValue ? 'floating-label--top' : ''}`}>
            {phoneNumberValue ? (
              <span>
                <FormattedMessage id={placeholderKey} />
                {` (Ex: ${placeholder} )`}
              </span>
            ) : placeholder}
          </span>
          <input className='form-control'
            id={id + '_mobile_phone'}
            name={id + '_mobile_phone'}
            onBlur={(e) => this.callValidationCallback(e, 1)}
            onChange={(e) => this.handlePhoneInputChange(e)}
            type='tel'
            value={phoneNumberValue}
            data-hasvalue={phoneNumberValue ? 'true' : 'false'} />
        </div>
      </div>
    )
  }
}
