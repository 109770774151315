import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import {
  defaultPaymentMethods,
  paymentMethodsByCountry,
  paymentIconByName
} from '../constants/payment'
import SvgImage from './SvgImage'

const AccountCreditCardPaymentInfo = ({ railsContext }) => {
  const { currentSite: { iso: country } } = railsContext
  const availablePaymentMethods = paymentMethodsByCountry[country] || defaultPaymentMethods

  return (
    <div className='box box-shadow-custom'>
      <h1>
        <FormattedMessage id='pages.credit_card.page_title' />
      </h1>
      <hr />
      <div className='credit-card__container'>
        <div className='credit-card__icon-payment'>
          {availablePaymentMethods.map(name =>
            <div>
              <SvgImage key={name} alt={paymentIconByName[name].alt} src={paymentIconByName[name].src} />
            </div>
          )}
        </div>
        <p>
          <strong>
            <FormattedMessage id='pages.credit_card.register_payment_info' />
          </strong>
        </p>
      </div>
      <div className='clearfix' />
      <p>
        <FormattedHTMLMessage id='pages.credit_card.secure_partner_html' />
      </p>
    </div>
  )
}

export default AccountCreditCardPaymentInfo
