import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import avatarUrl from '../../../../../../assets/images/icon-avatar.svg'
import { localePrefix, renderLoginModal } from '../../../helpers/authentication'

class LoginLink extends React.Component {
  handleButtonClicked = (e) => {
    const { isUserOnMobile, source } = this.props
    if (!isUserOnMobile && source !== 'booking') {
      e.preventDefault()
      this.connectionButtonClicked(e)
    }
  }

  connectionButtonClicked () {
    const { railsContext, source } = this.props
    renderLoginModal(railsContext, source)
  }

  render () {
    const { isUserOnMobile, source, price, refPrice } = this.props
    const prefix = localePrefix.call(this)

    let linkAttributes

    if (isUserOnMobile) {
      linkAttributes = { href: `${prefix}/connect?origin=${source}` }
    } else {
      linkAttributes = {
        href: '',
        onClick: this.handleButtonClicked
      }
    }

    const linkClasses = classNames('js-signin-link', {
      'block-strike-price': (refPrice),
      'o-btn o-btn--primary o-btn--booking': (price && !refPrice)
    })

    return (
      <a {...linkAttributes} className={linkClasses}>
        <div>
          <svg height='20px' width='20px' className='hide-mobile'>
            <use href={`${avatarUrl}#icon-avatar`} />
          </svg>
          <FormattedMessage id='authentication.connection.login.signin' />
        </div>
      </a>
    )
  }
}

export default LoginLink
