import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

class SideAccount extends Component {
  render () {
    const { userInitials, userSignedIn, userFullName, userSpaceLinks, headerLinks } = this.props

    return (
      <div className='side-menu'>
        {userSignedIn
          ? <section className='side-menu__user'>
            <div className='side-menu__title'>
              {userInitials &&
                <span className='side-menu__user--user-initials'>{userInitials}</span>
              }
              <span> {userFullName} </span>
            </div>

            <a className='side-menu__link' href={headerLinks.myProfile}><FormattedMessage id='pages.header.my_profile' /></a>
            {userSpaceLinks // case when user is supervisor or partner
              ? userSpaceLinks.map((link, linkIndex) => <a className='side-menu__link' href={link.url} key={linkIndex}>{link.label}</a>)
              : <>
                <a className='side-menu__link' href={headerLinks.myBookings}><FormattedMessage id='pages.header.my_bookings' /></a>
                <a className='side-menu__link' href={headerLinks.myPaymentDetails}><FormattedMessage id='pages.header.my_payment_details' /></a>
                <a className='side-menu__link' href={headerLinks.myInvoices}><FormattedMessage id='pages.header.my_invoices' /></a>
              </>
            }

            <a className='side-menu__link side-menu__link--highlighted' data-method='delete' href={headerLinks.signOut}>
              <FormattedMessage id='pages.header.log_out' />
            </a>
          </section>

          : <section className='side-menu__login'>
            <a className='side-menu__link side-menu__link--button' href={headerLinks.signIn}>
              <button className='btn btn-primary'> <FormattedMessage id='pages.header.log_in' /> </button>
            </a>

            <div className='side-menu__link side-menu__link--sign-up'>
              <FormattedMessage id='pages.header.sing_up_question' /> <br />
              <a href={headerLinks.signUp}><FormattedMessage id='pages.header.sing_up' /> </a>
            </div>
          </section>
        }
      </div>
    )
  }
}

export default SideAccount
