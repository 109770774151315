import { refreshToken, getClientTokens } from './authentication'

function defaultDeserializer (jsonResponse) {
  return jsonResponse.data.map(entry => transformKeysToCamelCase(entry.attributes))
}

const toCamelCase = string => string.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())

export function transformKeysToCamelCase (object) {
  return Object.keys(object).reduce((newObject, key) => {
    newObject[toCamelCase(key)] = object[key]
    return newObject
  }, {})
}

// It makes an authorized HTTP request to microservices and returns the body.
// If the microservices responds with 401/403, it tries to refresh the access token
// and returns whatever is the result of that second request.
export async function sendRequestToApiRaw (backendUrl, fetchOpts = {}) {
  const clientCredentials = await getClientTokens()
  const response = await fetch(backendUrl, { ...fetchOpts, headers: { 'Authorization': `Bearer ${clientCredentials.access_token}` } })
  if (![401, 403].includes(response.status)) return response

  const freshAccessToken = await refreshToken('client_credentials')
  return fetch(backendUrl, { ...fetchOpts, headers: { 'Authorization': `Bearer ${freshAccessToken}` } })
}

// It makes an authorized HTTP request to microservices.
// If response is ok then we parse the json body, then deserialize it.
// Otherwise we can do some callback with the response.
export async function sendRequestToApi (
  backendUrl, fetchOpts = {}, responseArraySerializer = defaultDeserializer, onError = (_response) => {}
) {
  const response = await sendRequestToApiRaw(backendUrl, fetchOpts)
  if (response.ok) {
    const responseJson = await response.json()
    return responseArraySerializer(responseJson)
  } else {
    const responseJson = await response.json()
    onError(responseJson)

    return []
  }
}
