import * as bookingDataTypes from '../types/BookingDataTypes'
import * as searchTypes from '../types/SearchTypes'
import partition from 'lodash/partition'
import { applyParkFilters } from '../bundles/Onepark/helpers/park'

const initialState = {
  clusters: [],
  parks: [],
  filteredParks: [],
  promotedParks: [],
  orderedParks: [],
  bookableParks: [],
  unbookableParks: [],
  sortBy: null,
  parkFilters: [],
  params: {
    address: null,
    beginDate: null,
    endDate: null,
    lat: null,
    lng: null,
    placeId: null,
    type: 'package'
  },
  nextParams: { // same as params
    address: null,
    beginDate: null,
    endDate: null,
    lat: null,
    lng: null,
    placeId: null,
    type: 'package'
  },
  queryState: 'success',
  parkSummariesById: {}
}

export default function searchReducer (state = initialState, action) {
  switch (action.type) {
    case bookingDataTypes.BOOKING_DATA_RESET: {
      const parks = state.parks.map(park => ({ ...park, bestOffer: null, noOfferReason: null, state: null }))
      return { ...state, ...updatedParks(parks, state.parkFilters, state.sortBy), promotedParks: [], orderedParks: [] }
    }
    case searchTypes.BEST_OFFERS_UPDATE: {
      const parks = state.parks.map(park => {
        const p = action.parks.find(p => p.id === park.id)
        return p ? { ...park, bestOffer: p.bestOffer, noOfferReason: p.noOfferReason, state: p.state } : park
      })
      return { ...state, ...updatedParks(parks, state.parkFilters, state.sortBy) }
    }
    case searchTypes.CLUSTERS_UPDATE: {
      const clusters = [...state.clusters, ...action.clusters.filter(cluster => !state.clusters.find(c => c.id === cluster.id))]
      return { ...state, clusters }
    }
    case searchTypes.PARKS_SEARCH_PENDING: {
      return { ...state, queryState: 'pending' }
    }
    case searchTypes.PARKS_SEARCH_SUCCESS: {
      return { ...state, queryState: 'success' }
    }
    case searchTypes.PARKS_UPDATE: {
      const notUpdatedParks = action.parks.filter(park => !state.parks.find(p => p.id === park.id))
      const parks = state.parks.reduce((parks, park) => {
        const oldPark = action.parks.find(p => p.id === park.id)
        parks.push(oldPark ? { ...oldPark, ...park } : park)
        return parks
      }, notUpdatedParks)
      return { ...state, ...updatedParks(parks, state.parkFilters, state.sortBy) }
    }
    case searchTypes.PARKS_REPLACE: {
      const parks = action.parks.map(park => ({ ...(state.parks.find(p => p.id === park.id) || {}), ...park }))
      return { ...state, ...updatedParks(parks, state.parkFilters, state.sortBy) }
    }
    case searchTypes.SORTING_PARAMETERS_UPDATE: {
      return { ...state, sortBy: action.sortBy, ...updatedParks(state.parks, state.parkFilters, action.sortBy), promotedParks: [], orderedParks: [] }
    }
    case searchTypes.NEXT_PARAMS_UPDATE : {
      let nextParams = { ...state.nextParams }
      if (action.params.address) {
        nextParams = { ...nextParams, placeId: null, lat: null, lng: null }
      }
      nextParams = { ...nextParams, ...action.params }
      return { ...state, nextParams }
    }
    case searchTypes.PARAMS_UPDATE : {
      let params = { ...state.params }
      if (action.params.address) {
        params = { ...params, placeId: null, lat: null, lng: null }
      }
      params = { ...params, ...action.params }
      return { ...state, params, nextParams: params }
    }
    case searchTypes.PARK_FILTERS_UPDATE:
      const parkFilters = action.payload
      const filteredParks = applyParkFilters(state.parks, parkFilters)
      const [bookableParks, unbookableParks] = partionedByBookability(filteredParks)

      return { ...state, bookableParks: bookableParks, unbookableParks: unbookableParks, filteredParks: filteredParks, parkFilters: parkFilters }
    default: {
      return state
    }
  }
}

const partionedByBookability = parks => partition(parks, park => park.bestOffer)

const sort = (parks, sortBy) => {
  if (sortBy === 'review') {
    return sortByReview(parks)
  }

  return sortBy === 'distance' ? sortByDistance(parks) : sortByPrice(parks)
}

const updatedParks = (parks, filters, sortBy) => {
  const sortedParks = sort(parks, sortBy)
  const filteredParks = applyParkFilters(sortedParks, filters)
  const [bookableParks, unbookableParks] = partionedByBookability(filteredParks)

  return {
    parks: sortedParks,
    filteredParks: filteredParks,
    bookableParks: bookableParks,
    unbookableParks: unbookableParks
  }
}

function sortByPrice (parks) {
  const bookable = parks
    .filter(park => park.bestOffer)
    .sort((p1, p2) => (p1.bestOffer.amount.totalWithoutOptions - p2.bestOffer.amount.totalWithoutOptions) || (p1.distance.distance - p2.distance.distance))
  const unbookable = parks
    .filter(park => !park.bestOffer)
    .sort((p1, p2) => p1.distance.distance - p2.distance.distance)
  return [...bookable, ...unbookable]
}

function sortByDistance (parks) {
  const bookable = parks
    .filter(park => park.bestOffer)
    .sort((p1, p2) => (p1.distance.distance - p2.distance.distance) || (p1.bestOffer.amount.totalWithoutOptions - p2.bestOffer.amount.totalWithoutOptions))
  const unbookable = parks
    .filter(park => !park.bestOffer)
    .sort((p1, p2) => p1.distance.distance - p2.distance.distance)
  return [...bookable, ...unbookable]
}

function sortByReview (parks) {
  const bookable = parks
    .filter(park => park.bestOffer)
    .sort((p1, p2) => p2.rating - p1.rating || p2.ratingCount - p1.ratingCount || p2.commission - p1.commission || p1.typologySortingRating - p2.typologySortingRating)
  const unbookable = parks
    .filter(park => !park.bestOffer)
    .sort((p1, p2) => p1.distance.distance - p2.distance.distance)
  return [...bookable, ...unbookable]
}
