import chunk from 'lodash/chunk'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import NearbyPlace from './NearbyPlace'

const NearbyPlacesSection = ({
  places,
  className,
  inline,
  placeNameTag,
  imgWidthDesktop,
  imgHeightDesktop,
  imgWidthMobile,
  imgHeightMobile
}) => places && places.length > 0 && (
  <section id='park-nearby-places' className={`nearby-section ${className}`}>
    <FormattedMessage id='pages.park.nearby_places.title' />

    {chunk(places, 2).map((pair, i) => (
      <div className='row hidden-xs hidden-sm' key={i}>
        {pair.map((place, j) => (
          <div key={j} className='col-md-6'>
            <NearbyPlace key={j} place={place} inline={inline} placeNameTag={placeNameTag} imgWidth={imgWidthDesktop} imgHeight={imgHeightDesktop} />
          </div>
        ))}
      </div>
    ))}

    <div className='nearby-section__slider hidden-md hidden-lg'>
      {places.map((place, i) => (
        <NearbyPlace key={i} place={place} inline={inline} placeNameTag={placeNameTag} imgWidth={imgWidthMobile} imgHeight={imgHeightMobile} />
      ))}
    </div>
  </section>
)

const mapStateToProps = ({ nearby: { places } }) => ({ places })

export default connect(mapStateToProps)(NearbyPlacesSection)
