import { isValidNumber, parseNumber } from 'libphonenumber-js'
import { datalayerPushGTM, defineAction } from '../helpers/application'
import { COUNTRY_LICENSE_PLATE_REGEX, LICENSE_PLATE_SAMPLES, OTHER_COUNTRY, VEHICLE_MODEL_FORMAT_REGEX } from 'Components/VehicleInformation/constants'
import moment from 'moment'

// Checks if str is a valid email
export function isStrAnEmail (str) {
  const reg = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(str)
}

// Checks if str is a valid card number
export function isCorrectLengthCardNumber (str) {
  const reg = /^\d{16}$/
  return reg.test(str)
}

// Checks if str is a valid expiry date for credit card
export function isCorrectDateCard (str) {
  const reg = /(0[1-9]|1[0-2])\/[0-9]{2}/
  const correctFormat = reg.test(str)
  if (!correctFormat) { return false }
  const month = str.split('/')[0]
  const year = str.split('/')[1]
  const currentYear = new Date().getFullYear().toString().substr(-2)
  const currentMonth = new Date().getMonth()
  return !(year < currentYear || (year === currentYear && month < currentMonth))
}

// Checks if str is a valid cryptogram
export function isCorrectLengthCryptogram (str) {
  const reg = /^[0-9]{3,4}$/
  return reg.test(str)
}

// Checks if phone number is valid
export function isCorrectPhoneNumber (phoneNumber) {
  const [prefix, number] = phoneNumber
  const phoneNumberProto = parseNumber(number, prefix)
  return phoneNumberProto.phone && isValidNumber(phoneNumberProto)
}

// Checks if marital status has been selected
export function isCorrectTitle (value) {
  const valid = (value !== '')
  return valid
}

// Array of validations
const validationFunctions = {
  presence: (value) => !value || (typeof value === 'string' && value.match(/^\s+$/)),
  phone_presence: (value) => !value[1],
  email: (value) => !isStrAnEmail(value),
  too_short: (value) => value.length < 8,
  match: (value, state) => value !== state.newPassword,
  presence_match: (value, state) => value !== state.confirmPassword && state.confirmPassword,
  digits_length: (value) => !isCorrectLengthCardNumber(value),
  expiry_date: (value) => !isCorrectDateCard(value),
  cvv_length: (value) => !isCorrectLengthCryptogram(value),
  phone_number: (value) => !isCorrectPhoneNumber(value),
  selected: (value) => !isCorrectTitle(value),
  between_dates: (value, state) => !(moment(value).isSameOrAfter(state.disabledBefore) && moment(value).isSameOrBefore(state.disabledAfter)),
  inclusion: (value, state) => !state.allowedValues.includes(value),
  plate_format: (value, _state, props) => !validateLicensePlate(value, props),
  vehicle_model_format: (value, _state, _props) => !validateVehicleModel(value),
  vehicle_model_length: (value, _state, _props) => value.length > 64
}

const validateLicensePlate = (licensePlate, props) => {
  // const { country } = props
  // TODO: to be changed when new requirements appears after [OPDEV-2150]
  return new RegExp(COUNTRY_LICENSE_PLATE_REGEX[null], 'gm').test(licensePlate.trim())
}

const validateVehicleModel = (vehicleModel) => {
  if (vehicleModel === '') {
    return false
  }

  return new RegExp(VEHICLE_MODEL_FORMAT_REGEX, 'gm').test(vehicleModel)
}

// Validates an input
export function performValidation (toValidate, value, stateKey, blankAttributes) {
  for (let i = 0; i < toValidate.length; i++) {
    const validationResult = validationFunctions[toValidate[i].validates](value, this.state, this.props)

    if (validationResult) {
      if (blankAttributes) {
        this.setState({ [stateKey]: (toValidate[i].key + '.' + blankAttributes + '.blank'), [`${stateKey}Values`]: '' })
        datalayerPushGTM('form-tracking', 'Forms', defineAction(), toValidate[i].key + '.' + blankAttributes + '.blank')
      } else {
        let errorMessage = toValidate[i].key

        if (toValidate[i].validates === 'plate_format') {
          if (this.props.country.id === OTHER_COUNTRY) {
            errorMessage = { key: 'purchase.purchase_error.invalid_license_plate_basic' }
          } else {
            errorMessage = { key: toValidate[i].key, values: { examples: LICENSE_PLATE_SAMPLES[this.props.country.id] } }
          }
        }

        this.setState({ [stateKey]: errorMessage })
        datalayerPushGTM('form-tracking', 'Forms', defineAction(), toValidate[i].key)
      }

      return false
    }
  }

  this.setState({ [stateKey]: '' })

  return true
}

export function performPasswordValidation (toValidate, value, stateKey) {
  for (let i = 0; i < toValidate.length; i++) {
    if (validationFunctions[toValidate[i].validates](value, this.state)) {
      if (toValidate[i].validates.includes('match')) {
        this.setState({
          'confirmPasswordError': toValidate[i].key,
          'newPasswordError': true,
          'matchPasswordError': true
        })
        datalayerPushGTM('form-tracking', 'Forms', defineAction(), toValidate[i].key)
      } else {
        this.setState({ [stateKey[0]]: toValidate[i].key })
        datalayerPushGTM('form-tracking', 'Forms', defineAction(), toValidate[i].key)
      }
      return false
    }
  }

  this.setState({ [stateKey[0]]: '' })

  if (this.state.matchPasswordError) {
    this.setState({ [stateKey[1]]: '', 'matchPasswordError': false })
  }

  return true
}

// Generic validation objects
export const genericUserBlankValidation = [
  { validates: 'presence', key: 'authentication.errors.user' }
]

export const genericEmailValidation = [
  { validates: 'presence', key: 'authentication.errors.user.email.blank' },
  { validates: 'email', key: 'authentication.errors.user.email.invalid' }
]

export const genericPasswordValidation = [
  { validates: 'presence', key: 'authentication.errors.user.password.blank' },
  { validates: 'too_short', key: 'authentication.errors.user.password.too_short' }
]

export const genericNewPasswordValidation = [
  { validates: 'presence', key: 'authentication.errors.user.password.blank' },
  { validates: 'too_short', key: 'authentication.errors.user.password.too_short' },
  { validates: 'presence_match', key: 'authentication.errors.user.password_confirmation.confirmation' }
]

export const genericConfirmPasswordValidation = [
  { validates: 'presence', key: 'authentication.errors.user.password_confirmation.blank' },
  { validates: 'match', key: 'authentication.errors.user.password_confirmation.confirmation' }
]

export const genericCardholderValidation = [
  { validates: 'presence', key: 'authentication.errors.user.payment_info.cardholder.blank' }
]

export const genericCardNumberValidation = [
  { validates: 'presence', key: 'authentication.errors.user.payment_info.card_number.blank' },
  { validates: 'digits_length', key: 'authentication.errors.user.payment_info.card_number.length' }
]

export const genericCardExpiryDateValidation = [
  { validates: 'presence', key: 'authentication.errors.user.payment_info.card_expiry_date.blank' },
  { validates: 'expiry_date', key: 'authentication.errors.user.payment_info.card_expiry_date.date' }
]

export const genericCryptogramValidation = [
  { validates: 'presence', key: 'authentication.errors.user.payment_info.cryptogram.blank' },
  { validates: 'cvv_length', key: 'authentication.errors.user.payment_info.cryptogram.length' }
]

export const genericPhoneNumberValidation = [
  { validates: 'phone_presence', key: 'authentication.errors.user.phone_number.blank' },
  { validates: 'phone_number', key: 'authentication.errors.user.phone_number.invalid' }
]

export const genericSelectValidation = [
  { validates: 'selected', key: 'authentication.errors.user.title.blank' }
]

export const genericComingFromValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.coming_from' }
]
export const genericLicensePlateValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.license_plate' },
  { validates: 'plate_format', key: 'purchase.purchase_error.invalid_license_plate' }
]

export const genericVehicleModelValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.vehicle_model' },
  { validates: 'vehicle_model_format', key: 'purchase.travel_informations.errors.vehicle_model_format' },
  { validates: 'vehicle_model_length', key: 'purchase.travel_informations.errors.field_length_limit' }
]

export const genericPassportNumberValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.id_passport_number' }
]

export const genericFlightNumberArrivalValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.flight_number_arrival' }
]

export const genericFlightNumberDepartureValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.flight_number_departure' }
]

export const genericMeetingPlaceArrivalValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.meeting_place_arrival' }
]

export const genericMeetingPlaceDepartureValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.meeting_place_departure' }
]

export const genericPassengerNumberValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.passenger_number' }
]

export const genericTrainNumberExitValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.train_number_exit' }
]

export const genericRealTimeArrivalValidation = [
  { validates: 'presence', key: 'purchase.travel_informations.errors.real_time_arrival' },
  { validates: 'between_dates', key: 'purchase.travel_informations.errors.incorrect_real_time_arrival' }
]

export const genericDiscountCodeValidation = [
  { validates: 'presence', key: 'purchase.discount_partner.errors.discount_code_presence' }
]

export const genericPartnerValidation = [
  { validates: 'presence', key: 'purchase.discount_partner.errors.partner_presence' }
]

export const genericPartnerNumberValidation = [
  { validates: 'presence', key: 'purchase.discount_partner.errors.partner_loyalty_number_presence' }
]

export const stripeTokenValidation = [
  { validates: 'presence', key: 'authentication.errors.user.payment_info.card_number.blank' }
]

export const creditCardBrandValidation = [
  { validates: 'inclusion', key: 'authentication.errors.user.payment_info.card_number.brand' }
]

export const getErrorFieldName = (name) => `${name}Error`
